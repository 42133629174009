import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {ButtonLabel, StudentManagerLink} from '../../index.styled';
import {ComponentProps} from 'react';
import {useNavigate} from 'react-router-dom';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {routes} from '@esgi/main/libs/core';

interface Props {
	onSaveAnyway: VoidFunction;
	onCancel: VoidFunction;
	portalProps?: ComponentProps<typeof Alert>['portalProps'];
	name: string;
}

export function SaveAnywayAlert({onSaveAnyway, onCancel, portalProps, name}: Props) {

	const alertRef = Alert.useRef();
	const navigate = useNavigate();

	return <Alert portalProps={portalProps} modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative}>
		<Alert.Header withBacklight onCloseIconClick={onCancel}>
			<Text>Save a duplicate?</Text>
		</Alert.Header>
		<Alert.Body>
			<GridBox>
				<FlexBox>
					<Text size='medium' color='neutral40'> {name} matches a student that already exists in ESGI. Please go to </Text>
					<StudentManagerLink size='medium' onClick={() => navigate(routes.teacher.studentManager)}>
						Student Manager
					</StudentManagerLink>
				</FlexBox>
				<Text size='medium' color='neutral40'>to view this student if you do not wish to create a duplicate.</Text>
			</GridBox>
		</Alert.Body>
		<Alert.Footer align='center' gapX={3}>
			<Button color='tertiary' onClick={() => onCancel()}>
				<ButtonLabel>Cancel</ButtonLabel>
			</Button>
			<Button onClick={() => {
				onSaveAnyway();
				onCancel();
			}} color='secondary'>
				<ButtonLabel>Create Duplicate Student</ButtonLabel>
			</Button>
		</Alert.Footer>
	</Alert>;
}
