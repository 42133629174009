import React, {useCallback} from 'react';
import {Content, EmptyContentBox, EmptyDataContainer, PlusIconBox} from './index.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {AssignmentsService} from '../../service';
import {AssignmentCard, AssignmentState, EmptyContent} from '@esgi/main/kits/assignments';
import {Assignment} from '../../../types';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {getBodyCardDate} from './utils/get-body-card-date';
import {Skeleton} from './skeleton';
import {useOpenEditAssignment} from '../../../hooks/use-open-edit-assignment';
import {useOpenAssignment} from '../../../hooks/use-open-assignment';
import {getStudentResult} from './utils/get-student-result';
import {dispatchAppEvent} from '@esgillc/events';
import {RemoveAssignmentFromList} from '../../../layout/events';
import {AssignmentStateTab} from '../../../layout/types';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Plus} from '@esgi/ui/icons';

type Props = {
	isInitialized: boolean;
	assignments: Assignment[];
	service: AssignmentsService;
	snackbarRef: React.RefObject<SnackbarManager>;
	selectedAssignmentState: AssignmentStateTab;
	isAssignmentsExist: boolean;
};

export function Body({
	isInitialized,
	assignments,
	service,
	snackbarRef,
	selectedAssignmentState,
	isAssignmentsExist,
}: Props) {
	const handleAssignmentCardClick = useOpenAssignment();
	const openEditAssignment = useOpenEditAssignment();

	const onDeleteAssignment = useCallback(
		({assignmentId, assignmentName}: {assignmentId: Assignment['id']; assignmentName: string}) => {
			service.deleteAssignment(assignmentId);

			dispatchAppEvent(RemoveAssignmentFromList, new RemoveAssignmentFromList(assignmentId));

			snackbarRef.current?.showSnackbar(`${assignmentName} has been successfully deleted.`);
		},
		[service, snackbarRef],
	);

	if (!isInitialized) {
		return <Skeleton />;
	}

	if (!assignments.length) {
		return (
			<EmptyDataContainer>
				<EmptyContent templateText={isAssignmentsExist ? 'No data to display' : undefined}>
					{!isAssignmentsExist && (
						<EmptyContentBox>
							<Text size='small' font='mono' color='neutral40'>
								No assignments to display.
							</Text>
							<GridBox flow='column' gap='2' align='center'>
								<Text size='small' font='mono' color='neutral40'>
									Please select the
								</Text>
								<PlusIconBox>
									<Plus />
								</PlusIconBox>
								<Text size='small' font='mono' color='neutral40'>
									icon to create an assignment.
								</Text>
							</GridBox>
						</EmptyContentBox>
					)}
				</EmptyContent>
			</EmptyDataContainer>
		);
	}

	return (
		<OverlayScrollbarsComponent defer style={{height: 'calc(100% + 0px)'}} options={{scrollbars: {autoHide: 'leave'}}}>
			<Content>
				{assignments.map(({name, posted, created, completed, state, id, students, tests, testSessions}) => (
					<AssignmentCard.Root onClick={() => handleAssignmentCardClick({assignmentId: id})} key={id}>
						<AssignmentCard.Header
							assignmentName={name}
							assignmentState={state}
							onDeleteAssignmentClick={() => onDeleteAssignment({assignmentId: id, assignmentName: name})}
							onEditAssignmentClick={() =>
								openEditAssignment({
									assignmentId: id,
									state: {
										from: 'assignment-list',
									},
								})
							}
							editAssignmentButtonDisabled={state === AssignmentState.Completed || state === AssignmentState.Published}
						/>
						<AssignmentCard.Body
							cardDate={getBodyCardDate({completed, created, posted})}
							studentsCount={students.length}
							testsCount={tests}
							result={getStudentResult({testSessions, tests, students})}
						/>
					</AssignmentCard.Root>
				))}
			</Content>
		</OverlayScrollbarsComponent>
	);
}
