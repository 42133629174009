import {useCallback, useEffect, useMemo, useState} from 'react';
import {Layer} from '@esgillc/ui-kit/core';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ScoreFlow} from './components/flow';
import {SessionResults} from './components/summary';
import {ScoreService} from './service';
import {StudentModel, SubjectModel, TestLauncherData} from '../types';
import {Summary} from './types';
import {Class} from '@esgi/main/libs/store';
import {ShareSyncService} from '../../kit/share-screen';

interface Props {
    launcherData: TestLauncherData,
    subject: Pick<SubjectModel, 'id' | 'type' | 'name'>;
    student: StudentModel;
    studentClass: Class;
    onClosed: () => void;
    onTestHistoryClicked?: () => void;
    onTestSessionDetailsClicked?: () => void;
}

export function ScoreTesting(props: Props) {
	const service = useService(ScoreService);
	const testingModel = useBehaviorSubject(service.testingModel);
	const [loaded, setLoaded] = useState(false);
	const [summary, setSummary] = useState<Summary>();

	const shareSyncService = useMemo(() => {
		if (testingModel) {
			return new ShareSyncService({
				userID: testingModel?.userID,
				testName: testingModel?.testName,
				studentName: testingModel?.studentName,
				isWhiteBackground: testingModel?.isWhiteBackground,
			});
		}
	}, [testingModel]);

	useEffect(() => {
		const {id: subjectId, type: subjectType} = props.subject;
		service.init(props.launcherData.testID, props.student.id, subjectId, subjectType).subscribe({
			next: (r) => setLoaded(true),
			error: () => props.onClosed(),
		});
	}, []);

	const onDoneHandler = (testSessionId, summaryText) => {
		if (summaryText !== null && summaryText.trim() !== '') {
			service.updateSummaryScore(testSessionId, summaryText);
		}
		handleClose();
	};

	const handleClose = useCallback(() => {
		props.onClosed();
		shareSyncService.destroy();
	}, [props, shareSyncService]);

	if (!loaded) {
		return;
	}

	return (
        <Layer>
            {
                !summary && <ScoreFlow
                    onFinished={setSummary}
                    service={service}
                    shareSyncService={shareSyncService}
                    onClosed={handleClose}
                />
            }
            {
                summary && <SessionResults
	                showChart={testingModel.showTestSessionResults}
                    summary={summary}
                    subject={props.subject}
                    student={props.student}
                    testName={testingModel.testName}
                    studentClass={props.studentClass}
                    onDoneClicked={onDoneHandler}
                    launcherData={props.launcherData}
                />
            }
        </Layer>
	);
}
