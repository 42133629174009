import {Question as SelfAssessQuestion, TemplateType} from '@esgi/selfassess';
import moment from 'moment';
import {Moment} from 'moment/moment';
import {SubjectType, TestType} from '@esgi/core/enums';
import {Responses} from 'shared/modules/test-details/api/models';
import {userStorage} from '@esgi/core/authentication';

export class Test {
	id: number;
	starred: boolean;
	hidden: boolean;
	readonly: boolean;
	name: string;
	description: string;
	type: TestType;
	testOwner: string;
	color: string;
	testIDN: string;
	testIntroPageID: number;
	testIntroModifyTicks: number;
	testIntroPregenerated: boolean;
	canEditTestIntroPage: boolean;
	createDate: Moment;
	canUnshare: boolean;
	accessCopy: boolean;
	contentAreaID: number;
	contentArea: string;
	gradeLevelIDs: number[];
	imageQuestionID: number;
	draft: boolean;
	questions: Question[] = [];
	stateStandard: string;
	stateStandardId: number;
	totalPossible: number;
	orderNum: number = 0;
	isWhiteBackground: boolean = false;
	selfAssessQuestions: SelfAssessQuestion[] = [];

	public static FromResponse(response: Responses.ITestInfo): Test {
		const result = new Test();
		const currentUser = userStorage.get();

		result.id = response.testID;
		result.starred = response.starred;
		result.hidden = response.hidden;
		result.readonly = response.readonly;
		result.name = response.name;
		result.description = response.description;
		if (response.type === TestType[TestType.Score]) {
			result.type = TestType.Score;
		} else if (response.type === TestType[TestType.YN]) {
			result.type = TestType.YN;
		} else {
			result.type = TestType.YN;
		}
		result.testOwner = response.testOwner;
		result.color = response.color;
		result.testIDN = response.testIDN;
		result.testIntroPageID = response.testIntroPageID;
		result.testIntroModifyTicks = response.testIntroModifyTicks;
		result.testIntroPregenerated = response.testIntroPregenerated;
		result.canEditTestIntroPage = response.canEditTestIntroPage;
		result.createDate = moment(response.createDate);
		result.canUnshare = response.canUnshare;
		result.accessCopy = response.accessCopy;
		result.contentAreaID = response.contentAreaID;
		result.contentArea = response.contentArea;
		result.gradeLevelIDs = response.gradeLevelIDs ?? [];
		result.imageQuestionID = response.imageQuestionID;
		result.draft = response.draft;
		result.questions = response.questions || [];
		
		if (response.stateStandards) {
			const userStateStandards = response.stateStandards?.filter(({stateID}) => stateID === currentUser.stateID);
			const commonCoreStandards = response.stateStandards?.filter(({stateID}) => stateID === 0);
			result.stateStandard = (userStateStandards.length ? userStateStandards : commonCoreStandards).
				map(({name}) => name).
				join(',');
		} else {
			result.stateStandard = null;
		}

		result.stateStandardId = response.stateStandardId;
		result.totalPossible = response.totalPossible;
		result.isWhiteBackground = response.isWhiteBackground;

		result.selfAssessQuestions = response.selfAssessQuestions?.map(q => ({
			id: q.id,
			bankQuestionID: q.bankQuestionID,
			copiedQuestionID: q.copiedFromQuestionID,
			templateType: TemplateType[q.templateType],
			thumbnailUrl: q.thumbnailUrl,
			...JSON.parse(q.template),
		})) || [];

		return result;
	}
}

export class Question {
	id: number;
	orderNum: number;
	shortTitle: string;
	questionImagePregenerated: boolean;
	questionImageIsProcessing: boolean;
	modifyTicks: number;
	imageQuestion: boolean;
	defStateStandardID?: number;
	defStateStandard: string;
	stateStandardID?: number;
	stateStandard: string;
	defContentAreaID: number;
	contentAreaID: number;
}

export class Subject {
	id: number;
	name: string;
	selected: boolean;
	type: SubjectType;
}

export class ContentArea {
	id: number;
	name: string;
}

export class TestIntro {
	pageId: number;
	testId: number;
	pregenerated: boolean;
	modifyTicks: number;
	readonly: boolean;
	imageIsProcessing: boolean;
}

export enum ViewMode {
	TeacherOneOnOne,
	SelfAssess
}

export interface SelfAssessBankQuestion {
	id: number;
	template: string;
	templateType: string;
	thumbnailUrl: string;
	bankQuestionID?: number;
}

export interface StateStandardModel {
	stateID: number;
	contentAreaID: number;
	gradeLevelID: number;
	domainID: number;
	clusterID: number;
	id: number;
	name: string;
}