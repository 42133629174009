import {Content} from '../../layout/index.styled';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {storage, SubjectTab, useStore} from '@esgi/main/libs/store';
import {SubjectSectionContent} from './components/subject-section-content';
import {useStateService} from './hooks/use-state-service';
import {Header} from './components/header';
import {TestSection} from './components/tests-section';
import {Settings} from './components/settings';
import {ContentBox, PageUntested, Panel} from './index.styled';
import {isNull, noop} from 'underscore';
import {StudentModel, Testing, TestLauncherState} from '@esgi/main/features/assessments';
import {useNavigate} from 'react-router-dom';
import {TestItem} from '@esgi/main/features/untested-students-report';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {TestContentArea} from '@esgi/main/kits/common';
import {Reports} from '@esgi/main/kits/reports';
import {SubjectSection} from 'apps/main/features/untested-students-report/src/kit/subject-section';
import {validateNumberValue} from './utils';

export default function UntestedStudents() {
	const {
		selectedSubjectId,
		activeSubjectTab,
		selectedClassId,
		selectedGroupId,
		sectionsState,
		stateService,
		groupingFilterType,
		showAllStudents,
		lastWeek,
		tests,
		students,
	} = useStateService();

	const navigate = useNavigate();

	const [hasSubjects, setHasSubjects] = useState(false);
	const [selectedSubject, setSelectedSubject] = useState<SubjectTab | null>(null);
	const [testLauncherState, setTestLauncherState] = useState<TestLauncherState & {student: StudentModel} | null>(null);

	const [classes] = useStore(storage.classes);
	const [groups] = useStore(storage.groups);

	const onSelectStudentsPanel = (value: SingleSelectState) => {
		if (!sectionsState) {
			return;
		}
		if (value.classId === null && value.groupId !== null) {
			stateService.setSelectedGroupId(value.groupId);
		}
		if (value.groupId === null && value.classId !== null) {
			stateService.setSelectedClassId(value.classId);
		}
	};

	useEffect(() => {
		if (classes.length && sectionsState) {
			const notEmptyClass = classes.find(({studentIDs}) => studentIDs.length);
			if (notEmptyClass) {
				stateService.setSelectedClassId(notEmptyClass.id);
			}
		}
	}, [classes, stateService, sectionsState]);

	const hasRequiredFileds = useMemo<boolean>(() => {
		return hasSubjects && !!(selectedClassId || selectedGroupId);
	}, [hasSubjects, selectedClassId, selectedGroupId]);

	const headerName = useMemo(() => {
		if (selectedClassId !== null) {
			return classes?.find(x => x.id === selectedClassId)?.name;
		} else if (selectedGroupId !== null) {
			return groups?.find(x => x.id === selectedGroupId)?.name;
		}
		return null;
	}, [selectedGroupId, selectedClassId, classes, groups]);

	const onCloseTestLauncher = useCallback(() => {
		stateService.testsService.forceFetchTests();
		setTestLauncherState((prev) => ({...prev, isOpen: false}));
	}, [stateService.testsService]);

	const onStartTestHandler = (test: TestItem, student: StudentModel) => {
		setTestLauncherState({
			isOpen: true,
			student: student,
			data: {
				contentArea: TestContentArea[test.contentArea],
				testType: test.type,
				testID: test.id,
				studentResult: null,
				analyticsData: null,
				isIncorrectDisabled: test.correct === test.maxScore,
			},
		});
	};

	return <PageUntested>
		<Panel>
			<Reports.ClassLevel.StudentPanel onSelect={onSelectStudentsPanel} hideEmptyClassAndGroup/>
		</Panel>
		<SubjectSection.Root>
			<SubjectSectionContent
				selectedSubjectId={selectedSubjectId}
				setSelectedSubjectId={stateService.setSelectedSubjectId.bind(stateService)}
				activeTab={activeSubjectTab}
				setActiveTab={stateService.setActiveSubjectTab.bind(stateService)}
				setHasSubjects={setHasSubjects}
				setSelectedSubject={setSelectedSubject}
			/>
		</SubjectSection.Root>
		<>
			<Content dataCy='untested-students-content'>
				<ContentBox>
					<Header
						headerName={headerName}
						subject={selectedSubject}
					/>
					<Settings
						groupingValue={groupingFilterType}
						onGroupingValueChange={stateService.setGroupingType.bind(stateService)}
						showAllStudent={showAllStudents}
						onShowAllStudentsChange={stateService.setShowAllStudents.bind(stateService)}
						lastWeek={lastWeek}
						onLastWeekChange={(value) => stateService.setLastWeek(validateNumberValue(value))}
					/>
					<TestSection
						mode={groupingFilterType}
						hasRequiredFields={hasRequiredFileds}
						tests={tests}
						students={students}
						onStartTest={onStartTestHandler}
						showAllStudents={showAllStudents}
					/>
				</ContentBox>
			</Content>
			{testLauncherState?.isOpen && !isNull(selectedClassId || selectedGroupId) && (
				<Testing
					onFlashCardsClicked={() => navigate('/activities/flashcards')}
					onTestSessionDetailsClicked={noop}
					student={testLauncherState.student}
					subject={{
						id: selectedSubject?.id,
						type: selectedSubject?.type,
						name: selectedSubject?.name,
					}}
					studentClass={classes.find(x => x.id === selectedClassId)}
					launcherData={testLauncherState.data}
					onClose={onCloseTestLauncher}
				/>
			)}
		</>
	</PageUntested>;
}
