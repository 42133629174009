import {BehaviorSubject, zip} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {storage, Class, Group, Student} from '@esgi/main/libs/store';

export class StudentService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public classes$ = new BehaviorSubject<Class[]>([]);
	public groups$ = new BehaviorSubject<Group[]>([]);
	public students$ = new BehaviorSubject<Student[]>([]);
	private storageClasses = storage.classes();
	private storageGroups = storage.groups();
	private storageStudents = storage.students();

	constructor() {
		super();

		zip(
			this.storageClasses.get(),
			this.storageGroups.get(),
			this.storageStudents.get()
		).subscribe(([classes, groups, students]) => {
			this.classes$.next(classes);
			this.groups$.next(groups);
			this.students$.next(students);
			this.loaded$.next(true);
		});
	}

	public override dispose() {
		this.storageClasses.dispose();
		this.storageGroups.dispose();
		this.storageStudents.dispose();
	}

	public get(studentId: Student['id']) {
		return this.students$.value.find(({id}) => id === studentId);
	}

	public getClass(classId: Class['id']) {
		return this.classes$.value.find(({id}) => id === classId);
	}

	public getGroup(groupId: Group['id']) {
		return this.groups$.value.find(({id}) => id === groupId);
	}
}
