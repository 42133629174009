import {Users} from '@esgi/ui/icons';
import {Drawer, StudentsSearchableList} from '@esgi/main/features/teacher/home';
import {Class} from '@esgi/main/libs/store';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useService} from '@esgi/core/service';
import {DrawerBody} from '../drawer-body.styled';
import {useEffect, useState} from 'react';
import {Student} from '../../types';
import {dispatchAppEvent} from '@esgillc/events';
import {AddNewClass} from '../../events';
import {ClassDataService} from './service';
import {ClassNameForm} from '../class-name-form';
import {useFormState} from '../../../hooks';

type Props = {
	onLoaded: (value: boolean) => void;
	onFormTouched: (value: boolean) => void;
};

export function AddClass({onLoaded, onFormTouched}: Props) {
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	const [className, setClassName] = useState<string | null>(null);
	const [restoredClassName, setRestoredClassName] = useState<string>('');
	const [isClassNameFormValid, setIsClassNameFormValid] = useState(false);
	const [isClassNameFormTouched, setIsClassNameFormTouched] = useState(false);

	const [selectedStudentIds, setSelectedStudentIds] = useState<Student['id'][]>([]);
	const [selectedStudentIdsChanged, setSelectedStudentIdsChanged] = useState(false);

	const dataService = useService(ClassDataService);

	const students = useBehaviorSubject(dataService.students$);

	const forceDrawerClose = Drawer.useForceDrawerClose();

	const {getState, setState} = useFormState('addClass');

	useEffect(() => {
		onLoaded(false);

		dataService.init().subscribe(() => {
			onLoaded(true);
			const state = getState(true);
			if (state?.className) {
				setRestoredClassName(state.className);
			}
		});
	}, []);

	useEffect(() => {
		onFormTouched(isClassNameFormTouched || selectedStudentIdsChanged);
	}, [isClassNameFormTouched, selectedStudentIdsChanged]);

	const handleSaveClass = () => {
		if (className) {
			setIsFormSubmitting(true);

			dataService.createClass({name: className, studentIDs: selectedStudentIds}).subscribe({
				next: ({classID}) => {
					const newClass: Class = {
						id: classID,
						name: className,
						studentIDs: selectedStudentIds,
					};

					dispatchAppEvent(AddNewClass, new AddNewClass(newClass));
				},
				complete: () => {
					setIsFormSubmitting(false);
					forceDrawerClose();
				},
			});
		}
	};

	const isActionButtonDisabled = !(isClassNameFormValid && isClassNameFormTouched) || isFormSubmitting;

	return (
		<>
			<Drawer.Header
				Icon={Users}
				sectionName='New Class'
				withActionButton
				actionButtonDisabled={isActionButtonDisabled}
				actionButtonText='Create Class'
				onActionButtonClick={handleSaveClass}
			/>
			<DrawerBody>
				<Drawer.ContentBlock title='Details'>
					<ClassNameForm
						restoredClassName={restoredClassName}
						setIsFormValid={setIsClassNameFormValid}
						setClassName={setClassName}
						onFormTouched={setIsClassNameFormTouched}
					/>
				</Drawer.ContentBlock>
				<Drawer.ContentBlock title='Select Students' titleBold>
					<StudentsSearchableList
						students={students ?? []}
						selectedStudentIds={selectedStudentIds}
						setSelectedStudentIds={setSelectedStudentIds}
						initialSelectedStudentIds={[]}
						setSelectedValueChanged={setSelectedStudentIdsChanged}
						onAddStudent={() => setState({className})}
					/>
				</Drawer.ContentBlock>
			</DrawerBody>
		</>
	);
}
