import Dexie from 'dexie';
import {BehaviorSubject, Observable, retry, skip, Subscription} from 'rxjs';
import {Disposable} from '@esgi/core/service';
import {logger} from './logger';

const dbName = 'esgi';

export function deleteDataBase(name: string) {
	return new Observable<void>(subscriber => {
		logger.info('deleting indexdb.');
		const request = indexedDB.deleteDatabase(name);
		request.onerror = (ev) => {
			logger.error('error while deleting indexdb.', ev);
			subscriber.error(ev);
		};
		request.onsuccess = (ev) => {
			logger.info('indexdb success deleted.');
			setTimeout(() => {
				subscriber.next();
				subscriber.complete();
			});
		};
		request.onblocked = (ev) => {
			logger.error('deleting indexdb request is blocked.', ev);
			subscriber.error(ev);
		};
	});
}

export class DexieAsyncWrapper implements Disposable {
	public instance: Observable<Dexie> = new Observable<Dexie>((subscriber) => {
		const value = this.dexieInstance.value;
		if (value) {
			subscriber.next(value);
		} else {
			const sub = this.dexieInstance.pipe(skip(1)).subscribe({
				next: subscriber.next,
			});
			return () => {
				sub.unsubscribe();
			};
		}
	});

	private dexieInstance = new BehaviorSubject<Dexie>(null);
	private initDexieSub: Subscription;
	private removeIndexDBSub: Subscription;

	constructor(private tableKeys: string[]) {
		this.deleteDB();
	}

	public dispose(): void {
		this.removeIndexDBSub.unsubscribe();
		this.initDexieSub.unsubscribe();
	}

	private deleteDB() {
		this.removeIndexDBSub = deleteDataBase(dbName)
			.pipe(retry(5))
			.subscribe({
				next: () => {
					this.initDexie();
				},
			});
	}

	private initDexie() {
		this.initDexieSub = new Observable((subscriber) => {
			logger.info('initializing dexie.');
			try {
				const store = new Dexie(dbName);
				const tables = {} as any;
				for (const tableKey of this.tableKeys) {
					tables[tableKey] = '++id, value';
				}
				logger.info(`tables: ${this.tableKeys}`);
				store.version(1).stores(tables);
				this.dexieInstance.next(store);
				logger.info('dexie initialized.');
			} catch (e) {
				subscriber.error(e);
			}
		})
			.pipe(retry(5))
			.subscribe();
	}
}