import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {Test} from '@esgi/main/kits/reports';
import {BehaviorSubject, combineLatest, debounceTime, switchMap, of, tap, Subscription} from 'rxjs';
import {
	StudentWithUnit,
	DownloadRequest,
	MarkingPeriodItem,
	Grade,
	Report,
	FileType,
	ReportSettingValueChangeParameters,
	ReportSettingsValue,
} from '../types';
import {StudentService} from './student-service';
import {SubjectService} from './subject-service';
import {TestService} from './test-service';
import {V2PagesReportsController, V2TeachersPagesReportsStudentDetailsController} from '@esgi/contracts/esgi';
import {SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {SsoTracker} from '@esgi/core/tracker';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';

export class StudentDetailService extends BaseService {
	public isPageLoading$ = new BehaviorSubject(true);

	public readonly selectedStudents$ = new BehaviorSubject<StudentWithUnit[]>([]);
	public readonly selectedSubjectID$ = new BehaviorSubject<SubjectTab['id']>(0);
	public readonly selectedTestsIDs$ = new BehaviorSubject<Test['id'][]>([]);

	public readonly canRunReportPreview$ = this.completeOnDestroy(
		combineLatest([this.selectedStudents$, this.selectedTestsIDs$]),
	).pipe(switchMap(([students, tests]) => of(Boolean(students.length) && Boolean(tests.length))));

	public readonly grades$ = new BehaviorSubject<Grade[]>([]);
	public readonly testResultsCorrectVerbiage$ = new BehaviorSubject('');
	public readonly testResultsIncorrectVerbiage$ = new BehaviorSubject('');

	public reportSettings$ = new BehaviorSubject<ReportSettingsValue>({
		carryScoresForward: false,
		includeQuestionNotes: false,
		includeSummaryNotes: false,
		showBaseline: false,
		includeGradeScore: false,
		printInColor: false,
		displayZeroIfNotTested: false,
		markingPeriodAll: false,
	});

	public readonly markingPeriods$ = new BehaviorSubject<MarkingPeriodItem[]>([]);

	public readonly isReportAlertOpen$ = new BehaviorSubject(false);
	public readonly report$ = new BehaviorSubject<Report | null>(null);

	public studentService = new StudentService();
	public subjectService = new SubjectService();
	public testService = new TestService();

	private initSubscription$: Subscription | null = null;

	private readonly controller = new V2TeachersPagesReportsStudentDetailsController();
	private readonly pagesReportsController = new V2PagesReportsController();

	constructor() {
		super();

		this.completeOnDestroy(combineLatest([this.selectedStudents$, this.selectedTestsIDs$]))
			.pipe(
				debounceTime(300),
				tap(([selectedStudents, selectedTestsIDs]) => {
					const subject = this.subjectService.get(this.selectedSubjectID$.value);

					if (!isUndefined(subject) && selectedStudents.length) {
						this.init({
							studentIDs: selectedStudents.map(({studentId}) => studentId),
							subjectID: subject.id,
							subjectType: subject.type,
							testIDs: selectedTestsIDs,
						});
					}
				}),
			)
			.subscribe();

		this.completeOnDestroy(combineLatest([this.subjectService.loaded$, this.subjectService.subjects$]))
			.pipe(
				tap(([isSubjectsLoaded, subjectsList]) => {
					if (isSubjectsLoaded && subjectsList.length) {
						this.selectedSubjectID$.next(subjectsList[0]!.id);
					}
				}),
			)
			.subscribe();

		this.completeOnDestroy(combineLatest([this.selectedSubjectID$, this.selectedStudents$]))
			.pipe(
				debounceTime(300),
				tap(([subjectId, students]) => {
					if (!students.length) {
						return;
					}

					const subject = this.subjectService.get(subjectId);

					this.testService.getTests(subject, students);
				}),
			)
			.subscribe();
	}

	public override destroy() {
		super.destroy();
		this.subjectService.destroy();
		this.testService.destroy();
	}

	public override dispose() {
		this.controller.dispose();
		this.pagesReportsController.dispose();
	}

	public init({
		subjectID,
		studentIDs,
		subjectType,
		testIDs,
	}: {
		subjectID: SubjectTab['id'];
		subjectType: SubjectType;
		studentIDs: StudentWithUnit['studentId'][];
		testIDs: Test['id'][];
	}) {
		this.initSubscription$?.unsubscribe();
		this.isPageLoading$.next(true);

		this.initSubscription$ = this.controller
			.init({
				testIDs,
				studentIDs,
				subjectID,
				subjectType,
			})
			.subscribe(({value: {report, teacher}}) => {
				this.grades$.next(report.gradeValues);
				this.testResultsCorrectVerbiage$.next(teacher.testResultsCorrectVerbiage);
				this.testResultsIncorrectVerbiage$.next(teacher.testResultsIncorrectVerbiage);

				this.reportSettings$.next({
					carryScoresForward: teacher.carryScoresForward.value,
					includeQuestionNotes: teacher.includeQuestionNotes.value,
					includeSummaryNotes: teacher.includeSummaryNotes.value,
					showBaseline: teacher.showBaseline,
					printInColor: teacher.printInColor.value,
					includeGradeScore: teacher.includeGradeScore.value,
					displayZeroIfNotTested: teacher.displayZeroIfNotTested.value,
					markingPeriodAll: teacher.markingPeriodAll,
				});

				this.isPageLoading$.next(false);
			});
	}

	public setStudents(value: StudentWithUnit[]) {
		this.selectedStudents$.next(value);
	}

	public setSubject(value: number) {
		this.selectedSubjectID$.next(value);
	}

	public setTestsIDs(value: Test['id'][]) {
		this.selectedTestsIDs$.next(value);
	}

	public reportSettingValueChange({key, newValue}: ReportSettingValueChangeParameters) {
		const currentSettings = this.reportSettings$.value;

		this.reportSettings$.next({
			...currentSettings,
			[key]: newValue,
		});

		if (key === 'showBaseline' || key === 'carryScoresForward') {
			const isReportAlertOpen = this.isReportAlertOpen$.value;

			if (isReportAlertOpen) {
				this.fetchReportData().subscribe();
			}

			this.controller.showBaseline({value: newValue}).subscribe();

			return;
		}

		if (key === 'markingPeriodAll') {
			this.pagesReportsController.userSettingsMarkingPeriodAll({value: newValue}).subscribe();

			return;
		}

		this.pagesReportsController
			.userSettingsUpdate({
				name: key,
				value: newValue,
			})
			.subscribe();
	}

	public openReportAlert() {
		this.fetchReportData().subscribe(() => {
			this.isReportAlertOpen$.next(true);
		});
	}

	public closeReportAlert() {
		this.report$.next(null);
		this.isReportAlertOpen$.next(false);
	}

	public onDownload(fileType: FileType) {
		const report = this.report$.value;

		if (isNull(report)) {
			return;
		}

		const reportSettings = this.reportSettings$.value;
		const testResultsCorrectVerbiage = this.testResultsCorrectVerbiage$.value;
		const testResultsIncorrectVerbiage = this.testResultsIncorrectVerbiage$.value;

		const downloadRequestData: DownloadRequest = {
			includeNotes: reportSettings.includeQuestionNotes,
			includeSummaryNotes: reportSettings.includeSummaryNotes,
			showBaseline: reportSettings.showBaseline,
			printInColor: reportSettings.printInColor,
			currentPeriod: !reportSettings.markingPeriodAll,
			includeGradeScore: report.gradeValues.length ? reportSettings.includeGradeScore : false,
			reportGuid: report.reportGuid,
			notTestedDisplayValue: reportSettings.displayZeroIfNotTested ? '0' : 'NT',
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
		};

		const documentDate = new Date().toLocaleDateString('en-US');
		SsoTracker.trackEvent({trackingEvent: 'PDF Standard', data: {reportType: 'Detail'}});

		this.httpClient.ESGIApi.file(
			'v2/teachers/pages/reports/student-details',
			'download-pdf',
			`Student_Detail_Report_${documentDate}.${fileType}`,
			{...downloadRequestData, zip: fileType === FileType.Zip},
		).subscribe();
	}

	private fetchReportData() {
		const reportSettings = this.reportSettings$.value;
		const currentUser = getUser();
		const selectedSubject = this.subjectService.get(this.selectedSubjectID$.value);

		if (isNull(currentUser) || isUndefined(selectedSubject)) {
			return of().pipe(
				tap(() => {
					this.report$.next(null);
					this.markingPeriods$.next([]);
				}),
			);
		}

		return this.controller
			.report({
				carryScores: reportSettings.carryScoresForward,
				includeQuestionNotes: reportSettings.includeQuestionNotes,
				sortBy: currentUser.studentSort,
				studentIDs: this.selectedStudents$.value.map(({studentId}) => studentId),
				subject: {
					name: selectedSubject.name,
					subjectID: selectedSubject.id,
					subjectLevel: selectedSubject.level,
					subjectType: selectedSubject.type,
				},
				testIDs: this.selectedTestsIDs$.value,
			})
			.pipe(
				tap(({value}) => {
					this.report$.next(value);
					this.markingPeriods$.next(this.getMarkingPeriods(value));
				}),
			);
	}

	private getMarkingPeriods(reportData: Report): MarkingPeriodItem[] {
		return [
			{index: 0, title: 'B'},
			...reportData.trackDates.map((_, index) => ({
				index: index + 1,
				title: (index + 1).toString(),
			})),
		];
	}
}
