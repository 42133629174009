import {AppEvent} from '@esgillc/events';
import {TestType} from '@esgi/core/enums';
import {Question, Test, SelfAssessBankQuestion} from 'shared/modules/test-details/models';
import {Question as SelfAssessQuestion} from '@esgi/selfassess';
import {QuestionModel} from '@esgi/contracts/esgi/types/esgi.assets/features/esgi.bankquestions/update-test/question-model';


export function Reset() {
	return;
}

export function TypeChanged(newType: TestType): TestType {
	return newType;
}

@AppEvent({broadcast: true})
export class TestChanged {
	constructor(
		public id: number,
		public newName: string,
		public color: string,
		public description: string,
		public gradeLevels: number[],
		public contentArea: string,
		public stateStandard: string,
		public questionsNumber: number,
		public draft: boolean,
		public testType: TestType,
		public isWhiteBackground: boolean,
	) {
	}
}

export class OpenQuestionBankEvent {
	constructor(
		public testId: number,
		public contentAreaId: number,
		public contentArea: string,
		public gradeLevels: number[],
		public stateStandard: string,
		public testName: string,
		public selfAssessQuestions: (SelfAssessQuestion & {name: string, orderNum: number})[],
	) {
	}
}

export class CloseQuestionBankEvent {
	constructor(
		public selfAssessQuestions: SelfAssessBankQuestion[] | null,
		public questions: QuestionModel[] | null,
	) {
	}
}

export class ForceCloseQuestionBankEvent {
	constructor () {
	}
}


export module TestChanged {
	export interface Args {
		id: number;
		newName: string;
		color: string;
		description: string;
		gradeLevels: number[];
		contentArea: string;
		stateStandard: string;
		questionsNumber: number;
		draft: boolean;
		testType: TestType;
		isWhiteBackground: boolean;
	}
}

export function TestCreated(id: number, type: TestType, name: string, draft: boolean): TestCreated.Args {
	return {
		id: id,
		type: type,
		name: name,
		draft: draft,
	};
}

export module TestCreated {
	export interface Args {
		id: number;
		type: TestType;
		name: string;
		draft: boolean;
	}
}

export function TestPublished(id: number, name: string, author: string, createDate: string, questions: Question[], color: string, type: string, contentArea: string, description: string, gradeLevels: number[], stateStandard: string, isWhiteBackground: boolean): TestPublished.Args {
	return {
		id: id,
		name: name,
		author: author,
		createdDate: createDate,
		questionsCount: questions.length,
		color: color,
		questions: questions,
		type: TestType[type],
		contentArea: contentArea,
		description: description,
		gradeLevels: gradeLevels,
		stateStandard: stateStandard,
		isWhiteBackground: isWhiteBackground,
	};
}

export module TestPublished {
	export interface Args {
		id: number;
		name: string;
		author: string;
		createdDate: string;
		questionsCount: number;
		color: string;
		description: string;
		contentArea: string;
		gradeLevels: number[];
		type: string;
		questions: Question[];
		stateStandard: string;
		isWhiteBackground: boolean;
	}
}

export function TestDeleted(id: number): TestDeleted.Args {
	return {
		id: id,
	};
}

export module TestDeleted {
	export interface Args {
		id: number;
	}
}

export function TestCopied(copiedName: string,
	testId: number,
	author: string,
	createdDate: string,
	questionsCount: number,
	color: string,
	description: string,
	contentArea: string,
	gradeLevels: number[],
	type: string,
	questions: Question[],
	stateStandard: string,
	isWhiteBackground: boolean, 
						   hasSelfAssessVersion?: boolean): TestCopied.Args {
	return {
		copiedName,
		testId,
		author,
		createdDate,
		questionsCount,
		color,
		description,
		contentArea,
		gradeLevels,
		type,
		questions,
		stateStandard,
		isWhiteBackground,
		hasSelfAssessVersion,
	};
}

export module TestCopied {
	export interface Args {
		copiedName: string;
		testId: number;
		author: string;
		createdDate: string;
		questionsCount: number;
		color: string;
		description: string;
		contentArea: string;
		gradeLevels: number[];
		type: string;
		questions: Question[];
		stateStandard: string;
		isWhiteBackground: boolean;
		hasSelfAssessVersion: boolean;
	}
}

@AppEvent({broadcast: true})
export class TestBackgroundChangedEvent {
	constructor(public id: number, public isWhiteBackground: boolean) {
	}
}

@AppEvent({broadcast: true})
export class TestQuestionListChanged {
	constructor(public id: number) {
	}
}

export class ResetRequested {
	test: Test;
	shared: boolean;
}

export class TestSubjectsChanged {
	constructor(public subjectIDs: number[]) {
	}
}
