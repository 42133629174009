import {useCallback} from 'react';
import {SelectableList, SelectableListItem} from './index.styled';
import {SelectableLisItemCheckbox} from '../checkbox';
import {SelectableLisItemContent} from '../list-item';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Test} from '../../../../types';
import {isNull} from 'underscore';

const getListValue = (value: string | number | null) => isNull(value)
	? ''
	: String(value);

interface Props {
	items: Test[];
	selected: Test['id'][] | null;
	setSelected: (itemId: Test['id']) => void;
	allSubjectsMode?: boolean;
}

export function SelectableListMultiple({items, selected, setSelected, allSubjectsMode}: Props) {
	const value = (selected || []).map(getListValue);
	const checked = useCallback((id: string) => value.includes(id), [value]);
	const disabled = ({correct, incorrect, untested, isTested}: Omit<Test, 'id'>) => !isTested;

	return (
		<OverlayScrollbarsComponent
			defer
			style={{height: 'calc(100% + 0px)'}}
			options={{scrollbars: {autoHide: 'leave'}, paddingAbsolute: true}}
		>
			<SelectableList>
				<SelectableList.GroupRoot
					type='multiple'
					value={value}
				>
					<SelectableList.Group>
						{items.map((item) => {
							const isChecked = checked(String(item.id));
							const isDisabled = disabled(item);

							return (
								<SelectableListItem
									key={item.id + '_' + item.subjectName}
									value={String(item.id)}
									checked={isChecked}
									disabled={isDisabled}
									setTextCurrentColor={false}
									onClick={() => setSelected(item.id)}
									data-cy='test-item-root'
								>
									<SelectableLisItemCheckbox
										checked={isChecked}
										disabled={isDisabled}
									/>
									<SelectableLisItemContent item={item} allSubjectMode={allSubjectsMode}/>
								</SelectableListItem>
							);
						})}
					</SelectableList.Group>
				</SelectableList.GroupRoot>
			</SelectableList>
		</OverlayScrollbarsComponent>
	);
}
