import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ComponentProps, forwardRef} from 'react';
import {EmptyContentBox} from './index.styled';

type Props = ComponentProps<typeof GridBox> & {
	templateText: string;
};

export const EmptyContent = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'test-dashboard-empty-content', css = {}, templateText, display = 'inlineGrid', ...props},
		forwardedRef,
	) => (
		<EmptyContentBox dataCy={dataCy} css={css} ref={forwardedRef} display={display} {...props}>
			<Text size='small' font='mono' color='neutral40'>
				{templateText}
			</Text>
		</EmptyContentBox>
	),
);
