import {BaseService} from '@esgi/core/service';
import {
	PagesTestExplorerServicesController,
	V2PagesTestExplorerController,
	V2TeachersPagesTestExplorerCommonController,
} from '@esgi/contracts/esgi';
import {BehaviorSubject, combineLatest, debounceTime, of, switchMap} from 'rxjs';
import {
	Administration,
	CardViewType,
	FilterScope,
	SortDirection,
	TestModel,
	TestsSortBy,
} from './types';
import {storage, SubjectTab} from '@esgi/main/libs/store';
import {EventBusManager} from '@esgillc/events';
import {TestSubjectsChanged} from 'shared/modules/test-details/events';
import {legacyIndicatorColors, TestContentArea} from '@esgi/main/kits/common';
import {isNull} from 'underscore';
import {StandardsService, OptionItem, AdditionSelectItem} from '@esgi/main/features/standards-drawer';

const itemsPerPage = 30;

export class TestExplorerService extends BaseService {
	public cardViewType = new BehaviorSubject<CardViewType>(CardViewType.List);
	public tests$ = new BehaviorSubject<TestModel[]>([]);
	public selectedTestIDs$ = new BehaviorSubject<TestModel['testID'][]>([]);

	public showOnlySelectedTests$ = new BehaviorSubject(false);
	public addSelectedToFavoritesChecked$ = new BehaviorSubject(false);
	public hasDrafts$ = new BehaviorSubject(false);

	public testsCount$ = new BehaviorSubject(0);
	public isLoaded$ = new BehaviorSubject(false);
	public drawerIsLoaded$ = new BehaviorSubject(false);
	public hasMoreTests$ = new BehaviorSubject(false);
	public hasOverlimit$ = new BehaviorSubject(false);

	public scope$ = new BehaviorSubject<FilterScope>(FilterScope.AllTests);
	public sortBy$ = new BehaviorSubject<TestsSortBy>(TestsSortBy.CreateDate);
	public selectedSortDirection$ = new BehaviorSubject<SortDirection>(SortDirection.Desc);
	public currentPageIndex$ = new BehaviorSubject(1);

	public notedAuthors$ = new BehaviorSubject<OptionItem[]>([]);
	public notedSeries$ = new BehaviorSubject<OptionItem[]>([]);

	public selectedNotedAuthors$ = new BehaviorSubject<OptionItem[]>([]);
	public selectedNotedSeries$ = new BehaviorSubject<OptionItem[]>([]);

	public selectedTestTypes$ = new BehaviorSubject<number[]>([]);
	public selectedNotedSeriesIDs$ = new BehaviorSubject<number[]>([]);
	public selectedNotedAuthorsIDs$ = new BehaviorSubject<number[]>([]);
	public selectedAdministration$ = new BehaviorSubject<Administration>(Administration.OneToOne);
	public showHidden$ = new BehaviorSubject<boolean>(false);
	public selectedSubject$ = new BehaviorSubject<SubjectTab>(null);
	public keyword$ = new BehaviorSubject('');
	private subjectStorage = storage.subjects();
	private controller = new V2PagesTestExplorerController();
	private oldController = new PagesTestExplorerServicesController();
	private stateController = new V2TeachersPagesTestExplorerCommonController();
	private readonly eventBus = new EventBusManager();

	constructor(public standardsService: StandardsService) {
		super();
		this.standardsService = standardsService;
		this.initializePageState();
		this.eventBus.subscribe(TestSubjectsChanged, () => this.subjectStorage.invalidateCache(true).subscribe());

		this.completeOnDestroy(combineLatest([
			this.scope$,
			this.sortBy$,
			this.selectedTestTypes$,
			this.standardsService.selectedContentAreaIDs$,
			this.selectedNotedAuthorsIDs$,
			this.selectedNotedSeriesIDs$,
			this.selectedAdministration$,
			this.showHidden$,
			this.standardsService.selectedGradeLevelIDs$,
			this.standardsService.selectedStandardsIDs$,
			this.selectedSortDirection$,
			this.keyword$,
		]))
			.pipe(debounceTime(0), switchMap(() => {
				this.currentPageIndex$.next(1);
				this.tests$.next([]);
				this.isLoaded$.next(false);

				return this.getTests(1);
			}))
			.subscribe((result) => {
				if (result?.value) {
					const {tests, count, hasDrafts} = result.value;
					this.onTestsLoaded(tests, count, hasDrafts);
				}
			});

		this.completeOnDestroy(combineLatest([this.currentPageIndex$]))
			.pipe(switchMap(([currenPageIndex]) => {
				if (currenPageIndex > 1) {
					this.isLoaded$.next(false);
					return this.getTests(currenPageIndex);
				}
				return of(null);
			}))
			.subscribe((result) => {
				if (result?.value) {
					const {tests, count, hasDrafts} = result.value;
					this.onTestsLoaded(tests, count, hasDrafts);
				}
			});
	}

	public get isAnyFilterSelected() {
		return [
			this.standardsService.selectedGradeLevelIDs$.value.length,
			this.standardsService.selectedContentAreaIDs$.value.length,
			this.standardsService.selectedStandardsIDs$.value.length,
			this.standardsService.selectedStandards$.value.length,

			this.selectedNotedAuthorsIDs$.value.length,
			this.selectedNotedAuthors$.value.length,
			this.selectedTestTypes$.value.length,
			this.selectedAdministration$.value !== Administration.OneToOne,
			this.showHidden$.value,
			this.selectedNotedSeries$.value.length,
			this.selectedNotedSeriesIDs$.value.length,
		].some(value => Boolean(value));
	}

	public getTests(pageIndex: number) {
		return this.controller.search({
			text: this.keyword$.value,
			standardIDs: this.standardsService.selectedStandardsIDs$.value,
			scope: this.scope$.value,
			administration: this.selectedAdministration$.value,
			sortBy: this.sortBy$.value,
			sortDirection: this.selectedSortDirection$.value,
			pageIndex: pageIndex,
			itemsPerPage: itemsPerPage,
			contentAreaIDs: this.standardsService.selectedContentAreaIDs$.value,
			gradeLevelIDs: this.standardsService.selectedGradeLevelIDs$.value,
			testTypes: this.selectedTestTypes$.value,
			notedSeriesIDs: this.selectedNotedSeriesIDs$.value,
			notedAuthors: this.selectedNotedAuthors$.value.map(({searchName}) => searchName),
			showHidden: this.showHidden$.value,
		});
	}

	public onTestsLoaded (tests: TestModel[], count: number, hasDrafts) {
		this.isLoaded$.next(true);
		this.hasMoreTests$.next(itemsPerPage === tests.length);

		this.hasDrafts$.next(hasDrafts);
		this.tests$.next([...this.tests$.value, ...tests]);
		this.testsCount$.next(count);

		const selectedIds = this.selectedTestIDs$.value;
		const filteredTestsIds = tests.map(test => test.testID).filter(x => selectedIds.includes(x));
		this.selectedTestIDs$.next(filteredTestsIds);
	}

	public getNotedAuthors() {
		this.drawerIsLoaded$.next(false);
		return this.controller.notedAuthors().subscribe(({value: {notedAuthors}}) => {
			const sortedAuthors = notedAuthors.sort((a, b) => a.name < b.name ? -1 : 1);
			const mappedAuthors = sortedAuthors.map(({id, name, imageSet, title, description, searchName}) => ({
				value: id,
				label: name,
				title,
				imageSet,
				description,
				searchName,
			}));

			this.notedAuthors$.next([
				{
					value: 0,
					label: AdditionSelectItem.System,
					title: 'ESGI Created Tests',
					description: 'Tests created in alignment with educational standards, skills, and more.',
					searchName: AdditionSelectItem.System,
				},
				...mappedAuthors,
			]);
			this.drawerIsLoaded$.next(true);
		});
	}

	public getNotedSeries() {
		this.drawerIsLoaded$.next(false);
		return this.oldController.featuredSeries().subscribe(({series}) => {
			this.notedSeries$.next(series.map(({id, name, description}) => ({
				value: id,
				label: name,
				description,
			})));
			this.drawerIsLoaded$.next(true);
		});
	}

	public onScopeChange(scope: string) {
		this.scope$.next(Number(scope));

		this.currentPageIndex$.next(1);
		this.showOnlySelectedTests$.next(false);


		if (Number(scope) === FilterScope.MyTests) {
			this.selectedNotedAuthorsIDs$.next([]);
			this.selectedNotedAuthors$.next([]);
			this.selectedNotedSeries$.next([]);
			this.selectedNotedSeriesIDs$.next([]);
		}
	}

	public onSortByChange(sortBy: string) {
		this.sortBy$.next(Number(sortBy));
	}

	public onSortDirectionChange() {
		this.selectedSortDirection$.next(this.selectedSortDirection$.value === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc);
	}

	public onTestSelect(testID: TestModel['testID']) {
		if (this.selectedTestIDs$.value.includes(testID)) {
			const filteredTests = [...this.selectedTestIDs$.value.filter((id) => id !== testID)];
			if (!filteredTests.length) {
				this.showOnlySelectedTests$.next(false);
			}

			this.selectedTestIDs$.next(filteredTests);
			return;
		}

		if (this.selectedTestIDs$.value.length >= 100) {
			this.hasOverlimit$.next(true);
			return;
		}
		this.selectedTestIDs$.next([...this.selectedTestIDs$.value, testID]);
	}

	public addSelectedTestsToFavorites() {
		const selectedTests = this.tests$.value.filter(({testID}) => this.selectedTestIDs$.value.includes(testID));
		const alreadyInFavorites = selectedTests.every(({starred}) => starred);

		const updatedTests = this.tests$.value.map(test =>
			this.selectedTestIDs$.value.includes(test.testID) ? ({...test, starred: !alreadyInFavorites}) : test
		);

		this.tests$.next(this.scope$.value === FilterScope.Favorites ? updatedTests.filter((test) => !this.selectedTestIDs$.value.includes(test.testID)) : updatedTests);

		if (alreadyInFavorites) {
			return this.controller.batchSetFavorite({value: false, testIDs: this.selectedTestIDs$.value}).subscribe();
		}

		return this.controller.batchSetFavorite({value: true, testIDs: this.selectedTestIDs$.value}).subscribe(() => {
			this.addSelectedToFavoritesChecked$.next(false);
		});
	}

	public hideSelectedTests(callback?: (tests: TestModel[]) => void) {
		const selectedTests = this.tests$.value.filter(({testID}) => this.selectedTestIDs$.value.includes(testID));
		const allHidden = selectedTests.every(({hidden}) => hidden);
		let updatedTests = this.tests$.value.map(test =>
			this.selectedTestIDs$.value.includes(test.testID) ? ({...test, hidden: !allHidden}) : test
		);

		if (!this.showHidden$.value) {
			updatedTests = updatedTests.filter((test) => !this.selectedTestIDs$.value.includes(test.testID));
		}

		if (this.showOnlySelectedTests$.value && !this.showHidden$.value) {
			this.showOnlySelectedTests$.next(false);
		}

		this.tests$.next(updatedTests);

		return this.controller.batchSetHidden({value: !allHidden, testIDs: this.selectedTestIDs$.value}).subscribe(() => {
			callback(selectedTests);
			if (!this.showHidden$.value) {
				const testsCount = this.testsCount$.value;
				this.testsCount$.next(testsCount - this.selectedTestIDs$.value.length);
				this.selectedTestIDs$.next([]);
			}
		});
	}

	public onAddToFavoriteClick({testID, starred}: TestModel) {
		const tests = this.tests$.value.map((test) => test.testID === testID ? {...test, starred: !starred} : test);
		this.tests$.next(this.scope$.value === FilterScope.Favorites ? tests.filter((test) => test.testID !== testID) : tests);

		if (starred) {
			return this.controller.batchSetFavorite({value: false, testIDs: [testID]}).subscribe();
		}

		return this.controller.batchSetFavorite({value: true, testIDs: [testID]}).subscribe();
	}

	public showOnlySelectedClick() {
		this.showOnlySelectedTests$.next(!this.showOnlySelectedTests$.value);
	}

	public closeSelectedTestsBar() {
		this.selectedTestIDs$.next([]);
		this.addSelectedToFavoritesChecked$.next(false);
		this.showOnlySelectedTests$.next(false);
	}

	public onTestTypeChange = (testType: number) => {
		if (this.selectedTestTypes$.value.includes(testType)) {
			this.selectedTestTypes$.next(this.selectedTestTypes$.value.filter((id) => id !== testType));
			return;
		}

		this.selectedTestTypes$.next([...this.selectedTestTypes$.value, testType]);
	};

	public onAdministrationChange = (administration: Administration) => {
		this.selectedAdministration$.next(this.selectedAdministration$.value === Administration.SelfAssess
			? Administration.OneToOne
			: administration
		);
	};

	public onShowHiddenChange(value: boolean) {
		this.showHidden$.next(value);
	}

	public onAuthorsSelect(selectedAuthorsIDs: number[]) {
		this.selectedNotedAuthorsIDs$.next(selectedAuthorsIDs);
		this.selectedNotedAuthors$.next(this.notedAuthors$.value.filter(({value}) => selectedAuthorsIDs.includes(value)));
	}

	public onSelectedAuthorRemove = (selectedAuthorID: number) => {
		const selectedAuthorsIDs = this.selectedNotedAuthorsIDs$.value.filter((item) => item !== selectedAuthorID);
		this.selectedNotedAuthorsIDs$.next(selectedAuthorsIDs);
		this.selectedNotedAuthors$.next(this.selectedNotedAuthors$.value.filter(({value}) => selectedAuthorsIDs.includes(value)));
	};

	public onSeriesSelect(selectedSeriesIDs: number[]) {
		this.selectedNotedSeriesIDs$.next(selectedSeriesIDs.map((item) => Number(item)));
		this.selectedNotedSeries$.next(this.notedSeries$.value.filter(({value}) => selectedSeriesIDs.includes(value)));
	}
	
	public onAuthorsClear(){
		if (this.selectedNotedAuthorsIDs$.value.length > 0) {
			this.selectedNotedAuthorsIDs$.next([]);
			this.selectedNotedAuthors$.next([]);
		}
	}

	public onNotedSeriesClear(){
		if (this.selectedNotedSeriesIDs$.value.length > 0) {
			this.selectedNotedSeriesIDs$.next([]);
			this.selectedNotedSeries$.next([]);
		}
	}

	public onSelectedSeriesRemove = (selectedSeriesID: number) => {
		const selectedSeriesIDs = this.selectedNotedSeriesIDs$.value.filter((item) => item !== selectedSeriesID);
		this.selectedNotedSeriesIDs$.next(selectedSeriesIDs);
		this.selectedNotedSeries$.next(this.notedSeries$.value.filter(({value}) => selectedSeriesIDs.includes(value)));
	};

	public onFiltersReset = () => {
		this.standardsService.selectedStandards$.next([]);
		this.standardsService.selectedStandardsIDs$.next([]);
		this.standardsService.selectedGradeLevelIDs$.next([]);
		this.standardsService.selectedContentAreaIDs$.next([]);
		this.selectedNotedAuthorsIDs$.next([]);
		this.selectedNotedAuthors$.next([]);
		this.selectedTestTypes$.next([]);
		this.selectedAdministration$.next(Administration.OneToOne);
		this.showHidden$.next(false);
		this.selectedNotedSeries$.next([]);
		this.selectedNotedSeriesIDs$.next([]);
	};

	public onSubjectSelect(subject: SubjectTab) {
		this.selectedSubject$.next(subject);
	}

	public onModalTestHidden(testId: number, hidden: boolean) {
		let updatedTests = this.tests$.value.map((item) => {
			if (item.testID === testId) {
				return {...item, hidden: hidden};
			}
			return item;
		});

		if (!this.showHidden$.value) {
			if (hidden) {
				const testsCount = this.testsCount$.value;
				this.testsCount$.next(testsCount - 1);
				this.selectedTestIDs$.next(this.selectedTestIDs$.value.filter(x => x !== testId));
			}
			updatedTests = updatedTests.filter((test) => !test.hidden);
		}

		this.tests$.next(updatedTests);
	}

	public onAddToSubject() {
		const {id, type} = this.selectedSubject$.value;
		return this.controller.batchAddToSubject({
			subjectID: id,
			subjectType: type,
			testIDs: this.selectedTestIDs$.value,
		}).pipe(
			switchMap(() => {
				return this.subjectStorage.update((iteratedSubject) => {
					if (iteratedSubject.id === id) {
						iteratedSubject.tests = [
							...iteratedSubject.tests,
							...this.tests$.value
								.filter(({testID}) => this.selectedTestIDs$.value.includes(testID))
								.map(({testID: id, type, name, contentArea, isWhiteBackground}) => {
									const testContentArea = contentArea as TestContentArea;
									const color = legacyIndicatorColors[testContentArea];
									return {
										id,
										name,
										contentArea,
										color,
										type,
										isWhiteBackground,
										maxScore: 0,
										creatorID: 0,
										testScreenTypes: [],
									};
								}),
						];
					}

					return iteratedSubject;
				});
			}),
		);
	}

	public override dispose() {
		this.controller.dispose();
		this.oldController.dispose();
		this.eventBus.destroy();
	}

	public updatePageState(cardView: CardViewType){
		this.stateController.stateUpdate({state: {cardView: cardView}}).subscribe();
	}

	private initializePageState() {
		this.stateController.init()
			.subscribe({
				next: value => {
					this.isLoaded$.next(false);
					if (!isNull(value.state.cardView)) {
						this.cardViewType.next(Number(CardViewType[value?.state?.cardView]));
					}
				},
				complete: () => {
					this.isLoaded$.next(true);
				},
			});
	}
}