import {Test} from '../../../../types';
import {TestColorIndicator} from '@esgi/main/kits/common';
import {CaptionText, Counter, CounterText} from './index.styled';
import {TestTypeLabel} from '../../../../../test-type-label';
import {TestType} from '@esgi/main/libs/core';

interface Props {
	item: Test;
}

function TestResult (props: {test: Test}) {
	if (props.test.type === TestType.YN) {
		return <Counter>
			<CounterText size='small' font='mono' bold type='correct' zero={!props.test.correct}>{props.test.correct}</CounterText>
			<CounterText size='small' font='mono' bold type='incorrect' zero={!props.test.incorrect}>{props.test.incorrect}</CounterText>
			<CounterText size='small' font='mono' bold type='untested' zero={!props.test.untested}>{props.test.untested}</CounterText>
		</Counter>;
	}
	if (props.test.type === TestType.Score || props.test.type === TestType.Rubric) {
		return <Counter>
			<CounterText size='small' font='mono' bold type='untested'>{props.test.correct}</CounterText>
			<CounterText size='small' font='mono' bold type='untested'>/</CounterText>
			<CounterText size='small' font='mono' bold type='untested'>{props.test.maxScore}</CounterText>
		</Counter>;
	}
	return null;
}

export function SelectableLisItemContent({item}: Props) {
	const hasResults = ({correct, incorrect, untested, type, maxScore}: Omit<Test, 'id'>) => {
		if (type === TestType.Score || type === TestType.Rubric) {
			return correct !== 0;
		} else {
			return correct + incorrect + untested !== 0;
		}
	};

	return (
		<>
			<TestColorIndicator testID={item.id} css={{height: 32}}/>
			<CaptionText data-cy='test-name' size='large' color='currentColor'>{item.name}</CaptionText>
			<TestTypeLabel testType={item.type} css={{justifySelf: 'start'}} />
			{hasResults(item)
				?
				<TestResult data-cy='test-result' test={item} />
				:
				<Counter>
					<CounterText size='small' font='mono'>No Results</CounterText>
				</Counter>
			}
		</>
	);
}
