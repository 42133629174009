import {map, Observable} from 'rxjs';
import {createStorage, isStoreConstructor, Store, StoreConfig} from '@esgi/store';

import {V2TeachersStorageController} from '@esgi/contracts/esgi';

import {Class, Student, Group, SubjectTab} from '../types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {AppEvent, EventBusManager} from '@esgillc/events';

export const storage = createStorage({
	classes: {
		dataFallback: () => new V2TeachersStorageController().classes().pipe(map(r => r.classes)),
	} as StoreConfig<Class>,
	groups: {
		dataFallback: () => new V2TeachersStorageController().groups().pipe(map(r => r.groups)),
	} as StoreConfig<Group>,
	students: {
		dataFallback: () => new V2TeachersStorageController().students().pipe(map(r => r.students)),
	} as StoreConfig<Student>,
	subjects: {
		dataFallback: () => new V2TeachersStorageController().subjects().pipe(map(r => r.subjects)),
	} as StoreConfig<SubjectTab>,
});

@AppEvent()
export class ClearStorageEvent {

}

new EventBusManager().subscribe(ClearStorageEvent, () => {
	for (const storageKey in storage) {
		const store = storage[storageKey];
		if(isStoreConstructor(store)) {
			const storeInstance = store();
			storeInstance.invalidateCache(false).subscribe({complete: () => storeInstance.dispose()});
		}
	}
});

type Setter<T> = (oldValue: T, newValue: T) => Observable<void>;

export function useStore<T>(store: () => Store<T>): [T[], Setter<T>, boolean] {
	const [value, setValue] = useState<T[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);

	const storage = useMemo(() => store(), [store]);

	useEffect(() => {
		storage.get().subscribe(d => {
			setValue(d);
			setLoaded(true);
		});
		return () => {
			storage.dispose();
		};
	}, [storage]);

	const update = useCallback((oldValue: T, newValue: T) => {
		return storage.update(oldValue, newValue);
	}, [storage]);

	return [value, update, loaded];
}