import {Observable, skip, Subject, switchMap, tap} from 'rxjs';
import {Crypto} from './crypto';
import {MapFunction, StoreConfig, StoreInstance, TableInfoSchema, UpdateMethod} from './index';
import {CryptoTable} from './crypto-table';
import {isEqual, isFunction} from 'underscore';
import {DexieAsyncWrapper} from './dexie-async-wrapper';

export class StoreInstanceImpl<T> implements StoreInstance<T> {
	private table: CryptoTable<T>;
	private loadingQueue: Subject<void> = new Subject<void>();
	private loading: boolean = false;

	constructor(crypto: Crypto, storage: DexieAsyncWrapper, public key: string, private config: StoreConfig<T>, private tableInfo: CryptoTable<TableInfoSchema>) {
		this.loadingQueue.subscribe(() => {
			try {
				if (!this.loading) {
					this.loading = true;
					this.config.dataFallback().pipe(
						switchMap((values) => this.table.set(...values)),
						switchMap(() => this.validateCache()),
					).subscribe({
						next: () => {
							this.loading = false;
						},
						complete: () => {
							this.loading = false;
						},
					});
				}
			} catch (e) {
				console.error(e);
			}
		});

		this.table = new CryptoTable<T>(key, storage, crypto);
	}

	public update: UpdateMethod<T> = (oldValue: T | MapFunction<T>, newValue?: T): Observable<void> => {
		if(isFunction(oldValue)) {
			return this.table.update(oldValue);
		}

		return this.table.update((value) => {
			if (isEqual(oldValue, value)) {
				return newValue;
			}

			return value;
		});
	};

	public add(...value: T[]): Observable<void> {
		return this.table.add(...value);
	}

	public get(): Observable<Readonly<T>[]> {
		return this.tableInfo
			.find((info) => info.tableID === this.key)
			.pipe(
				switchMap(tableInfo => {
					const data = this.table.data();
					if (!tableInfo || tableInfo?.expired) {
						if(!tableInfo) {
							this.loadingQueue.next();
						}

						return data.pipe(skip(1));
					}

					return data;
				}),
			);
	}

	public invalidateCache(immediate?: boolean): Observable<void> {
		const immediateOperator = switchMap(() => this.table.set());
		return this.tableInfo.update((info) => {
			if (info.tableID === this.key) {
				info.expired = true;
			}
			return info;
		}).pipe(
			immediate ? immediateOperator : tap(),
			tap(() => {
				this.loadingQueue.next();
			}));
	}

	public validateCache(): Observable<void> {
		return this.tableInfo.find((info) => info.tableID === this.key)
			.pipe(
				switchMap((tableInfo) => {
					if (tableInfo) {
						return this.tableInfo.update((info) => {
							if (info.tableID === this.key) {
								info.expired = false;
								info.lastModifyDate = new Date().getTime();
							}
							return info;
						});
					} else {
						return this.tableInfo.add({tableID: this.key, expired: false, lastModifyDate: new Date().getTime()});
					}
				})
			);
	}
}