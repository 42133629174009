import {TestPreview} from 'modules/assessments';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import React from 'react';
import {SharedComponent, SharedProps, ValidationResult, Validator} from '@esgi/deprecated/react';
import {TestType, ValidationStatus} from '@esgi/core/enums';
import {ModalWindow, OldAlerts} from '@esgi/deprecated/knockout';
import {OnHoverTooltip, ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {Question as SelfAssessQuestion} from '@esgi/selfassess';
import {Question, TestIntro, ViewMode} from 'shared/modules/test-details/models';
import {StateStandardsLibrary} from 'shared/modules/state-standards-library';
import {IUpdateResult, ShapeDefinitions} from 'shared/modules/question-editor/models';
import {
	QuestionEditor,
	State as QuestionEditorQuestionState,
} from 'shared/modules/question-editor/editors/question/question-editor';
import {
	TestIntroEditor,
	State as TestIntroEditorTestIntroState,
} from 'shared/modules/question-editor/editors/testIntro/test-intro-editor';
import {
	ShapeDefinitionBuilder,
	Black as ShapeDefinitionBuilderBlack,
	White as ShapeDefinitionBuilderWhite,
} from 'shared/modules/question-editor/utils/shape-definition-builder';
import {
	TotalPossible,
	State as TotalPossibleState,
} from 'shared/modules/test-details/components/question-panel/total-possible/component';
import {Api} from 'shared/modules/test-details/api/api';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {TestIntroTile} from 'shared/modules/test-details/components/tiles/test-intro-tile/component';
import {TestIntroCreaterTile} from 'shared/modules/test-details/components/tiles/test-intro-creater-tile/component';
import {QuestionTile} from 'shared/modules/test-details/components/tiles/question-tile/component';
import * as Events from 'shared/modules/test-details/components/question-panel/events';
import './component.less';
import {DragAndDrop} from 'shared/modules/drag-and-drop/component';
import {OsChecker, isIOS} from '@esgillc/ui-kit/utils';
import {userStorage, UserType} from '@esgi/core/authentication';
import {HttpClient} from '@esgi/api';
import {SelfAssessSwitch} from './self-assess-switch/self-assess-switch';
import SelfAssessPreview from './self-assess-preview/self-assess-preview';
import {OpenQuestionBankEvent, ResetRequested} from 'shared/modules/test-details/events';
import {deepCopy} from 'shared/utils';

const questionBankFeatureOn = true;
export class State {
	dirty: boolean = false;
	saving: boolean = false;
	questions: Question[] = [];
	dragAndDropIsProccessing: boolean;
	testIntroTile: TestIntro = new TestIntro();
	totalPossibleState: TotalPossibleState = new TotalPossibleState();
	questionEditor: QuestionEditorQuestionState = null;
	testIntroEditor: TestIntroEditorTestIntroState = null;
	validation: ValidationResult = new ValidationResult();
	lastSavedDirection: string = '';
	mostRecentColors: string[] = [];
	lastQuestionXml: string;
	viewMode: ViewMode = ViewMode.TeacherOneOnOne;
	showSelfAssessPreview: boolean = false;
	showPreview: boolean;
}

export class Props extends SharedProps<State> {
	backgroundChanged: () => void;
	isWhiteBackground: boolean;
	testId: number;
	canEdit: boolean;
	testType: TestType;
	testName: string;
	totalPossible: number;
	contentAreaId: number;
	contentArea: string;
	gradeLevelIDs: number[];
	stateStandard: string;
	displaySelfAssessArea: boolean;
	selfAssessQuestions: SelfAssessQuestion[];
	onQuestionDelete: (id: number) => void;
	saveTest: (callback: (id: number, name: string) => void) => void;
	totalPossibleTouched: boolean;
	mostRecentColorsChanged: (colors: string[]) => void;
	questionListChanged: () => void;
	viewMode?: ViewMode;
	stateStandardDetached?: () => void;
	toggleModalVisibility?: () => void;
	onStandardAffectingChange?: () => void;
	clearSS?: () => void;
	verificationModalWasShown?: boolean = false;
}

export class QuestionPanel extends SharedComponent<State, Props> {
	private openingEditor: boolean = false;
	private isIOS: boolean = isIOS();
	private addQuestionRef: HTMLDivElement = null;
	private validator: Validator;
	private shapeDefinitions: ShapeDefinitions = new ShapeDefinitions();
	private shapeDefinitionBuilder: ShapeDefinitionBuilder;
	private currentUser = userStorage.get();


	constructor(props?: Props) {
		super(props);

		this.state = new State();

		this.shapeDefinitionBuilder = this.props.isWhiteBackground
			? new ShapeDefinitionBuilderWhite()
			: new ShapeDefinitionBuilderBlack();
	}

	componentDidUpdate(prevProps: Props, prevState: State, snapshot): void {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if (prevProps.isWhiteBackground != this.props.isWhiteBackground) {
			if (this.props.isWhiteBackground) {
				this.shapeDefinitionBuilder.changeToWhite(this.shapeDefinitions);
			} else {
				this.shapeDefinitionBuilder.changeToBlack(this.shapeDefinitions);
			}
		}
	}

	componentDidMount(): void {
		this.subscribe(ResetRequested, () => this.onResetRequested());
		this.validator = Validator.validate(() => {
			if (!(this.state.questions.length || this.state.testIntroTile.pageId)) {
				return 'question count is 0';
			}
			return '';
		});
		if(this.props.displaySelfAssessArea){
			this.setState((prev) => ({...prev, viewMode: ViewMode.SelfAssess}));
		}

		this.shapeDefinitionBuilder.Build(this.shapeDefinitions);
	}

	public async validate() {
		const result = await this.validator.validate();

		this.setState({validation: result});
		return result.valid === ValidationStatus.Valid;
	}

	private onResetRequested() {
		const newState = deepCopy(this.state.questions);
		newState.forEach(x => {
			if (x.stateStandardID !== x.defStateStandardID) {
				x.stateStandardID = x.defStateStandardID;
				x.stateStandard = x.defStateStandard;
				x.contentAreaID = x.defContentAreaID;
				Api.updateQuestionStateStandard({
					questionID: x.id,
					stateStandardID: x.defStateStandardID,
				}).subscribe();
			}
		});
		this.setState({questions: newState});
	}

	private questionAddedOrUpdated(needClose: boolean, question?: IUpdateResult) {
		if (question) {
			if (this.state.questionEditor.question.questionID) {
				const updQuestions = this.state.questions.map(q => {
					if (q.id === question.id) {
						const updQuestion = {...q};
						updQuestion.id = question.id;
						updQuestion.shortTitle = question.name;
						updQuestion.modifyTicks = (new Date()).getTime();
						updQuestion.questionImagePregenerated = false;
						updQuestion.imageQuestion = q.imageQuestion;
						if (!!updQuestion.stateStandardID && !this.state.questionEditor.form.stateStandardID) {
							updQuestion.stateStandardID = null;
							updQuestion.stateStandard = '';
							updQuestion.contentAreaID = 0;

							Api.updateQuestionStateStandard({
								questionID: question.id,
								stateStandardID: null,
							}).subscribe();
						}
						return updQuestion;
					} else {
						return {...q};
					}
				});

				this.setState({questions: updQuestions});

				this.state.questions.forEach((q, i, arr) => {
					if (q.id === question.id && i === arr.length - 1) {
						this.setState({lastQuestionXml: question.xml});
					}
				});
			} else {
				const qm = {
					imageQuestion: false,
					modifyTicks: 0,
					orderNum: 0,
					id: question.id,
					defStateStandardID: null,
					defStateStandard: '',
					stateStandardID: null,
					stateStandard: '',
					contentAreaID: 0,
					defContentAreaID: 0,
					questionImagePregenerated: false,
					questionImageIsProcessing: false,
					shortTitle: question.name,
				};

				const directions = this.state.questionEditor.form.directions.value;

				this.setState({
					questions: this.state.questions.concat(qm),
					lastSavedDirection: directions,
					lastQuestionXml: question.xml,
				}, async () => {
					if (this.state.questions.length === 1) {
						this.makeTestExplorerImageQuestion(0);
					}
					await this.validate();
				});
			}

			if (needClose) {
				this.shapeDefinitions = question.definitions;
			}

			if (this.state.mostRecentColors.toString() != this.state.questionEditor.rightPanel.mostRecentColors.toString()) {
				this.props.mostRecentColorsChanged(this.state.questionEditor.rightPanel.mostRecentColors);
			}
		}

		if (needClose) {
			this.setState({questionEditor: null});
		}

		this.setState({dirty: true});
	}

	private addQuestion = (testId?: number, testName?: string) => {
		if (this.props.displaySelfAssessArea && questionBankFeatureOn) {
			this.props.toggleModalVisibility();
			this.eventBusManager.dispatch(
				OpenQuestionBankEvent,
				new OpenQuestionBankEvent(
					testId,
					this.props.contentAreaId,
					this.props.contentArea,
					this.props.gradeLevelIDs,
					this.props.stateStandard,
					this.props.testName,
					this.props.selfAssessQuestions.map((question) => {
						const originQuestion = this.state.questions.find((q) => q.id === question.id);
						return ({
							...question,
							name: originQuestion.shortTitle,
							orderNum: originQuestion.orderNum,
						});
					}),
				),
			);
			return;
		}

		const newState = {...this.state};
		newState.questionEditor = new QuestionEditorQuestionState();

		newState.questionEditor.question.testID = testId || this.props.testId;
		newState.questionEditor.question.testName =
			testName || this.props.testName;
		newState.questionEditor.question.isWhiteBackground =
			this.props.isWhiteBackground;
		newState.questionEditor.question.orderNum =
			this.state.questions.length + 1;
		newState.questionEditor.question.testType = this.props.testType;
		newState.questionEditor.rightPanel.mostRecentColors =
			this.state.mostRecentColors;

		if (this.state.lastSavedDirection.length > 0) {
			newState.questionEditor.form.directions.value =
				this.state.lastSavedDirection;
		}
		this.setState(newState);
	};

	private addTestIntro = (testId?: number) => {
		const newState = new TestIntroEditorTestIntroState();

		newState.testIntro.testID = testId || this.props.testId;
		newState.testIntro.isWhiteBackground = this.props.isWhiteBackground;
		newState.rightPanel.mostRecentColors = this.state.mostRecentColors;

		this.setState({testIntroEditor: newState});
	};

	private preview() {
		if (this.state.viewMode === ViewMode.SelfAssess) {
			this.setState({showSelfAssessPreview: true});
		} else {
			this.setState({showPreview: true});
		}
	}

	get canAddQuestion(): boolean {
		const isNotEmptySingleScore = this.state.questions.length > 0 && this.props.testType === TestType.Score;
		return this.props.canEdit && !isNotEmptySingleScore;
	}

	private deleteQuestion(indx: number) {
		if (!this.props.verificationModalWasShown && this.props.onStandardAffectingChange) {
			this.props.onStandardAffectingChange();
			return;
		}

		if (this.openingEditor) {
			return;
		}

		if (this.state.questions.length <= 1) {
			return OldAlerts.bsalert('You can not delete the last question.');
		} else {
			OldAlerts.bsconfirm(
				{
					title: 'Are You Sure You Want to Delete this Question?',
					message: 'Doing so will remove this question from existing test results. This action cannot be undone.',
					className: 'delete-question-dialog',
					modal: {
						buttons: [
							{
								className: 'btn btn-transparent btn-primary',
								title: 'CANCEL',
								cancel: true,
								closeModal: true,
							},
							{
								className: 'btn btn-transparent btn-primary',
								title: 'YES, DELETE',
								cancel: true,
								closeModal: true,
								onClick: () => {
									this.props.clearSS();

									const question = this.state.questions[indx];

									Api.deleteQuestion(question.id).subscribe(() => {
										const newQuestions = [...this.state.questions];
										this.props.onQuestionDelete(question.id);
										newQuestions.splice(indx, 1);

										this.setState({
											questions: newQuestions,
										});

										this.setDirty(true);

										this.props.questionListChanged();

										setTimeout(() => {
											if (question.imageQuestion) {
												this.makeTestExplorerImageQuestion(0);
											}
										}, 1);
									});
								},
							}],
					},
				});
		}
	}

	private editQuestion(indx: number) {
		if (!this.props.verificationModalWasShown && this.props.onStandardAffectingChange) {
			this.props.onStandardAffectingChange();
			return;
		}

		this.props.clearSS();

		const newState = {...this.state};
		newState.questionEditor = new QuestionEditorQuestionState();

		newState.questionEditor.question.questionID = this.state.questions[indx].id;
		newState.questionEditor.question.isWhiteBackground = this.props.isWhiteBackground;
		newState.questionEditor.rightPanel.mostRecentColors = this.state.mostRecentColors;

		this.setState(newState);
	}

	private editTestIntro = () => {
		if (!this.props.verificationModalWasShown && this.props.onStandardAffectingChange) {
			this.props.onStandardAffectingChange();
			return;
		}

		const newState = {...this.state};

		newState.testIntroEditor = new TestIntroEditorTestIntroState();
		newState.testIntroEditor.testIntro.testID = this.state.testIntroTile.testId;
		newState.testIntroEditor.testIntro.id = this.state.testIntroTile.pageId;
		newState.testIntroEditor.testIntro.isWhiteBackground = this.props.isWhiteBackground;
		newState.testIntroEditor.rightPanel.mostRecentColors = this.state.mostRecentColors;

		this.setState(newState);
	};

	private testIntroAddedOrUpdated(testIntro?: IUpdateResult) {
		if (testIntro) {
			const {testIntroTile} = this.state;
			let model: TestIntro;
			if (this.state.testIntroEditor.testIntro.id) {
				model = {
					testId: this.props.testId,
					modifyTicks: (new Date()).getTime(),
					pageId: testIntroTile.pageId,
					pregenerated: false,
					imageIsProcessing: false,
					readonly: testIntroTile.readonly,
				};
			} else {
				model = {
					testId: this.props.testId,
					modifyTicks: (new Date()).getTime(),
					pageId: testIntro.id,
					pregenerated: false,
					imageIsProcessing: false,
					readonly: false,
				};
			}

			if (this.state.mostRecentColors.toString() != this.state.testIntroEditor.rightPanel.mostRecentColors.toString()) {
				this.props.mostRecentColorsChanged(this.state.testIntroEditor.rightPanel.mostRecentColors);
			}

			this.shapeDefinitions = testIntro.definitions;
			this.setState({testIntroTile: model, dirty: true});
		}

		this.setState({testIntroEditor: null});
	}

	private makeTestExplorerImageQuestion(indx: number) {
		const question = this.state.questions[indx];
		const request = {
			testID: this.props.testId,
			questionID: question.id,
		};

		Api.setTestExplorerImage(request).subscribe(() => {

			console.log(`setTestExplorerImage request is done`);

			const questions = [...this.state.questions];
			const newQuestions = questions.map((q, i) => {
				q.imageQuestion = (indx == i);
				return q;
			});

			this.setState({questions: newQuestions});

			this.dispatch(Events.TestImageUpdated, Events.TestImageUpdated(this.props.testId, question.id));
		});
	}

	private deleteTestIntro = () => {
		if (!this.props.verificationModalWasShown && this.props.onStandardAffectingChange) {
			this.props.onStandardAffectingChange();
			return;
		}

		return OldAlerts.bsconfirm('Are you sure you want to delete the Test Intro Page?', (result) => {
			if (result) {
				return HttpClient.default.ESGIApi.post('assets/tests', 'intro-page/delete', {
					testIntroPageId: this.state.testIntroTile.pageId,
					testId: this.props.testId,
				}).subscribe(() => {
					const newState = {...this.state.testIntroTile};
					newState.pageId = 0;
					newState.testId = this.props.testId;
					this.setState({testIntroTile: newState, dirty: true});
				});
			}
		});
	};

	private randomizeQuestions = () => {
		const questions = [...this.state.questions];

		let j, x, i;
		for (i = questions.length; i; i -= 1) {
			j = Math.floor(Math.random() * i);
			x = questions[i - 1];
			questions[i - 1] = questions[j];
			questions[j] = x;
		}
		this.setState({questions: questions}, () => {
			const ids = questions.map(q => q.id);
			HttpClient.default.ESGIApi.post('assets/test-details', 'save-questions-order', {
				testId: this.props.testId,
				questionIDs: ids,
			}).subscribe(() => {
				this.setDirty(true);
			});
		});
	};

	private setDirty(isDirty: boolean) {
		this.setState({dirty: isDirty});
	}

	questionOrdered(elements) {
		const ids = elements.map((e) => parseInt(e.getAttribute('data-id'))).filter(e => !isNaN(e));

		const newState = {...this.state};
		newState.questions = [...this.state.questions].sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

		this.setState(newState, () => {
			HttpClient.default.ESGIApi.post('assets/test-details', 'save-questions-order', {
				testId: this.props.testId,
				questionIDs: ids,
			}).subscribe(() => {
				this.setDirty(true);
			});
		});
	}

	private get addQuestionTitle() {
		return this.isIOS && 'Question' || 'Add Question';
	}

	private get previewTestTitle() {
		return 'Preview';
	}

	private randomizeClicked = () => {
		OldAlerts.bsconfirm({
			className: 'randomize-questions-dialog',
			message: 'Are you sure you want to randomize the order of your ' + this.state.questions.length + ' test questions?',
			modal: {
				buttons: [
					{
						title: 'Cancel',
						className: 'btn btn-transparent btn-primary',
						closeModal: true,
					},
					{
						title: 'Yes, Randomize',
						className: 'btn btn-transparent btn-primary',
						closeModal: true,
						onClick: this.randomizeQuestions,
					},
				],
			},
		});
	};

	private changeStateStandart(questionID: number) {
		const question = this.state.questions.filter(x => x.id === questionID)[0];

		const stateStandardslibrary = new StateStandardsLibrary({
			selectedContentAreaId: question.contentAreaID || this.props.contentAreaId,
			selectedStateStandardId: question.stateStandardID,
			selectedStateStandardText: question.stateStandard,
			stateId: this.currentUser.stateID,
		});

		const modal = new ModalWindow(stateStandardslibrary,
			{
				allowClose: true,
				showHeader: true,
				showFooter: true,
				className: 'state-standard-modal',
				title: 'State Standards Library',
				buttons: [
					{
						title: 'Add',
						className: 'btn btn-primary btn-sm',
						submit: true,
						align: 'right',
						onClick: stateStandardslibrary.view.okClicked,
						closeModal: false,

					},
					{
						title: 'Close',
						className: 'btn btn-sm ',
						submit: true,
						align: 'right',
						icon: 'fa fa-times',
						closeModal: true,
						cancel: true,
					},
				],
			});

		stateStandardslibrary.events.onUpdate((event, changes) => {
			question.stateStandardID = changes.stateStandardId;
			question.stateStandard = changes.stateStandard;
			question.contentAreaID = changes.contentAreaId;

			const newState = {...this.state};
			newState.questions = this.state.questions.map(x => x.id === questionID ? question : {...x});
			this.setState(newState);
			showSnackbarNotification(`State Standard has been added to the question.`);
			Api.updateQuestionStateStandard({
				questionID: question.id,
				stateStandardID: changes.stateStandardId,
			}).subscribe();
		});

		stateStandardslibrary.events.onClosed(() => {
			modal.close();
		});

		modal.load();
	}

	private get disableAddButton() {
		return questionBankFeatureOn && !this.currentUser.showNewUIVersion && this.props.displaySelfAssessArea;
	}

	private get addQuestionDisabledTooltip() {
		if (!this.disableAddButton) {
			return '';
		}
		if (this.currentUser.userType === UserType.T) {
			return 'Tests with self-assess versions can only be customized using the Item Bank feature. Please switch to the new ESGI to add questions to this test.';
		}
		return 'Tests with self-assess versions cannot be edited.';
	}

	render() {
		let name = 'question-outer ';
		name += this.props.testType == TestType.Score && 'single-score ' || '';

		const tooltip = this.addQuestionRef && (this.state.validation.valid === ValidationStatus.Invalid &&
			<ValidationTooltip element={this.addQuestionRef} placement={'top'} container={this.addQuestionRef} notHide>
				Please create a test question
			</ValidationTooltip>
		) || null;
		const questionsToRender = this.state.questions.filter(
			(x) =>
				(this.state.viewMode === ViewMode.SelfAssess
					&& this.props.selfAssessQuestions?.find((saq) => saq.id === x.id))
					|| this.state.viewMode === ViewMode.TeacherOneOnOne);
		const questionsCount = this.state.viewMode === ViewMode.TeacherOneOnOne ? this.state.questions.length : this.props.selfAssessQuestions?.length ?? 0;
		return <>
			{this.state.showPreview &&
				<TestPreview testID={this.props.testId} onClose={() => this.setState({showPreview: false})}/>}
			{this.state.questionEditor != null &&
				<QuestionEditor testType={this.props.testType}
				                onSaved={(needClose: boolean, question: IUpdateResult) => this.questionAddedOrUpdated(needClose, question)}
				                questionAdded={this.props.questionListChanged}
				                shapeDefinitions={this.shapeDefinitions}
				                state={this.state.questionEditor}
				                onChange={(ch, cb) => this.setState({questionEditor: ch}, cb)}/>}
			{this.state.testIntroEditor != null && <TestIntroEditor
				onSaved={(testIntro: IUpdateResult) => this.testIntroAddedOrUpdated(testIntro)}
				shapeDefinitions={this.shapeDefinitions}
				state={this.state.testIntroEditor}
				onChange={(ch, cb) => this.setState({testIntroEditor: ch}, cb)}/>}
			<div className={'question-panel ' + (this.isIOS && 'mobile' || '')}>
				<div className='panel-header'>
					<div className='questions-count'>
						{this.props.testType === TestType.Score
							? <TotalPossible
								canEdit={this.props.canEdit}
								state={this.state.totalPossibleState}
								onChange={(ch, cb) => {
									this.setState({totalPossibleState: ch}, cb);
								}}
								totalPossible={this.props.totalPossible}
								touched={this.props.totalPossibleTouched}
							/>
							: this.props.testType == TestType.YN ? <> <span>Questions: </span>{questionsCount}<span
								className='questions-count-text'></span></> : null
						}
					</div>
					<div className='buttons'>
						{this.props.displaySelfAssessArea && <SelfAssessSwitch mode={this.state.viewMode}
						                                                       onModeChanged={(m) => this.setState({viewMode: m})}/>}
						{this.canAddQuestion &&
						<OnHoverTooltip message={this.addQuestionDisabledTooltip}>
							<div className='btn-add' ref={(r) => this.addQuestionRef = r}>
									<Primary
										className={(this.addQuestionRef && this.state.validation.valid === ValidationStatus.Invalid) && 'error'}
										onClick={() => {
											if (!this.props.verificationModalWasShown && this.props.onStandardAffectingChange) {
												this.props.onStandardAffectingChange();
												return;
											}

											this.props.clearSS();

											if (this.props.testId === 0) {
												this.setState({saving: true});
												this.props.saveTest((id, name) => {
													this.addQuestion(id, name);
													this.setState({saving: false});
												});
											} else {
												this.addQuestion(this.props.testId);
											}
										}}
										disabled={this.state.saving || this.disableAddButton}
									>
										<i className='ace-icon fa fa-plus'/>
										<span>{this.addQuestionTitle}</span>
									</Primary>
								{tooltip}
							</div>
						</OnHoverTooltip>
						}
						{this.state.questions.length &&
							<Primary
								onClick={() => this.preview()}
								disabled={!this.state.questions || this.state.questions.length < 1 || this.props.testId === 0}
								className='btn-preview'
							>
								<i className='ace-icon fa fa-play'/>
								<span>{this.previewTestTitle}</span>
							</Primary> || null}
					</div>
				</div>
				<div id='question-panel-for-loading'
				     className={`question-panel-for-loading ${OsChecker.isMac() && 'mac'}`}>
					<DragAndDrop enabled={this.props.canEdit && this.state.questions.length > 1}
					             itemSelector='.te-question:not(.test-intro-thumbnail)'
					             placeholder='te-question-placeholder ui-sortable-placeholder'
					             orderChanged={(elements) => this.questionOrdered(elements)}
					             onStop={(ui) => {
						             if (OsChecker.isMac()) {
							             ui.item.removeClass('mousedown').css({cursor: 'grab'});
						             }

						             const newState = {...this.state};
						             newState.dragAndDropIsProccessing = false;
						             this.setState(newState);
					             }}
					             onStart={(ui) => {
						             if (OsChecker.isMac()) {
							             ui.item.css({cursor: 'grabbing'});
						             }

						             const newState = {...this.state};
						             newState.dragAndDropIsProccessing = true;
						             this.setState(newState);
					             }}>
						<div className={name}>
							<div className='top-questions-container'>
								{this.props.canEdit && <div
									className={this.props.testType === TestType.Score ? 'background-selector single-score' : 'background-selector yes-no'}>
									<span>Background: </span>
									<div
										onClick={() => this.props.isWhiteBackground === true && this.props.backgroundChanged()}
										className={!this.props.isWhiteBackground ? 'background-box black selected' : 'background-box black'}>
									</div>
									<div
										onClick={() => this.props.isWhiteBackground === false && this.props.backgroundChanged()}
										className={this.props.isWhiteBackground ? 'background-box white selected' : 'background-box white'}>
									</div>
								</div>}
								{this.props.canEdit && this.state.questions.length > 1 &&
									<div className='randomize'>
										<a className='random-button' href='#'
										   onClick={this.randomizeClicked}>
											<i className='fa fa-random blue random-icon' aria-hidden='true'></i>
											<span>Randomize</span>
										</a>
									</div>
								}
							</div>
							<ul id='questions-ul-container' className='ace-thumbnails clearfix question-inner'>
								<>
									{(this.state.testIntroTile.pageId &&
											<TestIntroTile
												{...this.state.testIntroTile}
												isWhiteBackground={this.props.isWhiteBackground}
												dragAndDropIsProccessing={this.state.dragAndDropIsProccessing}
												editHandler={this.editTestIntro}
												deleteHandler={this.deleteTestIntro}
												testType={this.props.testType}
											/>) ||
										(this.props.canEdit && <TestIntroCreaterTile
											{...this.state.testIntroTile}
											createHandler={() => {
												if (this.props.testId === 0) {
													this.props.saveTest((id) => {
														this.addTestIntro(id);
													});
												} else {
													this.addTestIntro(null);
												}
											}}
											testType={this.props.testType}
										/>)
									}
									{questionsToRender.map((question, index) => <QuestionTile
											key={question.id}
											changeStateStandart={() => this.changeStateStandart(question.id)}
											stateStandart={question.stateStandard}
											stateStandartID={question.stateStandardID}
											questionId={question.id}
											readOnly={!this.props.canEdit}
											hasSelfAssessVersion={this.props.displaySelfAssessArea}
											orderNum={question.orderNum}
											shortTitle={question.shortTitle}
											imageQuestion={question.imageQuestion}
											questionImagePregenerated={question.questionImagePregenerated}
											questionImageIsProcessing={question.questionImageIsProcessing}
											modifyTicks={question.modifyTicks}
											testId={this.props.testId}
											deleteHandler={() => this.deleteQuestion(index)}
											editHandler={() => this.editQuestion(index)}
											makeTestExplorerImageHandler={() => this.makeTestExplorerImageQuestion(index)}
											testType={this.props.testType}
											isWhiteBackground={this.props.isWhiteBackground}
											dragAndDropIsProccessing={this.state.dragAndDropIsProccessing}
											viewMode={this.state.viewMode}
											selfAssessThumbnailUrl={this.props.selfAssessQuestions?.find(saq => saq.id === question.id)?.thumbnailUrl}
										/>)}
								</>
							</ul>
						</div>
					</DragAndDrop>
				</div>
			</div>
			{!!this.state.showSelfAssessPreview && <SelfAssessPreview questions={this.props.selfAssessQuestions}
			                                                          testName={this.props.testName}
			                                                          onClose={() => this.setState({showSelfAssessPreview: false})}/>}
		</>;
	}
}
