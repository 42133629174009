import {BaseService} from '@esgi/core/service';
import {ShareTestingModel, StudentScreenClient} from 'modules/assessments';
import {BehaviorSubject, Subject, switchMap} from 'rxjs';
import {SsoTracker} from '@esgi/core/tracker';
import {SessionCode, Summary} from './models';
import {ShareScreenImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';

const localStorageKey: string = 'secondScreenSecretKey';

export class SecondScreenService extends BaseService {
	public connectStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public onError: Subject<string> = new Subject<string>();
	public questionsLoadRange: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
	public testingModel: Subject<ShareTestingModel> = new Subject();
	public summaryModel: Subject<Summary> = new Subject();
	public questionIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public clientScreenSession = new StudentScreenClient();
	private questionLoadStep = 5;

	constructor() {
		super();

		this.clientScreenSession.onBadCode.subscribe(() => {
			SsoTracker.trackEvent({
				trackingEvent: '2SFail',
			});
			this.onError.next('This is not a valid code.');
		});

		this.clientScreenSession.onApplied.subscribe(() => {
			this.onError.next('Invalid code. This code is already in use.');
		});

		this.clientScreenSession.onConnectError.subscribe(() => {
			this.onError.next('Connection fail.');
		});

		this.clientScreenSession.onShowQuestion.subscribe(index => this.questionIndex.next(index));
		this.questionIndex.subscribe(index => this.refreshQuestionRange(index) );
		this.clientScreenSession.onSummary.subscribe(data => this.summaryModel.next(data.summary));

		this.clientScreenSession.onJoined.subscribe((data) => {
			if (data.secretCode) {
				const expiredTime = Date.now() + (24 * 60 * 60 * 1e3);
				localStorage[localStorageKey] = JSON.stringify(new SessionCode(data.secretCode, expiredTime));
			}
			const {test, summary} = data.testInfo;
			this.connectStatus.next(true);
			this.testingModel.next(test);
			this.summaryModel.next(summary);
			if (test) {
				this.questionIndex.next(test.currentQuestionIndex);
			}
		});

		this.clientScreenSession.onSessionEnded.subscribe(() => {
			this.clearStoredSecret();
			this.connectStatus.next(false);
		});

		this.clientScreenSession.onTestStarted.subscribe(data => {
			const shareScreenUrlBuilder = new ShareScreenImageUrlBuilder(data.bucketName, this.getStoredSecret());
			for(const q of data.questions){
				q.fallbackUrl = shareScreenUrlBuilder.large(q.questionID, data.testID, false, q.modifyTicks);
			}
			this.testingModel.next(data);
		});

		this.clientScreenSession.onTestEnded.subscribe(() => this.clearData());

		this.clientScreenSession.onDisconnected.pipe(switchMap(() => this.clientScreenSession.onConnected)).subscribe(() => {
			if(this.connectStatus.value && this.getStoredSecret()) {
				this.clientScreenSession.joinWithSecret(this.getStoredSecret());
			} else {
				this.connectStatus.next(false);
			}
		});

		this.clientScreenSession.onReconnected.subscribe((testInfo) => {
			if (testInfo.summary) {
				this.summaryModel.next(testInfo.summary);
			} else if (testInfo.test) {
				this.testingModel.next(testInfo.test);
				this.clientScreenSession.synchronizeCurrentQuestion();
			} else {
				this.clearData();
			}
		});
	}

	private refreshQuestionRange(index: number){
		const [minIndex, maxIndex] = this.questionsLoadRange.value;
		if(!minIndex && !maxIndex){
			this.questionsLoadRange.next([index-this.questionLoadStep, index+this.questionLoadStep]);
			return;
		}
		const newMinIndex = minIndex < index - this.questionLoadStep ? minIndex : index - this.questionLoadStep;
		const newMaxIndex = maxIndex > index + this.questionLoadStep ? maxIndex : index + this.questionLoadStep;
		this.questionsLoadRange.next([newMinIndex, newMaxIndex]);
	}

	public clearStoredSecret(): void {
		localStorage.removeItem(localStorageKey);
	}

	public getStoredSecret(): string | undefined {
		const storedSession = localStorage[localStorageKey];

		if (storedSession) {
			const sessionCode = JSON.parse(storedSession) as SessionCode;
			const expiredTime = new Date(sessionCode.expiredTime).getTime();
			const currentTime = Date.now();
			if (currentTime > expiredTime) {
				localStorage.removeItem(localStorageKey);
			} else {
				return sessionCode.code;
			}
		}
	}

	public join(code: string) {
		this.clientScreenSession.join(code);
	}

	public joinWithSecret(secret: string) {
		this.clientScreenSession.joinWithSecret(secret);
	}

	public syncCurrentQuestion(): void {
		this.clientScreenSession.synchronizeCurrentQuestion();
	}

	public clearData() {
		this.testingModel.next(null);
		this.summaryModel.next(null);
		this.questionIndex.next(0);
	}

	public clearService() {
		this.clearData();
		this.connectStatus.next(false);
	}
}
