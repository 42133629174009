import {styled} from '@stitches/react';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const ReportsFiltersContainer = styled(FlexBox, {
	borderRight: '2px solid $neutral99',
	padding: '10px',
	alignItems: 'flex-start',
	flexDirection: 'row',
	paddingBottom: '5px',
});

export const LabelStyled = styled(Text, {
	marginRight: '13px',
	fontSize: '12px',
	variants: {
		selected: {
			true: {
				fontWeight: 'bold',
			},
		},
		inactive: {
			true: {
				color: 'lightgray',
			},
		},
	},
});