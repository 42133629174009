import {computed, observable, observableArray} from '@esgi/deprecated/knockout';
import moment, {Moment} from 'moment';
import {PurchaseFormModel} from '../kit/purchase-form';
import {JoinToDistrictFormComponentModel} from './components/join-to-district-form';
import {ActivationCodeRequest, CalculateRenewalCartPriceResponce, Product, ProductName, ServiceModel} from './server';

export function roundMoney(price: number): number {
	return parseFloat(printMoney(price));
}

export function printMoney(price: number): string {
	if (price) {
		return price.toFixed(2);
	} else {
		return '0.00';
	}
}

export class CartModel {
	joinToDistrict: JoinToDistrictFormComponentModel;

	constructor() {
		this.expiration = new ExpirationCartModel();
		this.student = new StudentCartModel();
	}

	productsMaxExpirationDate = () => {
		return this.expiration.productsMaxExpirationDate();
	};

	productsMaxCurrExpirationDate = () => {
		return this.expiration.productsMaxCurrExpirationDate();
	};

	student: StudentCartModel;
	expiration: ExpirationCartModel;

	@computed()
	get studentsPrice() {
		let price = this.student.calculateFullPrice(this.student.newStudentLimit, this.productsMaxExpirationDate(), this.productsMaxCurrExpirationDate());

		return printMoney(price);
	}

	price = ko.computed({
		read: () => {
			let totalPrice = 0;

			totalPrice += parseFloat(this.studentsPrice);
			totalPrice += parseFloat(this.expiration.price());

			return printMoney(totalPrice);
		},
		deferEvaluation: true,
	});

	activationCode: ActivationCodeRequest;
	creditCard: PurchaseFormModel;

}

export class StudentCartModel {
	public static defaultStudensLimit = 35;
	public static maxStudensLimit = 50;

	@observable()
	currentStudentLimit: number = 0;

	@observable()
	newStudentLimit: number = 0;

	@computed()
	get isAbleToPurchase() {
		return this.newStudentLimit > StudentCartModel.defaultStudensLimit || this.currentStudentLimit > StudentCartModel.defaultStudensLimit;
	}

	@observable()
	studentYearPrice: number = 5;

	getFullPrice(studentsMonths: number, studentYearPrice: number, studentsCount: number): number {
		return parseFloat((studentsMonths / 12 * studentYearPrice).toFixed(2)) * studentsCount;
	}

	calculateFullPrice(newStudLimit: number, maxExpDate: Moment, maxCurrExpDate: Moment) {
		let result = 0;
		if (maxExpDate.diff(maxCurrExpDate) === 0) {
			let newStudMonth = this.monthsForNewStudents(maxExpDate);
			result = this.getCurrentStudentsPrice(maxCurrExpDate, maxExpDate, newStudLimit);

			let newExtraStudents = this.getNewExtraStudents(newStudLimit, this.currentStudentLimit);
			let newStudentsPrice = this.getFullPrice(newStudMonth, this.studentYearPrice, newExtraStudents);
			result += newStudentsPrice;
		} else {
			let newStudMonth = this.monthsForNewStudents(maxExpDate);
			let currentStudentsPrice = this.getCurrentStudentsPrice(maxCurrExpDate, maxExpDate, newStudLimit);
			result += currentStudentsPrice;

			let newExtraStudents = this.getNewExtraStudents(newStudLimit, this.currentStudentLimit);
			let newStudentsPrice = this.getFullPrice(newStudMonth, this.studentYearPrice, newExtraStudents);
			if (newStudentsPrice > 0) {
				result += newStudentsPrice;
			}
		}
		if (result < 0) {
			return 0;
		}
		return result;
	}

	monthsForNewStudents(maxExpirationDate: Moment): number {
		const now = moment().endOf('day');
		const diff = new momentDiffMonths(now, maxExpirationDate);
		return diff.Months;
	}

	monthsForCurrentStudents(maxExpirationDate: Moment, maxCurrExpirationDate: Moment): number {
		if (maxExpirationDate.diff(maxCurrExpirationDate) === 0){
			return 0;
		}
		if (maxExpirationDate > maxCurrExpirationDate){  // if current subscription not expired yet
			let diff = new momentDiffMonths(maxCurrExpirationDate, maxExpirationDate);
			const now = moment().endOf('day');
			if (maxCurrExpirationDate.isBefore(now)) { // if current subscription expired
				diff = new momentDiffMonths(now, maxExpirationDate);
			}
			
			return diff.Months;
		}
	}

	getCurrentStudentsPrice(maxCurrExpDate: Moment, maxExpDate: Moment, newStudLimit: number): number {
		let currentExtraStudents = this.getCurrentExtraStudents(newStudLimit);

		let currentMonths = this.monthsForCurrentStudents(maxExpDate, maxCurrExpDate);
		return this.getFullPrice(currentMonths, this.studentYearPrice, currentExtraStudents);
	}

	getCurrentExtraStudents(newStudLimit: number): number {
		let currentExtraStudents = 0;
		if (this.currentStudentLimit > StudentCartModel.defaultStudensLimit){
			currentExtraStudents = this.currentStudentLimit - StudentCartModel.defaultStudensLimit;
		}
		if (newStudLimit < this.currentStudentLimit) {
			currentExtraStudents = newStudLimit - this.currentStudentLimit;
		}
		return currentExtraStudents;
	}

	getNewExtraStudents(newStudentLimit: number, currentStudentLimit: number): number {
		let newExtraStudents = 0;
		if (currentStudentLimit < newStudentLimit ) {
			newExtraStudents = newStudentLimit - currentStudentLimit;
		}
		if (currentStudentLimit < StudentCartModel.defaultStudensLimit) {
			newExtraStudents = newStudentLimit - StudentCartModel.defaultStudensLimit;
		}
		return newExtraStudents;
	}

}

export class momentDiffMonths {
	constructor(expDate: Moment, newExpDate: Moment) {

		let diffMonths = newExpDate.diff(expDate, 'months', true);
		if (diffMonths < 0) {
			this.Months = 0;
			return;
		}

		let years = newExpDate.year() - expDate.year();
		let months = (newExpDate.month() + 1) - (expDate.month() + 1) + years * 12;
		let days = newExpDate.diff(expDate, 'days');

		let newDay = newExpDate.date();
		let curDay = expDate.date();

		if (newDay > curDay && newDay - curDay >= 15) {
			months = months + 1;
		}
		this.Months = months;
	}

	Months: number;
}

export class ExpirationCartModel {

	years = ko.observable(1);
	months = ko.observable(0);

	addProduct(p: ServiceModel) {
		let m = ExpirationProductModel.FromResponse(p);
		//m.years = this.years;
		//m.months = this.months;

		this.products.push(m);
	}

	products: ExpirationProductModel[] = new Array<ExpirationProductModel>();

	productsMaxExpirationDate = () => {
		let newExpDate = this.products[0].newExpirationDate();
		let maxCurrExpDate = this.productsMaxCurrExpirationDate();

		let maxExpDate = moment.max(newExpDate, maxCurrExpDate);
		return maxExpDate;
	};

	productsMaxCurrExpirationDate = () => {
		return this.products[0].expirationDate;
	};

	esgiExpDaysAffectTimeDropdown = 0;

	price = ko.computed({
		read: () => {
			let totalPrice = 0;
			if (this.products.length) {
				for (let i = 0; i < this.products.length; i++) {
					totalPrice += this.products[i].selected() ? roundMoney(this.products[i].price) : 0;
				}
			}

			return printMoney(totalPrice);
		},
		deferEvaluation: true,
	});
}

export class ExpirationProductModel {

	isPurchasable = ko.observable(true);

	@observable()
	expirationDate: Moment;

	newExpirationDate = ko.observable<Moment>(moment());
	name: 'ESGI';

	static FromResponse(r: ServiceModel) {
		let m = new ExpirationProductModel();
		m.expirationDate = moment(r.expirationDate);
		m.name = r.name;
		m.price = r.ratePerYear;

		return m;
	}

	expired = ko.computed({
		read: () => {
			let expDate = this.expirationDate;
			if (expDate) {
				return expDate.isBefore(moment());
			}

			return false;
		},
		deferEvaluation: true,
	});

	@observable()
	price;

	selected = ko.observable(false);
}

export class OrderSummary {
	@observableArray()
	public Products: OrderSummaryProductPriceModel[];
	@observableArray()
	public Students: OrderSummaryStudentsPriceModel[];
	@observable()
	public Total: string;

	constructor(private model: CalculateRenewalCartPriceResponce) {

		this.Total = printMoney(model.total);

		if (model.students) {
			this.Students = model.students.map(l => {
				let s = new OrderSummaryStudentsPriceModel();

				s.PayingMonths = l.payingMonths;
				s.Quantity = l.quantity;
				s.Price = printMoney(l.price);

				return s;
			});
		}

		if (model.products) {
			this.Products = model.products.map(l => {
				let p = new OrderSummaryProductPriceModel();

				p.Product = l.product;
				p.Price = printMoney(l.price);
				p.ProductName = ProductName[l.product];
				p.PayingMonths = l.months;

				return p;
			});
		}
	}

	public Update(responce: CalculateRenewalCartPriceResponce) {

		let newPrices = new OrderSummary(responce);

		(this.Products as any).removeAll?.();
		(this.Students as any).removeAll?.();

		this.Products = newPrices.Products;
		this.Students = newPrices.Students;
		this.Total = newPrices.Total;
	}
}

export class OrderSummaryProductPriceModel {
	@observable()
	public Product: Product;
	@observable()
	public ProductName: string;
	@observable()
	public Price: string;
	@observable()
	public PayingMonths: number;
}

export class OrderSummaryStudentsPriceModel {
	@observable()
	public PayingMonths: number;
	@observable()
	public Price: string;
	@observable()
	public Quantity: number;
}
