import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Container, Score, ScoreBox, StyledRow, StyledText, QuestionsBox} from './index.styled';
import {BarRect} from './components/bar-rect';
import {barLineGap, barLineWidth} from '../rubric-diagram/constants';
import {TestSession, TestInfo} from '../../../../../../types';
import {useDiagramData} from '../rubric-diagram/use-diagram-data';

type Props = {
	session: TestSession;
	testInfo: TestInfo;
};

export function DetailedRubricDiagram({session, testInfo}: Props) {
	const {data, maxScore} = useDiagramData({session, testInfo});

	return (
		<Container>
			<FlexBox justify='between'>
				<FlexBox>
					<Text size='xSmall' font='mono' color='lowContrast'>
						#
					</Text>
					<StyledText size='xSmall' font='mono' color='lowContrast'>
						Criteria
					</StyledText>
				</FlexBox>
				<Text size='xSmall' font='mono' color='blue'>
					Score
				</Text>
			</FlexBox>
			<ScoreBox>{new Array(maxScore).fill('').map((_, idx) => (
				<Score key={idx}>
					<Text size='xSmall' font='mono' color='blue'>
						{idx + 1}
					</Text>
				</Score>
			))}
			</ScoreBox>
			<QuestionsBox>
				{
					data.map(({score, criteriaName}, idx) => (
						<FlexBox align='center' key={idx}>
							<Text size='xSmall' font='mono' color='lowContrast'>{idx + 1}</Text>
							<StyledRow>
								<Text size='small' font='mono' color='neutral40'>
									{criteriaName}
								</Text>
							</StyledRow>
							<svg x={0} y={0} width={(barLineWidth + barLineGap) * maxScore} height={16}>
								<BarRect y={0} x={0} score={score} maxScore={maxScore} width={barLineWidth}/>
							</svg>
						</FlexBox>
					))
				}
			</QuestionsBox>
		</Container>
	);
}
