import {lazyComponent} from '@esgi/core/react';
import {SubjectTab} from '@esgi/main/libs/store';

import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {withClassReportPage} from './wrapper';
import {useFakeChangesCollector} from '../old-report-launcher';

const ItemAnalysisReportModal = lazyComponent(() => import('modules/reports/item-analysis-report'));

type Props = {
	open: boolean,
	close: VoidFunction,
	hierarchy: HierarchySnapshot,
	subject: SubjectTab
};

function TotalReport(props: Props) {
	const {
		open,
		close,
		hierarchy,
		subject,
	} = props;

	const changesCollector = useFakeChangesCollector(hierarchy);

	if(open) {
		return <ItemAnalysisReportModal
			hierarchy={hierarchy}
			changesCollector={changesCollector}
			onClose={close}
			subjectID={subject.id}
			subjectType={subject.type}/>;
	}
}

export default withClassReportPage(TotalReport)