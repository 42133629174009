import {environment} from '@esgi/core/environments';
import {DefaultErrorHandler} from './handler/default-handler';
import NotAuthorizedHandler from './handler/not-authorized-handler';
import {HttpClient} from '@esgi/api';

export class HttpClientBuilder {
	public static build(): HttpClient {
		return new HttpClient([new NotAuthorizedHandler(), new DefaultErrorHandler()], environment);
	}
}
