import {TestType} from '@esgi/main/libs/core';
import {CardHeaderBox, TestLabelsBox} from './index.styled';
import {OneLinedText, TestColorIndicator} from '@esgi/main/kits/common';
import {TestTypeLabel} from '../../../../../../../../kit';
import {StateStandard} from '../../../../../../../../types';
import {useUser} from '@esgi/core/authentication';
import {MultipleLabels} from '../../../../components/multiple-labels';

type Props = {
	testID: number;
	testName: string;
	testType: TestType;
	standards: StateStandard[];
};

export function CardHeader({testID, testName, testType, standards}: Props) {
	const user = useUser();

	const userStateStandard = standards.filter(({stateID}) => stateID === user.stateID);
	const commonStandards = standards.filter(({stateID}) => stateID === 0);

	const standardsList = userStateStandard.length ? userStateStandard : commonStandards;
	
	return (
		<CardHeaderBox>
			<TestColorIndicator testID={testID} />

			<OneLinedText
				size='xLarge'
				color='base'
				css={{
					lineHeight: '21px',
				}}
			>
				{testName}
			</OneLinedText>

			<TestLabelsBox>
				<TestTypeLabel testType={testType}/>
				{standardsList.length > 0 && <MultipleLabels items={standardsList.map(s => s.name)}/>}
			</TestLabelsBox>
		</CardHeaderBox>
	);
}
