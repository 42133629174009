import {Text} from '@esgi/ui/typography';
import {StyledEmptyWrapper, StyledEmptyBox} from './index.styled';

export function TestsListEmpty() {
	return (
		<StyledEmptyWrapper>
			<StyledEmptyBox>
				<Text size='small'>No tests to display</Text>
			</StyledEmptyBox>
		</StyledEmptyWrapper>
	);
}
