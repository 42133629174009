import React from 'react';
import {SelectableList} from '@esgi/ui/form-controls';
import {Drawer} from '@esgi/main/features/teacher/home';
import {Correct, Folder, Users} from '@esgi/ui/icons';
import {LocationFormType} from '../../../forms/location';
import {ClassItem, GroupItem} from '../../../types';
import {FormElementWrapper, FormWrapper, ScrollableWrapper} from '../../../index.styled';
import {
	SelectableListRoot,
	ListLabelContainer,
} from './index.styled';
import {FormElement} from '@esgillc/ui-kit/form';

interface LocationProps {
	form: LocationFormType;
	classes: ClassItem[];
	groups: GroupItem[];
	isDisabled?: boolean;
}

export function Location({form, classes, groups, isDisabled}: LocationProps) {

	return (
		<ScrollableWrapper>
			<FormWrapper controller={form}>
				<Drawer.ContentBlock title='Classes' withDivider>
					<FormElementWrapper>
						<FormElement control={form.controls.classIDs}>
						<SelectableListRoot dataCy='classes-list'>
							<SelectableList.GroupRoot type='multiple'>
								{classes?.map((classItem) => (
									<SelectableList.Item
										key={classItem.id}
										variant='outlined'
										withActiveBackground
										IconBefore={Users}
										value={classItem.id.toString()}
										disabled={isDisabled}
									>
										{(selected) => (
											<ListLabelContainer>
												{classItem.name}
												{selected && <Correct width={24} height={24}/>}
											</ListLabelContainer>
										)}
									</SelectableList.Item>
								))}
							</SelectableList.GroupRoot>
						</SelectableListRoot>
						</FormElement>
					</FormElementWrapper>
				</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Groups' withDivider>
				<FormElementWrapper>
					<FormElement control={form.controls.groupIDs}>
					<SelectableListRoot dataCy='groups-list'>
						<SelectableList.GroupRoot type='multiple'>
							{groups?.map((groupItem) => (
								<SelectableList.Item
									key={groupItem.id}
									variant='outlined'
									withActiveBackground
									IconBefore={Folder}
									value={groupItem.id.toString()}
									disabled={isDisabled || groupItem.disabled}
								>
									{(selected: boolean) => (
										<ListLabelContainer>
											{groupItem.name}
											{selected && <Correct width={24} height={24}/>}
										</ListLabelContainer>
									)}
								</SelectableList.Item>
							))}
						</SelectableList.GroupRoot>
					</SelectableListRoot>
					</FormElement>
				</FormElementWrapper>
			</Drawer.ContentBlock>
		</FormWrapper>
		</ScrollableWrapper>
	);
}
