import {AES, MD5, pad, enc, mode} from 'crypto-js';

export class Crypto {
	private paddings = {pad: function(){}, unpad: function(){}};
	private iv = enc.Hex.parse('101112131415161718191a1b1c1d1e1f');
	constructor(private salt: string) {
	}

	// public encrypt(value: string): string {
	// 	const encrypted = AES.encrypt(value, this.salt);
	// 	const out = encrypted.toString();
	// 	return out;
	//
	// }
	//
	// public decrypt(value: string): string {
	// 	debugger;
	// 	const decrypted = AES.decrypt(value, this.salt);
	// 	const out = decrypted.toString();
	// 	return out;
	// }

	public encrypt(value: string): string {
		// try {
		// 	const encrypted = AES.encrypt(value, this.salt, {
		// 		iv: this.iv,
		// 		mode: mode.CBC,
		// 		padding: pad.Pkcs7,
		// 	});
		// 	const out = encrypted.toString();
		// 	return out;
		// } catch (e) {
		// 	console.error('Unable to encrypt:' + value);
		// 	throw e;
		// }

		return value;

	}

	public decrypt(value: string): string {
		// const decrypted = AES.decrypt(value, this.salt, {
		// 	iv: this.iv,
		// 	mode: mode.CBC,
		// 	padding: pad.Pkcs7,
		// });
		// const out = decrypted.toString(enc.Utf8);
		// return out;

		return value;
	}
}