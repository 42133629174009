import {Class, Group} from '@esgi/main/libs/store';
import {Text} from '@esgi/ui/typography';
import {Skeletonable} from '@esgi/ui';
import {Select} from '@esgi/ui/controls';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {isNull} from 'underscore';
import {SelectField, SelectOption} from './index.styled';

interface Props extends Skeletonable {
	classes: Class[];
	groups: Group[];
	value: SingleSelectState;
	disabled?: boolean;
	onChange: (value: SingleSelectState) => void;
}

export enum SelectedType {
	Class,
	Group,
}

export function SelectableClassGroup(props: Props) {
	const {skeleton, classes, groups, value, disabled, onChange} = props;

	const selectedType = !isNull(value.classId) ? SelectedType.Class : SelectedType.Group;
	const placeholder = selectedType === SelectedType.Class ? 'Class' : 'Group';
	const selectedId = selectedType === SelectedType.Class ? value.classId : value.groupId;

	const handleValueChange = (newValue: string | null) => {
		const classId = selectedType === SelectedType.Class ? Number(newValue) : null;
		const groupId = selectedType === SelectedType.Group ? Number(newValue) : null;
		onChange({
			classId,
			groupId,
			studentId: null,
			studentFromClassId: null,
			studentFromGroupId: null,
		});
	};

	return (
		<Select.Root
			value={!isNull(selectedId) ? selectedId.toString() : ''}
			disabled={disabled}
			onValueChange={handleValueChange}
		>
			<SelectField skeleton={skeleton} placeholder={placeholder} />
			<Select.Content>
				{selectedType === SelectedType.Class &&
					classes.map(({id, name}) => (
						<SelectOption value={id.toString()} key={id}>
							<Text size='medium' bold>
								{name}
							</Text>
						</SelectOption>
					))}
				{selectedType === SelectedType.Group &&
					groups.map(({id, name}) => (
						<SelectOption value={id.toString()} key={id}>
							<Text size='medium' bold>
								{name}
							</Text>
						</SelectOption>
					))}
			</Select.Content>
		</Select.Root>
	);
}
