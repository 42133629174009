import {AnswerState} from '@esgi/core/enums';
import {QuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {mapToEnum} from 'shared/utils';
import {QuestionModel, QuestionResponseModel} from './models';


export function convertToQuestionModel(q: QuestionResponseModel, bucketName: string, testID: number, restoreOldAnswerState?: boolean, continueSession?: boolean) {

	const urlBuilder = new QuestionImageUrlBuilder(bucketName);

	const question = new QuestionModel();
	question.directUrl = urlBuilder.large(q.questionID, testID, q.pregenerated, q.modifyTicks);
	question.fallbackUrl = urlBuilder.large(q.questionID, testID, false, q.modifyTicks);
	question.direction = q.directions;
	question.questionID = q.questionID;

	question.oldAnswerState = mapToEnum(q.oldAnswerState, AnswerState);
	question.answerState = mapToEnum(restoreOldAnswerState ? q.oldAnswerState : q.cacheAnswerState, AnswerState);
	question.comment = (restoreOldAnswerState || continueSession) ? q.comment : undefined;
	question.duration = q.duration;
	question.modifyTicks = q.modifyTicks;

	return question;
}
