import {useCallback, useEffect, useState} from 'react';
import {Drawer} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {Plus} from '@esgi/ui/icons';
import {FlexBox} from '@esgi/ui/layout';
import {LanguagesAndTemplatesService} from './service';
import {Button, useBehaviorSubject, useStreamEffect} from '@esgi/ui';
import {SubSidebarMenu} from './components/sub-sidebar-menu';
import {AddLanguageAlert} from './components/add-language-alert';
import {Input} from '@esgi/ui/form-controls';
import {useService} from 'libs/core/src/service';
import {ParentLetterEditor} from './components/parent-letter-editor';
import {Form, FormElement} from '@esgi/ui/form';
import {ContentWrapper, PanelContent, SidebarHeader} from '../../../index.styled';
import {SubSideBarWrapper, DrawerPanelContent, FormElementWrapper, LanguageAndTemplatesWrapper} from './index.styled';
import {draggableButtons} from './components/parent-letter-editor/constants';
import {LanguageAndTemplate} from './types';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {SettingsTab} from '../../../../../types';
import {UnsavedChangesTabAlert} from '../../../unsaved-changes-tab-alert';
import {OptionButtons} from './components/option-buttons';

export interface LanguageItem {
	id: number,
	name: string,
}

interface Props {
	languages: LanguageAndTemplate[];
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData: (tab: SettingsTab, isTouched: boolean) => void;
}

export function LanguagesAndTemplates({languages, onCloseDrawer, onUpdateTabTouchedData}: Props) {

	const service = useService(LanguagesAndTemplatesService);

	const selectedLanguage = useBehaviorSubject<LanguageAndTemplate>(service.selectedLanguage);
	const languagesData = useBehaviorSubject(service.languagesData);

	const [isAddLanguageAlertOpen, setAddLanguageAlertOpen] = useState(false);
	const [isUnsavedChangesTabAlertOpen, setUnsavedChangesTabAlertOpen] = useState(false);
	const [isLanguageTemplateTouched, setLanguageTemplateTouched] = useState(false);
	const [nextLanguageTabID, setNextLanguageTabID] = useState<number>(null);
	const [isDisableButton, setIsDisableButton] = useState(true);

	const onSave = () => {
		setIsDisableButton(true);
		service.save().subscribe({
			error: () => setIsDisableButton(false), next: () => {
				onUpdateTabTouchedData(SettingsTab.LanguagesAndTemplates, false);
			},
		});
	};

	const handleAddLanguageAlertOpen = useCallback(() => setAddLanguageAlertOpen(true), []);

	const handelChangeLanguageTab = (id: number) => {
		if (isLanguageTemplateTouched) {
			setNextLanguageTabID(id);
			setUnsavedChangesTabAlertOpen(true);
		} else {
			service.changeSelectedLanguageData(id);
		}
	};

	useStreamEffect(service.form.onChanged, () => {
		const isTouched = service.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		setLanguageTemplateTouched(isTouched);
		onUpdateTabTouchedData(SettingsTab.LanguagesAndTemplates, isTouched);
	});

	useEffect(() => {
		service.init(languages);
	}, []);

	return <LanguageAndTemplatesWrapper>
			<SubSideBarWrapper>
				<Drawer.PanelSidebar>
					<SidebarHeader>
						<Text size='small' color='neutral56'>Languages</Text>
						<Button.Icon onClick={handleAddLanguageAlertOpen}>
							<Plus width={24} height={24}/>
						</Button.Icon>
					</SidebarHeader>
					<SubSidebarMenu
						selectedItem={selectedLanguage.id}
						items={languagesData}
						onChange={handelChangeLanguageTab}
					/>
				</Drawer.PanelSidebar>
			</SubSideBarWrapper>

			<DrawerPanelContent direction='column'>
				<Drawer.PanelHeader
					withActionButton
					actionButtonText='Save Changes'
					onActionButtonClick={onSave}
					actionButtonDisabled={isDisableButton}
					onClose={onCloseDrawer}
					withOptionButtons
					optionButtons={<OptionButtons onDeleteButtonClick={() => null}/>}
				>
					<FlexBox align='center'>
						<Text size='large' color='neutral24' bold>Languages & Templates</Text>
					</FlexBox>
				</Drawer.PanelHeader>

				<OverlayScrollbarsComponent
					defer
					options={{scrollbars: {autoHide: 'leave'}}}
				>
				<Form controller={service.form}>
				<PanelContent>
					<ContentWrapper>
						<Drawer.ContentBlock title='Language Setup'>
							<FormElementWrapper>
								<FormElement control={service.form.controls.title}>
									<Input.Base placeholder='Language Title'/>
								</FormElement>
							</FormElementWrapper>
						</Drawer.ContentBlock>

						<ParentLetterEditor
							messageControl={service.form.controls.message}
							templateNameControl={service.form.controls.templateName}
							draggableButtons={draggableButtons}
						/>
					</ContentWrapper>
				</PanelContent>
				</Form>
				</OverlayScrollbarsComponent>
			</DrawerPanelContent>

			{isAddLanguageAlertOpen && <AddLanguageAlert
				onClose={() => setAddLanguageAlertOpen(false)}
				onAddClick={(name) => service.addNewLanguage(name)}
				languages={languagesData}/>
			}

			{isUnsavedChangesTabAlertOpen && <UnsavedChangesTabAlert
				onChangeAnyway={() => {
					setLanguageTemplateTouched(false);
					service.changeSelectedLanguageData(nextLanguageTabID);
				}}
				onClose={() => setUnsavedChangesTabAlertOpen(false)}/>
			}
	</LanguageAndTemplatesWrapper>;
}


