import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {CheckAll, Plus, Search} from '@esgi/ui';
import React, {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {DrawerType} from '../../service/types';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Input} from '@esgi/ui/controls';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {Divider, SelectButton, Wrapper} from './index.styled';
import {Text} from '@esgi/ui/typography';
import levenshtein from 'js-levenshtein';
import {ListItem} from './components/list-item';
import {OptionItem, ItemsCounter, Skeleton} from '@esgi/main/features/standards-drawer';

type Props = {
	sectionName: string;
	items: OptionItem[];
	selectedItems: number[];
	onClose: VoidFunction;
	onAddButtonClick: (value: number[]) => void;
	type: DrawerType;
	loaded: boolean;
}

export function AddFilterDrawer({onClose, sectionName, items, selectedItems, onAddButtonClick, loaded, type}: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, onClose);

	const [selected, setSelected] = useState<string[]>(selectedItems.map(item => String(item)));

	const [searchValue, setSearchValue] = useState('');

	const itemsList = useMemo(() => {
		return items.filter(({label}) => {
			if (label) {
				const name = label.toLowerCase();
				const keyword = searchValue.toLowerCase();
				const nameDiff = levenshtein(name, keyword);

				return name.length - nameDiff >= keyword?.length;
			}
			return true;
		});
	}, [items, searchValue]);

	const onSelectAllClick = useCallback(() => {
		setSelected(selected.length === itemsList.length ? [] : itemsList.map(({value}) => String(value)));
	}, [itemsList, selected.length]);

	const onSearchValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearchValue(value);
	}, []);

	const onActionButtonClick = useCallback(() => {
		onAddButtonClick(selected.map(item => Number(item)));
		close();
	}, [close, onAddButtonClick, selected]);

	return (
		<Drawer width={400} drawerRef={drawerRef} onClickOutside={close}>
			<Drawer.Header
				Icon={Plus}
				sectionName={sectionName}
				actionButtonDisabled={!loaded || !selected.length}
				withActionButton
				onActionButtonClick={onActionButtonClick}
				actionButtonText='Add Filter'
				closeDrawer={close}
				childrenAlignment='right'
			>
				<ItemsCounter itemsCount={selected.length}/>
			</Drawer.Header>
			<Drawer.Body>
				<Wrapper>
					<Input.Iconable
						disabled={!loaded}
						placeholder='Search'
						value={searchValue}
						onChange={onSearchValueChange}
						skeleton={!loaded}
						dataCy='filter-search-input'
					>
						<Search />
					</Input.Iconable>
					<SelectButton color='tertiary' onClick={onSelectAllClick}>
						<CheckAll/>
						<Text size='medium' bold>Select All</Text>
					</SelectButton>
					<Divider />
					<OverlayScrollbarsComponent style={{height: 'calc(100% + 0px)'}} defer options={{scrollbars: {autoHide: 'leave'}}}>
						<SelectableList dataCy='test-explorer-selectable-list'>
							<SelectableList.GroupRoot type='multiple' value={selected} onValueChange={setSelected}>
								<SelectableList.Group>
									<GridBox gap={2}>
										{!loaded
											? <Skeleton variant='tiny' />
											: itemsList.map((item) => <ListItem key={item.value + item.label} item={item} type={type} />)}
									</GridBox>
								</SelectableList.Group>
							</SelectableList.GroupRoot>
						</SelectableList>
					</OverlayScrollbarsComponent>
				</Wrapper>
			</Drawer.Body>
		</Drawer>
	);
}