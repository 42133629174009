import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ComponentProps, ReactNode, forwardRef, useCallback} from 'react';
import {EmptyContentBox} from './index.styled';

type Props = Omit<ComponentProps<typeof GridBox>, 'children'> & {
	templateText?: string | undefined;
	children?: ReactNode | undefined;
};

export const EmptyContent = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'assignments-empty-content', css = {}, display = 'inlineGrid', templateText, children, ...props},
		forwardedRef,
	) => {
		const getContent = useCallback(() => {
			if (templateText) {
				return (
					<Text size='small' font='mono' color='neutral40'>
						{templateText}
					</Text>
				);
			}

			if (children) {
				return children;
			}

			return null;
		}, [children, templateText]);

		return (
			<EmptyContentBox dataCy={dataCy} css={css} ref={forwardedRef} display={display} {...props}>
				{getContent()}
			</EmptyContentBox>
		);
	},
);
