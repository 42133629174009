import {useMemo, useState} from 'react';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {userStorage} from '@esgi/core/authentication';
import {QuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {maxGradeLevelToRender} from '../../../../../../constants';
import {QuestionItemProps} from '../../../../../../types';
import {
	getItemGradeLevels,
	getItemTextColor,
	getTagVariant,
} from '../../../../../../utils';
import {Tag} from '../tag';
import {ImageWrapper, Row, Image, StyledCheckbox, StyledMetaWrapper, StyledTileName, TileCard} from './index.styled';
import {noop} from 'underscore';
import {MultipleLabels} from '../../../../../multiple-labels';

export function QuestionTileItem({
																	 question,
																	 selected,
																	 onCheckedChanged,
																	 onPreviewClick,
																	 selectedStandardType,
																 }: QuestionItemProps) {
	const userContext = userStorage.get();
	const urlBuilder = new QuestionImageUrlBuilder(userContext.awsEnvBucket);

	const [cardEntered, setCardEntered] = useState(false);

	const gradeLevelFieldName =
		question?.gradeLevelIDs?.length > maxGradeLevelToRender
			? 'shortName'
			: 'name';

	const textColor = getItemTextColor(cardEntered, selected);
	const gradeLevels = getItemGradeLevels(
		question?.gradeLevelIDs,
		gradeLevelFieldName
	);

	const handleCheck = (e: React.MouseEvent<unknown>) => {
		if (!question) {
			return;
		}
		e.stopPropagation();
		onCheckedChanged(question);
	};

	const handleMouseLeave = () => {
		setCardEntered(false);
	};

	const handleMouseEnter = () => {
		setCardEntered(true);
	};

	const handlePreviewButtonClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		if (!question || !onPreviewClick) {
			return;
		}

		onPreviewClick(question);
	};

	const stateStandards = useMemo(() => {
		const selectedStandardTypeStandards = question?.stateStandards?.filter(({stateID}) => stateID === selectedStandardType);
		if (selectedStandardTypeStandards?.length) {
			return selectedStandardTypeStandards.map(({name}) => name).sort();
		}

		const defaultStandards = question?.stateStandards?.filter(({stateID}) => stateID === 0);
		return defaultStandards?.map(({name}) => name).sort() || [];
	}, [question?.stateStandards, selectedStandardType]);

	return (
		<TileCard selected={selected} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={handleCheck}>
			{question && (
				<StyledCheckbox onCheckedChange={noop} entered={cardEntered} onClick={handleCheck} checked={selected} />
			)}
			<ImageWrapper entered={cardEntered} skeleton={!question} selected={selected} onClick={handlePreviewButtonClick}>
				{question && <Image src={urlBuilder.selfAssessUrl(question.thumbnailUrl, question.id)} />}
			</ImageWrapper>
			<StyledTileName skeleton={!question} selected={selected}>
				{question && (
					<Text size='large' color={textColor} bold>
						{question.name}
					</Text>
				)}
			</StyledTileName>
			<StyledMetaWrapper onClick={handleCheck}>
				<Row>
					<Text size='xSmall' color='mediumContrast' font='mono'>
						Content Area:
					</Text>
					<Tag>{question?.contentAreaName}</Tag>
				</Row>
				<Row>
					<Text size='xSmall' color='mediumContrast' font='mono'>
						Grade Level:
					</Text>
					<FlexBox>
						{gradeLevels?.map((level, index) => (
							<Tag
								key={index}
								variant={getTagVariant(index, gradeLevels.length)}
							>
								{level}
							</Tag>
						))}
					</FlexBox>
				</Row>
				<Row>
					<Text size='small' color='mediumContrast'>
						Standards:
					</Text>
					<MultipleLabels items={stateStandards} />
				</Row>
			</StyledMetaWrapper>
		</TileCard>
	);
}