import {Container, Divider, EmptyDataContainer, TableBody, TableRow, IndicatorBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {CheckboxesState} from '../../types';
import {Tooltip} from '@esgi/ui/tooltip';
import {EmptyContent} from '../../../../kit';
import {TestInfo, TestSession, TrackDate, GradeRange} from '../../../../types';
import {useTableData} from '../../hooks/use-table-data';

type TableProps = {
	checkboxState: CheckboxesState,
	testSessions: TestSession[];
	testInfo: TestInfo | null;
	trackDates: TrackDate[],
	gradeRanges: GradeRange[];
	isTablet: boolean;
}

export function Table({checkboxState: {grade, percentage, score}, trackDates, testSessions, testInfo, gradeRanges, isTablet}: TableProps) {
	const {columnTitles, scores, percents, grades} = useTableData({
		testInfo,
		testSessions,
		trackDates,
		gradeRanges,
	});

	if (!grade && !percentage && !score) {
		return (
			<EmptyDataContainer>
				<EmptyContent templateText='No data to display' />
			</EmptyDataContainer>
		);
	}

	return (
		<Container>
			<GridBox>
				<TableRow isTablet={isTablet}>
					<Text size='xSmall' color='mediumContrast' font='mono'>Marking Period</Text>
					{columnTitles.map(title => <Text size='medium' color='lowContrast' font='mono'>{title}</Text>)}
				</TableRow>
			</GridBox>
			<Divider/>
			<TableBody>
				{score && <TableRow isTablet={isTablet}>
					<Text size='xSmall' color='mediumContrast'>Score</Text>
					{scores.map((score) => <Text size='medium' color='neutral16'>{score || 'N/T'}</Text>)}
				</TableRow>}
				{percentage && <TableRow isTablet={isTablet}>
					<Text size='xSmall' color='mediumContrast'>Percentage</Text>
					{percents.map(item => <Text size='medium' color='neutral40'>{item ? `${item} %` : 'N/T'}</Text>)}
				</TableRow>}
				{grade && <TableRow isTablet={isTablet}>
					<Text size='xSmall' color='mediumContrast'>Grade</Text>
					{grades.map((grade, idx) => ( grade ? (
							<Tooltip key={idx + grade.color}>
								<Tooltip.Trigger>
									<FlexBox justify='center' align='center'>
										<IndicatorBox css={{backgroundColor: grade.color}}>
											<Text size='small' font='mono' color='vivid' bold>{grade?.name}</Text>
										</IndicatorBox>
									</FlexBox>
								</Tooltip.Trigger>
								<Tooltip.Content>
									<Text size='small'>{grade.description}</Text>
								</Tooltip.Content>
							</Tooltip>
						)
							: <Text size='medium' color='neutral40'>N/T</Text>
					))}
				</TableRow>}
			</TableBody>
		</Container>
	);
}