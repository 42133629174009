import {Wrapper, Link, CheckboxWrapper, CheckboxGroupWrapper, IncludeNotTestedWrapper} from './index.styled';
import {RubricResultReportService} from '../../service';
import {Info, useStream} from '@esgi/ui';
import {Checkbox} from '@esgi/ui/controls';
import {RightSidebar} from '../../../../../layout/index.styled';
import {ExpandablePanel, Reports, SettingsPanel as ReportsSettingsPanel, ToggleGroup} from '@esgi/main/kits/reports';
import {MarkingPeriod, NotTested} from '../../service/types';
import {Filter} from '../../service/settings-service';

type Props = {
	disabled: boolean,
	service: RubricResultReportService,
	setShowPreview: VoidFunction;
}

const markingPeriods = [{id: MarkingPeriod.Current, label: 'Current'}, {id: MarkingPeriod.All, label: 'All'}];
const notTestedTypes = [{id: NotTested.NT, label: 'NT'}, {id: NotTested.Zero, label: 'Zero'}];

export function SettingsPanel({disabled, service, setShowPreview}: Props) {
	const filter = useStream<Filter | null>(service.settingsService.filter$);

	return (
		<RightSidebar dataCy='right-sidebar'>
			<Reports.ReportPanel
				disabled={disabled}
				onReport={setShowPreview}
			/>
			<ExpandablePanel>
				<Wrapper>
					<ReportsSettingsPanel data-cy='settings-content' title='Settings'>
						<CheckboxGroupWrapper>
							<Checkbox
								disabled={disabled}
								label='Show Baseline'
								checked={filter?.showBaseline ?? false}
								onCheckedChange={(value) => service.settingsService.setShowBaseLine(Boolean(value))}
								dataCy='show-baseline-checkbox'
							/>
						</CheckboxGroupWrapper>
						<CheckboxWrapper>
							<Checkbox
								disabled={disabled}
								label='Carry Forward'
								checked={filter?.carryScoresForward ?? false}
								onCheckedChange={(value) => service.settingsService.setCarryForward(Boolean(value))}
								dataCy='carry-forward-checkbox'
							/>
							<Link
								href='https://support.esgisoftware.com/hc/en-us/articles/360024667492-Grade-Report-Editing-From-Within'
								target='_blank'
								rel='noreferrer'
								data-cy='info-link'
							>
								<Info width={24} height={24} />
							</Link>
						</CheckboxWrapper>
					</ReportsSettingsPanel>
					<ReportsSettingsPanel data-cy='marking-period-content' title='Marking Period'>
						<ToggleGroup
							value={filter?.showCurrentMarkingPeriod ?? MarkingPeriod.Current}
							options={markingPeriods}
							disabled={disabled}
							onChange={(value) => service.settingsService.setMarkingPeriod(value as MarkingPeriod)}
							dataCy='current-all-toggle'
						/>
					</ReportsSettingsPanel>
					<ReportsSettingsPanel data-cy='not-tested-content' title='Not Tested'>
						<IncludeNotTestedWrapper>
							<Checkbox
								disabled={disabled}
								label='Include not tested'
								checked={filter?.includeNotTested ?? true}
								onCheckedChange={(value) => service.settingsService.setIncludeNotTested(Boolean(value))}
								dataCy='include-not-tested-checkbox'
							/>
						</IncludeNotTestedWrapper>
						<ToggleGroup
							value={filter?.displayZeroIfNotTested ?? NotTested.NT}
							options={notTestedTypes}
							disabled={disabled}
							onChange={(value) => service.settingsService.setNotTested(value as NotTested)}
							dataCy='nt-zero-toggle'
						/>
					</ReportsSettingsPanel>
				</Wrapper>
			</ExpandablePanel>
		</RightSidebar>
	);
}