import {BehaviorSubject, combineLatest, debounceTime, Observable, tap} from 'rxjs';
import {ReportTest} from '@esgi/main/kits/reports';
import {Class, Group, storage, Student, SubjectLevel, SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {BaseService} from '@esgi/core/service';
import {createParentLetterSettingsForm} from '../form';
import {ColorType, GroupItemData, ParentLetterResultData, EntityType} from '../types';
import {StudentService} from './student-service';
import {ResultsService} from './results-service';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {TestService} from './test-service';

export class ParentLetterService extends BaseService {
	public readonly selectedStudentsIDs$ = new BehaviorSubject<number[]>([]);
	public readonly subject$ = new BehaviorSubject(0);

	public isSubjectsDataLoaded = new BehaviorSubject(false);
	public selectedSubject = new BehaviorSubject<SubjectTab>(null);
	public selectedTestIDs = new BehaviorSubject<number[]>(null);
	public subjects = new BehaviorSubject<SubjectTab[]>([]);
	public form = createParentLetterSettingsForm();
	public loading = new BehaviorSubject<boolean>(false);

	public groupLevelData = new BehaviorSubject<GroupItemData | null>(null);
	public singleSelectState = new BehaviorSubject<SingleSelectState | null>(null);

	public studentService = new StudentService();
	public resultService = new ResultsService();
	public testService = new TestService();

	private subjectStorage = storage.subjects();

	constructor() {
		super();

		this.subjectStorage.get().subscribe(subjects => {
			const filteredSubjects = subjects.filter(subject => !subject.hidden && subject.type === SubjectType.Personal && subject.level === SubjectLevel.Teacher);

			this.subjects.next(filteredSubjects);

			if(!this.isSubjectsDataLoaded.value) {
				this.isSubjectsDataLoaded.next(true);

				if(!this.selectedSubject.value){
					this.selectedSubject.next(filteredSubjects[0] ?? null);
				}
			}
		});

		this.completeOnDestroy(combineLatest([this.selectedSubject, this.selectedStudentsIDs$]))
			.pipe(
				debounceTime(300),
				tap(([subjectId, studentsIDs]) => {
					const subject = this.subjects.value.find(sub => sub.id === subjectId?.id);

					return this.testService.getTestCardsByStudent(subject, studentsIDs);
				}),
			).subscribe();
	}

	public report = (classes: Class[], groups: Group[], studentList: Student[], tests: ReportTest[]) => {
		const selectedTests = tests.filter(test => this.selectedTestIDs.value.includes(test.id)).map(t => ({
			testID: t.id,
			max: t.incorrect,
			testDate: null,
			noData: false,
			answersCount: t.correct + t.incorrect,
		}));

		const {
			printInColor,
			isShowNotes,
			isShowSummary,
			isIncludeGrades,
			isShowSessionDate,
			isIncludeParentMessage,
			isIncludeSkippedQuestions,
		} = this.form.value;

		const settingsModel = {
			includeUntestedQuestions: isIncludeSkippedQuestions,
			includeSummaryNotes: isShowSummary,
			showSessionDate: isShowSessionDate,
			includeTestNotes: isShowNotes,
			includeGradeScale: isIncludeGrades,
			includeLetter: isIncludeParentMessage,
			printInColor: printInColor ? ColorType.Colored : ColorType.BlackAndWhite,
		};

		const data: ParentLetterResultData = {
			subject: {
				name: this.selectedSubject.value.name,
				subjectID: this.selectedSubject.value.id,
				subjectType: this.selectedSubject.value.type,
				subjectLevel: this.selectedSubject.value.level,
			},
			lists: {
				classesList: classes,
				groupsList: groups,
				studentsList: studentList,
			},
			tests: selectedTests,
			groupItemLevel: this.groupLevelData.value.groupItemLevel,
			groupItemID: this.groupLevelData.value.groupItemID,
			studentIDs: this.selectedStudentsIDs$.value,
			settings: settingsModel,
			singleSelectState: this.singleSelectState.value,
			selectedTestIDs: this.selectedTestIDs.value,
			entityType: this.singleSelectState.value?.studentId ? EntityType.Student : EntityType.ClassGroup,
		};

		return new Observable<ParentLetterResultData>(subscriber => {
			subscriber.next(data);
			subscriber.complete();
		});
	};

	public setStudents(value: number[]) {
		this.selectedStudentsIDs$.next(value);
	}

	public override destroy() {
		super.destroy();
		this.studentService.destroy();
		this.resultService.destroy();
		this.testService.destroy();
	}
}
