import {GridBox} from '@esgi/ui/layout';
import {SkeletonRow, Title, Link, UnderlinedText} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {PropsWithChildren} from 'react';

type Props = PropsWithChildren & {
	isInitialized: boolean,
	label: string,
	clearVisible?: boolean,
	onListClear?: VoidFunction,
}

export function FiltersPanelItem({label, isInitialized, clearVisible, onListClear, children}: Props) {
	return (
		<GridBox gap={2}>
			<Title>
				<Text size='small' font='mono' color='mediumContrast'>{label}</Text>
				{clearVisible && <Link target='_blank' onClick={() => onListClear?.()}>
					<UnderlinedText size='small' color='primary'>
						Clear
					</UnderlinedText>
				</Link>}
			</Title>
			{isInitialized ? children : <SkeletonRow/>}
		</GridBox>
	);
}