import {sortBy} from 'underscore';
import {ChangeEvent, useCallback, useMemo} from 'react';
import {Button, Delete, Link, LinkDelete, Search, Tooltip} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {Text} from '@esgi/ui/typography';
import {ActionsContainer, DeleteButton, Divider, FiltersContainer, Layout, SearchInput, SearchWrapper, UnassignButton} from './styled';
import {
	ClassModel,
	Filter as FilterType,
	GradeLevelModel,
	GroupModel,
	StudentModel,
	TeacherModel,
	UpdateFilter,
} from '../../types';
import {ClassSelect} from './components/class';
import {TeacherSelect} from './components/primary-teacher';
import {GradeSelect} from './components/grade';
import {GroupSelect} from './components/group';

type Props = {
	loaded: boolean,
	filter: FilterType,
	updateFilter: UpdateFilter,
	agreementLevel: string,
	classes: ClassModel[],
	groups: GroupModel[],
	teachers: TeacherModel[],
	grades: GradeLevelModel[],
	selectedStudents: StudentModel[],
	onDeleteClicked: VoidFunction,
	onAssignClicked: VoidFunction,
	onUnassignClicked: VoidFunction,
}

export function Filter(props: Props) {
	const user = useUser();

	const onClassSelected = useCallback((value: number) => props.updateFilter({classID: value, groupID: 0}), [props.updateFilter]);
	const onTeacherSelected = useCallback((value: number) => props.updateFilter({teacherID: value, classID: 0, groupID: 0}), [props.updateFilter]);
	const onGradeSelected = useCallback((value: number) => props.updateFilter({gradeLevelID: value}), [props.updateFilter]);
	const onGroupSelected = useCallback((value: number) => props.updateFilter({groupID: value}), [props.updateFilter]);


	const selectedTeachers = useMemo(() => {
		if(props.agreementLevel !== 'T') {
			return props.filter.teacherID ? props.teachers.filter(t => t.teacherID === props.filter.teacherID) : props.teachers;
		}
		return props.teachers.filter(t => t.teacherID === user.userID);
	}, [props.agreementLevel, props.teachers, props.filter.teacherID, user.userID]);

	const classes = useMemo(() => {
		const allTeachersClasses = selectedTeachers.reduce((prev, current)=>{
			return [...prev, ...current?.classes ?? []];
		}, [] as ClassModel[]);
		return sortBy(allTeachersClasses, (c) => c);
	}, [selectedTeachers]);

	const classIDs = useMemo(() => classes.map(c => c.classID), [classes]);
	const selectedClass = useMemo(() => classes.find(c => c.classID === props.filter.classID), [classes, props.filter.classID]);

	const groups = useMemo(() => sortBy(selectedClass?.groups || [], (g) => g.name), [selectedClass]);

	const allTeacherGroupsIDs = useMemo(() => classes.reduce((prev, current)=>{
		const ids = current.groups?.map(gr => gr.groupID) ?? [];
		return [...prev, ...ids];
	}, [] as number[]), [classes]);


	const canAssign = useMemo(() => {
		const canBeAssign = props.selectedStudents.length
			&& props.selectedStudents.every(s => s.primaryTeacherID === user?.userID || s.primaryTeacherID === null);

		const someStudentNotAssignedToClass = props.selectedStudents
			.some(student => !classIDs?.every(classID => student.classIDs?.includes(classID)));

		const someStudentNotAssignedToGroup = props.selectedStudents
			.some(student => !allTeacherGroupsIDs.every(groupID => student.groupIDs?.includes(groupID)));

		return canBeAssign && (someStudentNotAssignedToClass || someStudentNotAssignedToGroup);

	}, [props.selectedStudents, user, classIDs, allTeacherGroupsIDs, props.agreementLevel]);

	const canUnassign = useMemo(() => {
		return props.selectedStudents.length && props.selectedStudents.every(s => s.primaryTeacherID === user?.userID && !!s.classIDs.length);
	}, [props.selectedStudents, user]);

	const canDelete = useMemo(() => {
		return props.selectedStudents.length && props.selectedStudents.every(s => s.creatorID === user.userID);
	}, [props.selectedStudents, user]);

	const onKeywordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => props.updateFilter({keyword: event.target.value}), [props.updateFilter]);
	const hasConcreteTeacher = selectedTeachers.length === 1;

	return <>
		<SearchWrapper>
			<SearchInput placeholder='Search by student name or ID' value={props.filter.keyword} onChange={onKeywordChange} skeleton={!props.loaded} dataCy='search-input'>
				<Search/>
			</SearchInput>
		</SearchWrapper>
		<Layout>
			<FiltersContainer>
				{props.agreementLevel !== 'T' && <TeacherSelect skeleton={!props.loaded} teachers={props.teachers} onChange={onTeacherSelected} selected={props.filter.teacherID}/>}
				<ClassSelect skeleton={!props.loaded} classes={classes} onChange={onClassSelected} selected={props.filter.classID} disabled={!hasConcreteTeacher}/>
				<GroupSelect skeleton={!props.loaded} groups={groups} onChange={onGroupSelected} selected={props.filter.groupID} disabled={!selectedClass}/>
				<GradeSelect skeleton={!props.loaded} selected={props.filter.gradeLevelID} grades={props.grades} onChange={onGradeSelected}/>
			</FiltersContainer>
			<ActionsContainer>
				{user?.agreementLevelCode === 'T' &&<Tooltip delayDuration={400}>
					<Tooltip.Trigger>
						<DeleteButton color='tertiary' disabled={!canDelete} onClick={props.onDeleteClicked}>
							<Delete/>
							<Text size='medium'>Delete</Text>
						</DeleteButton>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						Delete student(s) from the system
					</Tooltip.Content>
				</Tooltip>}
				<Divider/>
				<Tooltip delayDuration={400}>
					<Tooltip.Trigger>
						<UnassignButton color='tertiary' disabled={!canUnassign} onClick={props.onUnassignClicked}>
							<LinkDelete/>
							<Text size='medium'>Unassign</Text>
						</UnassignButton>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						Unassign student(s) from a class/group
					</Tooltip.Content>
				</Tooltip>
				<Tooltip delayDuration={400}>
					<Tooltip.Trigger>
						<Button color='secondary' disabled={!canAssign} onClick={props.onAssignClicked}>
							<Link/>
							<Text size='medium'>Assign</Text>
						</Button>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						Assign student(s) to a class/group
					</Tooltip.Content>
				</Tooltip>
			</ActionsContainer>
		</Layout>
	</>;
}