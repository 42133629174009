import {useMemo} from 'react';
import {Test} from './types';
import {Alert} from '@esgi/ui/alert';
import {Student, SubjectTab, storage, useStore} from '@esgi/main/libs/store';
import {AddYesNoSession} from './components/variant-test/add-yes-no-session';
import {TestContentArea} from '@esgi/main/kits/common';
import {TestType} from '@esgi/main/libs/core';
import {AddSingleScoreSession} from './components/variant-test/add-single-score-session';
import {AddRubricSession} from './components/variant-test/add-rubric-session';
import {DefaultSkeleton} from './components/default-skeleton';

type Props = {
	onAlertClose?: VoidFunction;
	subjectID: SubjectTab['id'];
	testID: Test['id'];
	studentID: Student['id'];
};

export function AddSessionDetails({subjectID, testID, onAlertClose, studentID}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onAlertClose);

	const [studentsList, , isStudentsListLoaded] = useStore(storage.students);
	const [subjects, , isSubjectsLoaded] = useStore(storage.subjects);

	const student = useMemo(() => studentsList.find(({id}) => id === studentID) ?? null, [studentsList, studentID]);

	const test = useMemo<Test | null>(() => {
		const subject = subjects.find(({id}) => id === subjectID);
		const test = subject?.tests.find(({id}) => id === testID);

		if (!subject || !test) {
			return null;
		}

		return {
			...test,
			contentArea: test.contentArea as TestContentArea,
			type: test.type as TestType,
		};
	}, [subjectID, subjects, testID]);

	const getContent = () => {
		if (!isStudentsListLoaded || !isSubjectsLoaded) {
			return <DefaultSkeleton onCloseAlert={closeAlert} />;
		}

		if (!test || !student) {
			return null;
		}

		if (test.type === TestType.YN) {
			return <AddYesNoSession student={student} onCloseAlert={closeAlert} test={test} />;
		}

		if (test.type === TestType.Score) {
			return <AddSingleScoreSession student={student} onCloseAlert={closeAlert} test={test} />;
		}

		if (test.type === TestType.Rubric) {
			return <AddRubricSession student={student} onCloseAlert={closeAlert} test={test} />;
		}

		return null;
	};

	return (
		<Alert
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					width: 510,
					maxHeight: 'calc(100% - 40px)',
					gridTemplateRows: 'auto 1fr auto',
					overflow: 'hidden',
				},
			}}
			dataCy='session-details-alert'
		>
			{getContent()}
		</Alert>
	);
}
