import {ClassEntity, StudentEntity} from './components/selected-entity';
import {TestType} from '@esgi/main/libs/core';
import {sectionTitleByTestType} from './constants';
import {TestInfo, TestSession} from '../../types';
import {isNull} from 'underscore';
import {SectionBlock} from '../../kit';
import {StudentInfo} from '../../class-group/types';

type Props =
	| {
			entityType: 'student';
			testSessions: TestSession[];
			testInfo: TestInfo | null;
			isTablet: boolean;
	  }
	| {
			entityType: 'class-group';
			testInfo: TestInfo | null;
			studentsInfo: StudentInfo[];
			isTablet: boolean;
	  };

export function ItemAnalysisSection(props: Props) {
	if (props.entityType === 'class-group') {
		const {testInfo, studentsInfo, isTablet} = props;

		if (isNull(testInfo) || testInfo.testType !== TestType.YN) {
			return null;
		}

		const sectionTitle = sectionTitleByTestType[testInfo.testType];

		return (
			<SectionBlock isTablet={isTablet} title={sectionTitle} childrenOverflow='visible'>
				<ClassEntity testInfo={testInfo} studentsInfo={studentsInfo} isTablet={isTablet}/>
			</SectionBlock>
		);
	}

	if (props.entityType === 'student') {
		const {testInfo, testSessions, isTablet} = props;
		const notDeletedSessions = testSessions.filter(el=>!el.isDeleted);

		if (isNull(testInfo) || testInfo.testType === TestType.Score) {
			return null;
		}

		const sectionTitle = sectionTitleByTestType[testInfo.testType];

		return (
			<SectionBlock isTablet={isTablet} title={sectionTitle} childrenOverflow='visible'>
				<StudentEntity testInfo={testInfo} testSessions={notDeletedSessions} isTablet={isTablet}/>
			</SectionBlock>
		);
	}

	return null;
}
