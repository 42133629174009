import {StudentRace, StudentSpecialProgram, StudentLunchStatus, StudentGenders} from './types';

export const specialPrograms = [
	{
		id: StudentSpecialProgram.GiftedTalented,
		name: 'Gifted/Talented',
		valueName: 'GiftedTalented',
	},
	{
		id: StudentSpecialProgram.SPED,
		name: 'SPED',
		valueName: 'SPED',
	},
	{
		id: StudentSpecialProgram.RTI,
		name: 'RTI',
		valueName: 'RTI',
	},
	{
		id: StudentSpecialProgram.ELLESL,
		name: 'ELL/ESL',
		valueName: 'ELLESL',
	},
	{
		id: StudentSpecialProgram.FiveOuFour,
		name: '504',
		valueName: 'FiveOuFour',
	},
];

export const lunchStatuses = [
	{
		id: StudentLunchStatus.Free,
		name: 'Free',
		valueName: 'Free',
	},
	{
		id: StudentLunchStatus.Reduced,
		name: 'Reduced',
		valueName: 'Reduced',
	},
	{
		id: StudentLunchStatus.Other,
		name: 'Other',
		valueName: 'Other',
	},
	{
		id: 3,
		name: 'N/A',
		valueName: 'NotAvailable',
	},
];

export const races = [
	{
		id: StudentRace.Asian,
		name: 'Asian',
		valueName: 'Asian',
	},
	{
		id: StudentRace.BlackOrAfricanAmerican,
		name: 'Black or African American',
		valueName: 'BlackOrAfricanAmerican',
	},
	{
		id: StudentRace.HispanicOrLatino,
		name: 'Hispanic or Latino',
		valueName: 'HispanicOrLatino',
	},
	{
		id: StudentRace.MiddleEasternOrNorthAfrican,
		name: 'Middle Eastern or North African',
		valueName: 'MiddleEasternOrNorthAfrican',
	},
	{
		id: StudentRace.Multiethnic,
		name: 'Multiethnic',
		valueName: 'Multiethnic',
	},
	{
		id: StudentRace.NativeAmericanOrAlaskaNative,
		name: 'Native American or Alaska Native',
		valueName: 'NativeAmericanOrAlaskaNative',
	},
	{
		id: StudentRace.NativeHawaiianOrPacificIslander,
		name: 'Native Hawaiian or Pacific Islander',
		valueName: 'NativeHawaiianOrPacificIslander',
	},
	{
		id: StudentRace.WhiteOrCaucasian,
		name: 'White or Caucasian',
		valueName: 'WhiteOrCaucasian',
	},
	{
		id: StudentRace.Other,
		name: 'Other',
		valueName: 'Other',
	},
];

export const genders = [
	{name: 'Female', id: StudentGenders.Female, valueName: 'Female'},
	{name: 'Male', id: StudentGenders.Male, valueName: 'Male'},
	{name: 'Other', id: StudentGenders.Other, valueName: 'Other'},
];
export const dictionary = {
	specialPrograms,
	lunchStatuses,
	races,
	genders,
};
