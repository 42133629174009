import {ComponentPropsWithoutRef, ReactNode, forwardRef} from 'react';
import {LegendWithTranscript, LegendWithTranscriptItem} from '../legend-with-transcript';
import {Container, FullScreenButton, Header, LegendBox} from './index.styled';
import {AspectRatio} from '@esgi/ui';
import {Box, FlexBox} from '@esgi/ui/layout';

type Props = ComponentPropsWithoutRef<'div'> & {
	leftHeaderContent?: ReactNode | undefined;
	legendItems: LegendWithTranscriptItem[];
	onFullScreenButtonClick: VoidFunction;
};

export const DiagramBox = forwardRef<HTMLDivElement, Props>(
	({legendItems, children, leftHeaderContent, onFullScreenButtonClick, ...containerProps}, forwardedRef) => (
		<Container ref={forwardedRef} {...containerProps}>
			<Header withLeftHeaderContent={Boolean(leftHeaderContent)}>
				{leftHeaderContent && <FlexBox>{leftHeaderContent}</FlexBox>}
				<LegendBox>
					{legendItems.map((props, index) => (
						<LegendWithTranscript key={index} {...props} />
					))}
					{/*<FullScreenButton color='secondary' onClick={onFullScreenButtonClick}>*/}
					{/*	<AspectRatio />*/}
					{/*</FullScreenButton>*/}
				</LegendBox>
			</Header>
			<Box>{children}</Box>
		</Container>
	),
);
