import {Observable} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {EventBusDispatcher} from '@esgillc/events';
import {deepEqual, mapToEnum} from 'shared/utils';
import {single} from 'shared/utils/rxjs';
import {TestFavoriteChangedEvent, TestHiddenChangedEvent} from '../../kits/test-details';
import {LevelDisplayMode, TestInfo} from '../common/models';
import RubricService from '../common/rubric-service';
import {ContentArea, RubricModel} from '../common/types';
import {RubricChangedEvent} from './events';
import {InitInfo} from './models';
import {TestChanged} from 'shared/modules/test-details/events';

export default class RubricDetailsService extends RubricService {
	public canModifyScore: boolean;
	public deletedSessionsExist: boolean;
	private controller = 'assets/rubric-details';
	private testsCommonController = 'assets/tests';
	private initialRubricModel: RubricModel;
	private contentAreas: ContentArea[] = [];

	public init(rubricID: number): Observable<InitInfo> {
		return this.httpClient.ESGIApi.get<InitInfo>(this.controller, 'init', {rubricID})
			.pipe(tap(r => {
				r.rubricModel.levelDisplayType = mapToEnum(r.rubricModel.levelDisplayType, LevelDisplayMode);
				this.initFromModel(r.rubricModel);
				this.contentAreas = r.contentAreas;
				this.initialRubricModel = r.rubricModel;
				this.canModifyScore = r.canModifyScore;
				this.deletedSessionsExist = r.deletedSessionsExist;
			}))
			.asObservable();
	}

	public updateHide(status: boolean): void {
		this.httpClient.ESGIApi.post<void>(this.testsCommonController, status ? 'hide' : 'unhide', {testID: this.testID})
			.pipe(
				takeUntil(this.destroy$),
				tap(() => EventBusDispatcher.dispatch(TestHiddenChangedEvent, new TestHiddenChangedEvent(this.testID, status))),
			).subscribe();
	}

	public updateStar(status: boolean): void {
		this.httpClient.ESGIApi.post<void>(this.testsCommonController, status ? 'star' : 'unstar', {testID: this.testID})
			.pipe(
				takeUntil(this.destroy$),
				tap(() => EventBusDispatcher.dispatch(TestFavoriteChangedEvent, new TestFavoriteChangedEvent(this.testID, status))),
			).subscribe();
	}

	public saveChanges(testInfo: TestInfo, saveAnyway: boolean): Observable<boolean> {
		let rubricModel = this.serialize();
		const rubricModelChanged = !deepEqual(this.initialRubricModel, rubricModel);
		const testInfoChanged = !deepEqual(testInfo, this.testInfo$.value);

		if (this.deletedSessionsExist && this.rubricStructureChanged(rubricModel) && !saveAnyway) {
			return single(true);
		}

		if (testInfoChanged || rubricModelChanged) {
			this.testInfo$.next(testInfo);
			rubricModel = this.serialize();
			return this.httpClient.ESGIApi.post<void>(this.controller, 'update', rubricModel).pipe(tap(() => {
				EventBusDispatcher.dispatch(RubricChangedEvent, {
					rubricModel,
					contentAreaName: this.contentAreas.find(c => testInfo.contentAreaID === c.id)?.name,
				} as RubricChangedEvent);
				EventBusDispatcher.dispatch(TestChanged, {
					...rubricModel,
					newName: rubricModel.name,
				});
			}), map(() => false)).asObservable();
		}

		return single(false);
	}

	public copy(testName: string): Observable<number> {
		return this.httpClient.ESGIApi.post<{ rubricID: number }>(this.controller, 'copy', {
			rubricID: this.testID,
			name: testName,
		}).pipe(map(v => v.rubricID)).asObservable();
	}

  private rubricStructureChanged(rubricModel: RubricModel){
    return this.initialRubricModel.levelModels.length != rubricModel.levelModels.length
      || this.initialRubricModel.criteriaModels.length != rubricModel.criteriaModels.length
      || this.initialRubricModel.criteriaModels.some(c => c.id < 0)
      || this.initialRubricModel.levelModels.some(c => c.id < 0);
  }
}
