import {Button, ErrorText, Eye, Hide, useStreamEffect} from '@esgi/ui';
import {InputWithIndicator} from '../input-indicator/component';
import {ButtonIcon, CancellBtnWrapper, CurrentPasswordErrorContainer, FormElementContainer, FormElementWrapper, FormWrapper} from './index.styled';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Snackbar} from '@esgi/ui/snackbar';
import {useEffect, useMemo, useState} from 'react';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {useService} from '@esgi/core/service';
import {ChangePasswordService} from './change-password-service';
import {ChangePasswordRequest, CurrentPasswordStatus} from '../../../../types';
import {Error} from '@esgi/contracts/esgi/types/esgi.app/endpoints/abstractions/error';
import {ChangePasswordErrors} from './types';

type Props = {
    setAllowChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
    onSetNewPassword: React.MutableRefObject<() => void>;
};

export function ChangePasswordForm({setAllowChangePassword, onSetNewPassword}: Props) {
	const setPasswordAlertRef = Alert.useRef();
	const openSetPasswordAlert = Alert.useOpen(setPasswordAlertRef);
	const closeSetPasswordAlert = Alert.useClose(setPasswordAlertRef);
	const service = useService(ChangePasswordService);
	const snackbarRef = Snackbar.useRef();
	const [formIsValid, setFormIsValid] = useState(false);
	const [isCurrentPasswordVisible, setCurrentPasswordVisible] = useState(false);
	const [isNewPasswordVisible, setNewPasswordVisible] = useState(false);
	const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const [currentPasswordStatus, setCurrentPasswordStatus] = useState(ElementStatus.untouched);
	const [newPasswordStatus, setNewPasswordStatus] = useState(ElementStatus.untouched);
	const [confirmPasswordStatus, setConfirmPasswordStatus] = useState(ElementStatus.untouched);
	const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState('Current password not valid');

	const getCurrentPasswordType = useMemo(() => {
		return isCurrentPasswordVisible ? 'text' : 'password';
	}, [isCurrentPasswordVisible]);

	const getNewPasswordType = useMemo(() => {
		return isNewPasswordVisible ? 'text' : 'password';
	}, [isNewPasswordVisible]);

	const getConfirmPasswordType = useMemo(() => {
		return isConfirmPasswordVisible ? 'text' : 'password';
	}, [isConfirmPasswordVisible]);

	useStreamEffect(service.form.onChanged, () => {
		service.form.validate().subscribe(res => {
			setFormIsValid(res.valid);
			setAllowChangePassword(res.valid);
		});
	});

	useEffect(() => {
		onSetNewPassword.current = openSetPasswordAlert;
	}, [onSetNewPassword, openSetPasswordAlert]);

	useStreamEffect(service.form.controls.currentPassword.onChanged, event => {
		setCurrentPasswordStatus(event.currState.status);
	});

	useStreamEffect(service.form.controls.newPassword.onChanged, event => {
		setNewPasswordStatus(event.currState.status);
	});

	useStreamEffect(service.form.controls.confirmPassword.onChanged, event => {
		setConfirmPasswordStatus(event.currState.status);
	});

	const handleCancellSetNewPassword = () => {
		closeSetPasswordAlert();
		snackbarRef.current.showSnackbar('Your password remains unchanged');
	};

	const submitForm = () => {
		if (!formIsValid) {
			return;
		}

		service.changePassword({
			password: service.form.controls.newPassword.value,
			confirmPassword: service.form.controls.confirmPassword.value,
			currentPassword: service.form.controls.currentPassword.value,
		} as ChangePasswordRequest).subscribe(r => {
			if(r.isSuccess) {
				resetForm();
				snackbarRef.current.showSnackbar('Your password has been successfully updated!');
			} else {
				processErrors(r.errors);
			}
			closeSetPasswordAlert();
		});
	};

	const processErrors = (errors: Error[]) => {
		if (!errors) {
			return;
		}

		errors.forEach(e => {
			switch (e.type) {
				case ChangePasswordErrors.CurrentPasswordNotValid:
					service.form.controls.currentPasswordIsValid.value = CurrentPasswordStatus.NotValid;
					setCurrentPasswordErrorText(e.description);
					break;

				case ChangePasswordErrors.BruteForce:
					service.form.controls.currentPasswordIsValid.value = CurrentPasswordStatus.NotValid;
					setCurrentPasswordErrorText(e.description);
					break;

				default:
					break;
			}
		});
	};

	const resetForm = () => {
		service.form.controls.currentPassword.value = '';
		service.form.controls.currentPasswordIsValid.value = CurrentPasswordStatus.NotValid;
		service.form.controls.newPassword.value = '';
		service.form.controls.confirmPassword.value = '';
		resetFormControlStatuses();
	};

	const resetFormControlStatuses = () => {
		Object.values(service.form.controls).forEach(
			control => control.status = ElementStatus.untouched,
		);
	};

	return (
		<>
            <FormWrapper controller={service.form}>
                <FormElementContainer>
                    <FormElementWrapper control={service.form.controls.currentPassword}>
                        <InputWithIndicator
                            placeholder='Current Password'
                            dataCy='current-password'
                            inpuType={getCurrentPasswordType}
                            controlStatus={currentPasswordStatus}
                        />
                        <ErrorText showOnError='required'>Enter current password</ErrorText>
						<CurrentPasswordErrorContainer>
							<ErrorText showOnError='currentPassword'>{currentPasswordErrorText}</ErrorText>
						</CurrentPasswordErrorContainer>
                    </FormElementWrapper>
                    <ButtonIcon dataCy='set-current-password-visible-icon' type='button' onClick={() => setCurrentPasswordVisible(prev => !prev)}>
                        {isCurrentPasswordVisible ? <Eye width={24} height={24}/> : <Hide width={24} height={24}/>}
                    </ButtonIcon>
                </FormElementContainer>
                <FormElementContainer>
                    <FormElementWrapper control={service.form.controls.newPassword}>
                        <InputWithIndicator
                            placeholder='New Password'
                            dataCy='new-password'
                            inpuType={getNewPasswordType}
                            controlStatus={newPasswordStatus}
                        />
                        <ErrorText showOnError='required'>Enter new password</ErrorText>
                        <ErrorText showOnError='length-min'>Please enter a password with at least 8 characters</ErrorText>
                        <ErrorText showOnError='oldAndNewPasswordEquals'>The new password must be different from the current</ErrorText>
                    </FormElementWrapper>
                    <ButtonIcon dataCy='set-new-password-visible-icon' type='button' onClick={() => setNewPasswordVisible(prev => !prev)}>
                        {isNewPasswordVisible ? <Eye width={24} height={24}/> : <Hide width={24} height={24}/>}
                    </ButtonIcon>
                </FormElementContainer>
                <FormElementContainer>
                    <FormElementWrapper control={service.form.controls.confirmPassword}>
                        <InputWithIndicator
                            placeholder='Re-enter New Password'
                            dataCy='confirm-password'
                            inpuType={getConfirmPasswordType}
                            controlStatus={confirmPasswordStatus}
                        />
                        <ErrorText showOnError='required'>Confirm new password</ErrorText>
                        <ErrorText showOnError='confirm'>New passwords do not match</ErrorText>
                    </FormElementWrapper>
                    <ButtonIcon dataCy='set-confirm-password-visible-icon' type='button' onClick={() => setConfirmPasswordVisible(prev => !prev)}>
                        {isConfirmPasswordVisible ? <Eye width={24} height={24}/> : <Hide width={24} height={24}/>}
                    </ButtonIcon>
                </FormElementContainer>
            </FormWrapper>
            <Snackbar snackbarRef={snackbarRef} />

            <Alert modalManagerRef={setPasswordAlertRef} initiallyHidden>
                <Alert.Header onCloseIconClick={closeSetPasswordAlert}>
                    <Text size='large'>Change Password</Text>
                </Alert.Header>
                <Alert.Body>
                    <Text size='medium' color='neutral40'>
                        Are you sure that you want to change your password?
                    </Text>
                </Alert.Body>
                <Alert.Footer>
                    <Button color='primary' onClick={submitForm}>
                        <Text size='medium' bold>
                            Yes
                        </Text>
                    </Button>
                    <CancellBtnWrapper color='secondary' onClick={handleCancellSetNewPassword}>
                        <Text size='medium' bold>
                            No
                        </Text>
                    </CancellBtnWrapper>
                </Alert.Footer>
            </Alert>
		</>
	);
}