export type GradeLevel = {
	id: number;
	shortName: string;
	name: string;
}

export const GradeLevelsMap: Record<number, GradeLevel> = {
	1: {id: 1, name: 'Pre-K', shortName: 'PK'},
	2: {id: 2, name: 'TK', shortName: 'TK'},
	4: {id: 4, name: 'Kindergarten', shortName: 'K'},
	8: {id: 8, name: 'Grade One', shortName: '1'},
	16: {id: 16, name: 'Grade Two', shortName: '2'},
	32: {id: 32, name: 'Grade Three+', shortName: '3+'},
};