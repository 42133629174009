import {useSubjectRootContext} from '../subject-root/context';
import {Label, SelectRoot, SelectContent, SelectField, SelectOption} from './index.styled';

export function SubjectControl() {
	const {
		isLoaded,
		subjects,
		selectedSubjectID,
		setSelectedSubject,
	} = useSubjectRootContext();

	return (
		<SelectRoot dataCy='subject-control-root' value={selectedSubjectID} onValueChange={setSelectedSubject}>
			<SelectField data-cy='subject-control-select-field' placeholder='Subject Tab' skeleton={!isLoaded} />
			<SelectContent dataCy='subject-control-item'>
				{subjects.map(({id, name}) => (
					<SelectOption value={String(id)} key={id}>
						<Label size='medium' bold>
							{name}
						</Label>
					</SelectOption>
				))}
			</SelectContent>
		</SelectRoot>
	);
}
