import {Student, storage, useStore} from '@esgi/main/libs/store';
import {SectionBlock} from '../section-block';
import {EmptyBoxMessage} from '../empty-box-message';
import {GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';
import {AccessAll, ClearAll} from '@esgi/ui/icons';
import {SelectedStudentRow} from './components/selected-student-row';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {NoCredentialsNotification} from './components/no-credentials-notification';

type Props = {
	selectedStudentsIDs: Student['id'][];
	onClearAllClick: VoidFunction;
	onOpenCredentialsForGroupStudents: Dispatch<Student['id'][]>;
	onRemoveStudentByID: Dispatch<Student['id']>;
	onOpenStudentCredentials: Dispatch<Student['id']>;
	onHasStudentsWithoutCredentialsChanged?: Dispatch<boolean>;
};

export function AssignmentSelectedStudents({
	selectedStudentsIDs,
	onClearAllClick,
	onOpenCredentialsForGroupStudents,
	onOpenStudentCredentials,
	onRemoveStudentByID,
	onHasStudentsWithoutCredentialsChanged,
}: Props) {
	const [studentsList] = useStore(storage.students);

	const [hasStudentsWithoutCredentials, setHasStudentsWithoutCredentials] = useState(false);

	const updateStudentsWithoutCredentialsValue = useCallback(
		(newValue: boolean) => {
			setHasStudentsWithoutCredentials(newValue);
			onHasStudentsWithoutCredentialsChanged?.(newValue);
		},
		[onHasStudentsWithoutCredentialsChanged],
	);

	useEffect(() => {
		const hasSelectedStudentsWithoutCredentials = selectedStudentsIDs.some(
			(studentID) => !studentsList.find(({id}) => id === studentID)?.hasCredentials,
		);

		updateStudentsWithoutCredentialsValue(hasSelectedStudentsWithoutCredentials);
	}, [selectedStudentsIDs, studentsList]);

	const onOpenCredentialsForSelectedStudents = useCallback(
		() => onOpenCredentialsForGroupStudents(selectedStudentsIDs),
		[onOpenCredentialsForGroupStudents, selectedStudentsIDs],
	);

	const getSectionContent = () => {
		if (!selectedStudentsIDs.length) {
			return <EmptyBoxMessage message='Please select a student' />;
		}

		return (
			<GridBox gap='3'>
				<GridBox flow='column' align='center' gap='3' justify='end' css={{paddingRight: 4}}>
					<Button.Icon dataCy='all-credentials-button' onClick={onOpenCredentialsForSelectedStudents}>
						<AccessAll />
					</Button.Icon>
					<Button.Icon dataCy='clear-all-button' onClick={onClearAllClick}>
						<ClearAll />
					</Button.Icon>
				</GridBox>

				<GridBox gap='1'>
					{selectedStudentsIDs.map((studentID) => {
						const student = studentsList.find(({id}) => studentID === id);

						if (!student) {
							return null;
						}

						const {id, firstName, lastName, hasCredentials} = student;

						return (
							<SelectedStudentRow
								id={id}
								key={id}
								firstName={firstName}
								lastName={lastName}
								hasCredentials={hasCredentials}
								onClearButtonClick={onRemoveStudentByID}
								onOpenStudentCredentials={onOpenStudentCredentials}
							/>
						);
					})}
				</GridBox>

				{hasStudentsWithoutCredentials && <NoCredentialsNotification />}
			</GridBox>
		);
	};

	return (
		<SectionBlock dataCy='selected-students-section' title='Selected Students'>
			{getSectionContent()}
		</SectionBlock>
	);
}
