import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {storage, SubjectTab} from '@esgi/main/libs/store';

export class SubjectService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public subjects$ = new BehaviorSubject<SubjectTab[]>([]);
	private subjectStorage = storage.subjects();

	constructor() {
		super();

		this.subjectStorage.get().subscribe((subjects) => {
			this.subjects$.next(subjects.filter(({hidden, tests}) => !hidden && tests.length > 0));

			if (!this.loaded$.value) {
				this.loaded$.next(true);
			}
		});
	}

	public override dispose() {
		this.subjectStorage.dispose();
	}

	public get(subjectId: SubjectTab['id']) {
		return this.subjects$.value.find(({id}) => id === subjectId);
	}

	public getByIndex(index: number) {
		return this.subjects$.value[index];
	}
}
