import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const EmptyBox = styled(Box, {
	margin: '20px auto',
	textAlign: 'center',
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $neutral92',
	background: '$neutral99',
});
