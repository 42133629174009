import {userStorage, IUserContext, UserInfo, UserType} from '@esgi/core/authentication';
import {BaseService} from '@esgi/core/service';
import {ClassicHierarchyLevel, HierarchyInstance, HierarchyMode} from 'modules/hierarchy/core/models';
import {TestHistorySubjectChangedEvent} from 'modules/reports/test-history/events';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {SubjectType} from '@esgi/core/enums';
import {PieChartsReportEvents} from 'shared/modules/reports/pie-chart/service/events';
import {EventBusManager} from '@esgillc/events';
import {ReportsPanelEvents} from '../../components/report-button/reports-panel';
import {SubjectChangedItselfEvent} from './events';
import EventsHandlingService from './events-handling-service';
import {districtTabsFilter, notHiddenFilter, schoolTabsFilter} from './filters';
import {LoadResponse, SubjectInfo, SubjectModel} from './models';
import {SubjectsStore} from './subjects-store';
import {canPassFilter, firstVisibleSubject, gradeLevelsByHierarchy} from './utils';


export default class SubjectsService extends BaseService {
	public readonly selectedSubject: BehaviorSubject<SubjectModel> = new BehaviorSubject<SubjectModel>(null);
	private readonly hierarchy$: BehaviorSubject<HierarchyInstance> = new BehaviorSubject<HierarchyInstance>(null);
	private readonly subjectsStore: SubjectsStore = new SubjectsStore();
	private readonly eventsHandlingService: EventsHandlingService = new EventsHandlingService(this.subjectsStore, (s) => this.selectSubject(s), this.selectedSubject);
	private readonly subjectsReloaded: Subject<void> = new Subject();
	private user: UserInfo;
	private readonly eventBus = new EventBusManager();
	private promiseSubjectTabs: Promise<LoadResponse> = null;
	private readonly controller = 'pages/home/subjects';

	constructor() {
		super();
		this.user = userStorage.get();

		this.eventBus.subscribe(ReportsPanelEvents.SubjectChanged, (event) => this.updateSubjectsOutside(this.hierarchy$.value, event));
		this.eventBus.subscribe(PieChartsReportEvents.SubjectChanged, (event) => this.updateSubjectsOutside(this.hierarchy$.value, event));
		this.eventBus.subscribe(TestHistorySubjectChangedEvent, (event) => this.updateSubjectsOutside(this.hierarchy$.value, event));

		this.completeOnDestroy(combineLatest(this.districtSubjects$(), this.schoolSubjects$(), this.personalSubjects$(), this.stockSubjects$()))
			.pipe(debounceTime(50)).subscribe(data => this.subjectsChangedHandler(data));
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
		this.eventsHandlingService.destroy();
	}

	public districtSubjects$(): Observable<SubjectModel[]> {
		return this.completeOnDestroy(combineLatest(this.subjectsStore
			.getSource(SubjectType.Deployed), this.hierarchy$)
			.pipe(map(data => {
				const source = data[0];
				const hierarchy = data[1];
				const grades = gradeLevelsByHierarchy(hierarchy);
				return districtTabsFilter(source.filter(s => s.level === 'District'), this.user.userType, grades, hierarchy);
			})));
	}

	public schoolSubjects$(): Observable<SubjectModel[]> {
		return this.completeOnDestroy(combineLatest(this.subjectsStore
			.getSource(SubjectType.Deployed), this.hierarchy$)
			.pipe(map(data => {
				const source = data[0];
				const hierarchy = data[1];
				const grades = gradeLevelsByHierarchy(hierarchy);
				return schoolTabsFilter(source.filter(s => s.level === 'School'), this.user.userType, grades, hierarchy);
			})));
	}

	public personalSubjects$(): Observable<SubjectModel[]> {
		return this.completeOnDestroy(this.subjectsStore
			.getSource(SubjectType.Personal)
			.pipe(map(s => notHiddenFilter(s))));
	}

	public stockSubjects$(): Observable<SubjectModel[]> {
		return this.completeOnDestroy(this.subjectsStore
			.getSource(SubjectType.Stock)
			.pipe(map(s => notHiddenFilter(s))));
	}

	public selectedSubject$(): Observable<SubjectModel> {
		return this.completeOnDestroy(this.selectedSubject);
	}

	public subjectsReloaded$(): Observable<void> {
		return this.completeOnDestroy(this.subjectsReloaded);
	}

	public async selectSubject(subject: SubjectModel) {
		await this.changeSubject(subject);
		this.eventBus.dispatch(SubjectChangedItselfEvent, {subject: subject} as SubjectChangedItselfEvent);
	}

	public async initialize(hierarchy: HierarchyInstance): Promise<SubjectModel> {
		this.hierarchy$.next(hierarchy);
		return await this.reloadSubjectTabs();
	}

	public async updateGlobalSchoolYear(globalSchoolYearID: number, hierarchy: HierarchyInstance): Promise<SubjectModel> {
		if (globalSchoolYearID !== this.user.globalSchoolYearID) {
			this.user.globalSchoolYearID = globalSchoolYearID;
			return await this.reloadSubjectTabs(hierarchy);
		} else {
			return await this.hierarchyChangedHandler(hierarchy);
		}
	}

	public updateSubjectsOutside = async (hierarchy: HierarchyInstance, subjectInfo?: SubjectInfo): Promise<SubjectModel> => {
		if (subjectInfo) {
			const {id, type} = subjectInfo;
			const subject = this.subjectsStore?.getSubject(id, type);
			if (subject && subject !== this.selectedSubject.value) {
				await this.changeSubject(subject);
			}
		}
		await this.hierarchyChangedHandler(hierarchy);
		this.hierarchy$.next(hierarchy);

		//wait subjectsChangedHandler complete
		return await firstValueFrom(this.selectedSubject.pipe(debounceTime(100)));
	};

	private async hierarchyChangedHandler(hierarchy: HierarchyInstance): Promise<SubjectModel> {
		if (this.hierarchy$.value !== hierarchy && !hierarchy.equal(this.hierarchy$.value)) {
			const newHierarchy = hierarchy;
			const prevHierarchy = this.hierarchy$.value;

			if (hierarchy.mode === HierarchyMode.Classic && prevHierarchy) {
				if (prevHierarchy.mode === HierarchyMode.Specialist) {
					return this.reloadSubjectTabs(hierarchy);
				}

				// if class changes always reload
				if (prevHierarchy.classic.classID !== newHierarchy.classic.classID) {
					return this.reloadSubjectTabs(hierarchy);
				}

				// if teacher changes always reload
				if (prevHierarchy.classic.teacherID !== newHierarchy.classic.teacherID) {
					return this.reloadSubjectTabs(hierarchy);
				}

				const prevSelected = prevHierarchy.classic.selected;
				const selected = newHierarchy.classic.selected;

				// if changes was done at the level below teacher, we won't reload subjects
				// because subjects do not change
				const belowTeacher = [ClassicHierarchyLevel.Student, ClassicHierarchyLevel.Group, ClassicHierarchyLevel.Class];
				if (belowTeacher.some(s => s === prevSelected.level) && belowTeacher.some(s => s === selected.level)) {
					return this.selectedSubject.value;
				}

				// we reload subjects if level was changed
				// e.g. teacher was selected and now student is selected
				if (prevSelected.level !== selected.level) {
					return this.reloadSubjectTabs(hierarchy);
				}


				// we reload when level wasn't changed, but the item was changed and the item is teacher or higher
				// e.g. if DA selects another teacher/school we have to reload subjects
				const upperTeacher = [ClassicHierarchyLevel.Teacher, ClassicHierarchyLevel.School, ClassicHierarchyLevel.District];
				if (upperTeacher.some(s => s === selected.level)) {
					if (prevSelected.levelID !== selected.levelID) {
						return this.reloadSubjectTabs(hierarchy);
					}

				}
			}

			if (hierarchy.mode === HierarchyMode.Specialist && prevHierarchy) {

				if (this.user.userType === UserType.C) {
					hierarchy.specialist.filter.schoolID = this.user.schoolID;
					hierarchy.snapshot.specialist.filter.schoolID = this.user.schoolID;
				}

				if (prevHierarchy.mode !== hierarchy.mode && prevHierarchy.classic.selected.level !== ClassicHierarchyLevel.District) {
					return this.reloadSubjectTabs(hierarchy);
				}

				const prevSpecialist = prevHierarchy.specialist;
				const specialist = hierarchy.specialist;

				if (prevSpecialist.userID !== specialist.userID || prevSpecialist.filter.schoolID !== specialist.filter.schoolID) {
					return this.reloadSubjectTabs(hierarchy);
				}
			}
			if (hierarchy.mode === HierarchyMode.PreAssess && prevHierarchy) {
				if (prevHierarchy.mode !== hierarchy.mode && prevHierarchy.classic.selected.level !== ClassicHierarchyLevel.District) {
					return this.reloadSubjectTabs(hierarchy);
				}

				return this.reloadSubjectTabs(hierarchy);
			}
		}

		return this.selectedSubject.value;
	}

	private async getSubjectTabs(subjectID, subjectType, fullHierarchy) {
		if (!this.promiseSubjectTabs) {
			this.promiseSubjectTabs = this.httpClient.ESGIApi.get<LoadResponse>(
				this.controller,
				'tabs',
				{subjectID, subjectType, fullHierarchy},
			).toPromise();
		}
		try {
			const result = await this.promiseSubjectTabs;
			return result;
		} finally {
			this.promiseSubjectTabs = null;
		}
	}

	private async reloadSubjectTabs(hierarchy?: HierarchyInstance): Promise<SubjectModel> {
		const currentHierarchy = hierarchy || this.hierarchy$.value;
		const result = await this.getSubjectTabs(
			this.selectedSubject.value?.id,
			this.selectedSubject.value?.type,
			currentHierarchy.snapshot,
		);
		if (!result) {
			return;
		}
		const subjects = result.deployedSubjects
			.concat(result.stockSubjects)
			.concat(result.subjects)
			.map(s => SubjectModel.FromResponse(s));

		this.subjectsStore.setSource(subjects);
		let selectedSubject = result.selectedSubject ?
			subjects.find((s) => (
				s.id === result.selectedSubject.id
				&& s.type === SubjectType[result.selectedSubject.subjectType]
				&& !s.hidden
			))
			: null;
		if (selectedSubject) {
			if (this.subjectsStore.getSubject(selectedSubject.id, selectedSubject.type)) {
				// Determine if a subject can pass filter before setting it as selected. Backend can return as
				// selected the subject that will not pass the filter.
				const grades = gradeLevelsByHierarchy(currentHierarchy);
				let silenceUpdate = true;
				if (!canPassFilter(selectedSubject, grades, currentHierarchy, this.user.userType)) {
					silenceUpdate = false;
					selectedSubject = firstVisibleSubject(this.subjectsStore.getSubjects(), currentHierarchy, this.user.userType);
				}
				this.changeSubject(selectedSubject, silenceUpdate);
			} else {
				const subjectToSelect = firstVisibleSubject(this.subjectsStore.getSubjects(), currentHierarchy, this.user.userType);
				this.changeSubject(subjectToSelect);
			}
		}

		if (!this.selectedSubject.value) {
			const subjectToSelect = firstVisibleSubject(this.subjectsStore.getSubjects(), currentHierarchy, this.user.userType);
			this.changeSubject(subjectToSelect, true);
		}
		this.subjectsReloaded.next();
		return this.selectedSubject.value;
	}

	private changeSubject(subject: SubjectModel, silent: boolean = false) {
		this.selectedSubject.next(subject);

		if (!silent && subject) {
			const {id: subjectId, type: subjectType} = subject;
			this.httpClient.ESGIApi.post(
				this.controller,
				'update-selected-subject',
				{subjectId, subjectType},
			).subscribe();
		}
	}

	//Determine which subject tab should select when subjects or grade levels changed.
	private subjectsChangedHandler(data: SubjectModel[][]) {
		if (!data.flat().length) {
			this.changeSubject(null, true);
		}
		const selected = this.selectedSubject.value;
		let newSelected = null, needUpdate = true;
		for (const source of data) {
			if (source.length) {
				if (selected) {
					if (selected.type === source[0].type) {
						if (selected.type === SubjectType.Deployed && selected.level !== source[0].level) {
							continue;
						}

						if (!source.find(s => s.id === selected.id)) {
							newSelected = source[0];
							break;
						} else {
							needUpdate = false;
						}
					}
				} else {
					newSelected = source[0];
					this.changeSubject(newSelected);
					break;
				}
			}
		}

		if (needUpdate) {
			if (!newSelected) {
				const flattedData = data.flat(1);
				newSelected = flattedData[0];
			}
			newSelected && this.changeSubject(newSelected);
		}
	}
}
