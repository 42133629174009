import {FlexBox, GridBox} from '@esgi/ui/layout';
import {AnswersInfoContainer, Divider, PercentageCheap, TooltipContainer} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {AnswerBarBoxVertical} from '../answer-bar-box';
import {useTooltipData} from './use-tooltip-data';
import {useItemStatistics} from '../../hooks/use-item-statistics';

type Props = {
	sessionsCount: number;
	correct: number;
	incorrect: number;
	questionTitle?: string;
};

export function TooltipContent({sessionsCount, correct, incorrect, questionTitle}: Props) {
	const itemStatistics = useItemStatistics({sessionsCount, correct, incorrect});
	const answerInfoItems = useTooltipData(itemStatistics);

	return (
		<TooltipContainer>
			{questionTitle && (
				<>
					<GridBox gap='2'>
						<Text size='small' font='mono' color='neutral40'>
							Question:
						</Text>
						<Text size='medium' bold color='base'>
							{questionTitle}
						</Text>
					</GridBox>

					<Divider />
				</>
			)}

			<AnswersInfoContainer>
				<GridBox gap='2'>
					{answerInfoItems.map(({title, value, valuePercentage, cheapType, cheapTextColor}, index) => (
						<FlexBox justify='between' align='center' key={index}>
							<Text size='small' font='mono' color='neutral40'>
								{title}
							</Text>
							<GridBox gap='2' flow='column' align='center'>
								<Text size='medium' font='mono' bold color='base'>
									{value}
								</Text>
								<PercentageCheap colorType={cheapType}>
									<Text size='medium' font='mono' bold color={cheapTextColor}>
										{valuePercentage}%
									</Text>
								</PercentageCheap>
							</GridBox>
						</FlexBox>
					))}
				</GridBox>

				<AnswerBarBoxVertical correct={correct} incorrect={incorrect} sessionsCount={sessionsCount} />
			</AnswersInfoContainer>

			<Divider />

			<GridBox justify='between' flow='column'>
				<Text size='small' font='mono' color='neutral40'>
					Question Sessions:
				</Text>
				<Text size='small' font='mono' bold color='base'>
					{sessionsCount}
				</Text>
			</GridBox>
		</TooltipContainer>
	);
}
