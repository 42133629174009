import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Pagination as PaginationRoot} from '../../../../../../kit';
import {Text} from '@esgi/ui/typography';

export const DiagramContainer = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr auto',
	gap: 20,
	alignItems: 'start',
});

export const SubHeaderRowBox = styled(GridBox, {
	gridColumnStart: 1,
	gridColumnEnd: 4,
	justifySelf: 'end',
	paddingLeft: 4,
	paddingRight: 4,
	marginTop: -4,
	marginBottom: -4,
});

export const Pagination = styled(PaginationRoot, {
	gridColumnStart: 1,
	gridColumnEnd: 4,
	justifySelf: 'end',
});

export const RightableText = styled(Text, {
	textAlign: 'right',
});

export const SessionNoteText = styled(Text, {
	gridColumnStart: 2,
	gridColumnEnd: 4,
});
