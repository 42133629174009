import {ChangeFunction} from '../types';
import {SubjectTab} from '@esgi/main/libs/store';
import {Label, SelectField, SelectOption} from './index.styled';
import {Select} from '@esgi/ui/controls';
import {Skeletonable} from '@esgi/ui';

interface Props extends Skeletonable {
	subjects: SubjectTab[];
	value: string;
	disabled?: boolean;
	onChange: ChangeFunction<string>;
}

export function SubjectControl(props: Props) {
	const {skeleton, subjects, value, disabled, onChange} = props;

	return (
		<Select.Root
			dataCy='subject-control-root'
			value={value}
			disabled={disabled}
			onValueChange={(value) => onChange(value)}
		>
			<SelectField
				data-cy='subject-control-select-field'
				skeleton={skeleton}
				placeholder='Subject Tab'
			/>
			<Select.Content dataCy='subject-control-item'>
				{subjects.map(({id, name}) => (
					<SelectOption
						key={id}
						value={id.toString()}
					>
						<Label size='medium' bold>{name}</Label>
					</SelectOption>
				))}
			</Select.Content>
		</Select.Root>
	);
}
