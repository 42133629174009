import {Link} from 'react-router-dom';
import {useCallback, useEffect, useMemo} from 'react';
import {Button, Cast, Copy} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useService} from '@esgi/core/service';
import {useUser} from '@esgi/core/authentication';
import {environment} from '@esgi/core/environments';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';

import {StudentScreenService} from './service/service';
import {
	BodyWrapper,
	ButtonDescription,
	CodeContainer,
	CodeContent,
	CodeDescription,
	CodeExpiration,
	StyledButton,
	SubTitle,
	WarningText,
} from './index.styled';

type Props = {
	onClose: VoidFunction,
};

export function StudentScreenDrawer (props: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, props.onClose);
	const {userID} = useUser();
	const service = useService(StudentScreenService);
	const expireTime = useBehaviorSubject(service.expireTime);
	const code = useBehaviorSubject(service.code);

	const copyToClipboard = useCallback(() => navigator.clipboard.writeText(code), [code]) ;

	const secondScreenLink = useMemo(() => {
		if(environment.environmentType === 'master') {
			return <a href='https://www.esgiss.com' target='_blank' rel='noreferrer'>esgiss.com</a>;
		} else {
			return <Link to={`${environment.url}/student-screen`} target='_blank'>esgiss.com</Link>;
		}
	}, []);

	const getCode = useCallback(() => service.createShareSession(userID), [service, userID]);

	useEffect(() => service.init(userID), [service, userID]);

	return (
		<Drawer data-cy='student-screen-panel' drawerRef={drawerRef} onClickOutside={close}>
			<Drawer.Header data-cy='student-screen-panel-header' Icon={Cast} sectionName='Student Screen' closeDrawer={close} />
			<Drawer.Body>
				<BodyWrapper>
					<SubTitle data-cy='student-screen-subtitle' size='medium'>
						Utilize a second screen that only displays test question content.
						Buttons, notes, and test directions are hidden from the student view.
					</SubTitle>
					<ButtonDescription data-cy='student-screen-button-description' size='small'>
						Press the button to generate a code
					</ButtonDescription>
					{code ? (
						<CodeContainer data-cy='code-container'>
							<CodeContent>
								<Text size='small' font='mono'>Your Code: </Text>
								<Text size='small'>{code}</Text>
							</CodeContent>
							<Button.Icon>
								<Copy width={24} height={24} onClick={copyToClipboard}/>
							</Button.Icon>
						</CodeContainer>
					) : (
						<StyledButton data-cy='get-code-button' color='secondary' onClick={getCode}>
							<Text size='medium'>Get a code</Text>
						</StyledButton>
					)}
					<CodeDescription data-cy='student-screen-code-description'>
						<Text size='small'>
							Go to {secondScreenLink} on your student screen.
							Enter the code. <br/>{!expireTime && 'Codes not entered within 5 minutes will expire.'}
						</Text>
					</CodeDescription>
					{expireTime && <CodeExpiration dataCy='code-expiration'>
						<Text font='mono' size='xSmall'>The code will expire in:</Text>
						<WarningText font='mono' size='xSmall'>{expireTime}</WarningText>
					</CodeExpiration>}
				</BodyWrapper>
			</Drawer.Body>
		</Drawer>
	);
}
