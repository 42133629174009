import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, map, tap} from 'rxjs';
import {
	CreateTrackRequest,
	NewPhotoContract,
	SavePersonalDataBody,
	SchoolYearModel,
	SchoolsByQueryParamsResponse,
	SecondaryProfile,
	TrackDateModel,
	TrackModel,
	UpdateTrackRequestParams,
	UserCountry,
	UserModel,
	UserPhoto,
	UserState,
} from './types';
import {isUndefined} from '@esgi/ui';
import moment from 'moment';
import {V2TeachersUserAccountController, V2TracksController} from '@esgi/contracts/esgi';
import {userStorage} from '@esgi/core/authentication';

export class UserAccountService extends BaseService {
	public schoolYearTypes$ = new BehaviorSubject<SchoolYearModel[] | null>(null);

	public user$ = new BehaviorSubject<UserModel | null>(null);
	public secondaryProfile$ = new BehaviorSubject<SecondaryProfile | null>(null);
	public countries$ = new BehaviorSubject<UserCountry[] | null>(null);
	public states$ = new BehaviorSubject<UserState[] | null>(null);

	public activeUserTrackId$ = new BehaviorSubject<TrackModel['trackID'] | null>(null);
	public tracks$ = new BehaviorSubject<TrackModel[] | null>(null);

	public isUserDataLoading$ = new BehaviorSubject(false);

	private controller = new V2TeachersUserAccountController();
	private tracksController = new V2TracksController();

	public init() {
		return this.controller.init().pipe(
			tap(({user, secondaryProfile, countries, states, tracksInfo: {selectedTrackID, tracks, schoolYearTypes}}) => {
				const currentTrack = tracks.find(({trackID}) => trackID === selectedTrackID) ?? tracks[0];

				if (isUndefined(currentTrack)) {
					throw new Error('Current Track is not existed');
				}

				this.user$.next(user as UserModel);
				this.secondaryProfile$.next(secondaryProfile);
				this.countries$.next(countries);
				this.states$.next(states);

				this.activeUserTrackId$.next(selectedTrackID);
				this.tracks$.next(tracks as TrackModel[]);

				this.schoolYearTypes$.next(schoolYearTypes);
			}),
		);
	}

	public createNewTrackRequest: CreateTrackRequest = ({trackDates, trackName, trackType}) => {
		const schoolYearTypeID = this.schoolYearTypes$.value?.find(({name}) => name === trackType)?.id;

		if (isUndefined(schoolYearTypeID)) {
			throw new Error('schoolYearTypeID is not found');
		}

		return this.tracksController
			.create({
				name: trackName,
				trackDates: trackDates.map((item) => ({
					dateFrom: moment(item.dateFrom.value).format('YYYY-MM-DD'),
					dateTo: moment(item.dateTo.value).format('YYYY-MM-DD'),
				})),
				schoolYearTypeID,
			})
			.pipe(
				tap((createdTrack) => {
					const currentTracks = this.tracks$.value;

					this.tracks$.next([
						...(currentTracks ?? []),
						{
							...createdTrack,
							schoolYearType: trackType,
						},
					]);
				}),
			);
	};

	public updateTrackRequest({updatedTrack, trackType, trackDates, trackName}: UpdateTrackRequestParams) {
		const schoolYearTypeID = this.schoolYearTypes$.value?.find(({name}) => name === trackType)?.id;

		if (isUndefined(schoolYearTypeID)) {
			throw new Error('schoolYearTypeID is not found');
		}

		const newCurrentUserTrack: TrackModel = {
			...updatedTrack,
			name: trackName,
			schoolYearTypeID,
			schoolYearType: trackType,
			trackDates: trackDates.map<TrackDateModel>((item, index) => ({
				trackID: updatedTrack.trackID,
				dateFrom: moment(item.dateFrom.value).format('YYYY-MM-DD'),
				dateTo: moment(item.dateTo.value).format('YYYY-MM-DD'),
				trackDateID: updatedTrack.trackDates[index]?.trackDateID ?? 0,
			})),
		};

		return this.tracksController
			.update({
				name: newCurrentUserTrack.name,
				schoolYearTypeID,
				trackID: updatedTrack.trackID,
				trackDates: newCurrentUserTrack.trackDates.map(({dateFrom, dateTo}) => ({
					dateFrom,
					dateTo,
				})),
			})
			.pipe(
				tap(() => {
					const currentTracks = this.tracks$.value;

					this.tracks$.next(
						currentTracks?.map((iteratedTrack) =>
							iteratedTrack.trackID === updatedTrack.trackID ? newCurrentUserTrack : iteratedTrack,
						) ?? [newCurrentUserTrack],
					);
				}),
			);
	}

	public setActiveTrackId(trackId: TrackModel['trackID']) {
		this.tracksController
			.setAsActive({trackID: trackId})
			.pipe(
				tap(() => {
					this.activeUserTrackId$.next(trackId);
				}),
			)
			.subscribe();
	}

	public savePersonalData({countryID, newPhoto, ...body}: SavePersonalDataBody) {
		this.isUserDataLoading$.next(true);

		const currentUserValue = this.user$.value;

		this.controller
			.tabsPersonalSave({...body, newPhoto: newPhoto as NewPhotoContract})
			.subscribe(({avatarUrl, avatarCropUrl}) => {
				if (currentUserValue) {
					let photo: UserPhoto | null = currentUserValue.photo;

					if (newPhoto?.remove === true) {
						photo = null;
					}

					if (newPhoto?.remove === false) {
						const {crop} = newPhoto;

						photo = {
							imageUrl: avatarUrl,
							imageCropUrl: avatarCropUrl,
							crop,
						};
					}

					this.user$.next({
						...currentUserValue,
						...body,
						photo,
						countryID,
					});

					userStorage.update({
						firstName: body.firstName,
						lastName: body.lastName,
						stateID: Number(body.stateID),
						avatarUrl: photo?.imageCropUrl ?? null,
					});

					this.isUserDataLoading$.next(false);
				}
			});
	}

	// TODO: should be rewritten on a new endpoint
	public getStatesByCountryID(countryID: UserCountry['countryID']) {
		return this.httpClient.ESGIApi.get<{id: number; name: string}[]>('profiles/common', 'get-states-by-country-id', {
			countryID,
		}).pipe(map((response) => response.map<UserState>(({id, name}) => ({stateID: id, name}))));
	}

	public saveSchoolName(schoolName: string) {
		return this.controller.tabsSchoolAndDistrictSave({name: schoolName});
	}

	// TODO: should be rewritten on a new endpoint
	public getSchoolsByQueryParams(schoolName: string) {
		const onlyLettersNumbersSpaces = /[^a-zA-Z0-9\s]/g;

		return this.httpClient.ESGIApi.get<SchoolsByQueryParamsResponse>('profiles/common', 'federal-schools', {
			Query: schoolName.replace(onlyLettersNumbersSpaces, ''),
			StateID: this.user$.value?.stateID,
		});
	}

	public saveSecondaryProfileData(updatedData: Partial<SecondaryProfile>) {
		const currentSecondaryProfile = this.secondaryProfile$.value;

		if (currentSecondaryProfile) {
			const newSecondaryProfileData: SecondaryProfile = {
				...currentSecondaryProfile,
				...updatedData,
			};

			this.secondaryProfile$.next(newSecondaryProfileData);

			return this.controller.updateSecondaryProfile(newSecondaryProfileData);
		}

		throw new Error('currentSecondaryProfile is null');
	}

	public override dispose() {
		this.controller.dispose();
		this.tracksController.dispose();
	}
}
