import {ToggleGroup} from '@esgi/ui/controls';
import {TestInfo, TestSession} from '../../../../../../../types';
import {useMemo, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {CenteredText, TabDescriptionBox, ToggleGroupContent, ToggleGroupItem} from './index.styled';
import {useDiagramLegendItems} from './hooks/use-diagram-legend-items';
import {DiagramBox} from '../../../../diagram-box';
import {noop} from 'underscore';
import {ItemAnalysisDiagram, ItemAnalysisDiagramDetailed} from '../../../../item-analysis-diagram';
import {Container, Sidebar} from '../content.styled';
import {TabId} from './types';
import {tabItems} from './constants';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	emptySessionsCount: number;
	isTablet: boolean;
};

export function YesNoContent({testInfo, testSessions, emptySessionsCount, isTablet}: Props) {
	const [activeTab, setActiveTab] = useState(TabId.QuickView);

	const isDetailedView = activeTab === TabId.DetailedView;

	const activeDescription = useMemo(
		() =>
			isDetailedView
				? ['To view this chart as a summary,', 'switch to Quick View.']
				: ['Hover over any bar to view question details, or switch to Detailed View.'],
		[isDetailedView],
	);

	const diagramLegendItems = useDiagramLegendItems({isDetailedView: activeTab === TabId.DetailedView});

	return (
		<Container isTablet={isTablet}>
			<Sidebar>
				<ToggleGroup.Root onValueChange={(tabId) => setActiveTab(tabId as TabId)} value={activeTab}>
					<ToggleGroupContent>
						{tabItems.map(({label, tabId}) => (
							<ToggleGroupItem value={tabId} disabled={tabId === activeTab} applyDisabledStyles={false} key={tabId}>
								<Text size='medium' bold>
									{label}
								</Text>
							</ToggleGroupItem>
						))}
					</ToggleGroupContent>
				</ToggleGroup.Root>
				<TabDescriptionBox isTablet={isTablet}>
					{activeDescription.map((textValue) => (
						<CenteredText size='small' font='mono' color='lowContrast' key={textValue}>
							{textValue}
						</CenteredText>
					))}
				</TabDescriptionBox>
			</Sidebar>

			<DiagramBox legendItems={diagramLegendItems} onFullScreenButtonClick={noop}>
				{isDetailedView ? (
					<ItemAnalysisDiagramDetailed
						sessions={testSessions}
						testInfo={testInfo}
						YAxisLabel='Students'
						emptySessionsCount={emptySessionsCount}
						isTablet={isTablet}
					/>
				) : (
					<ItemAnalysisDiagram
						sessions={testSessions}
						testInfo={testInfo}
						YAxisLabel='Students'
						emptySessionsCount={emptySessionsCount}
					/>
				)}
			</DiagramBox>
		</Container>
	);
}
