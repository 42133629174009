import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {SectionWrapper} from '../section-wrapper';
import {SsoLinks} from '../sso-links';
import {GeneralSectionFormType} from '../../../user-settings/forms/general-section';
import styles from './styles.module.less';
import {StudentSortModel, TimezoneValue} from 'shared/modules/user-settings/types';

interface Props {
	form: GeneralSectionFormType;
	sortValues: StudentSortModel[];
	timeZones: TimezoneValue[];
}

export function GeneralSection({
	                               form,
	                               sortValues,
	                               timeZones,
}: Props) {

	return (
		<div data-cy='general-section'>
			<SectionWrapper className={styles.section} title={'GENERAL'}>
				<Form controller={form}>
					<div data-cy={'sort-values'}>
						<FormElement control={form.controls.sortBy} className={styles.formElement}>
							<label className={styles.label}>Sort Students by</label>
							<Dropdown className={styles.dropdown} optionName={'name'}>
								{sortValues.map(x => <Option key={x.value} value={x}>{x.name}</Option>)}
							</Dropdown>
						</FormElement>
					</div>
					<div data-cy={'time-zones'}>
						<FormElement control={form.controls.timeZone} className={styles.formElement}>
							<label className={styles.label}>Time Zone:</label>
							<Dropdown className={styles.dropdown} optionName={'name'}>
								{timeZones.map(x => <Option key={x.id} value={x}>{x.name}</Option>)}
							</Dropdown>
						</FormElement>
					</div>
					<div className={styles.formElement}>
						<label className={styles.label}>Single Sign On</label>
						<SsoLinks
							isCleverAccountLinked={form.controls.isCleverAccountLinked.value}
							isOneClickAccountLinked={form.controls.isOneClickAccountLinked.value}
							setCleverAccountLinked={(v) => form.controls.isCleverAccountLinked.value = v}
							setOneClickAccountLinked={(v) => form.controls.isOneClickAccountLinked.value = v}
						/>
					</div>
				</Form>
			</SectionWrapper>
		</div>
	);
}
