import {getThemeColor} from '@esgi/ui';
import {ComponentProps} from 'react';
import {Bar} from 'recharts';
import {barLineGap} from '../../../rubric-diagram/constants';

type Props = Omit<ComponentProps<typeof Bar>, 'dataKey'> & {
	score?: number;
	maxScore: number;
}

export function BarRect({x, y, width, score, maxScore}: Props) {
	const squareSize = barLineGap + width;

	return (
		<>
			{new Array(maxScore).fill('').map((_, idx) => (
				<rect
					x={+x + squareSize * idx}
					y={y}
					width={width}
					height={width}
					rx={1}
					fill={getThemeColor(idx < score ? 'primaryMuted' : 'vivid')}
					key={+y + idx}
				/>
			))}
			<rect
				x={+x + squareSize * (score - 1) + width - 2}
				y={y}
				width={2}
				height={width}
				fill={getThemeColor('primary48')}
			/>
		</>
	);
}
