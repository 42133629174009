import {styled} from '@esgi/ui/theme';
import {SelectableList} from '@esgi/ui/layout';

export {SelectableList} from '@esgi/ui/layout';

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	alignItems: 'center',
	gridTemplateColumns: 'repeat(3, auto) 1fr',
	gap: 12,
	padding: '8px 12px',
	borderRadius: '6px',
	border: 0,
	color: '$neutral40',

	'&:not(:first-child)': {
		marginTop: '8px',
	},

	'& > svg': {
		width: '24px',
		height: '24px',
		alignSelf: 'start',
	},

	variants: {
		checked: {
			true: {
				color: '$primary',
			},
		},
		disabled: {
			true: {
				color: '$neutral80',
				backgroundColor: '$neutral99',
				cursor: 'default',
				pointerEvents: 'none',
			},
		},
	},
});
