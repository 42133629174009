import {BehaviorSubject, combineLatest, combineLatestWith, tap} from 'rxjs';
import {EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {AssetsBankQuestionsController} from '@esgi/contracts/esgi';
import {
	CloseQuestionBankEvent,
	ForceCloseQuestionBankEvent,
} from 'shared/modules/test-details/events';
import {
	QuestionContainer,
	FilterModel,
	SortDescriptor,
	BankQuestionModel,
	PageRequest,
	PageResponse,
	Test,
	ExtendedBankQuestionModel,
	ViewMode,
} from '../types';
import {sortQuestionByOrderNum} from '../utils';
import {LocalFilterBuilder} from './local-filter-builder';
import {defaultFilters, defaultSortBy} from '../constants';
import {isNull} from 'underscore';
import {StandardsService} from '@esgi/main/features/standards-drawer';

export class QuestionBankService extends BaseService {
	public selectedView = new BehaviorSubject<ViewMode>(ViewMode.List);
	public questions = new BehaviorSubject<QuestionContainer>({});
	public isLoading = new BehaviorSubject<boolean>(false);
	public hasChanges$ = new BehaviorSubject<boolean>(false);
	public initialSelection = new BehaviorSubject<QuestionContainer>({});
	public loadedQuestionsCount = new BehaviorSubject<number>(0);
	public isShowOnlySelected = new BehaviorSubject<boolean>(false);
	public pageRequest$ = new BehaviorSubject<PageRequest>({
		sortBy: {...defaultSortBy},
		filters: null,
		skip: 0,
		take: 25,
	});
	public isSaving = new BehaviorSubject<boolean>(false);
	public selectedQuestions = new BehaviorSubject<QuestionContainer>([]);
	public pageResponse$ = new BehaviorSubject<PageResponse | null>(null);
	public test$ = new BehaviorSubject<Test>(null);

	public isBeingClosedNaturally: boolean = false;

	public searchText$ = new BehaviorSubject<string>('');

	private readonly takeCount = 25;
	private localQuestionList: ExtendedBankQuestionModel[] = [];
	private controller: AssetsBankQuestionsController;
	private readonly defaultRequest: PageRequest = {
		take: this.takeCount,
		skip: 0,
		filters: null,
		sortBy: {...defaultSortBy},
	};

	private eventBusManager = new EventBusManager();

	constructor(public standardsService: StandardsService) {
		super();

		this.controller = new AssetsBankQuestionsController();
		this.controller.stateInit().subscribe((response) => {
			if (!isNull(response.state.cardView)) {
				this.selectedView.next(Number(ViewMode[response.state.cardView]));
			}
		});

		this.init();
	}

	public get isAnyFilterSelected() {
		return [
			this.standardsService.selectedGradeLevelIDs$.value.length,
			this.standardsService.selectedContentAreaIDs$.value.length,
			this.standardsService.selectedStandardsIDs$.value.length,
			this.standardsService.selectedStandards$.value.length,
		].some(value => Boolean(value));
	}

	public updateSearchText(value: string) {
		this.searchText$.next(value);
	}

	public resetFilters = () => {
		this.standardsService.onStandardsClear();
		this.pageRequest$.next({
			...this.pageRequest$.value,
			sortBy: defaultSortBy,
			filters: defaultFilters,
		});
	};

	public applySortBy(sortBy: SortDescriptor) {
		this.pageRequest$.next({...this.pageRequest$.value, sortBy, skip: 0});
	}

	public applySearchText(searchText: string) {
		const {filters} = this.pageRequest$.value;
		this.pageRequest$.next({
			...this.pageRequest$.value,
			filters: {...filters, searchText: searchText},
			skip: 0,
		});
	}

	public getPage(skip: number, take: number) {
		const prevValue = {...this.defaultRequest, ...this.pageRequest$.value};
		this.pageRequest$.next({...prevValue, skip, take});
	}

	public close(saQuestions = null, questions = null) {
		this.isBeingClosedNaturally = true;
		this.eventBusManager.dispatch<CloseQuestionBankEvent>(
			CloseQuestionBankEvent,
			new CloseQuestionBankEvent(saQuestions, questions)
		);
	}

	public forceClose() {
		this.eventBusManager.dispatch(
			ForceCloseQuestionBankEvent,
			new ForceCloseQuestionBankEvent()
		);
	}

	public saveTestAndClose() {
		const test = this.test$.value;
		const questionsCache = this.questions.value;
		const selectedQuestions = this.selectedQuestions.value;
		if (!test) {
			return;
		}

		this.isSaving.next(true);
		const selectedQuestionIDs = Object.keys(selectedQuestions).map(Number);

		const {bankQuestionIDs, questionIDs} = test.questions.reduce(
			(prev, current) => {
				const targetID = current.bankQuestionID ?? current.ID;
				return {
					questionIDs: [...prev.questionIDs, current.ID],
					bankQuestionIDs: [...prev.bankQuestionIDs, targetID],
				};
			},
			{questionIDs: [], bankQuestionIDs: []}
		);

		const deletedQuestionIDs = questionIDs.filter(
			(_, index) => !selectedQuestionIDs.includes(bankQuestionIDs[index])
		);

		const addedQuestionIDs = selectedQuestionIDs.filter(
			(id) => !bankQuestionIDs.includes(id)
		);

		this.controller
			.updateTest({testID: test.testID, addedQuestionIDs, deletedQuestionIDs})
			.subscribe({
				next: ({questions, selfAssessQuestions}) => {
					const saQuestions = selfAssessQuestions.map((question) => ({
						...question,
						thumbnailUrl:
							question.thumbnailUrl ??
							selectedQuestions[question.bankQuestionID]?.thumbnailUrl ??
							questionsCache[question.bankQuestionID]?.thumbnailUrl,
					}));

					this.close(saQuestions, questions);
				},
				complete: () => {
					this.isSaving.next(false);
				},
				error: () => {
					this.isSaving.next(false);
				},
			});
	}

	public loadTest(test: Test) {
		this.test$.next(test);
		const mappedQuestions = test.questions.reduce<QuestionContainer>(
			(prev, current) => ({
				...prev,
				[current.bankQuestionID ?? current.ID]: {
					...current,
					templateJson: current.template,
					templateType: current.templateType,
					contentAreaID: test.contentAreaID,
					contentAreaName: test.contentAreaName,
					gradeLevelIDs: [],
					stateStandardName: test.stateStandardName,
					stateStandards: [],
					name: current.name,
					thumbnailUrl: current.thumbnailUrl,
					id: current.bankQuestionID ?? current.ID,
					orderNum: current.orderNum,
				},
			}),
			{}
		);

		this.selectedQuestions.next(mappedQuestions);
		this.initialSelection.next({...mappedQuestions});
	}

	public toggleQuestion(question: BankQuestionModel) {
		const prevQuestions = this.selectedQuestions.value;
		if (prevQuestions[question.id]) {
			delete prevQuestions[question.id];
		} else {
			prevQuestions[question.id] = {...question};
		}

		this.selectedQuestions.next({...prevQuestions});
	}

	public toggleShowOnlySelected() {
		const prevValue = this.isShowOnlySelected.value;
		this.isShowOnlySelected.next(!prevValue);
	}

	public savePageState(viewMode: ViewMode) {
		this.controller.stateUpdate({state: {cardView: viewMode}}).subscribe();
	}
	public clearSelection() {
		this.selectedQuestions.next({});
		this.resetFilters();
	}

	public override dispose(): void {
		this.controller.dispose();
	}

	public override destroy() {
		super.destroy();

		this.standardsService.destroy();
		this.eventBusManager.destroy();
	}

	private applyLocalFilter(page: PageRequest): PageResponse {
		const localSource = new LocalFilterBuilder();
		const data = localSource
			.applyFilters(page.filters)
			.applySort(page.sortBy)
			.build(this.localQuestionList);
		return {
			data,
			total: data.length,
		};
	}

	private processResponse(req: PageRequest, response: PageResponse) {
		this.pageResponse$.next(response);
		let newQuestions = this.questions.value;
		if (req.skip === 0) {
			newQuestions = {};
		}
		let j = req.skip;
		for (let i = 0; i < response.data.length; i++) {
			newQuestions[j] = response.data[i];
			j++;
		}
		this.questions.next(newQuestions);
		this.isLoading.next(false);
	}

	private init() {
		this.completeOnDestroy(
			combineLatest({
				searchText: this.searchText$,
				contentAreaIDs: this.standardsService.selectedContentAreaIDs$,
				gradeLevels: this.standardsService.selectedGradeLevelIDs$,
				stateStandardIDs: this.standardsService.selectedStandardsIDs$,
			})
		)
			.pipe(
				tap((filters) => {
					this.applyFilters(filters);
				})
			)
			.subscribe();

		this.completeOnDestroy(this.pageRequest$)
			.pipe(
				tap((req) => {
					if (req == null) {
						return;
					}
					if (this.isShowOnlySelected.value) {
						this.processResponse(req, this.applyLocalFilter(req));
						return;
					}
					this.isLoading.next(true);
					this.controller.page(req).subscribe({
						next: (value) => {
							const {value: response} = value;
							this.processResponse(req, response);
						},
						complete: () => {
							this.isLoading.next(false);
						},
					});
				})
			)
			.subscribe();

		this.completeOnDestroy(this.questions)
			.pipe(
				tap((questions) => {
					this.loadedQuestionsCount.next(Object.keys(questions).length);
				})
			)
			.subscribe();

		this.completeOnDestroy(this.isShowOnlySelected)
			.pipe(
				tap((value) => {
					this.localQuestionList = [];
					if (!value) {
						this.resetFilters();
						return;
					}
					this.resetFilters();

					const selectedQuestions = this.selectedQuestions.value;
					const normalizedSelectedQuestions = Object.values(
						selectedQuestions
					).sort(sortQuestionByOrderNum);

					const newQuestions =
						normalizedSelectedQuestions.reduce<QuestionContainer>(
							(prev, question, index) => ({
								...prev,
								[index]: question,
							}),
							{}
						);

					this.localQuestionList = [...Object.values(newQuestions)];

					this.questions.next({...newQuestions});
					this.pageResponse$.next({
						...this.pageResponse$.value,
						total: normalizedSelectedQuestions.length,
					});
				})
			)
			.subscribe();

		this.completeOnDestroy(this.selectedQuestions)
			.pipe(combineLatestWith(this.initialSelection))
			.subscribe(([selectedQuestions, initialSelection]) => {
				const initialSelectionIDs = Object.keys(initialSelection);
				const changedSelectionIDs = Object.keys(selectedQuestions);

				const hasChanges =
					JSON.stringify(changedSelectionIDs) !==
					JSON.stringify(initialSelectionIDs);
				this.hasChanges$.next(hasChanges);
				if (changedSelectionIDs.length !== 0) {
					return;
				}

				this.isShowOnlySelected.next(false);
			});
	}

	private applyFilters(filters: FilterModel) {
		this.pageRequest$.next({
			...this.pageRequest$.value,
			filters,
			skip: 0,
		});
	}
}