import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {ButtonLabel} from '../../index.styled';
import {Button} from '@esgi/ui';
import {Drawer} from '@esgi/main/features/teacher/home';
import {useCallback} from 'react';
import {GridBox} from '@esgi/ui/layout';

export function UnsavedChangesAlert({onClose}: { onClose: VoidFunction }) {

	const forceDrawerClose = Drawer.useForceDrawerClose();

	const alertRef = Alert.useRef();
	const handelClose = Alert.useClose(alertRef, onClose);

	const closeAnyway = useCallback(() => {
		handelClose();
		forceDrawerClose();
	}, [handelClose, forceDrawerClose]);

	return <Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
		<Alert.Header withBacklight onCloseIconClick={handelClose}>
			<Text>Unsaved Changes</Text>
		</Alert.Header>
		<Alert.Body>
			<GridBox>
				<Text size='medium' color='neutral40'>
					You’ve made some changes but didn’t save them. All unsaved changes will be lost.
				</Text>
				<Text size='medium' color='neutral40'>
					Are you sure you want to close the window?
				</Text>
			</GridBox>
		</Alert.Body>
		<Alert.Footer align='center' gapX={3}>
			<Button color='tertiary' onClick={closeAnyway}>
				<ButtonLabel>Close Anyway</ButtonLabel>
			</Button>
			<Button onClick={handelClose} color='secondary'>
				<ButtonLabel>Go Back</ButtonLabel>
			</Button>
		</Alert.Footer>
	</Alert>;
}
