import {useEffect} from 'react';
import {lazyClass} from '@esgi/core/react';
import {ReportType} from '@esgi/core/enums';
import {SubjectTab} from '@esgi/main/libs/store';
import {useUser} from '@esgi/core/authentication';

import {ClassicHierarchyLevel, HierarchySnapshot} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {withClassReportPage} from './wrapper';

const TotalReportModal = lazyClass(() => import('shared/modules/reports/total-report/total-report-modal'));

function buildReportParams(h: HierarchySnapshot) {
	const selected = h.classic.selected;

	switch (selected.level) {
		case ClassicHierarchyLevel.Class:
			return {type: ReportType.Class, id: selected.levelID};
		case ClassicHierarchyLevel.Student:
			if (h.classic.groupID) {
				return {type: ReportType.Group, id: h.classic.groupID};
			} else {
				return {type: ReportType.Class, id: h.classic.classID};
			}
		case ClassicHierarchyLevel.Group:
			return {type: ReportType.Group, id: selected.levelID};
		case ClassicHierarchyLevel.Teacher:
			return {type: ReportType.Teacher, id: selected.levelID};
		case ClassicHierarchyLevel.School:
			return {type: ReportType.School, id: selected.levelID};
		case ClassicHierarchyLevel.District:
			return {type: ReportType.District, id: selected.levelID};
		case ClassicHierarchyLevel.SchoolsGroup:
			return {type: ReportType.SchoolsGroup, id: selected.levelID};
		case ClassicHierarchyLevel.TeachersGroup:
			return {type: ReportType.TeachersGroup, id: selected.levelID};
	}
}


type Props = {
	open: boolean,
	close: VoidFunction,
	hierarchy: HierarchySnapshot,
	subject: SubjectTab
};

function TotalReport(props: Props) {
	const {
		open,
		close,
		hierarchy,
		subject,
	} = props;


	const user = useUser();

	useEffect(() => {
		if(open) {
			TotalReportModal.load().then(trm => {
				const {type, id} = buildReportParams(hierarchy);

				const changesCollector = new ChangesCollector({
					id: subject.id,
					type: subject.type,
				}, hierarchy);

				const ctr = new trm({
					globalSchoolYearId: user?.globalSchoolYearID,
					reportId: id,
					subjectId: subject.id,
					reportType: type,
					subjectType: subject.type,
				}, changesCollector, hierarchy);

				ctr.load();

				ctr.events.onClosed(close);
			});
		}
	}, [open]);
}

export default withClassReportPage(TotalReport);