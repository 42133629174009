import {ThemeColorKeys, getThemeColor} from '@esgi/ui';
import {ComponentProps} from 'react';
import {Bar} from 'recharts';

type Props = Omit<ComponentProps<typeof Bar>, 'dataKey'> & {
	borderTopColor: ThemeColorKeys;
	itemType: 'incorrect' | 'correct';
};

export function BarRect({x, y, width, height = 0, fill, strokeWidth, stroke, borderTopColor, itemType}: Props) {
	return (
		<>
			<rect
				x={x}
				y={y}
				width={width}
				height={height - (itemType === 'incorrect' ? 1 : 0)}
				radius={0}
				fill={fill}
				strokeWidth={strokeWidth}
				stroke={stroke}
			/>

			{height && (
				<rect
					x={x}
					y={y}
					width={width}
					height={1}
					radius={10}
					fill={getThemeColor(borderTopColor)}
					strokeWidth={strokeWidth}
					stroke={getThemeColor(borderTopColor)}
				/>
			)}
		</>
	);
}
