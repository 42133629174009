import React, {useCallback, useEffect, useMemo} from 'react';
import {SubjectBelongsTo, SubjectTabId} from '../../types';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {useAllTabLists, useTabItems} from './hooks';
import {DataSubjectsService} from '../../services/data-subjects-service';
import {SubjectTab} from '@esgi/main/libs/store';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {PanelWithTabs} from './index.styled';
import {useSubjectSectionContext} from 'apps/main/features/untested-students-report/src/kit/subject-section/context/subject-section-context';
import {EmptyContentTemplate} from '@esgi/main/kits/reports';
import {UntestedStudentsReport} from '@esgi/main/features/untested-students-report';

type Props = {
	activeTab: SubjectTabId;
	setActiveTab: (tabId: SubjectTabId) => void;
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: (subjectId: SubjectTab['id'], belogsTo: SubjectBelongsTo) => void;
	setHasSubjects: (value: boolean) => void;
	setSelectedSubject: (value: SubjectTab | null) => void;
};

export function SubjectSectionContent({
	selectedSubjectId,
	setSelectedSubjectId,
	activeTab,
	setActiveTab,
	setHasSubjects,
	setSelectedSubject,
}: Props) {
	const {setLoaded, loaded} = useSubjectSectionContext();

	const dataService = useService(DataSubjectsService);

	const districtSubjects = useBehaviorSubject(dataService.districtSubjects$);
	const schoolSubjects = useBehaviorSubject(dataService.schoolSubjects$);
	const personalSubjects = useBehaviorSubject(dataService.personalSubjects$);

	useStreamEffect(dataService.isLoadedData$, setLoaded);

	const allSubjects = useMemo(
		() => [...districtSubjects, ...schoolSubjects, ...personalSubjects],
		[districtSubjects, schoolSubjects, personalSubjects],
	);

	useEffect(() => {
		const selectedSubject = allSubjects.find(({id}) => id === selectedSubjectId);

		setSelectedSubject(selectedSubject ?? null);
		setHasSubjects(Boolean(allSubjects.length));
	}, [selectedSubjectId, allSubjects]);

	const {allTabLists} = useAllTabLists({
		districtSubjects,
		schoolSubjects,
		personalSubjects,
	});

	const {tabItems} = useTabItems({activeTab});

	const updateSelectedSubjectId = useCallback(
		(subjectId: SubjectTab['id']) => {
			setSelectedSubjectId(subjectId, dataService.getBelongToValue(subjectId));
		},
		[setSelectedSubjectId, dataService],
	);

	const getTabContent = (): React.JSX.Element | null => {
		if (!loaded) {
			return null;
		}

		if (
			(activeTab === SubjectTabId.All &&
				!districtSubjects.length &&
				!schoolSubjects.length &&
				!personalSubjects.length) ||
			(activeTab === SubjectTabId.District && !districtSubjects.length) ||
			(activeTab === SubjectTabId.School && !schoolSubjects.length) ||
			(activeTab === SubjectTabId.Personal && !personalSubjects.length)
		) {
			return (
				<EmptyContentTemplate size='full' templateText='No subjects to display' dataCy='subjects-panel-empty-content' />
			);
		}

		if (activeTab === SubjectTabId.All) {
			return (
				<UntestedStudentsReport.SubjectSection.AllTabContent
					selectedSubjectId={selectedSubjectId}
					setSelectedSubjectId={updateSelectedSubjectId}
					lists={allTabLists}
				/>
			);
		}

		const subjectsByTabId: Record<Exclude<SubjectTabId, SubjectTabId.All>, {subjects: SubjectTab[]}> = {
			[SubjectTabId.District]: {
				subjects: districtSubjects,
			},
			[SubjectTabId.School]: {
				subjects: schoolSubjects,
			},
			[SubjectTabId.Personal]: {
				subjects: personalSubjects,
			},
		};

		return (
			<UntestedStudentsReport.SubjectSection.DefaultModeContent
				selectedSubjectId={selectedSubjectId}
				setSelectedSubjectId={updateSelectedSubjectId}
				subjects={subjectsByTabId[activeTab].subjects}
			/>
		);
	};

	return (
		<PanelWithTabs withTabs={Boolean(tabItems.length)}>
			<FlexBox justify='between' align='center' data-cy='untested-students-reports-subjects-panel'>
				<FlexBox align='center'>
					<Text size='small' color={'neutral56'} data-cy='untested-students-reports-subjects-panel-title'>
						Select a Subject tab
					</Text>
				</FlexBox>
			</FlexBox>
			<UntestedStudentsReport.Tabs<SubjectTabId>
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				tabItems={tabItems}
				dataCy='subjects-tabs'
			/>
			{getTabContent()}
		</PanelWithTabs>
	);
}
