import {getThemeColor} from '@esgi/ui';
import {ComponentProps} from 'react';
import {Bar} from 'recharts';
import {barLineGap} from '../../constants';

type Props = Omit<ComponentProps<typeof Bar>, 'dataKey'> & {
	score?: number;
	maxScore: number;
	detailedView?: boolean;
}

export function BarRect({x, y, width, score, maxScore}: Props) {
	const squareSize = barLineGap + width;
	const scoreDiff = maxScore - score;

	return (
		<>
			{new Array(maxScore).fill('').map((_, idx) => (
				<rect
					x={x}
					y={(score < maxScore ? +y - scoreDiff * squareSize : +y) - width/2 + squareSize * idx}
					width={width}
					height={width}
					rx={1}
					fill={getThemeColor(idx > scoreDiff - 1 ? 'primaryMuted' : 'vivid')}
					key={+x + idx}
				/>
			))}
			<rect
				x={x}
				y={+y + (score < maxScore ? 0 : width * scoreDiff) - width/2}
				width={width}
				height={2}
				fill={getThemeColor('primary48')}
			/>
		</>
	);
}
