import {useMemo, useState} from 'react';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {userStorage} from '@esgi/core/authentication';
import {QuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {
	getItemGradeLevels,
	getItemTextColor,
	getTagVariant,
} from '../../../../../../utils';
import {QuestionItemProps} from '../../../../../../types';
import {Tag} from '../tag';
import {BorderedFlexBox, MetaWrapper, Image, QuestionContent, QuestionMeta, Card, Row, ImageWrapper, StyledCheckbox} from './index.styled';
import {noop} from 'underscore';
import {MultipleLabels} from '../../../../../multiple-labels';

export function QuestionListItem({
																	 question,
																	 virtualKey,
																	 selected,
																	 onCheckedChanged,
																	 onPreviewClick,
																	 selectedStandardType,
																 }: QuestionItemProps) {
	const userContext = userStorage.get();
	const urlBuilder = new QuestionImageUrlBuilder(userContext.awsEnvBucket);

	const [cardEntered, setCardEntered] = useState(false);

	const textColor = getItemTextColor(cardEntered, selected);
	const gradeLevels = getItemGradeLevels(question?.gradeLevelIDs, 'name');

	const handleCheck = (e: React.MouseEvent<unknown>) => {
		if (!question) {
			return;
		}

		onCheckedChanged(question);
		e.stopPropagation();
	};

	const handleMouseLeave = () => {
		setCardEntered(false);
	};

	const handleMouseEnter = () => {
		setCardEntered(true);
	};

	const handlePreviewButtonClick = (e: React.MouseEvent<unknown>) => {
		e.stopPropagation();
		if (!question || !onPreviewClick) {
			return;
		}

		onPreviewClick(question);
	};

	const stateStandards = useMemo(() => {
		const selectedStandardTypeStandards = question?.stateStandards?.filter(({stateID}) => stateID === selectedStandardType);
		if (selectedStandardTypeStandards?.length) {
			return selectedStandardTypeStandards.map(({name}) => name).sort();
		}

		const defaultStandards = question?.stateStandards?.filter(({stateID}) => stateID === 0);
		return defaultStandards?.map(({name}) => name).sort() || [];
	}, [question?.stateStandards, selectedStandardType]);

	return (
		<Card key={virtualKey} selected={selected} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={handleCheck}>
			{question && (
				<StyledCheckbox entered={cardEntered} checked={selected} onCheckedChange={noop} onClick={handleCheck} />
			)}
			<ImageWrapper entered={cardEntered} skeleton={!question} selected={selected} onClick={handlePreviewButtonClick}>
				{question && <Image src={urlBuilder.selfAssessUrl(question.thumbnailUrl, question.id)} />}
			</ImageWrapper>
			<QuestionContent direction='column' justify='center'>
				<BorderedFlexBox skeleton={!question} selected={selected}>
					{question && (
						<Text bold size='large' color={textColor}>
							{question.name}
						</Text>
					)}
				</BorderedFlexBox>
				<QuestionMeta skeleton={!question}>
					{question && (
						<Row>
							<MetaWrapper>
								<Text size='small' color='mediumContrast'>
									Content Area:
								</Text>
								<Tag>{question.contentAreaName}</Tag>
							</MetaWrapper>
							<MetaWrapper>
								<Text size='small' color='mediumContrast'>
									Grade Level:
								</Text>
								<FlexBox>
									{gradeLevels?.map((level, index) => (
										<Tag
											key={index}
											variant={getTagVariant(index, gradeLevels.length)}
										>
											{level.toString()}
										</Tag>
									))}
								</FlexBox>
							</MetaWrapper>
							<MetaWrapper>
								<Text size='small' color='mediumContrast'>
									Standards:
								</Text>
								<MultipleLabels items={stateStandards} />
							</MetaWrapper>
						</Row>
					)}
				</QuestionMeta>
			</QuestionContent>
		</Card>
	);
}