import {RoutesMap} from '@esgi/main/libs/core';

export class TrialTemplate {
	public static Render() {
		return <div className='container'>
			<noscript>
				<img height='1' width='1' src='https://www.facebook.com/tr?id=491141843870117&ev=PageView&noscript=1' />
			</noscript>
			<div className='row'>
				<div className='col-xs-12 col-md-6 col-md-push-6 free-trial-box registration-modal fixed'>
					<div className='inner-modal'>
						<div className='header'>
							<div className='trial-header'>
								<div className='top-row'>
									<div className='col-xs-6'>
                                    <span>Ready to purchase?&nbsp;
	                                    <a href='#' data-bind='click: eventTriggers.purchaseClicked'>
                                            <b>BUY NOW</b>
                                        </a>
                                    </span>
									</div>
									<div className='col-xs-6 text-right'>
                                    <span>Already have an account?&nbsp;
	                                    <a href='/login/'>
                                            <b>LOGIN</b>
                                        </a>
                                    </span>
									</div>
								</div>
								<div className='title-container'>
									<div className='title'>
										<b>Free Trial Sign Up</b>
										<span>No credit card required</span>
									</div>
								</div>
							</div>
						</div>
						<div className='fields'>
							<div className='trial-content'>
								<div data-bind='render:email'/>
								<div data-bind="css: {'show': email.validationResult().isExist}"
								     className='exist-validation-message'>
									An account with this email already exists. Do you want to <a
									href='/login/'>login</a> to your existing account?
								</div>

								<div data-bind='render:username'/>

								<div data-bind='render:password'/>

								<label className='promo-code-label'>Promo Code?&nbsp;&nbsp;
									<a href='#' data-bind='click: eventTriggers.acFlowClicked'>Have an activation
										code?</a>
								</label>
								<div data-bind='render:pc'>
								</div>
								<div className='terms-policy'>
                                    <span>
                                        By creating an account, you are agreeing to our&nbsp;
																			<a href={RoutesMap.esgiAgreement}>
																				Terms of Use
																			</a>
	                                    &nbsp;and&nbsp;
																			<a href={RoutesMap.privacyPolicy}>
																				Privacy Policy
																			</a>.
                                    </span>
								</div>
							</div>
						</div>
						<div className='footer trial-footer'>
							<img className='sign-up-button-clever' alt='Sign Up with Clever'
							     data-bind='click: eventTriggers.signUpWithCleverClicked'
							     src='//s3.amazonaws.com/esgiwebfiles/Images/sign-up-with-clever-blue.png'/>
							<div className='actions'>
								<button className='btn cancel-btn' data-bind='click:eventTriggers.cancel'>CANCEL
								</button>
								<button className='btn next-btn'
								        data-bind='enable: !email.environmentError() && !userCreating(), click:eventTriggers.next'>CREATE
									TRIAL
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className='col-xs-12 col-md-6 col-md-pull-6 free-trial-box'>
					<div className='row'>
						<div className='free-trial-header free-trial-font free-trial-header1-font-size'>Start a Free
							Trial
						</div>
						<div className='free-trial-header free-trial-font free-trial-header2-font-size'>With ESGI
						</div>
					</div>
					<div className='row'>
						<div className='free-trial-subheader free-trial-font'>Progress Monitoring for Success in
							Pre-K-2nd Grade
						</div>
					</div>
					<div className='row'>
						<div className='free-trial-image'>
							<img src='//s3.amazonaws.com/esgiwebfiles/Images/Landing/FreeTrial/teacher-child.png'
							     alt='' width='305px' height='305px'/>
						</div>
					</div>
					<div className='row'>
						<div className='free-trial-dots'>
							<img className='free-trial-dots-image'
							     src='//esgiwebfiles.s3.amazonaws.com/Images/Landing/FreeTrial/dots.png'
							     alt=''/>
						</div>
					</div>
					<div className='free-trial-list free-trial-font'>
						<div>
							<div className='row marklist-item'>
								<img className='free-trial-check'
								     src='//esgiwebfiles.s3.amazonaws.com/Images/Landing/FreeTrial/check.png'/>
								<span><strong>E</strong>ffortlessly track progress of skills across content areas</span>
							</div>
							<div className='row marklist-item'>
								<img className='free-trial-check'
								     src='//esgiwebfiles.s3.amazonaws.com/Images/Landing/FreeTrial/check.png'/>
								<span><strong>S</strong>eamlessly report personalized data to parents</span>
							</div>
							<div className='row marklist-item'>
								<img className='free-trial-check'
								     src='//esgiwebfiles.s3.amazonaws.com/Images/Landing/FreeTrial/check.png'/>
								<span><strong>G</strong>ain access to a free library of customizable assessments</span>
							</div>
							<div className='row marklist-item'>
								<img className='free-trial-check'
								     src='//esgiwebfiles.s3.amazonaws.com/Images/Landing/FreeTrial/check.png'/>
								<span><strong>I</strong>mmediately determine students’ strengths and<br/>weaknesses to group for instruction</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>;
	}
}
