import {styled} from '@esgi/ui/theme';
import {FlexBox, Box, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const LayoutContainer = styled(FlexBox, {
	width: '100%',
	height: '100%',
	overflow: 'hidden',
});

export const EmptyBodyContainer = styled(FlexBox, {
	background: 'linear-gradient(126deg, #F0F0F0 0.11%, #EBEBEB 71.15%)',
	width: '100%',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
});

export const EmptyBodyText = styled(Text, {
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $vivid',
	background: '$background',
	color: '$neutral40',
});

export const LeftSidebar = styled(FlexBox, {
	maxWidth: '272px',
	padding: '24px 12px',
	flexDirection: 'column',
	align: 'start',
	gap: '24px',
	flexShrink: '0',
	backgroundColor: '#ececec',
	height: '95vh',

	'@media (max-width: 1024px)': {
		height: '87vh',
	},
});

export const RightSidebar = styled(FlexBox, {
	flexDirection: 'column',
	align: 'start',
	gap: '20px',
	maxWidth: '400px',
	width: '100%',
	paddingBottom: '20px',
	flexShrink: '0',
	borderLeft: '1px solid $mild',

	'@media (max-width: 1024px)': {
		height: '85vh',
	},
});

export const Content = styled(FlexBox, {
	padding: '16px 20px',
	flexDirection: 'column',
	align: 'start',
	gap: '16px',
	width: '100%',
	borderLeft: '1px solid $mild',
});

export const Page = styled(GridBox, {
	width: '100%',
	background: '$background',
	gridTemplateColumns: '272px 1fr 400px',
	height: '100%',
	overflow: 'hidden',
});

export const Panel = styled(Box, {
	overflow: 'hidden',
	height: '100%',
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',

	'> div': {
		width: '100%',
	},
});

export const Header = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'start',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto 1fr',
	gap: '$4',
	minHeight: '40px',

	'@media (max-width: 1200px)': {
		gridAutoFlow: 'row',
		gridTemplateColumns: 'none',
		gridTemplateRows: 'auto auto',
		gap: '$2',
		minHeight: 'auto',
		justifyContent: 'stretch',
	},
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$neutral92',

	'@media (max-width: 1200px)': {
		display: 'none',
	},
});

export const Wrapper = styled(GridBox, {
	gap: '$5',

	'& > :last-child': {
		marginTop: 'auto',
		paddingBottom: '20px',
	},

	'@media (max-width: 1024px)': {
		height: '65vh',
	},
});
