import {useState} from 'react';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Page} from '../../layout/index.styled';
import {Reports} from '@esgi/main/kits/reports';
import {OldReportLauncherWrapper} from '../old-report-launcher';
import {lazyComponent} from '@esgi/core/react';
import {useUser} from '@esgi/core/authentication';


const StudentDetailLauncher = lazyComponent(() => import('modules/reports/student-detail-report'));

export function OldStudentsDetail() {
	const [singleSelectedState, setSingleSelectedState] = useState<SingleSelectState>();
	const user = useUser();

	return <Page>
		<Reports.StudentLevel.SingleStudentPanel withoutAllClass onChange={(value) => {
			setSingleSelectedState(value);
		}}/>
		<OldReportLauncherWrapper singleState={singleSelectedState}>
			{(version, isOpen, close, hierarchySnapshot, subject, changesCollector) => {
				if (isOpen) {
					return <StudentDetailLauncher changesCollector={changesCollector}
					                              hierarchy={{
													  classic: hierarchySnapshot.classic as any,
													  snapshot: hierarchySnapshot,
					                              } as any}
					                              onClose={close}
					                              options={{
						                              classId: hierarchySnapshot.classic.classID,
						                              groupId: hierarchySnapshot.classic.groupID,
						                              studentId: hierarchySnapshot.classic.studentID,
						                              globalSchoolYearId: user?.globalSchoolYearID,
						                              subjectId: subject.id,
						                              subjectType: subject.type,
						                              userId: user?.userID,
					                              }}/>;
				}
			}}
		</OldReportLauncherWrapper>
	</Page>;
}