import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Skeletonable} from '../../../../types';
import {SelectItemProps as SelectItemRadixProps} from '@radix-ui/react-select';
import {styled} from '@esgi/ui/theme';
import * as SelectRadix from '@radix-ui/react-select';
import {CSS} from '@stitches/react';
import {Text} from '@esgi/ui/typography';

type SelectItemProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
} & Skeletonable &
	SelectItemRadixProps;

export const SelectOption = forwardRef<HTMLDivElement, SelectItemProps>(
	({dataCy, children, skeleton, onClick, ...props}, forwaredRef) => (
		<Option data-cy={dataCy ?? 'ui-kit-select-option'} ref={forwaredRef} {...props}>
			<SelectRadix.ItemText onClick={onClick}>{children}</SelectRadix.ItemText>
		</Option>
	),
);

const Option = styled(SelectRadix.Item, {
	padding: '8px 12px',
	borderBottomStyle: 'solid',
	borderBottomWidth: 1,
	borderBottomColor: '$border',
	backgroundColor: '$vivid',
	userSelect: 'none',
	outline: 'none',
	fontWeight: 600,
	fontSize: 13,
	lineHeight: '16px',
	color: '$base',

	'&[data-disabled]': {
		cursor: 'default',
		color: '$lowContrast',
	},

	'&[data-highlighted]:not([data-disabled]), &[data-state=checked]': {
		outline: 'none',
		borderBottomColor: '$primary72',
		backgroundColor: '$primary92',
		color: '$primary',

		// <SelectRadix.ItemText /> not be styled, render span
		'& span': {
			[`& > ${Text}`]: {
				color: 'currentColor',
			},
		},
	},
});
