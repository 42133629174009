import {GridBox} from '@esgi/ui/layout';
import {ComponentProps, forwardRef} from 'react';
import {Container} from './index.styled';

type Props = ComponentProps<typeof GridBox>;

export const ChartsBox = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'test-dashboard-statistics-charts-box', css = {}, display = 'inlineGrid', flow = 'column', ...props},
		forwardedRef,
	) => <Container dataCy={dataCy} css={css} display={display} flow={flow} ref={forwardedRef} {...props} />,
);
