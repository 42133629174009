import {StyledButton, SubjectNameWrapper, ContentWrapper, TaskIconStyled, TextStyled} from './index.styled';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectName} from '@esgi/main/kits/common';
import {Tooltip} from '@esgi/ui';

type Props = {
	subjects: SubjectTab[]
}

export function SubjectsCounter({subjects}: Props) {
	if (!subjects.length) {
		return;
	}

	return subjects.length === 1 ? (
		<SubjectNameWrapper subjectLevel={subjects[0].level}>
			<SubjectName subjectLevel={subjects[0].level} noWrap size='medium' bold>
				{subjects[0].name}
			</SubjectName>
		</SubjectNameWrapper>
	) : (
		<Tooltip>
			<Tooltip.Trigger>
				<StyledButton>
					<TextStyled size='medium' bold color='secondary'>
						<TaskIconStyled />
						{subjects.length}...
					</TextStyled>
				</StyledButton>
			</Tooltip.Trigger>
			<Tooltip.Content variant='default'>
				<ContentWrapper>
					{subjects.map((subject) => (
						<SubjectNameWrapper key={subject.id} subjectLevel={subjects[0].level}>
							<SubjectName subjectLevel={subject.level} noWrap size='medium' bold>
								{subject.name}
							</SubjectName>
						</SubjectNameWrapper>
					))}
				</ContentWrapper>
			</Tooltip.Content>
		</Tooltip>
	);
}