import {MoreButton, StyledText} from './index.styled';
import {useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';

type Props = { description: string }

export function ReadMoreButton({description}: Props) {
	const textRef = useRef<HTMLElement>(null);
	const [showFullDescription, setShowFullDescription] = useState(false);
	const [isTextTruncated, setIsTextTruncated] = useState(false);

	useResizeObserver(textRef, ({target: {scrollHeight, clientHeight}}) => {
		setIsTextTruncated((scrollHeight - 1) > clientHeight);
	});

	if (description.trim() === '') {
		return null;
	}

	return (
		<>
			<StyledText size='small' ref={textRef} showFullDescription={showFullDescription}>{description}</StyledText>
			{(isTextTruncated || showFullDescription) &&
				<MoreButton
					onClick={(e) => {
						e.stopPropagation();
						setShowFullDescription(prev => !prev);
					}}
					size='xSmall'
					font='mono'
				>
					{showFullDescription ? 'Hide' : 'Read more...'}
				</MoreButton>
			}
		</>
	);
}
