import {assignmentRoutes} from './assignment-routes';
import {reportRoutes} from './report-routes';
import {activitiesRoutes} from './activities-routes';
import {testDashboarRoutes} from './test-dashboard-routes';

export const teacherRoutes = {
	home: {
		root: '/home',
		...testDashboarRoutes,
	},
	testExplorer: '/test-explorer',
	assignments: {
		root: '/assignments',
		...assignmentRoutes,
	},
	studentManager: '/student-manager',
	parentConferencer: '/parent-conferencer',
	reports: {
		root: '/reports',
		...reportRoutes,
	},
	activities: {
		root: '/activities',
		...activitiesRoutes,
	},
} as const;
