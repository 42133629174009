import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {useCallback} from 'react';
import {GridBox} from '@esgi/ui/layout';

interface Props {
	onClose: VoidFunction,
	onCloseAnyway: () => void
}

export function UnsavedChangesAlert({onClose, onCloseAnyway}: Props) {

	const alertRef = Alert.useRef();
	const handelClose = Alert.useClose(alertRef, onClose);

	const closeAnyway = useCallback(() => {
		handelClose();
		onCloseAnyway();
	}, [handelClose, onCloseAnyway]);

	return <Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
		<Alert.Header withBacklight onCloseIconClick={handelClose}>
			<Text size='medium' font='mono' bold>Unsaved Changes</Text>
		</Alert.Header>
		<Alert.Body>
			<GridBox>
				<Text size='medium' color='neutral40'>
					You’ve made some changes but didn’t save them. All unsaved changes will be lost.
				</Text>
				<Text size='medium' color='neutral40'>
					Are you sure you want to close the window?
				</Text>
			</GridBox>
		</Alert.Body>
		<Alert.Footer align='center' gapX={3}>
			<Button color='tertiary' onClick={closeAnyway}>
				<Text size='medium' color='neutral40' bold>Close Anyway</Text>
			</Button>
			<Button onClick={handelClose} color='secondary'>
				<Text size='medium' color='neutral40' bold>Go Back</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}
