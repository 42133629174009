import {useCallback, useEffect, useState} from 'react';
import {TestSession} from '../../../../../../../../types';

export function useSectionData({testSessions}: {testSessions: TestSession[]}) {
	const [isDetailedViewChecked, setIsDetailedViewChecked] = useState(false);
	const [isAllSessionsChecked, setIsAllSessionsChecked] = useState(false);

	const [selectedSessionIds, setSelectedSessionIds] = useState<TestSession['id'][]>(
		testSessions[0] ? [testSessions[0].id] : [],
	);

	const isAllSessionsSelected = selectedSessionIds.length === testSessions.length;

	useEffect(() => {
		if (isAllSessionsSelected && !isAllSessionsChecked) {
			toggleAllSessionsCheckbox();
		}
	}, [isAllSessionsSelected]);

	const toggleDetailedViewCheckbox = useCallback(() => {
		setIsDetailedViewChecked((value) => !value);
	}, []);

	const toggleAllSessionItems = useCallback((isAllItemsSelected: boolean) => {
		if (isAllItemsSelected) {
			setSelectedSessionIds(testSessions.map(({id}) => id));
			return;
		}

		const firstTestSession = testSessions?.[0];
		if (!firstTestSession) {
			setSelectedSessionIds([]);
			return;
		}

		setSelectedSessionIds([firstTestSession.id]);
	}, [testSessions]);

	const toggleAllSessionsCheckbox = useCallback(() => {
		setIsAllSessionsChecked((value) => {
			const newCheckboxValue = !value;
			toggleAllSessionItems(newCheckboxValue);

			return newCheckboxValue;
		});
	}, [toggleAllSessionItems]);

	return {
		isAllSessionsSelected,
		selectedSessionIds,
		isDetailedViewChecked,
		toggleDetailedViewCheckbox,
		isAllSessionsChecked,
		toggleAllSessionsCheckbox,
		setSelectedSessionIds,
	};
}
