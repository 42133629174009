import {FormField, Validators} from '@esgi/deprecated/knockout';
import {AsyncFieldContainer} from '../../../kit/component';
import {CheckACResult} from '../../types';
import './ac-input.less';
import {resolvedPromise} from '@esgi/deprecated/utils';

export class ACInputComponent extends AsyncFieldContainer<string> {
	public iconClass = ko.computed(() => {
		if (this.field.showSuccessValidation()) {
			return {'fa-check': true};
		}
		if (this.field.showErrorValidation()) {
			return {'fa-close': true};
		}
		return false;
	});
	activationCodeInfo: KnockoutObservable<CheckACResult> = ko.observable<CheckACResult>({} as CheckACResult);
	private prevValue: string;

	constructor(props) {
		super(props.activationCode);
		this.activationCodeInfo(props?.activationCodeInfo);
	}

	public buildField(initValue?): FormField<string> {
		const CustomValidator = Validators.Custom((field) => {
			if (this.prevValue && field.value() !== this.prevValue) {
				this.prevValue = field.value();
				this.activationCodeInfo(null);
			}

			if (!this.activationCodeInfo()?.valid) {
				this.prevValue = field.value();
				return resolvedPromise(false);
			}

			return resolvedPromise(true);
		}, () => {
			const result = this.activationCodeInfo();
			let message = '';

			if (result && !result?.valid) {
				if (result.bruteForceThrow) {
					message = result.bruteForceMessage;
				}
				if (result.isExpired) {
					message = 'Activation code has already expired';
				}
				if (result.notFound) {
					message = 'Activation code not found';
				}
				if (result.isDeleted) {
					message = 'Activation code is no longer available';
				}
				if (result.allotmentNotAvailable) {
					message = `The maximum of allowed registrations (${result.allotment}) for this code has been reached`;
				}
			}

			return message;
		});

		const f = this.createField<string>(
			initValue || '',
			Validators.Required('Please enter your activation code.'),
			CustomValidator,
		);

		if (initValue) {
			f.disabled(true);
		}

		f.validation.validationMessageTitleUseHtml = true;
		f.validation.errorValidation(true);
		f.validation.successValidation(true);
		f.validation.showValidation(true);
		return f;
	}

	public afterRender(rootElement: JQuery): JQueryPromise<any> {
		return super.afterRender(rootElement);
	}

	template = () =>
		<div data-bind='var : {root: $data}, afterRender: true'>
      <ko data-bind='with: field'>
        <div className='form-group'
             data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
          <label className='control-label'>Activation Code</label>
          <input type='text' className='form-control input-md ac-input'
                 data-bind='value: value, disable: disabled' autoFocus={true}/>
          <div className='error-message visible-xs visible-sm hidden-md'>
            <span data-bind='text:validation.validationResults'/>
          </div>
          <ko data-bind='if: !root.inProcess()'>
            <i data-bind="css: {'fa-check': showSuccessValidation, 'fa-close': showErrorValidation, 'fa form-control-feedback': root.iconClass()}"/>
          </ko>
          <ko data-bind='if: root.inProcess()'>
            <i className='fa fa-spinner fa-spin form-control-feedback'/>
          </ko>
        </div>
      </ko>
    </div>;
}
