import {GridBox} from '@esgi/ui/layout';
import {SetupIcon} from '../setup-icon';
import {Reset} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Container, ResetFiltersButton} from './index.styled';

interface Props {
	isFiltersButtonDisabled: boolean;
	onFiltersReset: () => void;
}

export const FiltersPanelHeader = ({
	isFiltersButtonDisabled,
	onFiltersReset,
}: Props) => {
	return (
		<Container>
			<GridBox gap={1} align='center' flow='column'>
				<SetupIcon />
				<Text size='small' color='mediumContrast'>
					Filter
				</Text>
			</GridBox>
			<ResetFiltersButton
				disabled={isFiltersButtonDisabled}
				color='tertiary'
				onClick={onFiltersReset}
			>
				<Reset width={24} height={24} />
				<Text size='medium'>Reset Filters</Text>
			</ResetFiltersButton>
		</Container>
	);
};
