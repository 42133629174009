import {Criteria, Level} from '../models';
import {CriteriaInfo, DescriptionsInfo, LevelsInfo} from './common';
import {createCriteria, createLevel, createZeroLevel} from './creating';
import {sortLevels} from './sorting';

export function initializeCriteria(levels: Level[]): CriteriaInfo & DescriptionsInfo {
	let criteria = [];
	let safeDescriptions = [];

	const addCriteria = () => {
		const createCriteriaResult = createCriteria(criteria, levels, safeDescriptions);

		createCriteriaResult.entity.metaInfo = {defaultField: true};

		criteria = createCriteriaResult.criteria;
		safeDescriptions = createCriteriaResult.descriptions;
	};

	/*There are 2 criteria by default*/
	addCriteria();
	addCriteria();

	return {
		criteria: criteria,
		descriptions: safeDescriptions,
	};
}

export function initializeLevels(criteria: Criteria[], isZeroLevel: boolean = false): LevelsInfo & DescriptionsInfo {
	let levels = [];
	let safeDescriptions = [];

	const addLevel = () => {
		const createCriteriaResult = createLevel(criteria, levels, safeDescriptions);

		createCriteriaResult.entity.metaInfo = {defaultField: true};

		levels = createCriteriaResult.levels;
		safeDescriptions = createCriteriaResult.descriptions;
	};

	/*There are 4 levels by default*/
	addLevel();
	addLevel();
	addLevel();
	addLevel();
	
	if(isZeroLevel){
		const result = createZeroLevel(criteria, levels, safeDescriptions);
		return {
			levels: result.levels,
			descriptions: result.descriptions,
		}
	}

	return {
		levels: sortLevels(levels),
		descriptions: safeDescriptions,
	};
}
