import {useCallback} from 'react';
import {SelectableList, SelectableListItem} from './index.styled';
import {SelectableLisItemCheckbox} from '../checkbox';
import {SelectableLisItemContent} from '../list-item';
import {ExpandablePanel} from '../../../../../expandable-panel';
import {Test} from '../../../../types';
import {isNull} from 'underscore';

const getListValue = (value: string | number | null) => isNull(value)
	? ''
	: String(value);

interface Props {
	items: Test[];
	selected: Test['id'][] | null;
	setSelected: (itemId: Test['id']) => void;
}

export function SelectableListMultiple({items, selected, setSelected}: Props) {
	const value = (selected || []).map(getListValue);
	const checked = useCallback((id: string) => value.includes(id), [value]);
	const disabled = ({correct, incorrect, untested}: Omit<Test, 'id'>) =>
		correct + incorrect + untested === 0;

	return (
		<ExpandablePanel style={{height: 'calc(100% - 60px)'}}>
			<SelectableList>
				<SelectableList.GroupRoot
					type='multiple'
					value={value}
				>
					<SelectableList.Group>
						{items.map((item) => {
							const isChecked = checked(String(item.id));
							const isDisabled = disabled(item);

							return (
								<SelectableListItem
									data-cy='test-item-root'
									key={item.id}
									value={String(item.id)}
									checked={isChecked}
									disabled={isDisabled}
									setTextCurrentColor={false}
									onClick={() => setSelected(item.id)}
								>
									<SelectableLisItemCheckbox
										data-cy='test-item-checkbox'
										checked={isChecked}
										disabled={isDisabled}
									/>
									<SelectableLisItemContent data-cy='test-item-content' item={item}/>
								</SelectableListItem>
							);
						})}
					</SelectableList.Group>
				</SelectableList.GroupRoot>
			</SelectableList>
		</ExpandablePanel>
	);
}
