import {ServerClient} from 'pages/landing/kit/server-client';
import {UserType} from '@esgi/core/authentication';
import {BaseResponse} from '@esgi/api';


export class Dictionaries {
	private static readonly controllerName = 'Registration/Dictionaries';
	static loadTimeZones = (): JQueryPromise<TimeZonesResponse> => {
		return ServerClient.SSOAPI.Get<TimeZonesResponse>(Dictionaries.controllerName, 'TimeZones', {
			cache: true,
		});
	};
}

export class ValidationServer {
	private static readonly controllerName = 'Registration/Validations';
	static checkEmailExistence = (email: string) => {
		return ServerClient.SSOAPI.Get<CheckEmailResponse>(ValidationServer.controllerName, 'Email', {data: {email: email}});
	};
	static checkUserName = (username: string) => {
		return ServerClient.SSOAPI.Get<CheckUsernameResponse>(ValidationServer.controllerName, 'UserName', {
			data: {
				userName: username,
				cache: true,
			},
		});
	};
}

export class InvitationServer {
	private static readonly controllerName = 'Registration/Invitation';
	static validateCode = (code: string) => {
		return ServerClient.SSOAPI.Get<ValidateCodeResponse>(InvitationServer.controllerName, 'ValidateCode', {data: {code: code}});
	};
	static register = (model: UserInfoModel) => {
		return ServerClient.SSOAPI.Post<BaseResponse<CreateUserResponse>>(InvitationServer.controllerName, 'Register', {
			data: model,
		});
	};
}

export interface FlowResultModel<T> {
	model: T,
	captcha: string,
}

export interface UserInfoModel {
	code: string;
	userName: string;
	email: string;
	password: string;
	title: string;
	firstName: string;
	lastName: string;
	timeZone: string;
	captchaToken: string;
	action: string;
}

export interface CreateUserResponse {
	token: string;
	paymentFail: boolean;
}

export interface ValidateCodeResponse {
	userType: keyof typeof UserType;
	allotment?: number;
	isValid: boolean;
	schoolName: string;
	isActive: boolean;
	outOfUsages: boolean;
	validExpirationDate: boolean;
}

export interface CheckUsernameResponse {
	exist: boolean;
}

export interface CheckEmailResponse {
	exist: boolean;
	enviromentError: boolean;
}

export interface InfoModel {
	schoolID?: number,
	federalSchoolID?: number,
	name: string
}

export interface TimeZoneModel {
	id: string,
	displayName: string
}

export interface TimeZonesResponse {
	timeZones: Array<TimeZoneModel>
}
