import {BehaviorSubject, Subscription, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2PagesAssignmentsController, V2TeachersPagesAssignmentsController} from '@esgi/contracts/esgi';
import {AssignmentState, SortDirection} from '@esgi/main/kits/assignments';
import {storage} from '@esgi/main/libs/store';
import {AssignmentInfo, StudentModel} from '../types';
import {isNull} from 'underscore';
import {getUser} from '@esgi/core/authentication';
import moment from 'moment';

export class AssignmentService extends BaseService {
	public assignment$ = new BehaviorSubject<AssignmentInfo | null>(null);
	public studentsInAssignment$ = new BehaviorSubject<StudentModel[]>([]);

	private classes = storage.classes();
	private groups = storage.groups();
	private students = storage.students();

	private controller = new V2TeachersPagesAssignmentsController();
	private controllerAssignmentPage = new V2PagesAssignmentsController();

	private testsReorderRequestSubscription: Subscription | null = null;

	public init({assignmentId}: {assignmentId: AssignmentInfo['id']}) {
		return this.controller.info({assignmentID: assignmentId}).pipe(
			tap(({assignment}) => {
				this.assignment$.next(assignment);

				this.students.get().subscribe((students) => {
					const studentsInAssignment: StudentModel[] = [];

					students.forEach(({id: studentID, firstName, lastName, hasCredentials}) => {
						const studentInAssignment = assignment.students.find(({id}) => id === studentID);

						if (studentInAssignment) {
							let studentClasses: string[] = [];
							let studentGroups: string[] = [];

							this.classes.get().subscribe((classes) => {
								studentClasses = classes.filter(({studentIDs}) => studentIDs.includes(studentID)).map(({name}) => name);
							});

							this.groups.get().subscribe((groups) => {
								studentGroups = groups.filter(({studentIDs}) => studentIDs.includes(studentID)).map(({name}) => name);
							});

							const student: StudentModel = {
								studentID,
								hasCredentials,
								firstName,
								lastName,
								joinedClassName: studentClasses.join(', '),
								joinedGroupName: studentGroups.join(', '),
								progress: studentInAssignment.progress,
							};

							studentsInAssignment.push(student);
						}
					});

					this.studentsInAssignment$.next(studentsInAssignment);
				});
			}),
		);
	}

	public completePublishedAssignment({completedDate}: {completedDate: string}) {
		this.setAssignmentState({
			state: AssignmentState.Completed,
			assignmentData: {completed: completedDate},
		});
	}

	public postNotStartedAssignment() {
		this.setAssignmentState({state: AssignmentState.Published});
	}

	public swapTests(index1: number, index2: number) {
		const currentAssignment = this.assignment$.value;

		if (currentAssignment) {
			const assignmentTests = currentAssignment.tests;

			if (assignmentTests[index1] && assignmentTests[index2]) {
				[assignmentTests[index1], assignmentTests[index2]] = [assignmentTests[index2]!, assignmentTests[index1]!];
			}

			this.assignment$.next({
				...currentAssignment,
				tests: [...assignmentTests],
			});

			if (!isNull(this.testsReorderRequestSubscription)) {
				this.testsReorderRequestSubscription.unsubscribe();
			}

			this.testsReorderRequestSubscription = this.controllerAssignmentPage
				.testsReorder({
					assignmentID: currentAssignment.id,
					testIDs: assignmentTests.map(({id}) => id),
				})
				.subscribe();
		}
	}

	public deleteAssignmentRequest() {
		const currentAssignment = this.assignment$.value;

		if (currentAssignment) {
			this.controllerAssignmentPage.makeDelete({assignmentID: currentAssignment.id}).subscribe();
		}
	}

	public sortStudentsByKey({key, sortDirection}: {key: keyof StudentModel; sortDirection: SortDirection}) {
		const students = [...this.studentsInAssignment$.value];

		const stringKeys: (keyof StudentModel)[] = ['firstName', 'lastName', 'joinedClassName', 'joinedGroupName'];
		const numberedKeys: (keyof StudentModel)[] = ['progress'];

		if (stringKeys.includes(key)) {
			students.sort((a, b) => {
				const aValue = a[key];
				const bValue = b[key];

				if (typeof aValue === 'string' && typeof bValue === 'string') {
					return sortDirection === SortDirection.Asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
				}

				return 0;
			});
		}

		if (numberedKeys.includes(key)) {
			students.sort((a, b) => {
				const aValue = a[key];
				const bValue = b[key];

				if (typeof aValue === 'number' && typeof bValue === 'number') {
					return sortDirection === SortDirection.Asc ? aValue - bValue : bValue - aValue;
				}

				return 0;
			});
		}

		this.studentsInAssignment$.next(students);
	}

	public downloadStudentsCards(studentIDs: StudentModel['studentID'][]) {
		const currentUser = getUser();

		const formattedDate = moment().format('YYYY_MM_DD');

		const userName = [currentUser?.firstName.trim(), currentUser?.lastName.trim()].filter(Boolean).join('-');

		const fileName = `${userName}_student-credentials_${formattedDate}.pdf`;

		this.httpClient.ESGIApi.file('/v2/pages/assignments/student-credentials/export', '', fileName, {
			StudentIDs: studentIDs,
		}).subscribe();
	}

	public override dispose() {
		this.controller.dispose();
		this.classes.dispose();
		this.groups.dispose();
		this.students.dispose();
	}

	private setAssignmentState({
		state,
		assignmentData = {},
	}: {
		state: AssignmentState;
		assignmentData?: Omit<Partial<AssignmentInfo>, 'id' | 'state'>;
	}) {
		const currentAssignment = this.assignment$.value;

		if (currentAssignment) {
			this.assignment$.next({
				...currentAssignment,
				state,
				...assignmentData,
			});

			this.controllerAssignmentPage.setState({assignmentID: currentAssignment.id, state}).subscribe();
		}
	}
}
