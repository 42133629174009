import {Text} from '@esgi/ui/typography';
import {AnswersInfo} from '../../answers-info';
import {ContentWrapper, ProgressBarInfo, ProgressBarTranscriptWrapper} from './progress-bars.styled';
import {ProgressDashed} from './progress-dashed';

type Props = {
	value: number | null;
	maxValue: number;
	result: number | null;
};

export function StudentsTested({value, maxValue, result}: Props) {
	return (
		<ContentWrapper>
			<ProgressBarInfo>
				<ProgressDashed value={value} maxValue={maxValue} result={result} />
				<ProgressBarTranscriptWrapper>
					<AnswersInfo value={value ?? 0} maxValue={maxValue} caption='Students Tested' />
				</ProgressBarTranscriptWrapper>
			</ProgressBarInfo>
			<Text size='small' font='mono' color='neutral56'>
				Students Tested
			</Text>
		</ContentWrapper>
	);
}
