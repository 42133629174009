import {HttpEventBusManager} from '@esgi/api';
import {AxiosResponse} from 'axios';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {Countdown} from '@esgi/deprecated/utils';
import {MoveUserStatus} from '@esgi/core/enums';
import {userStorage} from '@esgi/core/authentication';
import {forceUserLogout} from '../utils';
import {useEffect, useMemo, useRef} from 'react';

export function useMoveTeacherHandler() {
	const forceLogoutWindowShown = useRef(false);

	const eventBus = useMemo(() => new HttpEventBusManager(), []);

	useEffect(() => {
		const handleRequest = (response: AxiosResponse): void => {
			const status = MoveUserStatus[response.headers['esgi-move-user-status'] as string];
			if (status === MoveUserStatus.UserWarned) {
				const remainingSecondsHeader = response.headers['esgi-move-user-remaining-seconds'];
				if (remainingSecondsHeader) {
					const remainingSeconds = parseInt(remainingSecondsHeader);
					Countdown.Start(remainingSeconds, () => {
						forceUserLogout();
					});
					if (!forceLogoutWindowShown.current) {
						forceLogoutWindowShown.current = true;
						OldAlerts.bsalert({
							message: 'Your account is being moved, which requires a logout. Please finish your task(s) now. You will be automatically logged out in 30 seconds.',
							modal:
								{
									allowClose: true,
									buttons: [
										{
											align: 'right',
											title: 'Logout now',
											className: 'btn btn-link',
											closeModal: true,
											onClick: () => {
												Countdown.Clear();
												forceUserLogout();
											},
										},
										{
											align: 'right',
											title: 'Finish work',
											className: 'btn btn-link',
											closeModal: true,
										},
									],
								},
						});
					}
				}
			} else {
				const user = userStorage.get();
				if (user && user.userID) {
					const userIDString = user.userID.toString();
					const userNotifiedOnLoginFlag = 'MoveTeacher_UserNotifiedOnLogin';
					if (status === MoveUserStatus.UserNotifiedOnLogin && localStorage.getItem(userNotifiedOnLoginFlag) !== userIDString) {
						localStorage.setItem(userNotifiedOnLoginFlag, userIDString);
						OldAlerts.bsalert({
							message: 'This account has been transferred. Updates will be made to the district, school, and/or student information. Please login again.',
							modal:
								{
									allowClose: true,
									buttons: [
										{
											align: 'right',
											title: 'OK',
											className: 'btn btn-link',
											closeModal: true,
											onClick: () => {
												Countdown.Clear();
												forceUserLogout();
											},
										},
									],
								},
						});
					}
				}
			}
		};
		eventBus.subscribe((r) => handleRequest(r));
		return () => eventBus.destroy();
	}, [eventBus]);
}