import {GridBox} from '@esgi/ui/layout';
import {MainText, TranscriptionText} from './index.styled';
import {useMemo} from 'react';
import moment from 'moment';
import {isNull} from 'underscore';

export type TextWithTranscriptProps = {
	mainText: string | Date | number | null;
	mainTextAsTimeInMs?: boolean;
	transcription: string;
	dataCy?: string;
};

export function TextWithTranscript({
	mainText,
	transcription,
	mainTextAsTimeInMs,
	dataCy = 'text-with-transcript',
}: TextWithTranscriptProps) {
	const mainTextValue = useMemo(() => {
		if (mainText instanceof Date) {
			return moment(mainText).format('MM/DD/YYYY');
		}

		if (isNull(mainText)) {
			return '-';
		}

		if (mainTextAsTimeInMs) {
			const mainTextAsMSeconds = typeof mainText === 'string' ? parseInt(mainText) : mainText;
			const seconds = Math.round((mainTextAsMSeconds / 1000) % 60);
			const minutes = Math.floor(seconds / 60);

			const secondsString = String(seconds).padStart(2, '0');

			if (!mainTextAsMSeconds) {
				return `${minutes}:${secondsString}`;
			}

			return `${String(minutes).padStart(2, '0')}:${secondsString}`;
		}

		return mainText.toString();
	}, [mainText, mainTextAsTimeInMs]);

	return (
		<GridBox gap='1' dataCy={dataCy}>
			<MainText size='medium' font='mono' bold color='base' data-cy={`${dataCy}-main-text-value`}>
				{mainTextValue}
			</MainText>
			<TranscriptionText size='small' font='mono' color='neutral56' data-cy={`${dataCy}-transcription`}>
				{transcription}
			</TranscriptionText>
		</GridBox>
	);
}
