import {join} from '@esgillc/ui-kit/utils';
import {Checkbox} from '@esgillc/ui-kit/control';
import styles from './styles.module.less';

interface Props {
	isChecked: boolean;
	label: string;
	onClick: () => void;
}

export function RemoveFromGroupsCheckbox({isChecked, label, onClick}: Props) {

	return <div data-cy='remove-from-groups' className={styles.checkboxContainer}>
		<div className={join(isChecked ? styles.checkbox : styles.hide)}>
			<label data-cy='remove-from-groups-checkbox' className={styles.labelContainer}>
				<Checkbox checked={!isChecked} onClick={onClick}/>
				<span data-cy='checkbox-label' className={styles.checkboxLabel}> {label} </span>
			</label>
		</div>
	</div>;
}
