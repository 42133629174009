import * as React from 'react';
import type {SVGProps} from 'react';

export function DragDropMode(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M13.8 16.8a.8.8 0 1 1 0-1.6h4.4a.8.8 0 0 1 0 1.6h-4.4ZM15.45 8.229a.773.773 0 0 1 1.099 0l2.423 2.438c.304.305.304.8 0 1.104a.773.773 0 0 1-1.098 0L16 9.886l-1.875 1.885a.773.773 0 0 1-1.098 0 .784.784 0 0 1 0-1.104l2.424-2.438ZM16.55 23.77a.773.773 0 0 1-1.098 0l-2.424-2.438a.784.784 0 0 1 0-1.104.773.773 0 0 1 1.098 0L16 22.114l1.874-1.885a.773.773 0 0 1 1.098 0c.304.305.304.8 0 1.104l-2.423 2.438Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
