import {FlexBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';
import {ReactNode, useCallback, useEffect, useMemo, useState, MouseEvent} from 'react';
import {MultiSelectState, SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {useUser} from '@esgi/core/authentication';
import {Group, storage, SubjectLevel, SubjectTab, SubjectType, Class} from '@esgi/main/libs/store';
import {first, take} from 'rxjs';

import {ClassicHierarchyLevel, HierarchyMode, HierarchySnapshot} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';

enum Version {
	Old = 'old',
	New = 'new',
}

type Props = {
	singleState?: SingleSelectState,
	multipleState?: MultiSelectState,
	onClick?: (close: VoidFunction, hierarchySnapshot: HierarchySnapshot, subject: SubjectTab, event: MouseEvent<HTMLButtonElement>) => void,
	children?: (version: Version, isOpen: boolean, close: VoidFunction, hierarchySnapshot: HierarchySnapshot, subject: SubjectTab, changesCollector: ChangesCollector) => ReactNode;
};

export function OldReportLauncherWrapper(props: Props) {
	const user = useUser();

	const [groups, setGroups] = useState<Group[]>();
	const [classes, setClasses] = useState<Class[]>();

	useEffect(() => {
		const groups = storage.groups();
		const classes = storage.classes();
		const groupsSub = groups.get().pipe(first()).subscribe((groups) => setGroups(groups));
		const classesSub = classes.get().pipe(first()).subscribe((classes) => setClasses(classes));

		return () => {
			classesSub.unsubscribe();
			groupsSub.unsubscribe();
			classes.dispose();
			groups.dispose();
		};
	}, []);

	const {isEntitySelected, hierarchy} = useMemo(() => {
		let studentID, groupID, classID, levelID: ClassicHierarchyLevel = ClassicHierarchyLevel.None;

		if (props.singleState) {
			studentID = props.singleState.studentId || 0;
			groupID = (props.singleState.groupId || props.singleState.studentFromGroupId) || 0;
			classID = (props.singleState.classId || props.singleState.studentFromClassId) || 0;
			if(groupID) {
				const group = groups.find(g => g.id === groupID);
				if(group) {
					classID = group.classID;
				}
			}
		}

		if (props.multipleState) {
			studentID = props.multipleState.studentIDs[0] || 0;
			groupID = props.multipleState.groupIDs[0] || 0;
			classID = props.multipleState.classIDs[0] || 0;
		}

		if (studentID) {
			levelID = ClassicHierarchyLevel.Student;
		}

		if (classID) {
			levelID = ClassicHierarchyLevel.Class;
		}

		if (groupID) {
			levelID = ClassicHierarchyLevel.Group;
		}

		if(studentID && !classID) {
			classID = classes.find(c => c.studentIDs.includes(studentID))?.id;
		}

		return {
			hierarchy: {
				districtID: user?.districtID,
				studentSort: user?.studentSort,
				mode: HierarchyMode.Classic,
				classic: {
					studentID,
					groupID,
					classID,
					teacherID: user?.userID,
					schoolID: user?.schoolID,
					districtID: user?.districtID,
					selected: {
						level: levelID,
						levelID: (studentID || groupID || classID) || 0,
					},
				},
				specialist: {
					studentID: 0,
					filter: {
						schoolID: 0,
						teacherID: 0,
					},
					type: 0,
					groupID: 0,
					userID: 0,
					districtID: 0,
					groupOfSpecialistsID: 0,
					selected: {
						level: 0,
						levelID: 0,
					},
				},
				preAssess: {
					studentID: 0,
					groupID: 0,
					userID: 0,
					districtID: 0,
					selected: {
						level: 0,
						levelID: 0,
					},
				},
			} as HierarchySnapshot,
			isEntitySelected: Boolean(studentID || groupID || classID),
		};
	}, [props.singleState, props.multipleState, user, groups, classes]);

	const [subject, setSubject] = useState<SubjectTab>();

	useEffect(() => {
		const store = storage.subjects();
		const sub = store.get()
			.pipe(take(1))
			.subscribe((subjects) => {
				const subject = subjects.find(subject => !subject.hidden);
				setSubject(subject);
			});
		return () => {
			store.dispose();
			sub.unsubscribe();
		};
	}, []);

	const [isOpen, setIsOpen] = useState(false);
	const close = useCallback(() => setIsOpen(false), []);

	const clickHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
		setIsOpen(true);
		props.onClick?.(close, hierarchy, subject, e);
	}, [props.onClick, isOpen, close, hierarchy, subject]);

	const changesCollector = useFakeChangesCollector(hierarchy);

	return <FlexBox css={{width: '100%', height: '100%'}} align='center' justify='center'>
		<Button disabled={isOpen || !isEntitySelected} onClick={clickHandler}>Run Report</Button>
		{props.children?.(Version.Old, isOpen, close, hierarchy, subject, changesCollector)}
	</FlexBox>;
}

export function useFakeChangesCollector(hierarchy: HierarchySnapshot) {
	return useMemo(() => new ChangesCollector({id: 0, type: 0}, hierarchy), []);
}