import React, {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {TestColorIndicatorLine, TestColorIndicatorWrapper} from './index.styled';
import {TestContentArea} from '../types';
import {BaseComponentProps, isUndefined} from '@esgi/ui';
import {useStore, storage} from '@esgi/main/libs/store';
import {Test} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/storage/subjects/test';
import {isNull} from 'underscore';
import {indicatorWrapperColor, legacyIndicatorColors, indicatorLineColor} from './constants';
import {TestColor} from './types';

type TestColorIndicatorProps = Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'color'> &
	BaseComponentProps & {
		testID: Test['id'];

		/**
		 * If test isn't be found in store by passed testID, a color indicator will be rendered by passed the "contentArea" property.
		 */
		contentArea?: TestContentArea;
	};

export const TestColorIndicator = forwardRef<HTMLDivElement, TestColorIndicatorProps>(
	(
		{dataCy = 'features-test-color-indicator', css = {}, testID, contentArea: additionalContentArea, ...props},
		forwardedRef,
	) => {
		const [subjectsList] = useStore(storage.subjects);

		const testColor = useMemo<TestColor | null>(() => {
			const test = subjectsList.flatMap(({tests}) => tests).find(({id}) => id === testID);

			if (!isUndefined(test)) {
				const {contentArea, color: currentTestColor} = test;

				const testContentArea = contentArea as TestContentArea;
				const legacyColor = legacyIndicatorColors[testContentArea];

				if (currentTestColor.toLocaleLowerCase() === legacyColor.toLocaleLowerCase()) {
					return {
						defaultColors: {
							indicatorWrapperColor: `$${indicatorWrapperColor[testContentArea]}`,
							indicatorLineColor: indicatorLineColor[testContentArea],
						},
						customColor: null,
					};
				}

				return {
					defaultColors: null,
					customColor: `#${currentTestColor}`,
				};
			}

			if (!isUndefined(additionalContentArea)) {
				return {
					defaultColors: {
						indicatorWrapperColor: `$${indicatorWrapperColor[additionalContentArea]}`,
						indicatorLineColor: indicatorLineColor[additionalContentArea],
					},
					customColor: null,
				};
			}

			return null;
		}, [additionalContentArea, subjectsList, testID]);

		if (isNull(testColor)) {
			return null;
		}

		return (
			<TestColorIndicatorWrapper
				css={{
					...css,
					backgroundColor: testColor.defaultColors?.indicatorWrapperColor ?? testColor.customColor ?? 'unset',
				}}
				ref={forwardedRef}
				{...props}
			>
				{!isNull(testColor.defaultColors) && (
					<TestColorIndicatorLine color={testColor.defaultColors.indicatorLineColor} dataCy={`${dataCy}-line`} />
				)}
			</TestColorIndicatorWrapper>
		);
	},
);
