import React, {ReactNode} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {SuggestedGradeLevel} from 'api/entities/suggested-grade-level';
import styles from '../styles.module.less';
import {Item} from '../models';

interface Props {
	readonly: boolean;
	control: FormControl<number[]>,
	stateStandard: FormControl<Item>;
	onStandardAffectingChange?: () => void;
	verificationModalWasShown?: boolean;
}

export class GradeLevelField extends React.PureComponent<Props> {
	onFocus() {
		if(!this.props.verificationModalWasShown && this.props.stateStandard.value.id) {
			this.props.onStandardAffectingChange();
		}
	}
	
	public render() {
		return <div className={styles.field}>
			<div className={join(styles.fieldTitle, styles.hideTablet)}>
				Add/Edit Grade:
			</div>
			<div className={join(styles.fieldTitle, styles.dTablet)}>
				Grade:
			</div>
			<div className={styles.fieldContent} onFocus={() => this.onFocus()}>
				<FormElement control={this.props.control}>
					{this.props.readonly ? this.renderText() : this.renderDropdown()}
				</FormElement>
			</div>
		</div>;
	}

	private renderText(): ReactNode {
		return <span>{this.mapToText(this.props.control.value)}</span>;
	}

	private renderDropdown(): ReactNode {
		return <Dropdown multiselect displayLabel={(v) => this.mapToText(v)} placeholder='Grade Level'>
			{SuggestedGradeLevel.list.map(g => <Option key={g.id} value={g.id}>{g.name}</Option>)}
		</Dropdown>;
	}

	private mapToText(gradeLevelIDs: number[]) {
		return gradeLevelIDs
			.sort((a, b) => a - b)
			.map(val => SuggestedGradeLevel.list.find(g => g.id === val).shortName)
			.join(', ');
	}
}
