import {EventBusDispatcher} from '@esgillc/events';
import React from 'react';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, IClassBox, IClassModel} from '../../core/api';
import {HierarchyEvents} from '../../core/events';
import {BoxType} from '../../core/models';

export class State {
}

export class Props {
	boxes: IBoxInfo[];
	classes: IClassBox;
	teacherID: number;
	selectedID: number;

	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
	onSelected: (id: number, callback: () => void) => void;
}

export class Class extends React.Component<Props, State> {
	constructor(props, context) {
		super(props, context);
	}

	get items(): ItemProps[] {
		const classes = Class.Filter(this.props.classes.items, this.props.teacherID);
		classes.sort(Class.sort);

		return classes.map(r => {
			return {id: r.classID, title: r.name};
		});
	}

	static sort(a: IClassModel, b: IClassModel) {
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1;
		}

		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1;
		}

		return 0;
	}

	static Filter(classes: IClassModel[], userID: number) {
		return classes.filter(s => s.userID === userID);
	}

	render() {
		if (!this.props.teacherID) {
			return null;
		}

		const options: BoxOptions = {
			canDrag: false,
			boxType: BoxType.ClassicClass,
			title: 'All Classes',
			canCreateTooltip: null,
			canAdd: this.props.classes.canCreate,
			canEdit: this.props.classes.canEdit,
			tooltipPostfix: 'class',
		};


		return <Box
			options={options}
			selectedID={this.props.selectedID}
			items={this.items}
			onDragEnd={null}
			onDragStart={null}
			empty={{message: 'You have 0 classes'}}
			scheduledIDs={null}
			open={this.props.boxes.filter(t => t.boxType === BoxType.ClassicClass && t.open).length > 0}
			onAddClicked={this.publish.add}
			onEditClicked={this.publish.edit}
			itemSelected={this.props.onSelected}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
		/>;
	}

	publish = {
		add: () => {
			const args: HierarchyEvents.Class.AddArgs = {
				countClasses: this.items.length,
				teacherID: this.props.teacherID,
			};
			EventBusDispatcher.dispatch(HierarchyEvents.Class.Add, args);
		},
		edit: (id: number) => {
			const item = this.props.classes.items.find(t => t.classID === id);
			const args: HierarchyEvents.Class.EditArgs = {
				countClasses: this.items.length,
				teacherID: item.userID,
				id: item.classID,
				name: item.name,
			};
			EventBusDispatcher.dispatch(HierarchyEvents.Class.Edit, args);
		},
	};
}
