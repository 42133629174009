import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {Form} from '@esgillc/ui-kit/form';
import {Loader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {TeacherService} from './service';
import {LocationFormProps} from '../../types';
import {StudentProfileMode, StudentProfileTab} from 'modules/forms/students-form/types';
import {TabFooter} from 'modules/forms/students-form/components/profile-modal/components/tab-footer';
import {ManagedCheckboxesList} from '../../components/managed-checkboxes-list';
import {RemoveFromClassesAlert} from '../../components/remove-from-classes-alert';
import {RemoveFromGroupsCheckbox} from '../../components/remove-from-groups-checkbox';
import styles from '../../styles.module.less';

export function TeacherLocation({
	                                studentID,
	                                mode,
	                                initData,
	                                tabsApi,
	                                preSelected,
	                                onClose,
}: LocationFormProps) {
	const service = useService(TeacherService);

	const classes = useBehaviorSubject(service.classes);
	const groups = useBehaviorSubject(service.groups);
	const serviceLoading = useBehaviorSubject(service.serviceLoading);
	const tabs = useBehaviorSubject(service.tabsStatus);
	const selectedClasses = useBehaviorSubject(service.selectedClasses);

	const [isShowCheckbox, setShowCheckbox] = useState(!!selectedClasses.length);
	const [isShowRemoveFromClassesAlert, setShowRemoveFromClassesAlert] = useState(false);
	const [isInitialized, setInitialized] = useState(false);

	useEffect(() => {
		service.init({initData, mode, studentID, preSelected, tabsApi}).subscribe({
			complete: () => {
				setInitialized(true);
			},
		});
	}, []);

	useEffect(() => {
		setShowCheckbox(Boolean(selectedClasses.length));
	}, [selectedClasses.length]);

	const onSave = (isSaveAndAdd?: boolean) => {
		if (tabs[StudentProfileTab.Location]?.isTouched || selectedClasses.length) {
			service.save(isSaveAndAdd).subscribe(() => {
				if (!isSaveAndAdd) {
					onClose();
				}
			});
		} else {
			if (!isSaveAndAdd) {
				onClose();
			}
		}
	};

	const handelCheckboxClicked = () => {
		service.form.controls.classIDs.value = [];
		service.form.controls.groupIDs.value = [];
		setShowCheckbox(false);
	};

	return <div data-cy='teacher-location-form'>
		<Loader show={serviceLoading} fullscreen/>
		{isInitialized && <><Form controller={service.form}>
			{mode !== StudentProfileMode.view && <RemoveFromGroupsCheckbox
				label='Remove student from all Classes / Groups'
				isChecked={isShowCheckbox}
				onClick={() => setShowRemoveFromClassesAlert(true)}
			/>}

			<div className={styles.row}>
				<ManagedCheckboxesList
					data-cy='classes-checkbox-list'
					listName='Classes'
					list={classes}
					control={service.form.controls.classIDs}
				/>
				<ManagedCheckboxesList
					data-cy='groups-checkbox-list'
					listName='Groups'
					list={groups}
					control={service.form.controls.groupIDs}
				/>
			</div>
		</Form>

			{isShowRemoveFromClassesAlert && <RemoveFromClassesAlert
				studentFullName={initData.general.firstName + ' ' + initData.general.lastName}
				selectedClasses={selectedClasses}
				onRemove={handelCheckboxClicked}
				onCancel={() => setShowRemoveFromClassesAlert(false)}
			/>}

			<TabFooter
				tabsApi={tabs}
				canSaveAndAdd={initData.isOpenedWithAdd}
				currentTab={StudentProfileTab.Location}
				mode={mode}
				onSave={() => onSave(false)}
				onSaveAndAdd={() => onSave(true)}
			/> </>}
	</div>;
}

