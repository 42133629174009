import {FlexBox, GridBox} from '@esgi/ui/layout';
import {SectionModeButton} from './components/section-mode-button';
import {AddSectionMode} from './components/add-section-mode';
import {AddModeMenuListItem, SectionModeButtonItem} from './types';
import {ActionButtonsDivider} from './components/action-buttons-divider.styled';
import {IconComponent} from '@esgi/ui';
import {ExpandablePanelIcon} from '../expandable-panel';
import {Text} from '@esgi/ui/typography';

export {type AddModeMenuListItem, type SectionModeButtonItem} from './types';

type Props = {
	addSectionModeItems: AddModeMenuListItem[];
	isAddSectionModeDisabled: boolean;
	SectionIcon: IconComponent;
	isDisplaySectionIcon: boolean;
	sectionTitle: string;
	sectionModeButtons: SectionModeButtonItem[];
	isAddSectionModeActive: boolean;
	onAddSectionModeOpenChanged: (open: boolean) => void;
	isViewMode: boolean;
	dataCy?: string;
	addActionTooltipText?: string;
};

export function TitleWithActionButtons({
	addSectionModeItems,
	isAddSectionModeDisabled,
	SectionIcon,
	isDisplaySectionIcon,
	sectionTitle,
	sectionModeButtons,
	isAddSectionModeActive,
	onAddSectionModeOpenChanged,
	isViewMode,
	addActionTooltipText,
	dataCy = 'title-with-action-buttons',
}: Props) {
	return (
		<>
			<FlexBox align='center'>
				{isDisplaySectionIcon && (
					<ExpandablePanelIcon fillColor={isViewMode ? 'neutral56' : 'primary'} Icon={SectionIcon} />
				)}
				<Text size='small' color={isViewMode ? 'neutral56' : 'primary'} data-cy={`${dataCy}-title`}>
					{sectionTitle}
				</Text>
			</FlexBox>

			<GridBox gap='1' flow='column'>
				{sectionModeButtons.map((buttonProps, index) => {
					return <SectionModeButton key={index} {...buttonProps} dataCy={buttonProps.dataCy} />;
				})}

				<ActionButtonsDivider />

				<AddSectionMode
					addSectionModeItems={addSectionModeItems}
					isActive={isAddSectionModeActive}
					tooltipText={addActionTooltipText}
					onOpenChange={onAddSectionModeOpenChanged}
					isAddSectionModeDisabled={isAddSectionModeDisabled}
					dataCy={`${dataCy}-action-menu`}
				/>
			</GridBox>
		</>
	);
}
