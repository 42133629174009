import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Checkbox} from '@esgi/ui/controls';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const Card = styled(FlexBox, {
	position: 'relative',
	background: '$surface',
	borderRadius: 12,
	padding: 12,
	border: 'solid 1px $surface',
	cursor: 'pointer',
	variants: {
		selected: {
			true: {
				background: '$primaryBackground',
				border: 'solid 1px $primaryHighlight',
			},
		},
	},
	'&:hover': {
		background: '$secondaryBackground',
		border: 'solid 1px $secondaryBackground',
	},
});

export const StyledCheckbox = styled(Checkbox, {
	position: 'absolute',
	top: 16,
	left: 16,
	zIndex: 2,
	background: 'none',
	'& > div': {
		background: '$background',
	},
	'&:hover': {
		background: 'none',
	},
	variants: {
		entered: {
			true: {
				'& > div': {
					background: '$background !important',
					'& svg path': {
						fill: '$secondary !important',
					},
				},
			},
		},
	},
});

export const ImageWrapper = styled(FlexBox, {
	marginRight: 16,
	position: 'relative',
	background: 'black',
	cursor: 'zoom-in',
	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: 286,
				minWidth: 286,
				height: 162,
				background: '$background',
			},
		},
		selected: {
			true: {
				'&:after': {
					content: '',
					left: 0,
					position: 'absolute',
					top: 0,
					zIndex: 1,
					width: '100%',
					height: '100%',
					opacity: 0.2,
					background: '$primaryBackground',
				},
			},
		},
		entered: {
			true: {
				'&:after': {
					content: '',
					left: 0,
					position: 'absolute',
					top: 0,
					zIndex: 1,
					width: '100%',
					height: '100%',
					opacity: 0.2,
					background: 'black',
				},
			},
		},
	},

});

export const Image = styled('img', {
	minWidth: 286,
	width: 286,
	height: 162,
	borderRadius: 6.5,
	objectFit: 'contain',
	background: '$neutral',
	cursor: 'zoom-in',
});

export const BorderedFlexBox = styled(FlexBox, {
	borderBottom: 'solid 1px $border',
	height: 80,
	justifyContent: 'space-between',
	variants: {
		selected: {
			true: {
				borderBottom: 'solid 1px $primaryHighlight',
			},
		},
		skeleton: {
			true: {
				background: '$background',
				width: 200,
				height: 20,
				...SkeletonStyles,
			},
		},
	},
});

export const QuestionContent = styled(FlexBox, {
	width: '100%',
	height: '100%',
});

export const QuestionMeta = styled(FlexBox, {
	height: 80,
	flexDirection: 'column',
	variants: {
		skeleton: {
			true: {
				background: '$background',
				width: 200,
				height: 20,
				marginTop: 20,
				...SkeletonStyles,
			},
		},
	},
});

export const MetaWrapper = styled(FlexBox, {
	justifyContent: 'start',
	'& > div': {
		marginRight: 6,
	},
	'& > span': {
		marginRight: 6,
	},
	alignItems: 'center',
	'& > span:last-child': {
		marginRight: 0,
		fontSize: '11px',
	},
	'& > span:first-child': {
		fontWeight: 500,
	},
	marginRight: 16,
});

export const Row = styled(FlexBox, {
	width: '100%',
	padding: '6px 0',
	alignItems: 'center',
	'&:first-child': {
		paddingTop: 12,
	},
});