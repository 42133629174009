import {DataModel} from 'pages/student-manager/models/models';
import React from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {SchoolYearsService} from '../services/school-years-service';
import {SchoolYearSelector} from './school-year-selector/school-year-selector';

type Props = {
	data: DataModel;
	schoolYearsService: SchoolYearsService;
	download: () => any;
}

export function Header(props: Props): JSX.Element {
	if (!props.data) {
		return null;
	}

	let schoolName = '';
	const user = userStorage.get();
	if (props.data && ![UserType.D, UserType.ISD].includes(user.userType)) {
		schoolName = props.data.schools.find(x => x.schoolID === user.schoolID)?.name;
	}

	return <>
		<div className='header'>
			<div className='export_container nonvisible'>
				<i className='fa fa-download'/>
				Download
			</div>
			<h1>Student Manager</h1>
			<div className='export_container' onClick={props.download}>
				<i className='fa fa-download'/>
				Download
			</div>
		</div>
		<SchoolYearSelector schoolYearsService={props.schoolYearsService}/>
		<div className='user-info user-data-container'>
			<div className='title'>Name:</div>
			<div className='name'>{props.data.userName}</div>
		</div>
		<div className='user-info district-data-container'>
			<div className='title'>District:</div>
			<div className='name'>{props.data.districtName}</div>
		</div>
		{schoolName &&
			<div className='user-info school-data-container'>
				<div className='title'>School:</div>
				<div className='name'>{schoolName}</div>
			</div>
		}
	</>;
}
