import {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {StudentCredentialsStateModel, StudentWithInitialCredentials} from '../types';
import {Student, storage, useStore} from '@esgi/main/libs/store';
import {StudentCredentialsService} from '../student-credentials-service';
import {useService} from '@esgi/core/service';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {isNull} from 'underscore';
import {getFullName} from '@esgi/main/kits/assignments';

export function useComponentState({
	studentId,
	snackbarRef,
	closeStudentCredentialsAlert,
}: {
	studentId: Student['id'];
	snackbarRef: RefObject<SnackbarManager>;
	closeStudentCredentialsAlert: VoidFunction;
}) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isCredentialsSaving, setIsCredentialsSaving] = useState(false);

	const [student, setStudent] = useState<StudentCredentialsStateModel>({
		firstName: '',
		lastName: '',
		photoUrl: '',
		isCredentialsValid: true,
		isCredentialsTouched: false,
		isPasswordHidden: true,
		initialUserName: '',
		userName: '',
		password: '',
		initialPassword: '',
	});

	const studentInitCredentials = useRef<StudentWithInitialCredentials | null>(null);

	const [studentList, updateStudentInStore, isStudentListLoaded] = useStore(storage.students);

	const studentCredentialsService = useService(StudentCredentialsService);

	useEffect(() => {
		if (isStudentListLoaded) {
			setIsLoaded(false);

			const student = studentList.find(({id}) => id === studentId);

			if (!student) {
				throw new Error('Student is not found in storage');
			}

			studentCredentialsService.init({studentIDs: [student.id]}).subscribe({
				next: ({studentCredentials}) => {
					const {firstName, lastName, photoUrl} = student;
					const studentWithCredentials = studentCredentials.find(({id}) => id === student.id);

					const userName = studentWithCredentials?.userName ?? '';
					const password = studentWithCredentials?.password ?? '';

					studentInitCredentials.current = {
						...student,
						initialUserName: userName,
						initialPassword: password,
					};

					setStudent({
						firstName,
						lastName,
						photoUrl,
						isCredentialsValid: true,
						isCredentialsTouched: false,
						isPasswordHidden: true,
						initialUserName: userName,
						userName,
						initialPassword: password,
						password,
					});
				},
				complete: () => {
					setIsLoaded(true);
				},
			});
		}
	}, [studentId, isStudentListLoaded, studentList]);

	const updateStudentValue = useCallback((newValue: Partial<StudentCredentialsStateModel>) => {
		setStudent((currentState) => {
			return {
				...currentState,
				...newValue,
			};
		});
	}, []);

	const setIsCredentialsTouched = useCallback(
		(value: boolean) => {
			updateStudentValue({isCredentialsTouched: value});
		},
		[updateStudentValue],
	);

	const onUserNameChanged = useCallback(
		(value: string) => {
			updateStudentValue({
				userName: value,
			});

			setIsCredentialsTouched(student.initialUserName !== value || student.initialPassword !== student.password);
		},
		[setIsCredentialsTouched, student.initialPassword, student.initialUserName, student.password, updateStudentValue],
	);

	const onPasswordChanged = useCallback(
		(value: string) => {
			updateStudentValue({
				password: value,
			});

			setIsCredentialsTouched(student.initialUserName !== student.userName || student.initialPassword !== value);
		},
		[setIsCredentialsTouched, student.initialPassword, student.initialUserName, student.userName, updateStudentValue],
	);

	const resetStudentCredentials = useCallback(() => {
		updateStudentValue({
			userName: studentInitCredentials.current?.initialUserName ?? '',
			password: studentInitCredentials.current?.initialPassword ?? '',
			isCredentialsTouched: false,
		});
	}, [updateStudentValue]);

	const togglePasswordHidden = useCallback(() => {
		updateStudentValue({
			isPasswordHidden: !student.isPasswordHidden,
		});
	}, [student.isPasswordHidden, updateStudentValue]);

	const onCredentialsValidValueChanged = useCallback(
		(value: boolean) => {
			updateStudentValue({
				isCredentialsValid: value,
			});
		},
		[updateStudentValue],
	);

	const handleSaveUserCredentials = useCallback(() => {
		if (isNull(student)) {
			throw new Error('student is null');
		}

		setIsCredentialsSaving(true);

		const {userName, password} = student;

		studentCredentialsService
			.saveStudentCredentials([
				{
					id: studentId,
					name: userName,
					password,
				},
			])
			.subscribe({
				next: () => {
					const student = studentList.find(({id}) => id === studentId);

					if (student) {
						updateStudentInStore(student, {
							...student,
							hasCredentials: Boolean(userName && password),
						}).subscribe();
					}
				},
				complete: () => {
					const studentFullName = getFullName({
						firstName: student.firstName,
						lastName: student.lastName,
					});

					closeStudentCredentialsAlert();

					setIsCredentialsSaving(false);

					if (!student.initialUserName && !student.initialPassword && userName && password) {
						snackbarRef.current?.showSnackbar(`Credentials for ${studentFullName} have been added successfully`);

						return;
					}

					if (student.initialUserName && student.initialPassword && !userName && !password) {
						snackbarRef.current?.showSnackbar(`Credentials for ${studentFullName} have been removed`);

						return;
					}

					snackbarRef.current?.showSnackbar(`Credentials for ${studentFullName} have been updated successfully`);
				},
			});
	}, [
		student,
		studentCredentialsService,
		studentId,
		studentList,
		updateStudentInStore,
		closeStudentCredentialsAlert,
		snackbarRef,
	]);

	const skeleton = !isLoaded || !isStudentListLoaded;

	return {
		student,
		studentCredentialsService,
		skeleton,
		handleSaveUserCredentials,
		isCredentialsSaving,
		onUserNameChanged,
		onPasswordChanged,
		resetStudentCredentials,
		togglePasswordHidden,
		onCredentialsValidValueChanged,
	};
}
