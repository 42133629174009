import {IFormControlValidatorResult, TextInput} from '@esgi/deprecated/elements';
import {Requests} from 'shared/modules/test-details/api/models';
import {Api} from 'shared/modules/test-details/api/api';
import {Modal, ModalBody, ModalFooter, ModalHeader, SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import React from 'react';
import {Question} from 'shared/modules/test-details/models';
import {TestCopied} from 'shared/modules/test-details/events';
import './component.less';
import {OldAlerts} from '@esgi/deprecated/knockout';

import styles from './styles.module.less';
import {BaseRadio} from '@esgi/deprecated/ui-kit/radio';
import {Box} from '@esgi/ui/layout';

const questionBankFeatureOn = true;

export class State {
	name: string;
	touched: boolean;
	valid: boolean;
	dirty: boolean;
	validator: IFormControlValidatorResult = {valid: true, message: ''};
	saving: boolean = false;
	copySelfAssessVersion: boolean;
}

export class Props extends SharedProps<State> {
	testId: number;
	name: string;
	close: () => void;
	stateStandard: string;
	owner: string;
	color: string;
	contentArea: string;
	gradeLevels: number[];
	testType: string;
	description: string;
	questions: Question[];
	createdDate: string;
	isWhiteBackground: boolean;
	hasSelfAssess: boolean;
	onSave?: VoidFunction;
}

const selfAssessWarning =
<>
	<span>Please enter a name for your copied test. Both versions will be copied.</span><br />
	<span>Note: the self-assess questions are <i>not</i> editable. Always check the self-assess version before making changes to a 1-1 question.</span>
</>;
const oneToOneWarning =
<>
	<span>Please enter a name for your copied test.</span><br />
	<span>Note: only the teacher-assessed version will be copied.</span>
</>;
export class CopyTest extends SharedComponent<State, Props> {
	private readonly maxLength = 65;

	componentDidMount() {
		this.setState({
			name: this.props.name,
			dirty: !!this.props.name,
			touched: true,
			valid: (!!this.props.name && this.props.name.length <= this.maxLength),
			copySelfAssessVersion: false,
		});
	}

	get isSaving(): boolean {
		return this.state.saving;
	}

	private nameValidator(): IFormControlValidatorResult {
		let text = '';

		if (!this.state.name) {
			text += 'Enter test name.';
		} else if (this.state.name.length > this.maxLength) {
			text += `Test name length cannot be greater than ${this.maxLength.toString()} symbols.`;
		}

		const valid = (!!this.state.name && this.state.name.length <= this.maxLength);

		return {
			valid: valid,
			message: (valid) ? '' : text,
		};
	}

	private copyModeChangedHandler = (value: boolean) => {
		return ()=> {
			this.setState({copySelfAssessVersion: value});
		};
	};

	private nameChanged(newName: string, validator: IFormControlValidatorResult) {
		this.setState({
			name: newName,
			dirty: newName !== this.state.name,
			valid: (!!newName && newName.length <= this.maxLength),
			touched: !(!!newName && newName.length <= this.maxLength),
			validator: validator,
		});
	}

	private save() {
		this.setState({
			saving: true,
		}, () => {
			const request: Requests.ICopyTest = {
				testID: this.props.testId,
				testName: this.state.name,
				copySelfassessVersion: this.state.copySelfAssessVersion,
			};
			Api.copyTest(request)
				.withCustomErrorHandler((response, errorHandlingStrategy) => {
					this.props.close();
					OldAlerts.bsalert('Error occurred. Please contact administrator.');
				})
				.subscribe((resp) => {
					this.props?.onSave();
					this.dispatch(TestCopied, TestCopied(
						this.state.name,
						resp.testID,
						this.props.owner,
						this.props.createdDate,
						this.props.questions.length,
						this.props.color,
						this.props.description,
						this.props.contentArea,
						this.props.gradeLevels,
						this.props.testType,
						this.props.questions,
						this.props.stateStandard,
						this.props.isWhiteBackground,
						this.state.copySelfAssessVersion,
					));
				});
		});
	}

	render() {
		return <Modal className='white-header copy-test' onCatchError={() => this.props.close()}>
			<ModalHeader title='Copy Test'/>
			<ModalBody>
				<div className={styles.body}>
					{this.renderSelfAssessWarn()}
					{this.props.hasSelfAssess && questionBankFeatureOn && <div className={styles.copyModeSelection}>
					<BaseRadio
						value={+(this.state.copySelfAssessVersion)}
						checked={!this.state.copySelfAssessVersion}
						onClick={this.copyModeChangedHandler(false)}
					>
						Teacher One-on-One
					</BaseRadio>

					<BaseRadio
						value={+(this.state.copySelfAssessVersion)}
						checked={this.state.copySelfAssessVersion}
						onClick={this.copyModeChangedHandler(true)}
					>
						Teacher One-on-One and Student Self-Assess
					</BaseRadio>
					</div>
					}
					<div className='copy-test-name'>
						<span className={styles.fieldTitle}>Test Name</span>
						<TextInput
							className='test-name'
							value={this.state.name}
							onEdit={(value, validation) => this.nameChanged(value, validation)}
							validator={() => this.nameValidator()}
							touched={this.state.touched}
							validationPlacement='right'
							placeholder='Enter Test Name*'
							maxLength={this.maxLength}
							autocomplete={false}
							initialFocus={true}
						/>
						<div
							className='limit-counter'>{this.maxLength - (this.state.name && this.state.name.length || 0)} characters
							left ({this.maxLength} max)
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => this.props.close()}
					title='Cancel'
					disabled={this.isSaving}
				/>
				<Primary
					disabled={this.isSaving || !(this.state.name && this.state.valid && this.state.dirty)}
					onClick={() => this.save()}
				>
					{this.isSaving && <i className='fa fa-circle-o-notch fa-spin'/>}Save
				</Primary>
			</ModalFooter>
		</Modal>;
	}

	private renderSelfAssessWarn() {
		if(this.props.hasSelfAssess) {
			return <Box className={styles.selfAssessWarn}>
				{this.state.copySelfAssessVersion && questionBankFeatureOn ? selfAssessWarning : oneToOneWarning}
			</Box>;
		}
	}
}
