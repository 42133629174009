import React, {useMemo, useState} from 'react';
import {TestType} from '@esgi/main/libs/core';
import {CheckboxGroup} from '@esgi/ui/controls';
import {ClassDiagrams} from './components/class-diagrams';
import {specialProgramsData} from './constants';
import {calculateClassAverageTestInfo, calculateFilteredStudentsCount, sortDates} from './utils';
import {CheckboxGroupRoot, ProgramsFilters, ProgramsFiltersContainer, StatisticsRoot} from './index.styled';
import {DateFormatText, ProgressWithPointer, Statistics, TimeText} from '../../../kit';
import {Text} from '@esgi/ui/typography';
import {StudentInfo, StudentSpecialProgram} from '../../types';

interface Props {
	testType: TestType;
	questionsCount: number;
	totalPossible: number;
	students: StudentInfo[]
	criteriaCount: number;
}

export function BodyStats({
	testType,
	totalPossible,
	questionsCount,
	students,
	criteriaCount,
}: Props) {

	const [filters, setFilters] = useState<StudentSpecialProgram[]>([]);

	const [averageSession, testedStudentsInfo, testDates] = useMemo(() => {
		return [
			calculateClassAverageTestInfo(students, totalPossible, filters, testType),
			calculateFilteredStudentsCount(students, students.length, filters),
			sortDates(students),
		];
	}, [students, totalPossible, filters, testType]);

	const onUpdateFilters = (value: (string | number)[]) => {
		const filters = value.map(filter => StudentSpecialProgram[filter]);
		setFilters(filters);
	};

	return <>
		<ProgramsFiltersContainer>
			<ProgramsFilters>
				<CheckboxGroupRoot checked={filters} onChange={onUpdateFilters}>
					{specialProgramsData.map(item => <CheckboxGroup.Checkbox label={item.label} value={item.id} key={item.id}/>)}
				</CheckboxGroupRoot>
			</ProgramsFilters>
		</ProgramsFiltersContainer>

		<StatisticsRoot>
			<Statistics.ChartsBox>
				{(testType === TestType.YN || testType === TestType.Score) && <ProgressWithPointer
					percentageType
					value={averageSession.progress}
					progressBarTitle='Class Average'/>
				}

				{testType === TestType.Rubric &&
					<ProgressWithPointer
						percentageType={false}
						maxValue={totalPossible}
						value={averageSession.progress}
						progressBarTitle='Class Average'/>
				}
				<Statistics.ChartsDivider/>

				<ClassDiagrams
					studentsTestedCount={testedStudentsInfo.testedStudentsCount}
					studentsCount={students.length}
					isProgressSuccess={testedStudentsInfo.isProgressSuccess}
				/>

			</Statistics.ChartsBox>
			<Statistics.InfoBox>
				<Statistics.InfoWithTranscript transcript=''/>
				<Statistics.InfoWithTranscript transcript='Sessions'>
					<Text size='medium' font='mono' bold color='base'>
						{averageSession.sessionsCount}
					</Text>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript='First Test Date'>
					<DateFormatText date={testDates.first} dateFormat='MM/DD/YYYY' size='medium' font='mono' bold color='base'/>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript={testType === TestType.Rubric ? 'Criteria' : 'Questions'}>
					<Text size='medium' font='mono' bold color='base'>
						{testType === TestType.Rubric ? criteriaCount :questionsCount}
					</Text>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript='Avg. Session Time'>
					<TimeText time={averageSession.duration} timeFormat='mm:ss' size='medium' font='mono' bold
							  color='base'/>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript='Last Test Date'>
					<DateFormatText date={testDates.last} dateFormat='MM/DD/YYYY' size='medium' font='mono' bold color='base'/>
				</Statistics.InfoWithTranscript>
			</Statistics.InfoBox>
		</StatisticsRoot>
	</>;
}
