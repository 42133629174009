import React from 'react';
import {Group, Players, Student} from 'shared/modules/bingo/types/models';
import {Checkbox, Radio} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';
import {BingoService} from 'shared/modules/bingo/service/service';

import styles from './players.module.less';

interface PlayerProps {
	service: BingoService;
	players: Players
}

export default function Players ({service, players: {classes, groups, specialistGroups, students, loaded}}: PlayerProps) {
	const renderGroupsInColumn = (groups: Group[]) => {
		return <div>
			{groups.map((x, key) => {
				return <Radio
					key={key}
					title={x.name}
					value={x.name}
					name='bingo-groups'
					id={x.id + '_' + x.name}
					checked={x.selected}
					onChange={() => service.updateGroups(x.id)}
				>
					<div className={join(
						styles.lbl,
						x.selected && styles.selected,
					)}>
						{x.name}
					</div>
				</Radio>;
			})}
		</div>;
	};

	const renderStudentsInColumn = (students: Student[]) => {
		return (
			<div>
				{students.map((x, index) => {
					return (
						<div className={styles.contentRow}>
							<Checkbox
								key={index}
								checked={x.selected}
								id={x.id + '_' + x.name}
								onClick={() => service.updateStudents(x.id)}
							>
								<div className={join(
									styles.lbl,
									x.selected && styles.selected,
								)}>
									{x.name}
								</div>
							</Checkbox>
						</div>
					);
				})}
			</div>
		);
	};
	
	const renderGroups = () => {
		const filteredGroups = [...groups.filter(x => x.classID === classes.filter(c => c.selected)[0].id || x.id === 0)];
		const columnSize = filteredGroups.length > 6 ? Math.trunc(filteredGroups.length / 2) : 3;
		
		let column1Size: number, column2Size: number;
		
		if (filteredGroups.length > 6) {
			const modulo = filteredGroups.length % 6;
			column1Size = modulo > 0 ? columnSize + 1 : columnSize;
			column2Size = modulo > 1 ? columnSize + 1 : columnSize;
		} else {
			column1Size = columnSize;
			column2Size = columnSize;
		}

		const groups1 = filteredGroups.slice(0, column1Size);
		const groups2 = filteredGroups.slice(column1Size, column1Size + column2Size);

		if (filteredGroups.length === 1) {
			return <div className={styles.noEntitiesLabel}>No groups have been created</div>;
		}

		return (
			<div className={styles.groups}>
				{renderGroupsInColumn(groups1)}
				{renderGroupsInColumn(groups2)}
			</div>
		);
	};

	const renderStudents = () => {
		const selectedGroups = groups ? groups.filter(c => c.selected) : null;
		const selectedClasses = classes ? classes.filter(c => c.selected) : null;
		const selectedSpecialistGroups = specialistGroups ? specialistGroups.filter(c => c.selected) : null;

		const selectedGroupID = selectedGroups && selectedGroups.length > 0 ? selectedGroups[0].id : 0;
		const selectedClassID = selectedClasses && selectedClasses.length > 0 ? selectedClasses[0].id : 0;
		const selectedSpecialistGroupID = selectedSpecialistGroups && selectedSpecialistGroups.length > 0 ? selectedSpecialistGroups[0].id : 0;

		const newStudents = selectedSpecialistGroupID === 0
			? selectedGroupID === 0
				? students.filter(x => x.classes.some(id => id === selectedClassID) || x.id === 0)
				: students.filter(x => x.groups.some(id => id === selectedGroupID) || x.id === 0)
			: students.filter(x => x.specialistGroups.some(id => id === selectedSpecialistGroupID) || x.id === 0);

		const columnSize = newStudents.length > 32 ? Math.trunc(newStudents.length / 4) : 8;
		let column1Size: number,
			column2Size: number,
			column3Size: number,
			column4Size: number;

		if (newStudents.length > 32) {
			const modulo = newStudents.length % 32;
			column1Size = modulo > 0 ? columnSize + 1 : columnSize;
			column2Size = modulo > 1 ? columnSize + 1 : columnSize;
			column3Size = modulo > 2 ? columnSize + 1 : columnSize;
			column4Size = modulo > 3 ? columnSize + 1 : columnSize;
		} else {
			column1Size = columnSize;
			column2Size = columnSize;
			column3Size = columnSize;
			column4Size = columnSize;
		}

		const students1 = newStudents.slice(0, column1Size);
		const students2 = newStudents.slice(column1Size, column1Size + column2Size);
		const students3 = newStudents.slice(column1Size + column2Size, column1Size + column2Size + column3Size);
		const students4 = newStudents.slice(column1Size + column2Size + column3Size, column1Size + column2Size + column3Size + column4Size);

		if (newStudents.length === 1) {
			return (
					<div className={styles.noEntitiesLabel}>
						There are no students in this { selectedGroupID !== 0 || selectedSpecialistGroupID !== 0 ? 'Group' : 'Class'}
					</div>
			);
		}


		return (
			<div className={styles.bottom}>
				{renderStudentsInColumn(students1)}
				{renderStudentsInColumn(students2)}
				{renderStudentsInColumn(students3)}
				{renderStudentsInColumn(students4)}
			</div>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.topHeaderMain}>
				Who will be playing?
			</div>
			{specialistGroups &&
				<div className={styles.panel}>
					<div className={styles.header}>Groups</div>
					<div className={styles.content}>
						<div>
							{loaded && specialistGroups.map((x, key) => {
								return <Radio
									key={key}
									title={x.name}
									value={x.name}
									name='bingo-groups'
									id={x.id + '_' + x.name}
									checked={x.selected}
									onChange={() => service.updateSpecialistGroups(x.id)}
								/>;
							})}
						</div>
					</div>
				</div>
			}
			{!specialistGroups &&
				<div className={styles.row}>
					<div className={styles.panel}>
						<div className={styles.header}>Classes</div>
						<div className={styles.content}>
							<div className={styles.contentRow}>
								{loaded && classes.map((x, key) => {
									return (
											<Radio
												key={key}
												value={x.id}
												name='bingo-classes'
												id={x.id + '_' + x.name}
												checked={x.selected}
												onChange={() => service.updateClasses(x.id)}
											>
												<div className={join(
													styles.lbl,
													x.selected && styles.selected,
												)}>
													{x.name}
												</div>
											</Radio>
									);
								})}
							</div>
						</div>
					</div>
					<div className={styles.panel}>
						<div className={styles.header}>Groups</div>
						<div className={styles.content}>
							{loaded && renderGroups()}
						</div>
					</div>
				</div>
			}
			<div className={styles.row}>
				<div className={styles.panel}>
					<div className={styles.header}>Students</div>
					{loaded && renderStudents()}
				</div>
			</div>
		</div>
	);
}
