import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {TooltipContent} from '@radix-ui/react-tooltip';

export const Content = styled(TooltipContent, {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderWidth: 1,
	borderStyle: 'solid',

	[`& > ${Text}`]: {
		color: 'currentColor',
	},

	'&:hover': {
		boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
	},

	variants: {
		variant: {
			default: {
				backgroundColor: '$tertiaryBackground',
				borderColor: '$tertiaryMuted',
				color: '$tertiary',
			},

			secondary: {
				backgroundColor: '$vivid',
				borderColor: '$mild',
				color: '$neutral40',
			},

			error: {
				backgroundColor: '$negativeBackground',
				borderColor: '$red92',
				color: '$negativeVivid',
			},
		},

		size: {
			small: {
				paddingTop: 3,
				paddingBottom: 3,
				paddingLeft: 6,
				paddingRight: 6,
				borderRadius: 3,
			},

			medium: {
				paddingTop: 6,
				paddingBottom: 6,
				paddingLeft: 12,
				paddingRight: 12,
				borderRadius: 6,
			},
		},
	},
});
