import {Users} from '@esgi/ui/icons';
import {IconWrapper, Counter} from './index.styled';
import {CounterBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useStudentProgressReportService} from '../../hooks';
import {useBehaviorSubject} from '@esgi/ui';

export function StudentCounter() {
	const service = useStudentProgressReportService();
	const selectedStudentsIDs = useBehaviorSubject(service.selectedStudentsIDs$);

	return (
		<Counter data-cy='students-counter' align='center'>
			<IconWrapper data-cy='multiple-students-icon'>
				<Users />
			</IconWrapper>
			<CounterBox>
				<Text data-cy='counter-box'
					font='mono'
					size='small'
				>
				{selectedStudentsIDs.length}
				</Text>
			</CounterBox>
			<Text data-cy='counter-label' size='small'>Students</Text>
		</Counter>
	);
}
