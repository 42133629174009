import {RubricCriterion, TestInfo, TestSession} from '../../../../../types';
import {AlertBody} from '../../../../components.styled';
import {TestNameWithBar} from '../test-name-with-bar';
import {EditableSessionTimeInfo} from '../../../../editable-session-time-info';
import {Textarea} from '@esgi/ui/controls';
import {AlertFooter} from '../../../../alert-footer';
import {GridBox} from '@esgi/ui/layout';
import {ScoreResultText} from './index.styled';
import {AlertHeader} from '../../../../alert-header';
import {
	NoChangesConfirmation,
	QuestionRowEditable,
	QuestionsContainer,
	ToggleScoreResult,
	maxRubricCommentLength,
} from '../../../../../../kit';
import {useNoChangesConfirmationState} from '../../../hooks/use-no-changes-confirmation-state';
import {useEditModeState} from './use-edit-mode-state';
import {Student} from '@esgi/main/libs/store';

type Props = {
	canEdit: boolean;
	sessionInfo: TestSession;
	testInfo: TestInfo;
	questionTitlesById: Record<RubricCriterion['id'], RubricCriterion['name']>;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	onCloseAlert: VoidFunction;
	student: Student;
	onUpdateTestSession: (args: {summaryNotes: string; testDate: string; answers: TestSession['rubricAnswers']}) => void;
};

export function EditModeContent({
	canEdit,
	sessionInfo,
	testInfo,
	questionTitlesById,
	setIsEditMode,
	onCloseAlert,
	student,
	onUpdateTestSession,
}: Props) {
	const {
		isDataTouched,
		progressBarItems,
		correctAnswers,
		setSessionTimeInfoTouched,
		setIsSessionTimeInfoValid,
		dateTimeRef,
		maxCriteriaValue,
		onSetScore,
		sessionNote,
		onUpdateSessionNote,
		isDataValid,
		handleSave,
		editableSessionInfo,
		onAddCommentToEditableSession,
		onDeleteCommentFromEditableSession,
	} = useEditModeState({sessionInfo, testInfo, onUpdateTestSession});

	const {handleCloseAlert, setViewMode, isOpenNoChangesConfirmation, onCloseNoChangesConfirmation, onCloseAnyway} =
		useNoChangesConfirmationState({isDataTouched, onCloseAlert, setIsEditMode});

	return (
		<>
			<AlertHeader
				canEdit={canEdit}
				isEditMode
				onCloseAlert={handleCloseAlert}
				onEditModeClick={setViewMode}
				student={student}
			/>
			<AlertBody>
				<TestNameWithBar testInfo={testInfo} progressBarItems={progressBarItems} correctAnswers={correctAnswers} />

				<EditableSessionTimeInfo
					sessionInfo={editableSessionInfo}
					setTouched={setSessionTimeInfoTouched}
					setisValid={setIsSessionTimeInfoValid}
					editableSessionTimeRef={dateTimeRef}
					validateOnMinDate={{
						value: new Date(2020, 7, 29), // 2020-08-29
						message: 'Incorrect Date',
					}}
				/>

				<QuestionsContainer>
					{editableSessionInfo.rubricAnswers.map(({criteriaID, notes, score}, index) => (
						<QuestionRowEditable
							questionNumber={index + 1}
							questionTitle={questionTitlesById[criteriaID] ?? ''}
							comment={notes}
							withQuestionUnderline={index !== editableSessionInfo.rubricAnswers.length - 1}
							key={criteriaID}
							onAddComment={(comment) => onAddCommentToEditableSession({criteriaID, comment})}
							onDeleteComment={() => onDeleteCommentFromEditableSession({criteriaID})}
							maxCommentLength={maxRubricCommentLength}
						>
							<GridBox gap='3' flow='column'>
								<ScoreResultText size='xSmall' font='mono' bold color='primary'>
									{score}
								</ScoreResultText>
								<ToggleScoreResult
									maxScore={maxCriteriaValue}
									score={score}
									onClick={(newScore) => onSetScore({criteriaID, newScore})}
								/>
							</GridBox>
						</QuestionRowEditable>
					))}
				</QuestionsContainer>

				<Textarea placeholder='Summary Note' value={sessionNote} onChange={onUpdateSessionNote} />
			</AlertBody>

			<AlertFooter isEditMode isSaveDisabled={!isDataTouched || !isDataValid} onSave={handleSave} />

			{isOpenNoChangesConfirmation && (
				<NoChangesConfirmation onCloseAnyway={() => onCloseAnyway?.()} onClose={onCloseNoChangesConfirmation} />
			)}
		</>
	);
}
