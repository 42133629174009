import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TagList = styled(GridBox, {
	marginLeft: 'auto',
	gridAutoFlow: 'column',
	gap: '$3',
	marginRight: '16px',
	alignItems: 'center',
});

export const NotTestedCounter = styled(TagList, {
	marginRight: '0',
	gap: '$2',
});

export const Tag = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: '16px',
	height: '16px',
	border: '1px solid $neutral88',
	borderRadius: '4px',
	backgroundColor: '$neutral99',
	color: '$neutral64',
	cursor: 'default',

	variants: {
		type: {
			green: {
				backgroundColor: '$green88',
				borderColor: '$green88',

				'& path': {
					fill: '$green48',
				},
			},
			red: {
				backgroundColor: '$red92',
				borderColor: '$red92',

				'& path': {
					fill: '$red48',
				},
			},
		},
	},
});
