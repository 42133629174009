import {useCallback, useEffect, useRef, useState} from 'react';
import {Subscription} from 'rxjs';
import {StandardsService} from '../services';
import {mappedGradeLevels} from '../constants';
import {OptionItem} from '../types';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {useBehaviorSubject} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';

type Props = {
	service: StandardsService,
	contentAreas: OptionItem[]
	setGradeLevels: React.Dispatch<React.SetStateAction<OptionItem[]>>
}

export function useStandardsDrawerState({service, contentAreas, setGradeLevels}: Props) {
	const [showAddStandardsDrawer, setShowAddStandardsDrawer] = useState(false);
	const osRef = useRef<OverlayScrollbarsComponentRef>(null);
	const user = useUser();

	const gradeLevelsRequestSubscription = useRef<Subscription | null>(null);
	const domainsRequestSubscription = useRef<Subscription | null>(null);
	const standardsRequestSubscription = useRef<Subscription | null>(null);

	const isStandardsDrawerInitialised = useBehaviorSubject(service.isStandardsDrawerInitialised$);
	const isContentAreasLoading = useBehaviorSubject(service.isContentAreasLoading$);
	const isDomainsLoading = useBehaviorSubject(service.isDomainsLoading$);
	const isStandardsLoading = useBehaviorSubject(service.isStandardsLoading$);

	const hasMoreStandards = useBehaviorSubject(service.hasMoreStandards$);

	const selectedDomain = useBehaviorSubject(service.selectedDomain$);
	const selectedContentArea = useBehaviorSubject(service.selectedContentArea$);
	const selectedGradeLevel = useBehaviorSubject(service.selectedGradeLevel$);
	const selectedStandardType = useBehaviorSubject(service.selectedStandardType$);
	const searchKeyword = useBehaviorSubject(service.standardsSearchKeyword$);

	const selectedGradeLevelIDs = useBehaviorSubject(service.selectedGradeLevelIDs$);
	const selectedContentAreaIDs = useBehaviorSubject(service.selectedContentAreaIDs$);
	const selectedStandardsFilter = useBehaviorSubject(service.selectedStandards$);
	const selectedStandardsIDs = useBehaviorSubject(service.selectedStandardsIDs$);

	const standardsContentAreas = useBehaviorSubject(service.standardsContentAreas$);
	const standardsList = useBehaviorSubject(service.standardsList$);
	const domainsList = useBehaviorSubject(service.domainsList$);
	const standardTypeOptions = useBehaviorSubject(service.standardTypeOptions$);

	const [selectedStandards, setSelectedStandards] = useState<OptionItem[]>([]);

	const showAddStandardsDrawerToggle = useCallback(() => setShowAddStandardsDrawer(prev => !prev), []);

	const onStandardSelect = useCallback((selected: OptionItem, hasChild?: boolean) => {
		/* Left in case the substandard selection logic returns
		const childs = hasChild
			? service.standards$.value.filter(({parentStandardID}) => parentStandardID === selected.value).map(({id: value, name: label}) => ({value, label}))
			: [];
		*/
		const childs = [];
		const childIDs = childs.map(({value}) => value);
		const selectedStandardIDs = selectedStandards.map(({value}) => value);

		if (selectedStandardIDs.includes(selected.value)) {
			setSelectedStandards(selectedStandards.filter((item) => childs.length
				? !childIDs.includes(item.value) && item.value !== selected.value
				: item.value !== selected.value
			));
			return;
		}

		setSelectedStandards([
			...selectedStandards,
			{...selected, contentArea: service.selectedContentArea$.value, gradeLevel: service.selectedGradeLevel$.value},
			...childs,
		]);
	}, [selectedStandards, service.selectedContentArea$.value, service.selectedGradeLevel$.value]);

	const onStandardSelectMany = useCallback((isAdd = true) => {
		const selectedStandardIDs = selectedStandards.map(({value}) => value);

		const selected: OptionItem[] = service.standardsList$.value.flatMap(({standards}) => {
			return standards.flatMap(({id, name, children}) => [
				{value: id, label: name},
				...children.map(({id, name}) => ({value: id, label: name})),
			]);
		});

		if (isAdd) {
			const added = selected.filter(({value}) => !selectedStandardIDs.includes(value)).map((item) => ({
				...item,
				contentArea: service.selectedContentArea$.value,
				gradeLevel: service.selectedGradeLevel$.value,
			}));
			setSelectedStandards([...selectedStandards, ...added]);
			return;
		}

		const selectedIDs = selected.map(({value}) => value);
		setSelectedStandards(selectedStandards.filter(({value}) => !selectedIDs.includes(value)));
	}, [selectedStandards, service.standardsList$.value, service.selectedContentArea$.value, service.selectedGradeLevel$.value]);

	const onStandardRemove = useCallback((id: number) => {
		if (selectedStandards.length === 0){
			service.selectedStandardType$.next(user?.stateID);
		}
		setSelectedStandards(prev => prev.filter(({value}) => value !== id));
	}, [setSelectedStandards, selectedStandards, service, user]);

	const getStandards = useCallback((id: number, pageIndex?: number) => {
		if (standardsRequestSubscription.current) {
			standardsRequestSubscription.current?.unsubscribe();
		}

		standardsRequestSubscription.current = service.getStandards(id, pageIndex, 30)
			.subscribe(({value: {stateStandardModels, searchCount}}) =>
				service.onStandardsLoaded(stateStandardModels, searchCount));

		return () => standardsRequestSubscription.current?.unsubscribe();
	}, [service]);

	const onDomainSelect = useCallback((id: number) => {
		if (!id) {
			return;
		}

		service.selectedDomain$.next(id);
		service.standards$.next([]);
		service.standardsList$.next([]);
		service.currentStandardsPageIndex$.next(0);

		getStandards(id, 1);
	}, [getStandards, service]);

	const onGradeLevelSelect = useCallback((id: number, contentArea?: number) => {
		service.selectedGradeLevel$.next(id);
		service.standards$.next([]);
		service.standardsList$.next([]);
		service.isDomainsLoading$.next(true);

		if (id) {
			domainsRequestSubscription.current?.unsubscribe();
			domainsRequestSubscription.current = service.getStandardsDomains(contentArea || selectedContentArea, id)
				.subscribe(({value: {domainModels}}) => {
					service.isDomainsLoading$.next(false);
					service.domainModels$.next(domainModels);
					service.domainsList$.next(domainModels.map(item => ({
						...item,
						value: item.id,
						label: item.name,
						description: item.description,
					})));

					onDomainSelect(domainModels?.[0]?.id);
				});
		} else {
			service.isDomainsLoading$.next(false);
			service.domainsList$.next([]);
			service.standardsList$.next([]);
		}

		return () => domainsRequestSubscription.current?.unsubscribe();
	}, [onDomainSelect, selectedContentArea, service]);

	const onContentAreaSelect = useCallback((id: number) => {
		service.selectedContentArea$.next(id);
		gradeLevelsRequestSubscription.current?.unsubscribe();

		gradeLevelsRequestSubscription.current = service.getGradeLevels(id)
			.subscribe(({value}) => {
				const gradeLevelIDs = value.gradeLevels.map(x => x.gradeLevelId);
				const filteredGradeLevels = mappedGradeLevels.filter(x => gradeLevelIDs.includes(x.value)).map((item) => ({
					...item,
					disabled: false,
				}));
				setGradeLevels(filteredGradeLevels);
				service.isContentAreasLoading$.next(false);

				const selectedGradeNotDisabled = selectedGradeLevel && !filteredGradeLevels.find(item => item.value === selectedGradeLevel).disabled;
				onGradeLevelSelect(selectedGradeNotDisabled ? selectedGradeLevel : filteredGradeLevels.find(item => !item.disabled)?.value, id);
			});

		return () => gradeLevelsRequestSubscription.current?.unsubscribe();
	}, [setGradeLevels, onGradeLevelSelect, selectedGradeLevel, service]);

	const getStandardsContentAreas = useCallback(() => {
		service.isContentAreasLoading$.next(true);
		service.getStandardsContentAreas().subscribe(({value: {contentAreas: standardsContentAreas}}) => {
			const contentAreaIDs = standardsContentAreas.map(x => x.contentAreaID);
			service.standardsContentAreas$.next(contentAreas.filter(x => contentAreaIDs.includes(x.value)).map(item => ({
				...item,
				disabled: false,
			})));
			onContentAreaSelect(standardsContentAreas[0]?.contentAreaID);
			service.isContentAreasLoading$.next(false);
		});
	}, [contentAreas, onContentAreaSelect, service]);

	const onNextStandardsPage = useCallback(() => {
		if (hasMoreStandards && !isStandardsLoading) {
			service.currentStandardsPageIndex$.next(service.currentStandardsPageIndex$.value + 1);
		}
	}, [hasMoreStandards, isStandardsLoading, service.currentStandardsPageIndex$]);

	const onStandardTypeChange = useCallback((id: string) => {
		service.selectedStandardType$.next(Number(id));
		getStandardsContentAreas();
		setSelectedStandards([]);
	}, [getStandardsContentAreas, service.selectedStandardType$]);

	useEffect(() => {
		if (!isStandardsDrawerInitialised && contentAreas.length) {
			getStandardsContentAreas();

			if (standardTypeOptions.length === 1) {
				service.getStates();
			}

			service.isStandardsDrawerInitialised$.next(true);
		}
	}, [contentAreas.length, getStandardsContentAreas, isStandardsDrawerInitialised, service, service.isStandardsDrawerInitialised$, standardTypeOptions.length]);

	useEffect(() => {
		setSelectedStandards(service.selectedStandards$.value);
	}, [service.selectedStandards$.value]);

	return {
		osRef,
		isContentAreasLoading,
		standardsContentAreas,
		selectedContentArea,
		onContentAreaSelect,
		selectedGradeLevel,
		onGradeLevelSelect,
		isDomainsLoading,
		domainsList,
		isStandardsLoading,
		standardsList,
		selectedDomain,
		onDomainSelect,
		selectedStandards,
		selectedStandardsFilter,
		onStandardRemove,
		onStandardSelect,
		onStandardSelectMany,
		onNextStandardsPage,
		selectedStandardType,
		standardTypeOptions,
		onStandardTypeChange,
		searchKeyword,
		hasMoreStandards,
		contentAreas,
		selectedStandardsIDs,
		selectedGradeLevelIDs,
		selectedContentAreaIDs,
		showAddStandardsDrawerToggle,
		showAddStandardsDrawer,
	};
}
