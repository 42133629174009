export enum LevelLogger {
	Error,
	Verbose,
}

class Logger {
	private prefix = 'Store: ';
	private level: LevelLogger = LevelLogger.Error;

	public log(...args: any[]) {
		if (this.level === LevelLogger.Verbose) {
			console.log(this.prefix, ...args);
		}
	}

	public debug(...args: any[]) {
		if (this.level === LevelLogger.Verbose) {
			console.debug(this.prefix, ...args);
		}
	}

	public info(...args: any[]) {
		if (this.level === LevelLogger.Verbose) {
			console.info(this.prefix, ...args);
		}
	}

	public error(...args: any[]) {
		console.error(this.prefix, ...args);
	}
}

export const logger = new Logger();