import {Student} from '@esgi/main/libs/store';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {CredentialsRow} from '../components/credentials-row';
import {RefObject} from 'react';
import {NoChangesConfirmation} from '../components/no-changes-confirmation';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {AlertBody, alertRootCss} from '../components/components.styled';
import {useComponentState} from './use-component-state';
import {StudentInfo} from '../components/student-info';
import {AlertFooter} from '../components/alert-footer';

type Props = {
	studentId: Student['id'];
	onAlertClose: VoidFunction;
	snackbarRef: RefObject<SnackbarManager>;
};

export function SingleStudentCredentialsAlert({studentId, onAlertClose, snackbarRef}: Props) {
	const studentCredentialsAlertRef = Alert.useRef();
	const closeStudentCredentialsAlert = Alert.useClose(studentCredentialsAlertRef, onAlertClose);

	const noChangesConfirmationAlertRef = Alert.useRef();
	const openUnsavedChangesAlert = Alert.useOpen(noChangesConfirmationAlertRef);

	const {
		student,
		studentCredentialsService,
		skeleton,
		handleSaveUserCredentials,
		isCredentialsSaving,
		onPasswordChanged,
		onUserNameChanged,
		resetStudentCredentials,
		togglePasswordHidden,
		onCredentialsValidValueChanged,
	} = useComponentState({
		closeStudentCredentialsAlert,
		snackbarRef,
		studentId,
	});

	const handleStudentCredentialsAlertClose = () => {
		if (student.isCredentialsTouched) {
			openUnsavedChangesAlert();
			return;
		}

		closeStudentCredentialsAlert();
	};

	return (
		<>
			<Alert modalManagerRef={studentCredentialsAlertRef} css={alertRootCss} dataCy='student-credentials-alert'>
				<Alert.Header withBacklight={false} onCloseIconClick={handleStudentCredentialsAlertClose}>
					<StudentInfo
						skeleton={skeleton}
						firstName={student.firstName}
						lastName={student.lastName}
						photoUrl={student.photoUrl}
						studentNameColor='primary'
					/>
				</Alert.Header>
				<AlertBody>
					<Text size='medium' color='neutral40'>
						This student can login using these credentials and access the assigned tests.
					</Text>

					<CredentialsRow
						studentId={studentId}
						userName={student.userName}
						initialUserName={student.initialUserName}
						onUserNameChanged={onUserNameChanged}
						onValidateUsername={studentCredentialsService.validateUsername.bind(studentCredentialsService)}
						password={student.password}
						initialPassword={student.initialPassword}
						onPasswordChanged={onPasswordChanged}
						onValidatePassword={studentCredentialsService.validatePassword.bind(studentCredentialsService)}
						isPasswordHidden={student.isPasswordHidden}
						togglePasswordHidden={togglePasswordHidden}
						onCredentialsValidValueChanged={onCredentialsValidValueChanged}
						skeleton={skeleton}
					/>
				</AlertBody>
				<AlertFooter
					skeleton={skeleton}
					isCredentialsTouched={student.isCredentialsTouched}
					isCredentialsValid={student.isCredentialsValid}
					onSaveUserCredentials={handleSaveUserCredentials}
					resetCredentials={resetStudentCredentials}
					isCredentialsSaving={isCredentialsSaving}
				/>
			</Alert>

			<NoChangesConfirmation
				confirmationAlert={noChangesConfirmationAlertRef}
				onCloseAnyway={closeStudentCredentialsAlert}
			/>
		</>
	);
}
