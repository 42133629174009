import {getUrl} from '@esgi/selfassess';
import {environment, Environments} from '@esgi/core/environments';

export abstract class ImageUrlBuilderBase {

	protected correctHost: string;
	constructor(protected bucketName: string) {
		this.correctHost = document.location.host.replace('app', '');
		if (this.correctHost.startsWith('.')) {
			this.correctHost = `www${this.correctHost}`;
		}
	}

	large(entityId, testId, pregenerated, modifyTicks) {
		let baseUrl = this.buildBaseUrl(entityId, testId, pregenerated);
		if (pregenerated) {
			return baseUrl + '/large.png?ticks=' + modifyTicks;
		} else {
			return baseUrl + '&ticks=' + modifyTicks;
		}
	}

	white(entityId, testId, pregenerated, modifyTicks) {
		let baseUrl = this.buildBaseUrl(entityId, testId, pregenerated);
		if (pregenerated) {
			return baseUrl + '/white.png?ticks=' + modifyTicks;
		} else {
			return baseUrl + '&width=400&height=253&ticks=' + modifyTicks;
		}
	}

	medium(entityId, testId, pregenerated, modifyTicks) {
		let baseUrl = this.buildBaseUrl(entityId, testId, pregenerated);
		if (pregenerated) {
			return baseUrl + '/medium.png?ticks=' + modifyTicks;
		} else {
			return baseUrl + '&width=221&height=100&ticks=' + modifyTicks;
		}
	}

	small(entityId, testId, pregenerated, modifyTicks) {
		let baseUrl = this.buildBaseUrl(entityId, testId, pregenerated);
		if (pregenerated) {
			return baseUrl + '/small.png?ticks=' + modifyTicks;
		} else {
			return baseUrl + '&width=80&height=37&ticks=' + modifyTicks;
		}
	}

	abstract buildBaseUrl(entityId, testId, pregenerated);
}

export class QuestionImageUrlBuilder extends ImageUrlBuilderBase {
	buildBaseUrl(questionId, testId, pregenerated) {
		const isLocal = environment.environmentType === Environments.Local;
		const isTest = environment.environmentType === Environments.Test;
		if (pregenerated) {
			if (isLocal || isTest) {
				return 'https://s3.amazonaws.com/' + this.bucketName + '/QuestionCards/test1.esgisoftware.com/' + testId + '/' + questionId;
			}
			return `https://s3.amazonaws.com/${this.bucketName}/QuestionCards/${this.correctHost}/${testId}/${questionId}`;
		}

		return `${environment.esgiApiURL}/assets/questions/image?questionID=${questionId}`;
	}

	selfAssessUrl(thumbnailUrl: string, questionID: number): string {
		if (thumbnailUrl == null) {
			return `${environment.esgiApiURL}/assets/questions/self-assess-image?id=${questionID}`;
		}

		return getUrl(this.bucketName, thumbnailUrl);
	}
}

export class ShareScreenImageUrlBuilder extends QuestionImageUrlBuilder {
	constructor(bucketName: string, private code: string) {
		super(bucketName);
	}

	buildBaseUrl(questionId, testId, pregenerated) {
		if (pregenerated) {
			return super.buildBaseUrl(questionId, testId, pregenerated);
		}

		return `${environment.esgiApiURL}/assets/questions/share-screen-image?questionID=${questionId}&AuthenticationCode=${this.code}`;
	}
}

export class TestIntroImageUrlBuilder extends ImageUrlBuilderBase {
	buildBaseUrl(testIntroPageId, testId, pregenerated) {
		if (pregenerated) {
			const isLocal = environment.environmentType === Environments.Local;
			const isTest = environment.environmentType === Environments.Test;
			if(isLocal || isTest) {
				return 'https://s3.amazonaws.com/' + this.bucketName + '/TestIntroPages/test1.esgisoftware.com/' + testId + '/' + testIntroPageId;
			}
			return `https://s3.amazonaws.com/${this.bucketName}/TestIntroPages/${this.correctHost}/${testId}/${testIntroPageId}`;
		}
		return `${environment.esgiApiURL}/assets/tests/intro-page/image?testIntroPageID=${testIntroPageId}`;
	}
}
