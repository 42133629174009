import React, {Suspense} from 'react';
import {HierarchyEvents, HierarchyStudentAdd, HierarchyStudentEdit} from './core/events';
import {PreSelected} from 'modules/forms/students-form/types';
import {lazyComponent} from '@esgi/core/react';
import {EventBusManager} from '@esgillc/events';
import {GroupType, PreModel, ViewType} from 'modules/forms/admin-group-forms/models/types';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {ISchoolBox} from './core/api';

const ClassFormModal = lazyComponent(() => import('modules/forms/class-form'));
const GroupFormModal = lazyComponent(() => import('modules/forms/group-form'));
const AdminGroupFormModal = lazyComponent(() => import('modules/forms/admin-group-forms'));
const SpecialistGroupFormModal = lazyComponent(() => import('shared/modules/forms/specialist-group-form/modal'));
const StudentProfile = lazyComponent(() => import('modules/forms/students-form'));
const TeacherFormModal = lazyComponent(() => import('shared/modules/forms/teacher-form/modal'));

class State {
	classAddArgs: HierarchyEvents.Class.AddArgs;
	classEditArgs: HierarchyEvents.Class.EditArgs;
	groupAddArgs: HierarchyEvents.Group.AddArgs;
	groupEditArgs: HierarchyEvents.Group.EditArgs;
	schoolsGroupAddArgs: HierarchyEvents.SchoolsGroup.Add;
	schoolsGroupEditArgs: HierarchyEvents.SchoolsGroup.Edit;
	teachersGroupAddArgs: HierarchyEvents.TeachersGroup.Add;
	teachersGroupEditArgs: HierarchyEvents.TeachersGroup.Edit;
	specialistsGroupAddArgs: HierarchyEvents.GroupOfSpecialists.Add;
	specialistsGroupEditArgs: HierarchyEvents.GroupOfSpecialists.Edit;
	specialGroupAddArgs: HierarchyEvents.SpecialGroup.AddArgs;
	specialGroupEditArgs: HierarchyEvents.SpecialGroup.EditArgs;
	teacherAddArgs: HierarchyEvents.Teacher.AddArgs;
	teacherEditArgs: HierarchyEvents.Teacher.EditArgs;
	studentAddArgs: HierarchyStudentAdd;
	studentEditArgs: HierarchyStudentEdit;
}

class Props {
	schoolID: number;
	hierarchyState: HierarchyInstance;
	schools?: ISchoolBox;
}

export class HierarchyModalHandler extends React.Component<Props, State> {
	private eventBus = new EventBusManager();

	constructor(props) {
		super(props);
		this.state = new State();
	}

	public render() {
		return <React.Fragment>
			{this.renderClassEditor()}
			{this.renderGroupEditor()}
			{this.renderSchoolsGroupEditor()}
			{this.renderTeachersGroupEditor()}
			{this.renderSpecialistsGroupEditor()}
			{this.renderSpecialistGroupEditor()}
			{this.renderTeacherEditor()}
			{this.renderStudentEditor()}
		</React.Fragment>;
	}

	public componentDidMount() {
		/*Class*/
		this.eventBus.subscribe(HierarchyEvents.Class.Add, (args: HierarchyEvents.Class.AddArgs) => {
			this.setState({classAddArgs: args});
		});
		this.eventBus.subscribe(HierarchyEvents.Class.Edit, (args: HierarchyEvents.Class.EditArgs) => {
			this.setState({classEditArgs: args});
		});
		/*Group*/
		this.eventBus.subscribe(HierarchyEvents.Group.Add, (args: HierarchyEvents.Group.AddArgs) => {
			this.setState({groupAddArgs: args});
		});
		this.eventBus.subscribe(HierarchyEvents.Group.Edit, (args: HierarchyEvents.Group.EditArgs) => {
			this.setState({groupEditArgs: args});
		});
		/*SchoolsGroup*/
		this.eventBus.subscribe(HierarchyEvents.SchoolsGroup.Add, (args: HierarchyEvents.SchoolsGroup.Add) => {
			this.setState({schoolsGroupAddArgs: args});
		});
		this.eventBus.subscribe(HierarchyEvents.SchoolsGroup.Edit, (args: HierarchyEvents.SchoolsGroup.Edit) => {
			this.setState({schoolsGroupEditArgs: args});
		});
		/*TeachersGroup*/
		this.eventBus.subscribe(HierarchyEvents.TeachersGroup.Add, (args: HierarchyEvents.TeachersGroup.Add) => {
			this.setState({teachersGroupAddArgs: args});
		});
		this.eventBus.subscribe(HierarchyEvents.TeachersGroup.Edit, (args: HierarchyEvents.TeachersGroup.Edit) => {
			this.setState({teachersGroupEditArgs: args});
		});
		/*GroupOfSpecialists*/
		this.eventBus.subscribe(HierarchyEvents.GroupOfSpecialists.Add, (args: HierarchyEvents.GroupOfSpecialists.Add) => {
			this.setState({specialistsGroupAddArgs: args});
		});
		this.eventBus.subscribe(HierarchyEvents.GroupOfSpecialists.Edit, (args: HierarchyEvents.GroupOfSpecialists.Edit) => {
			this.setState({specialistsGroupEditArgs: args});
		});
		/*Student*/
		this.eventBus.subscribe(HierarchyStudentAdd, (event: HierarchyStudentAdd) => {
			this.setState({studentAddArgs: event});
		});
		this.eventBus.subscribe(HierarchyStudentEdit, (args: HierarchyStudentEdit) => {
			this.setState({studentEditArgs: args});
		});
		/*Specialist Group*/
		this.eventBus.subscribe(HierarchyEvents.SpecialGroup.Add, (args: HierarchyEvents.SpecialGroup.AddArgs) => {
			this.setState({specialGroupAddArgs: args});
		});
		this.eventBus.subscribe(HierarchyEvents.SpecialGroup.Edit, (args: HierarchyEvents.SpecialGroup.EditArgs) => {
			this.setState({specialGroupEditArgs: args});
		});
		/*Teacher*/
		this.eventBus.subscribe(HierarchyEvents.Teacher.Add, (args: HierarchyEvents.Teacher.AddArgs) => {
			this.setState({teacherAddArgs: args});
		});
		this.eventBus.subscribe(HierarchyEvents.Teacher.Edit, (args: HierarchyEvents.Teacher.EditArgs) => {
			this.setState({teacherEditArgs: args});
		});
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	private renderClassEditor() {
		if (this.state.classAddArgs) {
			return <Suspense fallback={<div/>}>
				<ClassFormModal classID={0}
				                teacherID={this.state.classAddArgs.teacherID}
				                numberOfClasses={this.state.classAddArgs.countClasses}
				                className={''}
				                onClose={() => this.setState({classAddArgs: null})}/>
			</Suspense>;
		}
		if (this.state.classEditArgs) {
			const {teacherID, id, countClasses, name} = this.state.classEditArgs;
			return <Suspense fallback={<div/>}>
				<ClassFormModal onClose={() => this.setState({classEditArgs: null})}
				                classID={id}
				                teacherID={teacherID}
				                className={name}
				                numberOfClasses={countClasses}/>
			</Suspense>;
		}
	}

	private renderGroupEditor() {
		if (this.state.groupAddArgs) {
			const {className, teacherID, classID} = this.state.groupAddArgs;
			return <Suspense fallback={<div/>}>
				<GroupFormModal onClose={() => this.setState({groupAddArgs: null})}
				                classID={classID}
				                teacherID={teacherID}
				                groupName={''}
				                groupID={0}
				                className={className}/>
			</Suspense>;
		}
		if (this.state.groupEditArgs) {
			const {teacherID, id, className, classID, name} = this.state.groupEditArgs;
			return <Suspense fallback={<div/>}>
				<GroupFormModal onClose={() => this.setState({groupEditArgs: null})}
				                classID={classID}
				                teacherID={teacherID}
				                groupName={name}
				                groupID={id}
				                className={className}/>;
			</Suspense>;
		}
	}


	private renderSchoolsGroupEditor() {
		if (this.state.schoolsGroupAddArgs) {
			const {districtID, userID} = this.state.schoolsGroupAddArgs;
			const model = new PreModel();
			model.districtID = districtID;
			model.userID = userID;

			return <Suspense fallback={<div/>}>
				<AdminGroupFormModal onClose={() => this.setState({schoolsGroupAddArgs: null})}
				                     preModel={model}
				                     viewType={ViewType.SchoolsGroup}/>
			</Suspense>;
		}
		if (this.state.schoolsGroupEditArgs) {
			const {districtID, userID, name, id} = this.state.schoolsGroupEditArgs;
			const model = new PreModel();
			model.districtID = districtID;
			model.userID = userID;
			model.groupName = name;
			model.groupID = id;

			return <Suspense fallback={<div/>}>
				<AdminGroupFormModal onClose={() => this.setState({schoolsGroupEditArgs: null})}
				                     preModel={model}
				                     viewType={ViewType.SchoolsGroup}/>
			</Suspense>;
		}
	}

	private renderTeachersGroupEditor() {
		if (this.state.teachersGroupAddArgs) {
			const {districtID, schoolID, userID, userTypes} = this.state.teachersGroupAddArgs;
			const model = new PreModel();
			model.districtID = districtID;
			model.userTypes = userTypes;
			model.groupType = GroupType.TeachersGroup;
			model.userID = userID;
			model.schoolID = schoolID;

			return <Suspense fallback={<div/>}>
				<AdminGroupFormModal onClose={() => this.setState({teachersGroupAddArgs: null})}
				                     preModel={model}
				                     viewType={ViewType.TeachersGroup}/>
			</Suspense>;
		}
		if (this.state.teachersGroupEditArgs) {
			const {districtID, schoolID, id, name, userTypes} = this.state.teachersGroupEditArgs;
			const model = new PreModel();
			model.districtID = districtID;
			model.userTypes = userTypes;
			model.groupName = name;
			model.groupID = id;
			model.schoolID = schoolID;

			return <Suspense fallback={<div/>}>
				<AdminGroupFormModal onClose={() => this.setState({teachersGroupEditArgs: null})}
				                     preModel={model}
				                     viewType={ViewType.TeachersGroup}/>
			</Suspense>;
		}
	}

	private renderSpecialistsGroupEditor() {
		if (this.state.specialistsGroupAddArgs) {
			const {districtID, schoolID, userID, userTypes} = this.state.specialistsGroupAddArgs;
			const model = new PreModel();
			model.districtID = districtID;
			model.userTypes = userTypes;
			model.groupType = GroupType.GroupOfSpecialists;
			model.userID = userID;
			model.schoolID = schoolID;

			return <Suspense fallback={<div/>}>
				<AdminGroupFormModal onClose={() => this.setState({specialistsGroupAddArgs: null})}
				                     preModel={model}
				                     viewType={ViewType.GroupOfSpecialists}/>
			</Suspense>;
		}
		if (this.state.specialistsGroupEditArgs) {
			const {districtID, schoolID, id, name, userTypes} = this.state.specialistsGroupEditArgs;
			const model = new PreModel();
			model.districtID = districtID;
			model.userTypes = userTypes;
			model.groupName = name;
			model.groupID = id;
			model.schoolID = schoolID;

			return <Suspense fallback={<div/>}>
				<AdminGroupFormModal onClose={() => this.setState({specialistsGroupEditArgs: null})}
				                     preModel={model}
				                     viewType={ViewType.GroupOfSpecialists}/>
			</Suspense>;
		}
	}

	private renderStudentEditor() {
		if (this.state.studentAddArgs) {
			const add = this.state.studentAddArgs;
			const preSelected: PreSelected = {
				groupID: add.groupID,
				userType: add.userType,
				specialistGroupID: add.specialistGroupID,
				classID: add.classID,
				schoolID: add.schoolID,
				userID: add.userID,
			};
			const hasStudentsInClass = () => {
				if (preSelected.classID) {
					return this.props.hierarchyState?.students.filter(s => s.classes.includes(preSelected.classID)).length > 0;
				}
				return null;
			};

			const hasStudentsInGroup = () => {
				if (preSelected.groupID) {
					return this.props.hierarchyState?.students.filter(s => s.specialistGroups.includes(preSelected.groupID)).length > 0;
				}
				return null;
			}

			return <Suspense fallback={<div/>}>
				<StudentProfile onClose={() => this.setState({studentAddArgs: null})}
				                studentID={null}
				                preSelected={preSelected}
				                hasStudentsInClass={hasStudentsInClass()}
				                hasStudentsInSpecialistsGroup={hasStudentsInGroup()}
				                schools={this.props.schools}
				/>
			</Suspense>;
		}
		if (this.state.studentEditArgs) {
			const edit = this.state.studentEditArgs;
			const preSelected: PreSelected = {
				groupID: null,
				userType: edit.userType,
				specialistGroupID: null,
				classID: null,
				schoolID: null,
				userID: edit.userID,
			};
			return <Suspense fallback={<div/>}>
				<StudentProfile onClose={() => this.setState({studentEditArgs: null})}
				                studentID={edit.studentID}
				                schools={this.props.schools}
				                preSelected={preSelected}
				/>
			</Suspense>;
		}
	}

	private renderSpecialistGroupEditor() {
		if (this.state.specialGroupAddArgs) {
			const {userID, numberOfGroups, userType} = this.state.specialGroupAddArgs;
			return <Suspense fallback={<div/>}>
				<SpecialistGroupFormModal onClose={() => this.setState({specialGroupAddArgs: null})}
				                          userID={userID} numberOfGroups={numberOfGroups} groupID={0}
				                          groupName={''} userType={userType}/>
			</Suspense>;
		}
		if (this.state.specialGroupEditArgs) {
			const {id, name, userID, numberOfGroups, userType} = this.state.specialGroupEditArgs;
			return <Suspense fallback={<div/>}>
				<SpecialistGroupFormModal onClose={() => this.setState({specialGroupEditArgs: null})}
				                          groupID={id} userID={userID} numberOfGroups={numberOfGroups}
				                          groupName={name} userType={userType}/>
			</Suspense>;
		}
	}

	private renderTeacherEditor() {
		if (this.state.teacherAddArgs) {
			return <Suspense fallback={<div/>}>
				<TeacherFormModal onClose={() => this.setState({teacherAddArgs: null})}
				                  teacherID={0}
				                  schoolID={this.props.schoolID}/>
			</Suspense>;
		}
		if (this.state.teacherEditArgs) {
			return <Suspense fallback={<div/>}>
				<TeacherFormModal onClose={() => this.setState({teacherEditArgs: null})}
				                  teacherID={this.state.teacherEditArgs.id} schoolID={this.props.schoolID}/>
			</Suspense>;
		}
	}

}
