import React, {useCallback, useMemo} from 'react';
import {noop} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {CheckAll, Correct} from '@esgi/ui';
import {GridBox, SelectableList, useSelectableListGroupContext} from '@esgi/ui/layout';
import {useDeepMemo} from '../../../hooks';
import {TestItem} from '../../../types';
import {StyledSelectableListTrigger, StyledTestContainer, StyledIconBefore, StyledTestColorIndicator, StyledCollapse} from './index.styled';

interface Props<ListItem> {
	item: TestItem,
	list?: ListItem[];
	listVocabulary?: string[];
	onListItemsSelect?: (testId: TestItem['id']) => (list: string[]) => void;
	onListItemsSelectAll?: () => void;
	isSelectable?: boolean;
	isDisabled?: boolean;
	widgetLeft?: JSX.Element | boolean;
	widgetRight?: JSX.Element | boolean;
	children?(props: ListItem): JSX.Element;
}

export function TestsItem<ListItem>({item, list, listVocabulary, onListItemsSelect, onListItemsSelectAll, isSelectable, isDisabled, widgetLeft, widgetRight, children}: Props<ListItem>) {
	const {
		value: testListVocabulary,
		onItemActivate,
		onItemDeactivate,
	} = useSelectableListGroupContext();

	const listVocabularyMemo = useDeepMemo(listVocabulary ?? []);

	const isTestWithSelectableList = useMemo(() => {
		return list && children;
	}, [list, children]);

	const isTestSelected = useMemo(() => {
		if (isTestWithSelectableList) {
			return listVocabularyMemo.length === list?.length;
		}

		return testListVocabulary.includes(String(item.id));
	}, [isTestWithSelectableList, listVocabularyMemo, testListVocabulary, list, item]);

	const handleSelectTest = useCallback((event) => {
		event.stopPropagation();
		if (!isSelectable) {
			return;
		}

		const stringID = String(item.id);
		if (isTestSelected) {
			onItemDeactivate(stringID);
			return;
		}

		onItemActivate(stringID);
		if (!isTestWithSelectableList) {
			return;
		}

		onListItemsSelectAll?.();
	}, [isTestSelected, isSelectable, onItemActivate, item, isTestWithSelectableList, onListItemsSelectAll, onItemDeactivate]);

	const IconBefore = useMemo(() => (
		isTestWithSelectableList
			? CheckAll
			: Correct
	), [isTestWithSelectableList]);

	return (
		<SelectableList data-cy='test-item-root'>
			<SelectableList.GroupRoot
				type='multiple'
				value={listVocabularyMemo}
				onValueChange={onListItemsSelect?.(item.id)}
			>
				<StyledSelectableListTrigger
					selected={isTestSelected}
					disabled={isDisabled}
					openList={isTestWithSelectableList ? undefined : false}
					onClick={isTestWithSelectableList ? noop : handleSelectTest}
				>
					{({isListOpenInner}) => (
						<>
							<StyledTestContainer>
								{isSelectable && (
									<StyledIconBefore data-cy='test-item-checkbox' onClick={handleSelectTest} checked={isTestSelected}>
										{isTestSelected && <IconBefore />}
									</StyledIconBefore>
								)}
								<StyledTestColorIndicator testID={Number(item.id)} />
								<Text size='large' color='currentColor'>{item.name}</Text>
								{widgetLeft && widgetLeft}
							</StyledTestContainer>
							<GridBox flow='column' align='center' justify='end' gap={4}>
								{widgetRight && widgetRight}
								{isTestWithSelectableList && (
									<StyledCollapse height={24} width={24} rotate={isListOpenInner} />
								)}
							</GridBox>
						</>
					)}
				</StyledSelectableListTrigger>
				{isTestWithSelectableList && (
					<SelectableList.Group>
						{list?.map((listItem) => children?.(listItem))}
					</SelectableList.Group>
				)}
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}