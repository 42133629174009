import {SubjectModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/parent-letters/results/init/subject-model';
import {TestModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/parent-letters/results/init/test-model';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Class, Group, Student} from '@esgi/main/libs/store';

export {type TestModel as TestResultModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/parent-letters/results/init/test-model';
export {type FullHierarchyModel as Hierarchy} from '@esgi/contracts/esgi/types/esgi.bl/abstractions/models/full-hierarchy-model';
export {type Request as ReportRequest} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/parent-letters/results/init/request';
export {type TestCard} from '@esgi/contracts/esgi/types/esgi.apigateway/endpoints/teachers/pages/home/sections/tests/test-cards/by-student-ids/test-card';

export type ReportTestSession = {
	correct: number;
	incorrect: number;
	untested: number;
	sessionID?: number;
	testDate: string;
};

export enum FileTypeTemp {
	Pdf = 'Pdf',
	Word = 'Word',
	WordZip = 'WordZip',
	PdfZip = 'PdfZip',
}

export interface GroupItemData {
	groupItemID: number,
	groupItemLevel: 'Class' | 'Group',
}

export enum ClassicHierarchyLevel {
	None = 0,
	Student = 1,
	Group = 2,
	Class = 3,
	Teacher = 4,
	School = 5,
	District = 6,
	SchoolsGroup = 7,
	TeachersGroup = 8,
}

export enum HierarchyMode {
	Classic = 0,
	Specialist = 1,
	PreAssess = 2,
}

export interface ParentLetterResultData {
	subject: SubjectModel,
	tests: TestModel[],
	studentIDs: number[],
	groupItemLevel: 'Class' | 'Group',
	groupItemID: number,
	settings: ParentLetterSettingsData,
	lists: {
		classesList: Class[];
		groupsList: Group[];
		studentsList: Student[];
	}
	entityType: EntityType
	selectedTestIDs: number[]
	singleSelectState: SingleSelectState
}

export interface ParentLetterSettingsData {
	includeUntestedQuestions: boolean,
	includeSummaryNotes: boolean,
	showSessionDate: boolean,
	includeTestNotes: boolean,
	includeGradeScale: boolean,
	includeLetter: boolean,
	printInColor: ColorType,
}

export enum EntityType {
	Student = 'Student',
	ClassGroup = 'ClassGroup'
}

export interface MenuItem {
	text: string,
	value: FileTypeTemp,
	description: string,
}

export enum ColorType {
	Colored = 'Colored',
	BlackAndWhite = 'BlackAndWhite',
}

export type Translations = {
	class: string,
	correct: string,
	defaultStudent: string,
	femaleStudent: string,
	grade: string,
	group: string,
	incorrect: string,
	maleStudent: string,
	mrTeacher: string,
	msTeacher: string,
	notes: string,
	outOf: string,
	score: string,
	subjectTab: string,
	summary: string,
	testName: string,
	testSessionDate: string,
	totalPossible: string,
}
