import {StyledImage} from './index.styled';
import {QuestionImageUrlBuilder} from '../../question-image-url-builder';
import {useUser} from '@esgi/core/authentication';
import {CardViewType, QuestionModel, TestModel} from '../../../../service/types';
import {useCallback, useMemo, useRef, useState} from 'react';
import {notAvailableSrc} from '../../constants';
import {imageQueueService} from '../../service';

type Props = {
	testID: TestModel['testID'],
	question: QuestionModel | undefined,
	cardView: CardViewType,
}

export function QuestionsSliderItem({question, testID, cardView}: Props) {
	const imgRef = useRef<HTMLImageElement>(null);
	const currentUser = useUser();
	const {current: urlBuilder} = useRef(new QuestionImageUrlBuilder(currentUser?.awsBucket));
	const [imageLoadingErrorTimes, setImageLoadingErrorTimes] = useState(0);

	const url = useMemo(() => {
		if (!question){
			return notAvailableSrc;
		}

		return urlBuilder.medium(question?.questionID, testID, question?.pregenerated, question?.ticks);
	}, [urlBuilder, question, testID]);

	const updateImage = useCallback((url: string) => imgRef.current &&
		imageQueueService.addToQueue(url, imgRef.current), []) ;

	const onImageLoadingError = useCallback(() => {
		if (imageLoadingErrorTimes === 0) {
			updateImage(urlBuilder.medium(question?.questionID, testID, false, question?.ticks));
		} else if(imgRef.current) {
			imgRef.current.src = notAvailableSrc;
		}

		setImageLoadingErrorTimes(prev => prev + 1);
	}, [imageLoadingErrorTimes, question, testID, updateImage, urlBuilder]);

	return (
		<StyledImage ref={imgRef} src={url} onError={onImageLoadingError} isListView={cardView === CardViewType.List}/>
	);
}