import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {useCallback} from 'react';
import {GridBox} from '@esgi/ui/layout';

interface Props {
	onClose: VoidFunction,
	onChangeAnyway: () => void,
}

export function UnsavedChangesTabAlert({onClose, onChangeAnyway}: Props) {

	const alertRef = Alert.useRef();
	const handelClose = Alert.useClose(alertRef, onClose);

	const changeAnyway = useCallback(() => {
		handelClose();
		onChangeAnyway();
	}, [handelClose, onChangeAnyway]);

	return <Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
		<Alert.Header withBacklight onCloseIconClick={handelClose}>
			<Text size='medium' font='mono' bold>Unsaved Changes</Text>
		</Alert.Header>
		<Alert.Body>
			<GridBox>
				<Text size='medium' color='neutral40'>
					You’ve made some changes but didn’t save them. All unsaved changes will be lost.
				</Text>
				<Text size='medium' color='neutral40'>
					Are you sure you want to change this tab?
				</Text>
			</GridBox>
		</Alert.Body>
		<Alert.Footer align='center' gapX={3}>
			<Button color='tertiary' onClick={changeAnyway}>
				<Text size='medium' color='neutral40' bold>Change Anyway</Text>
			</Button>
			<Button onClick={handelClose} color='secondary'>
				<Text size='medium' color='neutral40' bold>Go Back</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}
