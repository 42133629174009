import {environment} from '@esgi/core/environments';
import {useService} from '@esgi/core/service';
import {Buttons} from '@esgillc/ui-kit/button';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {Fade} from '@esgillc/ui-kit/transition';
import React, {useCallback, useState, MouseEvent} from 'react';
import {PasswordReminder} from 'modules/password-reminder/password-reminder';
import {WindowTool} from '@esgi/deprecated/utils';
import {LoginService} from './service';
import {FlexBox} from '@esgillc/ui-kit/layout';

import styles from './styles.module.less';

function loginWithCleverClicked() {
	const windowTool = new WindowTool();
	windowTool.openAndResizeWindow(WindowTool.getESGIWindowName(), null, `${environment.ssoApiURL}/Integrations/Clever/AuthEsgi`, (w) => {
		w.sessionStorage.setItem('esgi-registration-disallow', 'true');
	});
}

export default function LoginForm() {
	const service = useService(LoginService);

	const backClicked = useCallback((e: MouseEvent) => {
		window.location.href = environment.landingUrl;
	}, []);

	const loginClicked = useCallback((e: MouseEvent) => {
		service.form.validate().subscribe((r) => {
			if (r.valid) {
				const ignoreMaintenanceMode = e.shiftKey && e.ctrlKey;
				service.makeLogin(ignoreMaintenanceMode).subscribe();
				document.body.focus();
			}
		});
	}, []);

	const onChange = useCallback((event) => {
		if (event.key === 'Enter') {
			loginClicked(event);
		}
	}, []);

	const [showReminder, setShowReminder] = useState(false);
	const {username, password} = service.form.controls;

	return <FlexBox justify='start' direction='column' align='center'
		         className={styles.container}>
			<ServiceLoader trackingService={service} fullscreen/>
			<Fade in duration={300} animateOnMount>
				<FlexBox direction='column'>
					<img className={styles.logo} alt='Logo' src='//s3.amazonaws.com/ESGI/Umbraco/media/redesign/new_logo.png'/>
					<div className={styles.loginFormContainer}>
						<div>
							<Form controller={service.form}>
								<FormElement control={username} disableValidateOnBlur>
									<Input tabIndex={1} className={styles.loginInput} onKeyPress={onChange}
									       name='username'
									       type='text' autoComplete='on'
									       placeholder='Username'/>
									<OnErrorTooltip showOnError='required' placement='right' notHide>
										Please enter your username.
									</OnErrorTooltip>
								</FormElement>
								<FormElement control={password} disableValidateOnBlur>
									<Input tabIndex={2} className={styles.loginInput} onKeyPress={onChange}
									       name='password'
									       type='password' autoComplete='on'
									       placeholder='Password'/>
									<OnErrorTooltip showOnError='required' placement='right' notHide>
										Please enter your password.
									</OnErrorTooltip>
								</FormElement>
							</Form>
							<FlexBox justify='center' align='start' direction='column'>
								<Buttons.Link tabIndex={4} onClick={() => setShowReminder(true)}
								              className={styles.forgotPasswordBtn}>
									Forgot Password
								</Buttons.Link>
							</FlexBox>
							<FlexBox>
								<Buttons.Gray onClick={(e) => backClicked(e)}
								              className={styles.backButton}
								                   tabIndex={5}>
									BACK
								</Buttons.Gray>
								<Buttons.Contained onClick={(e) => loginClicked(e)}
								                   tabIndex={3}
								                   className={styles.loginBtn}>
									Login
								</Buttons.Contained>
							</FlexBox>
							<hr data-content='OR' className={styles.hr}/>
							<FlexBox direction='column' align='center' justify='center'>
								<a onClick={loginWithCleverClicked} tabIndex={5}>
									<img src='https://s3.amazonaws.com/esgiwebfiles/Images/Login-Clever-Button.png'/>
								</a>
								<br/>
								<a href={`${environment.ssoApiURL}/integrations/class-link/auth-esgi`} tabIndex={6}>
									<img src='https://s3.amazonaws.com/esgiwebfiles/Images/login-classlink-button.png'
									     width='145px;'/>
								</a>
							</FlexBox>
						</div>
					</div>
				</FlexBox>
			</Fade>
			{showReminder && <PasswordReminder onClose={() => setShowReminder(false)}/>}
		</FlexBox>;
}
