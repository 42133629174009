import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {UntestedStudentsReportService} from '../../../service';
import styles from '../../modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService;
}

export function ReportUntestedWeeks({service}: Props) {
	const lastUntestedWeeks = useBehaviorSubject(service.lastUntestedWeeks);
	const emptyReport = useBehaviorSubject(service.emptyReport);

	return !emptyReport &&
		<div>
			<div className={join(styles.settingsCell, styles.bold)}>
				<span>Show students who haven’t been tested in the last:</span>
				<input
					type='number'
					min={1} max={40} step={1}
					className={styles.untestedWeeks}
					value={lastUntestedWeeks} onChange={(e) => service.setLastUntestedWeeks(+e.target.value)}
				/>
				<span>{'week' + (lastUntestedWeeks != 1 ? 's' : '')}</span>
				<span>
                                <small>   (maximum 40)</small>
                            </span>
				<OnHoverTooltip message='Help'>
					<a className={styles.inlineTooltip}
					   href='https://support.esgisoftware.com/hc/en-us/articles/209159826-Untested-Students#numberofweeks'
					   target='_blank' rel='noreferrer'>
						<sup aria-hidden='true'
						     className={join(
							     'fa',
							     styles.fa,
							     'fa-question-circle',
						     )}/>
					</a>
				</OnHoverTooltip>
			</div>
		</div>;
}
