import {BehaviorSubject, skip} from 'rxjs';
import {useEffect, useMemo, useState} from 'react';

export function useBehaviorSubject1<T>(subject: BehaviorSubject<T>): T {
	const initial = subject.value;
	const [value, setValue] = useState<T>(initial);
	const stream = useMemo(() => subject.pipe(skip(1)), [subject]);
	useEffect(() => {
		if(subject.value !== initial) {
			setValue(subject.value);
		}
		const sub = stream.subscribe(setValue);
		return () => sub.unsubscribe();
	}, [stream]);

	return value;
}