import {useCallback, useEffect, useMemo, useState} from 'react';
import {isNull} from 'underscore';
import {Layout} from '../kit/page';
import {SubjectSectionContent} from './components/subject-section-content';
import {TestSectionContent} from './components/test-section-content';
import {
	DrawerWithClassForm,
	DrawerWithGroupForm,
	DrawerWithStudentViaFileForm,
	AddTestDrawer,
	StudentProfileDrawer,
	TeacherDrawerName,
} from '@esgi/main/features/teacher/drawers';
import {TeacherPageContext} from './context/teacher-page-context';
import {useTeacherPageContextValue} from './context/use-teacher-page-context-value';
import {Snackbar} from '@esgi/ui/snackbar';
import {
	TestSection,
	StudentSection,
	SubjectSection,
	TestModel,
	DrawerMode,
	useDrawerMode,
} from '@esgi/main/features/teacher/home';
import {Class, SubjectTab, Group} from '@esgi/main/libs/store';
import {Testing, TestLauncherState} from '@esgi/main/features/assessments';
import {useStateService} from './hooks/use-state-service';
import {ClassNotTestedDialog, StudentNotTestedDialog} from './components/not-tested-dialog';
import {TestDashboardSectionID, useNavigateToTestDashboard} from '@esgi/main/features/teacher/test-dashboard';
import {useNavigate} from 'react-router-dom';
import {useRootPageContext, ScreenSize} from '@esgi/main/kits/common';
import {TabletViewSections} from './components/tablet-view-sections';
import {StudentPanel} from './components/student-panel';
import {AddSessionDetails} from '@esgi/main/features/teacher/test-session-details';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {DataSubjectsService} from './services/data-subjects-service';
import {useTestSectionContentSingleStudentProps} from './use-test-section-content-single-student-props';
import {useTestSectionContentGroupStudentsProps} from './use-test-section-content-group-students-props';
import {useService} from '@esgi/core/service';
import {useUser} from '@esgi/core/authentication';
import {useFlashcardUrlWithParams} from '@esgi/main/features/teacher/utils';
import {useBehaviorSubject1} from './hot-fix-hooks';

export default function () {
	const {testResultsCorrectVerbiage, testResultsIncorrectVerbiage} = useUser();
	const [selectedSubject, setSelectedSubject] = useState<SubjectTab | null>(null);

	const [studentFromClassID, setStudentFromClassID] = useState<Class['id'] | null>(null);
	const [studentFromGroupID, setStudentFromGroupID] = useState<Group['id'] | null>(null);

	const [hasStudents, setHasStudents] = useState(false);

	const [testLauncherState, setTestLauncherState] = useState<TestLauncherState | null>(null);

	const [showAddTestDrawer, setShowAddTestDrawer] = useState(false);

	const [openAddSessionDetails, setOpenAddSessionDetails] = useState(false);

	const {
		isFirstEntry,
		isUpdatingState,
		sectionsState,
		selectedSubjectId,
		activeSubjectTab,
		selectedStudentId,
		selectedClassId,
		selectedGroupId,
		activeStudentTab,
		stateService,
		isStateLoaded,
		isSubjectStateLoaded,
		testSectionCardViewType,
	} = useStateService();

	const openTestDashboard = useNavigateToTestDashboard();

	const {screenSize} = useRootPageContext();
	const drawerMode = useDrawerMode({drawerName: TeacherDrawerName.AddTest});

	const [showStudentNotTestedAlert, setShowStudentNotTestedAlert] = useState(false);
	const [showClassNotTestedAlert, setShowClassNotTestedAlert] = useState(false);
	const [testModel, setTestModel] = useState<TestModel>(null);

	const dataService = useService(DataSubjectsService);

	const districtSubjects = useBehaviorSubject1(dataService.districtSubjects$);
	const schoolSubjects = useBehaviorSubject1(dataService.schoolSubjects$);
	const personalSubjects = useBehaviorSubject1(dataService.personalSubjects$);
	const flashcardUrl = useFlashcardUrlWithParams();

	const allSubjects = useMemo(
		() => [...districtSubjects, ...schoolSubjects, ...personalSubjects],
		[districtSubjects, schoolSubjects, personalSubjects],
	);

	useEffect(() => {
		const selectedSubject = allSubjects.find(({id}) => id === selectedSubjectId);

		setSelectedSubject(selectedSubject ?? null);
	}, [selectedSubjectId, allSubjects]);

	const goToTestDashboard = useCallback(
		({goToSection, testID}: {goToSection?: TestDashboardSectionID; testID: number}) => {
			if (selectedClassId) {
				openTestDashboard({
					dashboardType: 'class',
					subjectID: selectedSubject?.id,
					testID,
					subjectType: selectedSubject?.type,
					classID: selectedClassId,
					goToSection,
				});
			}

			if (selectedGroupId) {
				openTestDashboard({
					dashboardType: 'group',
					subjectID: selectedSubject?.id,
					testID,
					subjectType: selectedSubject?.type,
					groupID: selectedGroupId,
					goToSection,
				});
			}

			if (selectedStudentId) {
				openTestDashboard({
					dashboardType: 'student',
					subjectID: selectedSubject?.id,
					testID,
					subjectType: selectedSubject?.type,
					studentID: selectedStudentId,
					studentFromClassID,
					studentFromGroupID,
					goToSection,
				});
			}
		},
		[
			openTestDashboard,
			selectedClassId,
			selectedGroupId,
			selectedStudentId,
			selectedSubject,
			studentFromClassID,
			studentFromGroupID,
		],
	);

	const handleClickTestCard = useCallback(
		(test: TestModel) => {
			if (test.analyticsData) {
				goToTestDashboard({testID: test.testInfo.id});
				return;
			}

			setTestModel(test);
			if (selectedStudentId) {
				setShowStudentNotTestedAlert(true);
			} else {
				setShowClassNotTestedAlert(true);
			}
		},
		[goToTestDashboard, selectedStudentId],
	);

	const onAllSessionsClick = useCallback(
		({testID}: {testID: number}) => {
			goToTestDashboard({testID, goToSection: TestDashboardSectionID.Sessions});
		},
		[goToTestDashboard],
	);

	const handleStartNewSession = useCallback(() => {
		setShowStudentNotTestedAlert(false);
		setTestLauncherState({
			isOpen: true,
			data: {
				testID: testModel.testInfo.id,
				analyticsData: !isNull(testModel.analyticsData)
					? {
							...testModel.analyticsData,
							lastSessionDate: new Date(testModel.analyticsData.lastSessionDate),
					  }
					: null,
				testType: testModel.testInfo.type,
				contentArea: testModel.testInfo.contentArea,
				studentResult: null,
			},
		});
	}, [testModel]);

	const navigate = useNavigate();

	const singleStudentTestSectionContentProps = useTestSectionContentSingleStudentProps({
		handleClickTestCard,
		selectedStudentId,
		studentFromClassID,
		studentFromGroupID,
		setTestLauncherState,
	});

	const groupStudentsTestSectionContentProps = useTestSectionContentGroupStudentsProps({
		handleClickTestCard,
		selectedClassId,
		selectedGroupId,
	});

	const teacherPageContextValue = useTeacherPageContextValue();

	const onCloseTestLauncher = () => {
		setTestLauncherState((prev) => ({...prev, isOpen: false}));
	};

	useEffect(() => {
		if (drawerMode === DrawerMode.Create && selectedSubject) {
			setShowAddTestDrawer(true);
		}
	}, [drawerMode, selectedSubject]);

	if (isNull(sectionsState)) {
		return null;
	}

	return (
		<Layout>
			<TeacherPageContext.Provider value={teacherPageContextValue}>
				{screenSize === ScreenSize.PortraitTablet ? (
					<TabletViewSections
						stateService={stateService}
						isUpdatingState={isUpdatingState}
						screenSize={screenSize}
						setStudentFromClassID={setStudentFromClassID}
						setStudentFromGroupID={setStudentFromGroupID}
						isFirstEntry={isFirstEntry}
						selectedStudentId={selectedStudentId}
						selectedStudent={singleStudentTestSectionContentProps?.student ?? null}
						selectedClassId={selectedClassId}
						selectedGroupId={selectedGroupId}
						activeStudentTab={activeStudentTab}
						setHasStudents={setHasStudents}
						selectedSubjectId={selectedSubjectId}
						activeSubjectTab={activeSubjectTab}
						setSelectedSubject={setSelectedSubject}
						dataService={dataService}
						districtSubjects={districtSubjects}
						schoolSubjects={schoolSubjects}
						personalSubjects={personalSubjects}
					/>
				) : (
					<>
						<StudentSection.Root
							isPanelOpened={sectionsState.studentSection.opened}
							togglePanelOpened={stateService.toggleOpenPanel.bind(stateService, 'studentSection')}
							disablePanelToggling={isUpdatingState}
							isTablet={screenSize === ScreenSize.Tablet}
						>
							{isStateLoaded && (
								<StudentPanel
									isFirstEntry={isFirstEntry}
									setHasStudents={setHasStudents}
									activeTab={activeStudentTab}
									setActiveTab={stateService.setActiveStudentTab.bind(stateService)}
									selectedClassId={selectedClassId}
									setSelectedClassId={stateService.setSelectedClassId.bind(stateService)}
									selectedGroupId={selectedGroupId}
									setSelectedGroupId={stateService.setSelectedGroupId.bind(stateService)}
									selectedStudentId={selectedStudentId}
									setSelectedStudentId={stateService.setSelectedStudentId.bind(stateService)}
									setStudentFromClassID={setStudentFromClassID}
									setStudentFromGroupID={setStudentFromGroupID}
								/>
							)}
						</StudentSection.Root>
						<SubjectSection.Root
							isPanelOpened={sectionsState.subjectSection.opened}
							togglePanelOpened={stateService.toggleOpenPanel.bind(stateService, 'subjectSection')}
							disablePanelToggling={isUpdatingState}
							isTablet={screenSize === ScreenSize.Tablet}
						>
							{isSubjectStateLoaded && (
								<SubjectSectionContent
									selectedSubjectId={selectedSubjectId}
									setSelectedSubjectId={stateService.setSelectedSubjectId.bind(stateService)}
									activeTab={activeSubjectTab}
									setActiveTab={stateService.setActiveSubjectTab.bind(stateService)}
									dataService={dataService}
									districtSubjects={districtSubjects}
									schoolSubjects={schoolSubjects}
									personalSubjects={personalSubjects}
									selectedStudent={singleStudentTestSectionContentProps?.student ?? null}
								/>
							)}
						</SubjectSection.Root>
					</>
				)}
				<TestSection.Root
					testResultsIncorrectVerbiage={testResultsIncorrectVerbiage}
					testResultsCorrectVerbiage={testResultsCorrectVerbiage}
					cardViewType={testSectionCardViewType}
					onCardViewTypeChanged={stateService.setTestSectionCardViewType.bind(stateService)}
					allSessionsClick={onAllSessionsClick}
				>
					<TestSectionContent
						subject={selectedSubject}
						hasSubjects={Boolean(allSubjects.length)}
						hasStudents={hasStudents}
						widgets={sectionsState.widgets}
						onAddTestClick={() => setShowAddTestDrawer(true)}
						showAddTestDrawer={showAddTestDrawer}
						singleStudentData={singleStudentTestSectionContentProps}
						groupStudentsData={groupStudentsTestSectionContentProps}
					/>
				</TestSection.Root>
				{testLauncherState?.isOpen && (
					<Testing
						onFlashCardsClicked={() => flashcardUrl({
							studentId: selectedStudentId?.toString(),
							subjectId: selectedSubjectId?.toString(),
							testId: testLauncherState?.data.testID.toString(),
						})}
						onTestSessionDetailsClicked={null}
						student={singleStudentTestSectionContentProps?.student ?? null}
						subject={selectedSubject}
						studentClass={singleStudentTestSectionContentProps?.selectedStudentClass}
						launcherData={testLauncherState?.data}
						onClose={onCloseTestLauncher}
					/>
				)}
				{showStudentNotTestedAlert && (
					<StudentNotTestedDialog
						testID={testModel.testInfo.id}
						testName={testModel.testInfo.name}
						testType={testModel.testInfo.type}
						firstName={singleStudentTestSectionContentProps?.student.firstName ?? ''}
						lastName={singleStudentTestSectionContentProps?.student.lastName ?? ''}
						avatarUrl={singleStudentTestSectionContentProps?.student.photoUrl ?? ''}
						onClose={() => setShowStudentNotTestedAlert(false)}
						onAddSessionClick={() => {
							setOpenAddSessionDetails(true);
							setShowStudentNotTestedAlert(false);
						}}
						onStartTestClick={handleStartNewSession}
					/>
				)}
				{showClassNotTestedAlert && (
					<ClassNotTestedDialog
						testID={testModel.testInfo.id}
						testName={testModel.testInfo.name}
						testType={testModel.testInfo.type}
						className={singleStudentTestSectionContentProps?.selectedStudentClass?.name ?? ''}
						onClose={() => setShowClassNotTestedAlert(false)}
					/>
				)}
				<DrawerWithClassForm />
				<DrawerWithGroupForm />
				<DrawerWithStudentViaFileForm />
				<StudentProfileDrawer />

				{showAddTestDrawer && (
					<AddTestDrawer
						onClose={() => setShowAddTestDrawer(false)}
						subject={selectedSubject}
						snackbarRef={teacherPageContextValue.teacherSnackbarRef}
					/>
				)}

				{openAddSessionDetails && singleStudentTestSectionContentProps?.student && selectedSubject && (
					<AddSessionDetails
						studentID={singleStudentTestSectionContentProps.student.id}
						subjectID={selectedSubject.id}
						onAlertClose={() => setOpenAddSessionDetails(false)}
						testID={testModel.testInfo.id}
					/>
				)}

				<Snackbar snackbarRef={teacherPageContextValue.teacherSnackbarRef} />
			</TeacherPageContext.Provider>
		</Layout>
	);
}
