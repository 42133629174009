import React, {Fragment, PropsWithChildren, useEffect, useState} from 'react';
import {environment, Environments, IEnvironment} from '@esgi/core/environments';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

const baseMessage = `Uh oh. Something seems to be wrong on our end. Please reload the page. If you continue to see this message - please let us know via 'Contact us' button.`;

export function EnvironmentLayer(props: PropsWithChildren) {
	const [environmentLoaded, setEnvironmentLoaded] = useState(false);
	const [hasError, setHasError] = useState(false);

	useEffect(() => {
		fetch('/esgi/env2').then(async r => {
			if (r.status === 200) {
				const response = (await r.json()) as IEnvironment & { type: Environments };
				environment.init({
					esgiApiURL: response.esgiApiURL,
					ssoApiURL: response.ssoApiURL,
					url: response.url,
					studentAppUrl: response.studentAppUrl,
					landingUrl: response.landingUrl,
				}, response.type);
				setEnvironmentLoaded(true);
			} else {
				setHasError(true);
				console.error('Page Initialization: env request failed.');
			}
		}).catch(e => {
			console.error(e);
			setHasError(true);
		});
	}, []);

	if (hasError) {
		return <Alert>
			<Alert.Body>
				{baseMessage}
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Contained onClick={() => location.reload()}>OK</Buttons.Contained>
			</Alert.Footer>
		</Alert>;
	}

	if (environmentLoaded) {
		return <Fragment>
			{props.children}
		</Fragment>;
	}
}