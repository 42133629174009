import React, {ReactNode} from 'react';
import {deepEqual} from 'shared/utils';
import Matrix from '@esgi/deprecated/ui-kit/matrix/matrix';
import {BodyRenderer, HeaderRenderer, RowData} from '@esgi/deprecated/ui-kit/matrix/types';
import {isEqual} from 'underscore';
import {OverlayScrollbars} from 'overlayscrollbars';
import {DragDropContext, DraggableProvidedDragHandleProps, Droppable} from 'react-beautiful-dnd';
import {Criteria, Description, Level, LevelDisplayMode} from '../models';
import RubricService from '../rubric-service';
import {ColumnWidth, RowHeight} from './utils';
import AddCriteriaRow from './components/add-criteria-row/add-criteria-row';
import {CriteriaActiveView} from './components/parts/criteria/criteria-active-view';
import {DescriptionActiveView} from './components/parts/description/description-active-view';
import {LevelActiveView} from './components/parts/level/level-active-view';
import Header from './components/header/header';
import RowBody from './components/row-body/row-body';
import rowAnimationStyles from './row.animation.module.less';
import columnAnimationStyles from './column.animation.module.less';
import styles from './test-form.module.less';
import {join, tryCall, isIOS} from '@esgillc/ui-kit/utils';
import {
	BottomPanel,
	NextColumnButton,
	NextRowButton,
	RightPanel,
	scrollToElement,
	scrollYToBottom,
} from '@esgi/deprecated/ui-kit/matrix';
import {AnimationTimer} from '@esgillc/ui-kit/transition';
import {ArrowIcon} from '@esgi/deprecated/ui-kit/matrix/arrow-icon';
import {Options} from './types';

class State {
	criteria: Criteria[] = [];
	levels: Level[] = [];
	descriptions: Description[] = [];
	levelDisplayMode: LevelDisplayMode;
	rowToAdd: number;
	rowToDelete: number;
	columnToAdd: number;
	columnToDelete: number;
}

interface Props {
	options: Options;
	rubricService: RubricService;

	onCriteriaRendered?: (id: number, ref: CriteriaActiveView) => void;
	onLevelsRendered?: (id: number, ref: LevelActiveView) => void;
	onDescriptionsRendered?: (id: number, ref: DescriptionActiveView) => void;

	overlayScrollRef: (ref: OverlayScrollbars) => void;

	prohibitModifyScore?: boolean;
}

export const TableTopOffset = 92;
export const TableLeftOffset = 235;
export const TableWidth = 1005; // 3 columns.
export const TableHeight = 560; // 3 row.

type BaseMatrix = Matrix<Level, Criteria, Description>;


export class RubricEditForm extends React.Component<Props, State> {
	private readonly rubricMatrixBodyRef: React.RefObject<BaseMatrix> = React.createRef<BaseMatrix>();
	private readonly criteriaToRef: Map<number, CriteriaActiveView> = new Map();
	private readonly levelsToRef: Map<number, LevelActiveView> = new Map();
	private readonly descriptionsToRef: Map<number, DescriptionActiveView> = new Map();

	private addRowAnimationFrame: AnimationTimer;
	private removeRowAnimationFrame: AnimationTimer;
	private addColumnAnimationFrame: AnimationTimer;
	private removeColumnAnimationFrame: AnimationTimer;
	private nextButtonRef: React.RefObject<ArrowIcon> = React.createRef<ArrowIcon>();

	public state = new State();

	private get tooltipContainer() {
		return this.osInstance?.elements().viewport;
	}

	private get osInstance() {
		return this.rubricMatrixBodyRef.current?.osInstance;
	}

	public componentDidMount() {
		this.props.rubricService.criteria$.subscribe(c => this.setState({criteria: c}));
		this.props.rubricService.levels$.subscribe(l => this.setState({levels: l}));
		this.props.rubricService.descriptions$.subscribe(d => this.setState({descriptions: d}));
		this.props.rubricService.levelDisplayMode$.subscribe(mode => this.setState({levelDisplayMode: mode}));
	}

	private onCriteriaRendered = (id: number, ref: CriteriaActiveView) => {
		this.criteriaToRef.set(id, ref);
		tryCall(this.props.onCriteriaRendered, id, ref);
	};

	private onLevelRendered = (id: number, ref: LevelActiveView) => {
		this.levelsToRef.set(id, ref);
		tryCall(this.props.onLevelsRendered, id, ref);
	};

	private onDescriptionsRendered = (id: number, ref: DescriptionActiveView) => {
		this.descriptionsToRef.set(id, ref);
		tryCall(this.props.onDescriptionsRendered, id, ref);
	};

	public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
		const {options: nextOptions, ...otherNextProps} = nextProps;
		const {options: currOptions, ...otherCurrProps} = this.props;

		if(!deepEqual(nextOptions, currOptions)) {
			return true;
		}

		if (!isEqual(otherNextProps, otherCurrProps)) {
			return true;
		}

		if(!isEqual(this.state, nextState)) {
			return true;
		}

		return false;
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		//Make an animation of add/remove row.
		if (prevState.rowToAdd !== this.state.rowToAdd && this.state.rowToAdd && this.addRowAnimationFrame) {
			!this.addRowAnimationFrame.isStarted && this.addRowAnimationFrame.start();
		}
		if (prevState.rowToDelete !== this.state.rowToDelete && this.state.rowToDelete && this.removeRowAnimationFrame) {
			!this.removeRowAnimationFrame.isStarted && this.removeRowAnimationFrame.start();
		}
		if (prevState.columnToAdd !== this.state.columnToAdd && this.state.columnToAdd && this.addColumnAnimationFrame) {
			!this.addColumnAnimationFrame.isStarted && this.addColumnAnimationFrame.start();
		}
		if (prevState.columnToDelete !== this.state.columnToDelete && this.state.columnToDelete && this.removeColumnAnimationFrame) {
			!this.removeColumnAnimationFrame.isStarted && this.removeColumnAnimationFrame.start();
		}
	}

	public render() {
		return <div className={styles.formContainer}>
			<DragDropContext
				onDragEnd={(props) => {
					if (props.source && props.destination) {
						this.props.rubricService.reorderCriteria(props.source.index, props.destination.index);
					}
				}}>
				<Matrix
					ref={this.rubricMatrixBodyRef}
					className={{
						prevRowButton: styles.prevRowButton,
						prevColumnButton: styles.prevColumnButton,
					}}
					columnHeaderOptions={{
						cells: this.state.levels,
						cellRenderer: (c) => this.renderLevel(c),
					}}
					rowHeaderOptions={{
						cells: this.state.criteria,
						cellRenderer: (c) => this.renderCriteria(c),
					}}
					cellsOptions={{
						cells: this.state.descriptions,
						cellRenderer: (c) => this.renderDescription(c),
						cellGetter: (source, col, row) => source.find(d => d.levelID === col.id && d.criteriaID === row.id),
					}}
					maxWidth={TableWidth}
					maxHeight={TableHeight}
					overlayScrollRef={(ref) => tryCall(this.props.overlayScrollRef, ref)}

					/*Table renderers*/
					renderHeader={(data, headerRenderer) => this.renderHeader(headerRenderer)}
					renderBody={(bodyData, bodyRenderer) => this.renderBody(bodyRenderer)}
					renderRow={(rowData) => this.rowRenderer(rowData)}
				>
					<RightPanel>
						<NextColumnButton nextColumnRef={this.nextButtonRef} className={styles.nextColumnButton} columnWidth={ColumnWidth}/>
					</RightPanel>
					<BottomPanel>
						<NextRowButton className={styles.nextRowButton} rowHeight={RowHeight}/>
					</BottomPanel>
				</Matrix>
			</DragDropContext>
		</div>;
	}

	private renderHeader(headerRenderer: HeaderRenderer) {
		return <Header headerRenderer={headerRenderer}
		               canAddLevel={!this.props.prohibitModifyScore}
		               rubricService={this.props.rubricService}
		               displayMode={this.state.levelDisplayMode}
		               showContextActions={this.props.options.levelsOptions?.editMode}
		               canChangeLevelMode={this.props.options.levelsOptions?.canChangeLevelMode}
		               onAddLevelClicked={() => this.addColumn()}
					   onSetMinimumZeroLevel={() => this.addZeroLevel()}
					   showEditButton={!this.props.prohibitModifyScore}
		               levelsCount={this.state.levels.length}/>;
	}

	private renderBody(bodyRenderer: BodyRenderer): ReactNode {
		return <Droppable droppableId='criteria' direction='vertical' ignoreContainerClipping>
			{(provided, snapshot) => {
				return <tbody ref={provided.innerRef} {...provided.droppableProps} className={styles.body}>
				{bodyRenderer()}
				{snapshot.isDraggingOver && provided.placeholder}
				{!this.props.prohibitModifyScore &&
					<AddCriteriaRow className={join(this.props.options.criteriaOptions?.elementClassName)}
					                onClicked={() => this.addRow()}
					                show={this.props.options.criteriaOptions?.editMode && this.state.criteria.length < 9}
					                columnsCount={this.state.levels.length}/>}
				</tbody>;
			}}
		</Droppable>;
	}

	private rowRenderer = (rowData: RowData<Criteria, Description>): ReactNode => {
		return <RowBody dragContainer={this.osInstance?.elements().viewport}
		                canDrag={this.props.options?.criteriaOptions?.editMode}
		                className={join(
			                this.state.rowToAdd === rowData.row.id && rowAnimationStyles.appearAnimationRow,
			                this.state.rowToDelete === rowData.row.id && rowAnimationStyles.removeAnimationRow,
		                )}
		                key={rowData.row.id}
		                id={rowData.row.id}
		                order={rowData.row.order}>
			{(dragHandleProps: DraggableProvidedDragHandleProps) => {
				return <>
					{this.renderCriteria(rowData.row, dragHandleProps)}
					{rowData.cells.map(d => this.renderDescription(d))}
				</>;
			}}
		</RowBody>;
	};

	private renderCriteria(criteria: Criteria, dragHandlerOptions?: DraggableProvidedDragHandleProps): ReactNode {
		let placeholder = '';
		let helpInfo = '';

		const index = criteria.order;
		if (index === 1) {
			placeholder = 'i.e. "Stays on Track"';

			if(this.props.prohibitModifyScore) {
				helpInfo = 'Criteria cannot be added or removed when test sessions exist.';
			}
		}

		if (index === 2) {
			placeholder = 'i.e. "Tries to Understand"';
		}

		return <th key={criteria.id}>
			<CriteriaActiveView ref={(ref) => this.onCriteriaRendered(criteria.id, ref)}
			                    className={this.props.options.criteriaOptions?.elementClassName}
			                    criteria={criteria}
			                    placeholder={placeholder}
			                    hasRelatedData={this.state.descriptions.filter(d => d.criteriaID === criteria.id).map(d => d.description?.trim()).some(d => !!d)}
			                    editMode={this.props.options.criteriaOptions?.editMode}
			                    canRemove={!this.props.prohibitModifyScore && this.state.criteria.length > 2}
			                    dragProps={dragHandlerOptions}
			                    tooltipContainer={this.tooltipContainer}
			                    onRemoveClicked={() => this.removeRow(criteria)}
			                    helpInfo={helpInfo}
			                    onFocused={(element) => isIOS() && scrollToElement(element, this.osInstance, TableTopOffset, TableLeftOffset)}
			                    onChanged={v => this.props.rubricService.updateCriteria(criteria, {name: v})}/>
		</th>;
	}

	private renderLevel(level: Level): ReactNode {
		return <th key={level.id}>
			<LevelActiveView ref={(ref) => this.onLevelRendered(level.id, ref)}
			                 className={this.props.options.levelsOptions?.elementClassName}
			                 level={level}
			                 isActive={!this.props.options.levelsOptions?.hide}
			                 isEditMode={this.props.options.levelsOptions?.editMode}
			                 canRemove={!this.props.prohibitModifyScore && this.state.levels.length > 2}
			                 displayMode={this.state.levelDisplayMode}
			                 highest={this.state.levels.findIndex(l => l.id === level.id) === 0}
			                 tooltipContainer={this.tooltipContainer}
			                 hasRelatedData={this.state.descriptions.filter(d => d.levelID === level.id).map(d => d.description?.trim()).some(d => !!d)}
			                 removeClicked={() => this.removeColumn(level)}
			                 onChange={(v) => this.props.rubricService.updateLevel(level, {name: v})}/>
		</th>;
	}

	private renderDescription(description: Description): ReactNode {
		const criteria = this.state.criteria.find(l => l.id === description.criteriaID);
		const level = this.state.levels.find(l => l.id === description.levelID);

		const criteriaName = `Criteria ${criteria?.order}`;
		let levelName = `Level ${level.score}`;
		if (this.state.levelDisplayMode === LevelDisplayMode.Text) {
			levelName = level.name;
		}

		return <td key={description.id}>
			<DescriptionActiveView ref={(ref) => this.onDescriptionsRendered(description.id, ref)}
			                       className={this.props.options.descriptionsOptions?.elementClassName}
			                       isActive={!this.props.options.descriptionsOptions?.hide}
			                       isEditMode={this.props.options.descriptionsOptions?.editMode}
			                       levelName={levelName}
			                       description={description}
			                       criteriaName={criteriaName}
			                       tooltipContainer={this.tooltipContainer}
			                       onDetailsChanged={(v) => this.props.rubricService.updateDescriptions(description, {details: v})}
			                       onNameChanged={(v) => this.props.rubricService.updateDescriptions(description, {description: v})}/>
		</td>;
	}

	//#region Animations handlers

	private getRowItemsRefs = (criteriaID: number) => {
		const descriptionIDs = this.state.descriptions.filter(d => d.criteriaID === criteriaID).map(d => d.id);

		const criteriaRef = this.criteriaToRef.get(criteriaID);
		const descriptionRefs = [];
		for (const descriptionID of descriptionIDs) {
			descriptionRefs.push(this.descriptionsToRef.get(descriptionID));
		}
		return {criteriaRef, descriptionRefs};
	};

	private getColumnItemsRefs = (levelID: number) => {
		const descriptionIDs = this.state.descriptions.filter(d => d.levelID === levelID).map(d => d.id);

		const levelRef = this.levelsToRef.get(levelID);
		const descriptionRefs = [];
		for (const descriptionID of descriptionIDs) {
			descriptionRefs.push(this.descriptionsToRef.get(descriptionID));
		}
		return {levelRef, descriptionRefs};
	};

	private addRow() {
		const criteriaID = this.props.rubricService.addCriteria().id;
		const appearAnimation = rowAnimationStyles.appearAnimationCell;

		this.addRowAnimationFrame = new AnimationTimer()
			.setDuration(200)
			.onStart(() => {
				const {criteriaRef, descriptionRefs} = this.getRowItemsRefs(criteriaID);

				criteriaRef.boxRef.current.classList.add(appearAnimation);
				descriptionRefs.forEach(d => d.boxRef.current.classList.add(appearAnimation));
			})
			.onEnd(() => {
				const {criteriaRef, descriptionRefs} = this.getRowItemsRefs(criteriaID);

				criteriaRef.boxRef.current.classList.remove(appearAnimation);
				descriptionRefs.forEach(d => d.boxRef.current.classList.remove(appearAnimation));

				this.setState({rowToAdd: 0}, () => scrollYToBottom(this.osInstance));
				this.addRowAnimationFrame = null;
			});
		this.setState({rowToAdd: criteriaID}); //Needs to start animation after React complete render new row.
	}

	private removeRow(criteria: Criteria) {
		const criteriaID = criteria.id;
		const disappearAnimation = rowAnimationStyles.removeAnimationCell;

		this.removeRowAnimationFrame = new AnimationTimer()
			.setDuration(200)
			.onStart(() => {
				const {criteriaRef, descriptionRefs} = this.getRowItemsRefs(criteriaID);

				criteriaRef.boxRef.current.classList.add(disappearAnimation);
				descriptionRefs.forEach(d => d.boxRef.current.classList.add(disappearAnimation));
			})
			.onEnd(() => {
				this.props.rubricService.removeCriteria(criteria);
				this.removeRowAnimationFrame = null;
				this.setState({rowToDelete: 0});
			});
		this.setState({rowToDelete: criteriaID});
	}

	private addColumn() {
		if(this.state.levels.length >= 9) {
			return;
		}
		const level = this.props.rubricService.addLevel();
		const appearAnimation = columnAnimationStyles.appearAnimationCell;
		this.addColumnAnimationFrame = new AnimationTimer()
			.setDuration(200)
			.onStart(() => {
				const {levelRef, descriptionRefs} = this.getColumnItemsRefs(level.id);

				levelRef.boxRef.current.classList.add(appearAnimation);
				descriptionRefs.forEach(d => d.boxRef.current.classList.add(appearAnimation));
			})
			.onEnd(() => {
				const {levelRef, descriptionRefs} = this.getColumnItemsRefs(level.id);

				levelRef.boxRef.current.classList.remove(appearAnimation);
				descriptionRefs.forEach(d => d.boxRef.current.classList.remove(appearAnimation));

				this.addColumnAnimationFrame = null;
				this.setState({columnToAdd: 0});
			});
		this.setState({columnToAdd: level.id});

	}

	private addZeroLevel(){
		const level = this.props.rubricService.addZeroLevel();
		const appearAnimation = columnAnimationStyles.appearAnimationCell;
		this.addColumnAnimationFrame = new AnimationTimer()
			.setDuration(200)
			.onStart(() => {
				const {levelRef, descriptionRefs} = this.getColumnItemsRefs(level.id);

				levelRef.boxRef.current.classList.add(appearAnimation);
				descriptionRefs.forEach(d => d.boxRef.current.classList.add(appearAnimation));
			})
			.onEnd(() => {
				const {levelRef, descriptionRefs} = this.getColumnItemsRefs(level.id);

				levelRef.boxRef.current.classList.remove(appearAnimation);
				descriptionRefs.forEach(d => d.boxRef.current.classList.remove(appearAnimation));

				this.addColumnAnimationFrame = null;
				this.setState({columnToAdd: 0});
				if (this.nextButtonRef.current) {
					this.nextButtonRef.current.props.onClick();
				}
			});
		this.setState({columnToAdd: level.id});
	}

	private removeColumn(level: Level) {
		const removeAnimationCell = columnAnimationStyles.removeAnimationCell;

		this.removeColumnAnimationFrame = new AnimationTimer()
			.setDuration(200)
			.onStart(() => {
				const {levelRef, descriptionRefs} = this.getColumnItemsRefs(level.id);

				levelRef.boxRef.current.classList.add(removeAnimationCell);
				descriptionRefs.forEach(d => d.boxRef.current.classList.add(removeAnimationCell));
			})
			.onEnd(() => {
				this.addColumnAnimationFrame = null;
				this.props.rubricService.removeLevel(level);
				this.setState({columnToDelete: 0});
			});
		this.setState({columnToDelete: level.id});

	}
	//#endregion Animations
}
