import * as React from 'react';
import type {SVGProps} from 'react';

export function Users(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M12.4 8.889c-1.988 0-3.6 1.592-3.6 3.555C8.8 14.408 10.412 16 12.4 16c.746 0 1.454-.225 2.053-.64a.897.897 0 0 0 .225-1.249c-.285-.403-.83-.475-1.237-.195-.3.208-.666.306-1.041.306-.994 0-1.8-.795-1.8-1.778 0-.982.806-1.777 1.8-1.777.294 0 .562.064.816.194.44.227 1.008.074 1.237-.361a.914.914 0 0 0-.394-1.222 3.614 3.614 0 0 0-1.659-.39Zm-2.644 8.306C8.106 17.868 7 19.516 7 21.333v1.778c0 .49.403.889.9.889h3.6c.497 0 .9-.398.9-.889a.895.895 0 0 0-.9-.889H8.8v-.889c0-.999.6-1.916 1.29-2.32.648.27 1.335.475 1.945.526a.911.911 0 0 0 .984-.817.87.87 0 0 0-.811-.945c-.396-.033-.827-.15-1.327-.36-.12-.05-.19-.09-.422-.195a.877.877 0 0 0-.703-.027ZM19.15 8c-2.237 0-4.05 1.791-4.05 4s1.813 4 4.05 4 4.05-1.791 4.05-4-1.813-4-4.05-4Zm0 1.778c1.243 0 2.25.994 2.25 2.222s-1.007 2.222-2.25 2.222S16.9 13.228 16.9 12s1.007-2.222 2.25-2.222Zm-3.094 7.417c-1.65.673-2.756 2.321-2.756 4.138v1.778c0 .49.403.889.9.889h9.9c.497 0 .9-.398.9-.889v-1.778c0-1.774-1.112-3.383-2.756-4.083a.928.928 0 0 0-.732 0c-.796.353-1.572.528-2.362.528-.788 0-1.566-.185-2.39-.556a.877.877 0 0 0-.704-.027Zm.346 1.818c.85.319 1.876.543 2.748.543.865 0 1.848-.21 2.681-.513.813.457 1.369 1.312 1.369 2.29v.89h-8.1v-.89c0-1.035.59-1.908 1.302-2.32Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
