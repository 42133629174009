import {Navigate, Route} from 'react-router-dom';
import {lazyComponent} from '@esgi/core/react';

import {withErrorHandling} from '@esgillc/ui-kit/core';

import {
	ReportsEmptyBody,
	Root,
	ReportsLayout,
	ParentConferencer,
	StudentManager,
	TestExplorer,
	Home,
	Assignment,
	AssignmentsLayout,
	AssignmentsList,
	ManageAssignment,
	Bingo,
	RubricReport,
	TotalReport,
	StudentTestDashboard,
	ClassGroupTestDashboard,
	ItemsAnalysisReport,
	ParentLetter,
	Flashcards,
	SightWords,
	PieCharts,
	StudentProgressReport,
	StudentDetail,
	ClassGrades,
	UntestedStudents,
	ActivitiesLayout,
	ActivitiesEmptyBody,
	WritingPractice,
	NotFound,
} from '@esgi/main/pages/teacher';
import {routes} from '@esgi/main/libs/core';

const TestDashboardLayout = withErrorHandling(
	lazyComponent(() => import('../../../features/teacher/test-dashboard/src/layout')),
);

const WrappedActivitiesLayout = withErrorHandling(ActivitiesLayout);
const WrappedActivitiesEmptyBody = withErrorHandling(ActivitiesEmptyBody);

const WrappedReportsLayout = withErrorHandling(ReportsLayout);
const WrappedReportsEmptyBody = withErrorHandling(ReportsEmptyBody);

export function RedesignRoutes() {
	return (
		<Route element={<Root/>}>
			<Route index element={<Navigate to={routes.teacher.home.root} />} />
			<Route path={routes.teacher.home.root} element={<Home/>} />
			<Route path={routes.teacher.home.testDashboard} element={<TestDashboardLayout />}>
				<Route
					path={routes.teacher.home.testDashboardStudent}
					element={<StudentTestDashboard/>}
				/>
				<Route
					path={routes.teacher.home.testDashboardClass}
					element={<ClassGroupTestDashboard/>}
				/>
				<Route
					path={routes.teacher.home.testDashboardGroup}
					element={<ClassGroupTestDashboard/>}
				/>
			</Route>
			<Route path={routes.teacher.testExplorer} element={<TestExplorer/>} />
			<Route path={routes.teacher.studentManager} element={<StudentManager />} />
			<Route path={routes.teacher.parentConferencer} element={<ParentConferencer />} />
			<Route path={routes.teacher.activities.root} element={<WrappedActivitiesLayout />}>
				<Route index element={<WrappedActivitiesEmptyBody />} />
				<Route path={routes.teacher.activities.bingo} element={<Bingo/>} />
				<Route path={routes.teacher.activities.flashcards} element={<Flashcards/>} />
				<Route path={routes.teacher.activities.writingPractice} element={<WritingPractice/>} />
				<Route path={routes.teacher.activities.sightWords} element={<SightWords/>} />
			</Route>
			<Route path={routes.teacher.reports.root} element={<WrappedReportsLayout />}>
				<Route index element={<WrappedReportsEmptyBody />} />
				<Route path={routes.teacher.reports.parentLetter} element={<ParentLetter/>} />
				<Route path={routes.teacher.reports.pieCharts} element={<PieCharts/>} />
				<Route path={routes.teacher.reports.studentProgress} element={<StudentProgressReport/>} />
				<Route path={routes.teacher.reports.studentDetail} element={<StudentDetail/>} />
				<Route path={routes.teacher.reports.classGrades} element={<ClassGrades/>} />
				<Route path={routes.teacher.reports.classTotals} element={<TotalReport/>} />
				<Route path={routes.teacher.reports.itemAnalysis} element={<ItemsAnalysisReport/>} />
				<Route path={routes.teacher.reports.rubric} element={<RubricReport/>} />
				<Route path={routes.teacher.reports.untestedStudents} element={<UntestedStudents/>} />
			</Route>
			<Route path={routes.teacher.assignments.root} element={<AssignmentsLayout/>}>
				<Route path={routes.teacher.assignments.assignmentsList} element={<AssignmentsList/>} />
				<Route path={routes.teacher.assignments.assignment} element={<Assignment/>} />
				<Route path={routes.teacher.assignments.assignmentEdit} element={<ManageAssignment />} />
				<Route path={routes.teacher.assignments.assignmentAdd} element={<ManageAssignment />} />
			</Route>
			<Route path='*' element={<NotFound/>} />
		</Route>
	);
}

export function NotFoundRoute() {
	return <NotFound/>;
}
