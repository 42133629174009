import {PanelContent} from '../../components/panels.styled';
import {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {DisabledInput} from '../../components/disabled-input';
import {ContentBox} from '../../components/content-box.styled';
import {Input} from '@esgi/ui/controls';
import {Edit} from '@esgi/ui/icons';
import {ObservableBuilder} from '@esgi/api';
import {
	BaseResponse,
	SchoolModel,
	SchoolsByQueryParamsResponse,
	UserCountry, UserModel,
	UserState,
} from '../../../../types';
import {Drawer} from '@esgi/main/kits/common';
import {Observable, Subject, debounceTime} from 'rxjs';
import {LinkedToDistrictInfo} from '../../components/linked-to-district-info';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ManageAccount} from '@esgi/main/features/user-profile';

type Props = {
	districtName: string;
	isLinkedToDistrict: boolean;
	initialSchoolName: string;
	onSchoolNameSave: (schoolName: string) => Observable<BaseResponse>;
	getSchoolsByQueryParams(schoolName: string): ObservableBuilder<SchoolsByQueryParamsResponse>;
	countries: UserCountry[] | null;
	states: UserState[] | null;
	user: UserModel
};

interface DropdownItem {
	id: number,
	value: string
}

interface TempSaveData {
	districtName: string;
	schoolName: string
	selectedCountry: DropdownItem,
	selectedState: DropdownItem,
	countries: DropdownItem[],
	states: DropdownItem[]
}

export function SchoolAndDistrictContent({
	districtName: initialDistrictName,
	isLinkedToDistrict,
	initialSchoolName,
	onSchoolNameSave,
	getSchoolsByQueryParams,
	countries,
	states,
	user,
}: Props) {
	const [schoolName, setSchoolName] = useState(initialSchoolName);
	const [districtName, setDistrictName] = useState(initialDistrictName);
	const [schoolList, setSchoolList] = useState<SchoolModel[]>([]);
	const [isManageAccountAlertOpen, setManageAccountAlertOpen] = useState(false);
	const [inputFocused, setInputFocused] = useState(false);

	const {current: searchInputSchool$} = useRef(new Subject<string>());

	useEffect(() => {
		const subscription = searchInputSchool$.pipe(debounceTime(300)).subscribe((value) => {
			getSchoolsByQueryParams(value).subscribe({
				next: ({items}) => {
					setSchoolList(items);
				},
			});
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const handleSchoolNameChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			setSchoolName(value);
			searchInputSchool$.next(value);
		},
		[searchInputSchool$],
	);

	const handleInputFocusChanged = useCallback(() => {
		setInputFocused((value) => !value);
	}, []);

	const handleSave = () => {
		onSchoolNameSave(schoolName).subscribe();
	};
	//Parse data to fit legacy modal
	const parsedStates = states?.map(state => {
		return {
			id: state.stateID,
			value: state.name,
		};
	});
	const parsedCountries = countries?.map(country => {
		return {
			id: country.countryID,
			value: country.name,
		};
	});

	const selectedCountry = parsedCountries.find(country => country.id === user.countryID) || parsedCountries[0];
	const selectedState = parsedStates.find(state => state.id === user.stateID) || parsedStates[0];

	const onLegacyModalSchoolSaved = (data: TempSaveData) => {
		setSchoolName(data.schoolName);
		setDistrictName(data.districtName);
	};

	const handleLegacyModalOpen = () => {
		if (user.agreementLevelCode === 'T') {
			setManageAccountAlertOpen(true);
		}
	};

	return (
		<>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled
			>
				<PanelHeaderTitle title='School & District' />
			</Drawer.PanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox gap='60'>
						<Drawer.ContentBlock title='District'>
							<DisabledInput value={districtName} placeholder='District' dataCy='district-disabled-input' />
							<LinkedToDistrictInfo isLinkedToDistrict={isLinkedToDistrict} />
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='School'>
							{user.agreementLevelCode === 'T'
								? < Input.Iconable
									dataCy='school-input'
									value={schoolName}
									placeholder='School'
									focused={inputFocused}
									onFocus={handleInputFocusChanged}
									onBlur={handleInputFocusChanged}
									onClick={handleLegacyModalOpen}
									onIconClick={handleLegacyModalOpen}
								>
									<Edit/>
								</Input.Iconable>
								: <DisabledInput value={schoolName} placeholder='School' dataCy='school-input'/>
							}
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>

				{isManageAccountAlertOpen && <ManageAccount
					schoolName={schoolName}
					districtName={districtName}
					onClose={() => setManageAccountAlertOpen(false)}
					onSaved={onLegacyModalSchoolSaved}
					defaultStates={parsedStates}
					defaultCountries={parsedCountries}
					selectedState={selectedState}
					selectedCountry={selectedCountry}
				/>}
			</OverlayScrollbarsComponent>
		</>
	);
}
