import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupContent, ToggleGroupItem, ToggleGroupTitle} from './index.styled';
import {Cheap} from '@esgi/ui';
import {TabItem} from './types';

export {type TabItem} from './types';

type Props<TabId extends string = string> = {
	activeTab: TabId;
	setActiveTab: (tabId: TabId) => void;
	tabItems: TabItem<TabId>[];
	dataCy?: string,
	showTitle?: boolean,
};

export function Tabs<TabId extends string = string>({activeTab, setActiveTab, tabItems, showTitle = true, dataCy = 'section-tabs-controller'}: Props<TabId>) {
	if (!tabItems.length) {
		return null;
	}

	return (
		<ToggleGroup.Root onValueChange={setActiveTab} value={activeTab} dataCy={dataCy}>
			<ToggleGroupContent dataCy={`${dataCy}-content`}>
				{tabItems.map(({value, valueDescription, label, applyDisabledStyles, permitted, dataCy, ...restArgs}) => {
					let itemContent: React.JSX.Element = (
						<Text size='medium' bold data-cy={dataCy}>
							{label}
						</Text>
					);

					const {contentType} = restArgs;

					const isCheapContent = contentType === 'cheap';

					if (isCheapContent) {
						const {color} = restArgs;

						itemContent = <Cheap color={color}>{itemContent}</Cheap>;
					}

					return (
						<ToggleGroupItem
							value={value}
							valueDescription={valueDescription}
							key={value}
							disabled={!permitted}
							applyDisabledStyles={applyDisabledStyles}
							isCheapContent={isCheapContent}
							dataCy={dataCy}
						>
							{itemContent}
						</ToggleGroupItem>
					);
				})}
			</ToggleGroupContent>
			{showTitle && <ToggleGroupTitle dataCy={`${dataCy}-title`}>
				{({description}) => (
					<Text size='small' font='mono' data-cy={`${dataCy}-description`}>
						{description}
					</Text>
				)}
			</ToggleGroupTitle>}
		</ToggleGroup.Root>
	);
}
