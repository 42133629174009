import {IClassModel, IGroupModel, ISpecialistGroupModel, StudentModel, ITeacherModel} from '../../api';
import {HierarchyMode} from '../enums';
import {ClassicHierarchySnapshot} from '../snapshot/classic-hierarchy-snapshot';
import {HierarchySnapshot} from '../snapshot/hierarchy-snapshot';
import {SpecialistHierarchySnapshot} from '../snapshot/specialist-hierarchy-snapshot';
import {ClassicHierarchyInstance} from './classic-hierarchy-instance';
import {SpecialistHierarchyInstance} from './specialist-hierarchy-instance';
import {StudentSort} from '@esgi/core/enums';
import {PreAssessHierarchySnapshot} from 'modules/hierarchy/core/models/snapshot/preassess-hierarchy-snapshot';
import {PreAssessHierarchyInstance} from 'modules/hierarchy/core/models/instance/preassess-hierarchy-instance';

export class HierarchyInstance {
	public districtID: number;
	public mode: HierarchyMode;
	public readonly apply = {
		classID: (classID: number) => {
			if (this.classic.classID !== classID) {
				this.classic.classID = classID;
				this.classic.groupID = 0;
				this.classic.studentID = 0;
			}
			this.snapshot = this.extract();
		},
		groupID: (groupID: number) => {
			if (this.mode === HierarchyMode.Classic) {
				if (this.classic.groupID !== groupID) {
					this.classic.groupID = groupID;
					this.classic.studentID = 0;
				}

			}
      if (this.mode === HierarchyMode.Specialist) {
				if (this.specialist.groupID !== groupID) {
					this.specialist.groupID = groupID;
					this.specialist.studentID = 0;
				}
			}
      if (this.mode === HierarchyMode.PreAssess) {
        if (this.preAssess.groupID !== groupID) {
          this.preAssess.groupID = groupID;
          this.preAssess.studentID = 0;
        }
      }
			this.snapshot = this.extract();
		},
    groupOfSpecialistsID: (groupOfSpecialistsID: number) => {
        if (this.specialist.groupOfSpecialistsID !== groupOfSpecialistsID) {
          this.specialist.groupOfSpecialistsID = groupOfSpecialistsID;
      }
      this.snapshot = this.extract();
    },
    schoolsGroupID: (schoolsGroupID: number) => {
      if (this.mode === HierarchyMode.Classic) {
        if (this.classic.schoolsGroupID !== schoolsGroupID) {
          this.classic.schoolsGroupID = schoolsGroupID;
        }
      }
      this.snapshot = this.extract();
    },
    teachersGroupID: (teachersGroupID: number) => {
      if (this.mode === HierarchyMode.Classic) {
        if (this.classic.teachersGroupID !== teachersGroupID) {
          this.classic.teachersGroupID = teachersGroupID;
        }
      }
      this.snapshot = this.extract();
    },
		specialistGroupID: (groupID) => {
			this.specialist.groupID = groupID;
			this.snapshot = this.extract();
		},
		studentID: (studentID: number) => {
			if (this.mode === HierarchyMode.Classic) {
				this.classic.studentID = studentID;
			}
      if (this.mode === HierarchyMode.Specialist) {
				this.specialist.studentID = studentID;
			}
      if (this.mode === HierarchyMode.PreAssess) {
        this.preAssess.studentID = studentID;
      }
			this.snapshot = this.extract();
		},

	};
	public classic: ClassicHierarchyInstance;
	public specialist: SpecialistHierarchyInstance;
  public preAssess: PreAssessHierarchyInstance;

	//for compatibility
	public students: StudentModel[] = [];
	public teachers: ITeacherModel[] = [];
	public specialistGroups: ISpecialistGroupModel[] = [];
	public classes: IClassModel[] = [];
	public groups: IGroupModel[] = [];
	public title: string;
	public trackID: number;
	public studentSort: StudentSort;

	public static build(
    mode: HierarchyMode,
    classic: ClassicHierarchySnapshot,
    specialist: SpecialistHierarchySnapshot,
    preAssess: PreAssessHierarchySnapshot): HierarchyInstance {
		const m = new HierarchyInstance();
		m.mode = mode;
		m.classic = ClassicHierarchyInstance.build(classic);
		m.specialist = SpecialistHierarchyInstance.build(specialist);
    m.preAssess = PreAssessHierarchyInstance.build(preAssess);
		m.districtID = m.classic.districtID || m.specialist.districtID;
		m.snapshot = m.extract();
		return m;
	}

	public copy(): HierarchyInstance {
		const m = new HierarchyInstance();
		m.districtID = this.districtID;
		m.mode = this.mode;
		m.specialist = this.specialist.copy();
		m.classic = this.classic.copy();
    m.preAssess = this.preAssess.copy();
		m.snapshot = m.extract();
		m.studentSort = this.studentSort;

		return m;

	}

	public snapshot: HierarchySnapshot;

	protected extract(): HierarchySnapshot {
		return {
			districtID: this.districtID,
			mode: this.mode,
			classic: this.classic.extract(),
			specialist: this.specialist.extract(),
			studentSort: this.studentSort,
			preAssess: this.preAssess.extract(),
		};
	}

	public equal(outer: HierarchyInstance): boolean {
		if (!outer) {
			return false;
		}

		if (this.mode !== outer.mode) {
			return false;
		}
		if (!this.classic.equal(outer.classic)) {
			return false;
		}

		if (!this.specialist.equal(outer.specialist)) {
			return false;
		}

    if (!this.preAssess.equal(outer.preAssess)) {
      return false;
    }

		return true;
	}
}
