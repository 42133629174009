import {BehaviorSubject, concatMap, of, switchMap} from 'rxjs';
import {Class, Group, storage, Student} from '@esgi/main/libs/store';
import {isNull} from 'underscore';
import {BaseService} from '@esgi/core/service';
import {StudentSort, userStorage} from '@esgi/core/authentication';
import {updateGroupsOrderInClass} from '../types';
import {V2TeachersClassesController, V2TeachersGroupsController} from '@esgi/contracts/esgi';
import {convertPrimitiveArrayToDictionary} from '../utils';
import {AddStudent} from '@esgi/main/features/teacher/drawers';

export class DataStudentsService extends BaseService {
	public isLoadedData$ = new BehaviorSubject({classes: false, groups: false, students: false});

	public classes$ = new BehaviorSubject<Class[]>([]);
	public groups$ = new BehaviorSubject<Group[]>([]);
	public studentBox$ = new BehaviorSubject<Student[]>([]);
	public currentUser = userStorage.get();
	public sortBy$ = new BehaviorSubject<StudentSort>(this.currentUser.studentSort);

	private classesController = new V2TeachersClassesController();
	private groupsController = new V2TeachersGroupsController();

	private classes = storage.classes();
	private groups = storage.groups();
	private students = storage.students();

	constructor() {
		super();
		this.classes.get().subscribe((v) => {
			this.isLoadedData$.next({...this.isLoadedData$.value, classes: true});
			this.classes$.next(v);
		});
		this.groups.get().subscribe((v) => {
			this.isLoadedData$.next({...this.isLoadedData$.value, groups: true});
			this.groups$.next(v);
		});
		this.students.get().subscribe((v) => {
			this.isLoadedData$.next({...this.isLoadedData$.value, students: true});
			this.studentBox$.next(v);
		});
	}

	public updateClassesOrder(classes: Class[]) {
		this.classes$.next(classes);
		const classIDs = classes.map(({id}) => id);

		return this.classesController.reorder({classIDs}).pipe(
			concatMap(() => {
				let iteratedItemIndex = -1;

				return this.classes.update((oldClass) => {
					iteratedItemIndex++;
					return {
						...oldClass,
						...classes[iteratedItemIndex],
					};
				});
			}),
		);
	}

	public updateGroupsOrderInClass: updateGroupsOrderInClass = ({classId, groups}) => {
		const currentGroupsValue = this.groups$.value;
		const reorderedGroupsIDs = groups.map(({id}) => id);

		if (isNull(classId) && currentGroupsValue) {
			return this.reorderGroupsRequest({newGroups: groups, reorderedGroupsIDs});
		}

		if (classId && currentGroupsValue) {
			let reorderedGroupsIndex = 0;

			const reorderedGroupsByClass = currentGroupsValue.map((iteratedGroup) =>
				iteratedGroup.classID === groups[reorderedGroupsIndex]?.classID
					? groups[reorderedGroupsIndex++]!
					: iteratedGroup,
			);

			return this.reorderGroupsRequest({newGroups: reorderedGroupsByClass, reorderedGroupsIDs});
		}

		return of();
	};

	public addNewClass(value: Class) {
		this.classes.add(value).subscribe();
	}

	public editClass(value: Class) {
		const old = this.classes$.value.find((g) => g.id === value.id);

		if (old) {
			this.classes.update(old, value).subscribe();
		}
	}

	public removeClass(classID: Class['id']) {
		this.classes
			.update((oldClass) => {
				if (oldClass.id === classID) {
					return;
				}

				return oldClass;
			})
			.subscribe();
	}

	public addNewGroup(value: Group) {
		this.groups.add(value).subscribe();
	}

	public editGroup(value: Group) {
		const old = this.groups$.value.find((g) => g.id === value.id);

		if (old) {
			this.groups.update(old, value).subscribe();
		}
	}

	public removeGroup(groupdID: Group['id']) {
		const currentGroups = this.groups$.value;
		const filteredGroups = currentGroups.filter(({id}) => id !== groupdID);

		this.groups
			.update((oldGroup) => {
				if (oldGroup.id === groupdID) {
					return;
				}

				return oldGroup;
			})
			.subscribe();

		return of(filteredGroups);
	}

	public addNewStudentsToClass({students, classId}: {students: Student[]; classId: Class['id']}) {
		const currentClasses = this.classes$.value;
		const currentStudentBox = this.studentBox$.value;

		if (currentStudentBox && currentClasses.some(({id}) => id === classId)) {
			const noneReppededStudents = students.filter(el=>!currentStudentBox.find(student=>student.id===el.id));
			if(noneReppededStudents.length){
				this.students.add(...noneReppededStudents).subscribe();
			}

			const newStudentsIds = students.map(({id}) => id);

			this.classes$.next(
				currentClasses.map((classItem) =>
					classItem.id === classId
						? {...classItem, studentIDs: [...classItem.studentIDs, ...newStudentsIds]}
						: classItem,
				),
			);
		}
	}

	public changeSortBy(sortBy: StudentSort) {
		this.sortBy$.next(sortBy);
	}

	public addStudent({student, classIds, groupIds}: AddStudent['studentsData']) {
		this.students
			.add(student)
			.pipe(
				switchMap(() =>
					this.classes.update((classModel) => {
						if (classIds.includes(classModel.id)) {
							classModel.studentIDs.push(student.id);
						}
						return classModel;
					}),
				),
				switchMap(() =>
					this.groups.update((group) => {
						if (groupIds.includes(group.id)) {
							group.studentIDs.push(student.id);
						}
						return group;
					}),
				),
			)
			.subscribe();
	}

	public editStudent({student, classIds, groupIds}: AddStudent['studentsData']) {
		const currentStudentBox = this.studentBox$.value;
		const currentStudent = currentStudentBox.find((studentItem) => studentItem.id === student.id);
		const classIDsLibrary = convertPrimitiveArrayToDictionary(classIds);
		const groupIDsLibrary = convertPrimitiveArrayToDictionary(groupIds);

		this.students
			.update(currentStudent, student)
			.pipe(
				switchMap(() =>
					this.classes.update((classModel) => {
						const studentIdsLibrary = convertPrimitiveArrayToDictionary(classModel.studentIDs);
						if (classIDsLibrary[classModel.id]) {
							if (!studentIdsLibrary[student.id]) {
								classModel.studentIDs.push(student.id);
							}
						} else {
							if (studentIdsLibrary[student.id]) {
								classModel.studentIDs = classModel.studentIDs.filter((item) => item !== student.id);
							}
						}
						return classModel;
					}),
				),
				switchMap(() =>
					this.groups.update((groupModel) => {
						const studentIdsLibrary = convertPrimitiveArrayToDictionary(groupModel.studentIDs);
						if (groupIDsLibrary[groupModel.id]) {
							if (!studentIdsLibrary[student.id]) {
								groupModel.studentIDs.push(student.id);
							}
						} else {
							if (studentIdsLibrary[student.id]) {
								groupModel.studentIDs = groupModel.studentIDs.filter((item) => item !== student.id);
							}
						}
						return groupModel;
					}),
				),
			)
			.subscribe();

		if (classIds.length === 0){
			this.removeStudentFromList(student.id);
		}
	}
	public removeStudent(studentId: Student['id']){
		const currentStudentBox = this.studentBox$.value;
		const filteredStudents = currentStudentBox.filter(({id}) => id !== studentId);
		this.removeStudentFromList(studentId);
		return of(filteredStudents);
	}

	private removeStudentFromList(studentID: number){
		this.students.update((oldStudent) => {
			if (oldStudent.id === studentID) {
				return;
			}
			return oldStudent;
		}).subscribe();
	}

	public override dispose() {
		super.dispose();
		this.classes.dispose();
		this.groups.dispose();
		this.students.dispose();
		this.groupsController.dispose();
		this.classesController.dispose();
	}

	private reorderGroupsRequest({
		newGroups,
		reorderedGroupsIDs,
	}: {
		newGroups: Group[];
		reorderedGroupsIDs: Group['id'][];
	}) {
		this.groups$.next(newGroups);

		return this.groupsController.reorder({groupIDs: reorderedGroupsIDs}).pipe(
			concatMap(() => {
				let iteratedItemIndex = -1;

				return this.groups.update((oldGroup) => {
					iteratedItemIndex++;
					return {
						...oldGroup,
						...newGroups[iteratedItemIndex],
					};
				});
			}),
		);
	}
}
