import {useCallback, useState} from 'react';
import {Drawer, DrawerMode, useDrawerMode, useDrawerParameters} from '@esgi/main/features/teacher/home';
import {EditClassFormParams, TeacherDrawerName} from '../types';
import {AddClass} from './components/add-class';
import {EditClass} from './components/edit-class';
import {Alert} from '@esgi/ui/alert';
import {NoChangesConfirmation} from './components/no-changes-confirmation';

export * from './events';

export function DrawerWithClassForm() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const drawerMode = useDrawerMode({drawerName: TeacherDrawerName.TeacherClass});

	const {classId, schoolYear} = useDrawerParameters<EditClassFormParams>(['classId', 'schoolYear']);

	const confirmationAlert = Alert.useRef();

	const openUnsavedChangesAlert = Alert.useOpen(confirmationAlert);

	const onBeforeCloseDrawerCb = useCallback(() => {
		if (isFormTouched) {
			openUnsavedChangesAlert();
		}

		return !isFormTouched;
	}, [isFormTouched, openUnsavedChangesAlert]);

	return (
		<Drawer
			drawerName={TeacherDrawerName.TeacherClass}
			skeleton={!isLoaded}
			beforeCloseDrawerCb={onBeforeCloseDrawerCb}
			dataCy='drawer-with-class'
		>
			{drawerMode === DrawerMode.Create && <AddClass onLoaded={setIsLoaded} onFormTouched={setIsFormTouched} />}
			{drawerMode === DrawerMode.Edit && classId && (
				<EditClass onLoaded={setIsLoaded} classID={Number(classId)} onFormTouched={setIsFormTouched} schoolYear={Number(schoolYear)}/>
			)}

			<NoChangesConfirmation confirmationAlert={confirmationAlert} />
		</Drawer>
	);
}
