import {useService} from '@esgi/core/service';
import {Layer} from '@esgillc/ui-kit/core';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useEffect, useState} from 'react';
import {AnswerState, SessionType} from '@esgi/core/enums';
import {TestSessionStatus} from '../kit/enums';
import {SessionStatusAlert} from '../kit/session-status-alert';
import {SessionStartedAlert} from './components/alerts/session-started-alert';
import {NoIncorrectItemsAlert} from './components/no-incorrect-items-alert';
import {TestSessionSelector} from './components/session-selector/session-selector';
import {YesNoFlow} from './components/yes-no-flow';
import {useTestingAlerts} from './hooks/use-testing-alerts';
import {TestingModel} from './models';
import {YNService} from './service';
import {AlertType, ScreenType} from './types';
import {getShuffleQuestion} from './utils';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';

type YnProps = {
	testID: number;
	classID: number;
	studentID: number;
	questionIds?: number[];
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClose: () => void;
	onTestHistoryClicked: () => void;
	onFlashCardsClicked: () => void;
	onTestSessionDetailsClicked: () => void;
}

export default function YesNoTesting({testID, studentID, ...props}: YnProps) {
	const {id: subjectId, type: subjectType} = props.subject;
	const [screenType, setScreenType] = useState<ScreenType>(ScreenType.None);
	const [shuffleQuestions, setShuffleQuestions] = useState(false);
	const [continueMode, setContinueMode] = useState(false);
	const [testSessionStatus, setTestSessionStatus] = useState<TestSessionStatus>(-1);
	const [resumeSession, setResumeSession] = useState(false);
	const [testingModel, setTestingModel] = useState<TestingModel>();
	const [sessionType, setSessionType] = useState<SessionType>();
	const [showNoIncorrectItemsAlert, setShowNoIncorrectItemsAlert] = useState(false);
	const [guid, setGuid] = useState<string>();

	const service = useService(YNService);

	const runSession = (sessionType: SessionType) => {
		setSessionType(sessionType);
		setScreenType(ScreenType.Flow);
	};

	useEffect(() => {
		service.init(testID, studentID, subjectId, subjectType, props.questionIds).subscribe({
			next: (model) => {
				setShuffleQuestions(getShuffleQuestion(model, subjectType));

				setTestingModel(model);
				setTestSessionStatus(model.testSessionStatus);
				setGuid(model.general.guid);
				if (model.progress.started) {
					return;
				}

				if (props.questionIds) {
					return runSession(SessionType.TestSubset);
				}

				if (model.availableSessionTypes.length > 1) {
					return setScreenType(ScreenType.SessionSelector);
				}

				if (model.resumeModel.resumeAvailable) {
					return setScreenType(ScreenType.SessionSelector);
				}

				if (model.availableSessionTypes.length === 1 && model.availableSessionTypes[0] === SessionType.TestIncorrect) {
					if (model.questions.every(q => q.oldAnswerState === AnswerState.Correct)) {
						return setShowNoIncorrectItemsAlert(true);
					}
				}

				runSession(model.availableSessionTypes[0]);
			},
			error: () => props.onClose(),
		});
	}, []);

	const continueSession = () => service.updateGuid().subscribe({
		next: ({testSessionStatus, testSessionGuid}) => {
			if (!testSessionStatus) {
				setContinueMode(true);
				runSession(testingModel.progress.sessionType);
				setTestSessionStatus(testSessionStatus);
			}
			setGuid(testSessionGuid);
		},
		error: () => props.onClose(),
	});

	const alertType = useTestingAlerts(testSessionStatus, testingModel);

	return <Layer>
		<Switch test={screenType}>
			<Case value={ScreenType.SessionSelector}>
				<TestSessionSelector
					onCancel={props.onClose}
					subjectType={subjectType}
					testingModel={testingModel}
					onStartTest={(sessionType, shuffleQuestions, resume) => {
						setShuffleQuestions(shuffleQuestions);
						setResumeSession(resume);
						runSession(sessionType);
					}
					}/>
			</Case>
			<Case value={ScreenType.Flow}>
				<YesNoFlow
					guid={guid}
					sessionType={sessionType}
					testingModel={testingModel}
					continueSession={continueMode}
					questionIds={props.questionIds}
					shuffleQuestions={shuffleQuestions}
					resumeSession={resumeSession}
					hierarchy={props.hierarchy}
					subject={props.subject}
					onFlashCardsClicked={props.onFlashCardsClicked}
					onTestHistoryClicked={props.onTestHistoryClicked}
					onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
					onSessionStatusChanged={setTestSessionStatus}
					onClose={props.onClose}
				/>
			</Case>
		</Switch>
		<Switch test={alertType}>
			<Case value={AlertType.TestSessionStatus}>
				<SessionStatusAlert
					status={testSessionStatus}
					testName={testingModel?.testName}
					studentName={testingModel?.studentName}
					onConfirm={props.onClose}/>
			</Case>
			<Case value={AlertType.SessionStarted}>
				<SessionStartedAlert
					studentName={testingModel?.studentName}
					testName={testingModel?.testName}
					onConfirm={continueSession}
					onCancel={props.onClose}/>
			</Case>
		</Switch>
		<ServiceLoader fullscreen trackingService={service}/>
		{showNoIncorrectItemsAlert && <NoIncorrectItemsAlert onClose={() => {
			setShowNoIncorrectItemsAlert(false);
			props.onClose();
		}}/>}
	</Layer>;
}
