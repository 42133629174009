export enum TestType {
	None = 'None',
	YN = 'YN',
	Rubric = 'Rubric',
	Score = 'Score',
}

export type Question = {
	id: number;
	directions: string;
	label: string;
};

export type RubricCriteria = {
	id: number;
	name: string;
	orderNum: number;
};

export type StateStandard = {
	standardID: number;
	stateID: number;
	name: string;
}

export type TestInfo = {
	creatorName: string;
	description: string;
	gradeLevels: number[];
	questions: Question[];
	name: string;
	rubricCriteria: RubricCriteria[];
	selfAssessVersion: boolean;
	stateStandards: StateStandard[];
	subjectName: string;
	testType: TestType;
	totalPossible: number;
	contentArea: string;
};

export enum GradeRangeType {
	Percent = 'Percent',
	Score = 'Score',
}

export type GradeRange = {
	id: number;
	markingPeriodNumber: number;
	from: number;
	to: number;
	gradeRangeType: GradeRangeType;
	gradeScaleEntry: {
		color: string;
		description: string;
		name: string;
		orderNumber: number;
	};
};

export enum AnswerState {
	Incorrect = 'Incorrect',
	Correct = 'Correct',
	NotTested = 'NotTested',
}

export enum SessionType {
	TestIncorrect = 'TestIncorrect',
	TestAll = 'TestAll',
	TestSubset = 'TestSubset',
}

export type Answer = {
	answerState: AnswerState;
	comment: string;
	questionID: number;
};

export type RubricAnswer = {
	criteriaID: number;
	notes: string;
	score: number;
};

export type TestSession = {
	answers: Answer[];
	correctAnswers: number;
	duration: number;
	id: number;
	isDeleted: boolean;
	notes: string;
	rubricAnswers: RubricAnswer[];
	sessionType: SessionType;
	testDate: string;
};

export type TrackDate = {
	dateFrom: string;
	dateTo: string;
};

export enum EntityType {
	Student = 'student',
	ClassGroup = 'class-group',
}

export enum TestDashboardSectionID {
	Sessions = 'sessions',
}

export type NavigationState = {
	initVisibleSection: TestDashboardSectionID | null;
};
