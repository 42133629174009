import {lazyComponent} from '@esgi/core/react';
import {Dispatch, Fragment, Suspense, useCallback, useState} from 'react';
import {TestSingleStudentModel} from '../../../../../../../types';
import {Class, Student, SubjectTab} from '@esgi/main/libs/store';
import {CardViewType, TestDetailsState, TestSectionSingleStudent} from '../../../../../types';
import {TestLauncherState, TestSavedEvent} from '@esgi/main/features/assessments';
import {isNull} from 'underscore';
import {dispatchAppEvent} from '@esgillc/events';
import {TestCard} from '../../../../test-cards';
import {adaptedTestTypeToContact} from './constants';
import {SubjectType} from '@esgi/core/enums';

const SelfAssessment = lazyComponent(() => import('modules/assessments/testing/self-assessment/root'));

type Props = TestSingleStudentModel & {
	onTestCardClicked: Dispatch<TestSingleStudentModel>;
	studentID: Student['id'];
	subject: SubjectTab;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	openTestDetails: Dispatch<TestDetailsState>;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	showSelfAssessOption: boolean;
	classID: Class['id'];
	statisticEntity: TestSectionSingleStudent['statisticEntity'];
	setTestLauncherState: Dispatch<TestLauncherState>;
	cardViewType: CardViewType;
	isSmallScreen: boolean;
};

export function SingleStudentCard({
	onTestCardClicked,
	studentID,
	subject,
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	onTestMoveTo,
	onRemoveTest,
	showSelfAssessOption,
	testInfo,
	analyticsData,
	studentResult,
	studentsResult,
	lastTestSessions,
	classID,
	statisticEntity,
	setTestLauncherState,
	cardViewType,
	isSmallScreen,
}: Props) {
	const [selfAssessTestingOpened, setSelfAssessTestingOpened] = useState(false);

	const onStartTestHandler = useCallback(() => {
		setTestLauncherState({
			isOpen: true,
			data: {
				contentArea: testInfo.contentArea,
				testType: testInfo.type,
				testID: testInfo.id,
				isIncorrectDisabled: studentResult?.correctAnswers === testInfo.totalPossible,
				studentResult,
				analyticsData: !isNull(analyticsData)
					? {
							...analyticsData,
							lastSessionDate: new Date(analyticsData.lastSessionDate),
					  }
					: null,
			},
		});
	}, [
		analyticsData,
		setTestLauncherState,
		studentResult,
		testInfo.contentArea,
		testInfo.id,
		testInfo.totalPossible,
		testInfo.type,
	]);

	const onStartSelfAssessTestHandler = useCallback(() => {
		setSelfAssessTestingOpened(true);
	}, []);

	const onEndSelfAssessTestHandler = () => {
		dispatchAppEvent(
			TestSavedEvent,
			new TestSavedEvent({
				testID: testInfo.id,
				studentID,
			}),
		);
		setSelfAssessTestingOpened(false);
	};

	const handleTestCardClicked = useCallback(() => {
		onTestCardClicked({
			analyticsData,
			lastTestSessions,
			studentResult,
			studentsResult,
			testInfo,
		});
	}, [analyticsData, lastTestSessions, onTestCardClicked, studentResult, studentsResult, testInfo]);

	const onOpenTestDetails = useCallback(() => {
		openTestDetails({testId: testInfo.id, testType: testInfo.type});
	}, [openTestDetails, testInfo.id, testInfo.type]);

	const renderTestCard = useCallback(() => {
		if (cardViewType === CardViewType.List) {
			return (
				<TestCard.ListCard.SingleStudentCard
					dataCy='single-student-test-card'
					testInfo={testInfo}
					analyticsData={analyticsData}
					studentResult={studentResult}
					lastTestSessions={lastTestSessions}
					subjectID={subject.id}
					onTestMoveTo={onTestMoveTo}
					onRemoveTest={onRemoveTest}
					withSelfAssess={showSelfAssessOption && testInfo.selfAssessmentTestAllowed}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemoveTest}
					classID={classID}
					onStartSelfAssessTestHandler={onStartSelfAssessTestHandler}
					onStartTestHandler={onStartTestHandler}
					onTestCardClicked={handleTestCardClicked}
					openTestDetails={onOpenTestDetails}
					statisticEntity={statisticEntity}
					studentsResult={studentsResult}
					isSmallScreen={isSmallScreen}
				/>
			);
		}

		if (cardViewType === CardViewType.Grid) {
			return (
				<TestCard.GridCard.SingleStudentCard.Default
					testInfo={testInfo}
					analyticsData={analyticsData}
					studentResult={studentResult}
					lastTestSessions={lastTestSessions}
					subjectID={subject.id}
					onTestMoveTo={onTestMoveTo}
					onRemoveTest={onRemoveTest}
					showSelfAssessOption={showSelfAssessOption}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemoveTest}
					classID={classID}
					onStartSelfAssessTestHandler={onStartSelfAssessTestHandler}
					onStartTestHandler={onStartTestHandler}
					onTestCardClicked={handleTestCardClicked}
					openTestDetails={onOpenTestDetails}
				/>
			);
		}

		throw new Error('cardViewType inconsistent with CardViewType enum');
	}, [
		analyticsData,
		cardViewType,
		classID,
		handleTestCardClicked,
		isSmallScreen,
		lastTestSessions,
		onOpenTestDetails,
		onRemoveTest,
		onStartSelfAssessTestHandler,
		onStartTestHandler,
		onTestMoveTo,
		showSelfAssessOption,
		statisticEntity,
		studentResult,
		studentsResult,
		subject.id,
		testInfo,
		userCanEditTest,
	]);

	return (
		<>
			{renderTestCard()}
			{selfAssessTestingOpened && (
				<Suspense fallback={<Fragment />}>
					<SelfAssessment
						studentID={studentID}
						testSessionEnded={onEndSelfAssessTestHandler}
						closed={() => setSelfAssessTestingOpened(false)}
						subject={{
							id: subject.id,
							name: subject.name,
							type: adaptedTestTypeToContact[subject.type],
							hidden: subject.hidden,
							level: subject.level,
							gradeLevels: subject.gradeLevels,
							testsCount: subject.tests.length,
							selected: false,
							published: false,
							canEdit: SubjectType[subject.type] === SubjectType.Personal,
						}}
						testID={testInfo.id}
					/>
				</Suspense>
			)}
		</>
	);
}
