import React, {ReactNode} from 'react';
import {ModalWindow} from '@esgi/deprecated/knockout';
import {StateStandardsLibrary} from 'shared/modules/state-standards-library';
import {userStorage} from '@esgi/core/authentication';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {DependElement, FormControl} from '@esgillc/ui-kit/form';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {join} from '@esgillc/ui-kit/utils';
import {Item} from '../models';
import styles from '../styles.module.less';

interface Props {
	readonly: boolean;
	control: FormControl<Item>
	contentAreaControl: FormControl<number[]>;
}

export class StateStandardField extends React.PureComponent<Props> {

	public render() {
		if (this.props.readonly && !this.props.control.value.id) {
			return <></>;
		}

		return <div className={styles.stateStandardField}>
			<div className={join(styles.fieldTitle, styles.hideTablet)}>
				State Standard:
			</div>
			<div className={join(styles.fieldTitle, styles.dTablet)}>
				Standard:
			</div>
			<DependElement control={this.props.control}>
				{state => this.renderContent(state.value)}
			</DependElement>
		</div>;
	}

	private renderContent(fieldValue: Item): ReactNode {
		return <div className={join(styles.fieldContent, styles.stateStandardContainer)}>
			{this.props.readonly ? this.renderText(fieldValue) : this.renderStateStandardButton(fieldValue)}
		</div>;
	}

	private renderText(fieldValue: Item): ReactNode {
		return <span>{fieldValue.name}</span>;
	}

	private renderStateStandardButton(fieldValue: Item): ReactNode {
		return <>
			<OnHoverTooltip message={fieldValue?.name}>
							<span className={styles.stateStandardText}>
							{fieldValue?.name || 'Add State Standard'}
						</span>
			</OnHoverTooltip>
			{!!fieldValue?.id && !this.props.readonly && <OnHoverTooltip message='Clear'>
				<div className={styles.clearIcon} onClick={() => this.clearStateStandard()}>
					<svg width='24' height='24' viewBox='0 0 48 48'>
						<g fill='none' fillRule='evenodd'>
							<rect width='48' height='48'/>
							<path stroke='#828282' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'
							      transform='matrix(-1 0 0 1 48 0)'/>
							<path stroke='#828282' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'/>
						</g>
					</svg>
				</div>
			</OnHoverTooltip>}
		</>;
	}


	private openStateStandard(): void {
		const currentUser = userStorage.get();

		const stateStandardsLibrary = new StateStandardsLibrary(
			{
				selectedStateStandardId: this.props.control.value?.id,
				selectedStateStandardText: this.props.control.value?.name,
				selectedContentAreaId: this.props.contentAreaControl.value[0],
				stateId: currentUser.stateID,
			},
		);

		let modal = new ModalWindow(stateStandardsLibrary,
			{
				allowClose: true,
				showHeader: true,
				showFooter: true,
				className: 'state-standard-modal',
				title: 'State Standards Library',
				buttons: [
					{
						title: 'Add',
						className: 'btn btn-primary btn-sm',
						submit: true,
						align: 'right',
						onClick: stateStandardsLibrary.view.okClicked,
						closeModal: false,

					},
					{
						title: 'Close',
						className: 'btn btn-sm ',
						submit: true,
						align: 'right',
						icon: 'fa fa-times',
						closeModal: true,
						cancel: true,
					},
				],
			});

		stateStandardsLibrary.events.onUpdate((event, changes) => {
			showSnackbarNotification(`State Standard has been added`);
			this.props.control.value = {
				name: changes.stateStandard,
				id: changes.stateStandardId,
			};
		});

		stateStandardsLibrary.events.onClosed(() => {
			modal.close();
		});

		modal.load();
	}

	private clearStateStandard() {
		this.props.control.value = {id: 0, name: ''};
	}
}
