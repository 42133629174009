import {useService} from '@esgi/core/service';
import {DataStateService} from '../services/data-state-service';
import {useBehaviorSubject1} from '../hot-fix-hooks';

export function useStateService() {
	const stateService = useService(DataStateService);

	const isFirstEntry = useBehaviorSubject1(stateService.isFirstEntry$);
	const isUpdatingState = useBehaviorSubject1(stateService.isUpdatingState$);
	const sectionsState = useBehaviorSubject1(stateService.sectionsState$);
	const selectedSubjectId = useBehaviorSubject1(stateService.selectedSubjectId$);
	const activeSubjectTab = useBehaviorSubject1(stateService.activeSubjectTab$);
	const selectedStudentId = useBehaviorSubject1(stateService.selectedStudentId$);
	const selectedClassId = useBehaviorSubject1(stateService.selectedClassId$);
	const selectedGroupId = useBehaviorSubject1(stateService.selectedGroupId$);
	const activeStudentTab = useBehaviorSubject1(stateService.activeStudentTab$);
	const isStateLoaded = useBehaviorSubject1(stateService.isLoadedData$);
	const isSubjectStateLoaded = useBehaviorSubject1(stateService.isInitedSubjectData$);
	const testSectionCardViewType = useBehaviorSubject1(stateService.testSectionCardViewType$);

	return {
		isFirstEntry,
		isUpdatingState,
		sectionsState,
		selectedSubjectId,
		activeSubjectTab,
		selectedStudentId,
		selectedClassId,
		selectedGroupId,
		activeStudentTab,
		stateService,
		isStateLoaded,
		isSubjectStateLoaded,
		testSectionCardViewType,
	};
}
