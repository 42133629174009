import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	titleText: string;
	dataCy?: string;
};

export function CardTitle({titleText, dataCy = 'card-title'}: Props) {
	return (
		<OneLinedText dataCy={dataCy} size='xxLarge' color='neutral16'>
			{titleText}
		</OneLinedText>
	);
}