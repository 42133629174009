import {
	Entities,
	StudentsPanel,
	SingleSelectState,
	StudentsPanelTabID,
} from '@esgi/main/features/teacher/students-panel';
import {ClassesList} from './components/classes-list';
import {GroupsList} from './components/groups-list';
import {Text} from '@esgi/ui/typography';
import {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {storage, useStore} from '@esgi/main/libs/store';
import {Panel} from './index.styled';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';

type Props = {
	withoutAllClass?: boolean;
	hideEmptyClassAndGroup?: boolean;
	selected?: SingleSelectState | undefined;
	onSelect?: (value: SingleSelectState) => void;
	onEntityChanged?: Dispatch<Entities>;
};

export function ClassLevelStudentsPanel({
	withoutAllClass = true,
	hideEmptyClassAndGroup = false,
	onSelect,
	onEntityChanged,
	selected: externalSelected,
}: Props) {
	const [isPanelInitializated, setIsPanelInitializated] = useState(false);

	const [selected, setSelected] = useState<SingleSelectState>(
		externalSelected ?? {
			classId: null,
			groupId: null,
			studentId: null,
			studentFromClassId: null,
			studentFromGroupId: null,
		},
	);
	const [activeTab, setActiveTab] = useState<StudentsPanelTabID>(StudentsPanelTabID.Classes);

	const [classList, updateClassList, isClassListLoaded] = useStore(storage.classes);
	const [groupList, updateGroupList, isGroupListLoaded] = useStore(storage.groups);
	const [studentList, updateStudentList, isStudentListLoaded] = useStore(storage.students);

	const entities = useMemo<Entities>(
		() => ({
			classes: classList.filter(({studentIDs}) => (hideEmptyClassAndGroup ? studentIDs.length : true)),
			groups: groupList.filter(({studentIDs}) => (hideEmptyClassAndGroup ? studentIDs.length : true)),
			students: studentList,
		}),
		[classList, groupList, studentList, hideEmptyClassAndGroup],
	);

	const onSetSelected = useCallback(
		(state: SingleSelectState) => {
			setSelected(state);
			onSelect?.(state);
		},
		[onSelect],
	);

	useEffect(() => {
		if (!isPanelInitializated && Object.values(selected).every(isNull) && isClassListLoaded && classList.length) {
			onSetSelected({
				classId: classList[0]!.id ?? null,
				groupId: null,
				studentId: null,
				studentFromClassId: null,
				studentFromGroupId: null,
			});

			setIsPanelInitializated(true);
		}
	}, [isPanelInitializated, isClassListLoaded, classList, selected, onSetSelected]);

	useEffect(() => {
		onEntityChanged?.(entities);
	}, [entities, onEntityChanged]);

	useEffect(() => {
		if (!isUndefined(externalSelected)) {
			setSelected(externalSelected);
		}
	}, [entities, activeTab, externalSelected]);

	const isPanelLoaded = isClassListLoaded && isGroupListLoaded && isStudentListLoaded;

	return (
		<Panel dataCy='student-panel'>
			<StudentsPanel.Root
				skeleton={!isPanelLoaded}
				entities={entities}
				activeTab={activeTab}
				onActiveTabChanged={setActiveTab}
			>
				<StudentsPanel.Selection.Single selected={selected} onSelectedChange={onSetSelected}>
					<StudentsPanel.Header.Title>
						<Text size='small' color='neutral56' data-cy='student-panel-title'>
							Select a Class or a Group
						</Text>
					</StudentsPanel.Header.Title>
					<StudentsPanel.Header.Tabs />
					<StudentsPanel.Tabs.Root>
						<StudentsPanel.Tabs.Classes sectionTitle='Classes'>
							<ClassesList withAllClassButton={!withoutAllClass} />
						</StudentsPanel.Tabs.Classes>
						<StudentsPanel.Tabs.Groups sectionTitle='Groups'>
							<GroupsList />
						</StudentsPanel.Tabs.Groups>
					</StudentsPanel.Tabs.Root>
				</StudentsPanel.Selection.Single>
			</StudentsPanel.Root>
		</Panel>
	);
}
