import {Reports} from '@esgi/main/kits/reports';
import {useMemo, useState} from 'react';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Page} from '../../layout/index.styled';
import {OldReportLauncherWrapper, useFakeChangesCollector} from '../old-report-launcher';
import {ErrorBoundary, lazyComponent} from '@esgi/core/react';
import {SubjectType} from '@esgi/main/libs/store';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';

const ParentLetterLauncher = lazyComponent(() => import('modules/reports/parent-letter/launcher'));

export function OldParentLetterReport() {
	const [singleSelectedState, setSingleSelectedState] = useState<SingleSelectState>();

	return <Page>
		<Reports.StudentLevel.SingleStudentPanel withoutAllClass onChange={(value) => {
			setSingleSelectedState(value);
		}}/>
		<OldReportLauncherWrapper singleState={singleSelectedState}>
			{(version, isOpen, close, hierarchySnapshot, subject, changesCollector) => {
				if(isOpen) {
					return <ParentLetterWrapper onClose={close} hierarchySnapshot={hierarchySnapshot} subjectID={subject.id}
					                            subjectType={subject.type}/>;
				}
			}}
		</OldReportLauncherWrapper>
	</Page>;
}

type Props = {
	subjectID: number,
	subjectType: SubjectType,

	hierarchySnapshot: HierarchySnapshot,

	onClose: VoidFunction,
}

function ParentLetterWrapper(props: Props) {
	const {
		hierarchySnapshot,
		subjectID,
		subjectType,
	} = props;

	const initModel = useMemo(() => {
		return {
			selectedGroupItemID: hierarchySnapshot.classic.groupID,
			selectedStudentID: hierarchySnapshot.classic.studentID,
			subjectID: subjectID,
			subjectType: subjectType,
			selectedTestID: 0,
		};
	}, [hierarchySnapshot, subjectID, subjectType]);

	const changesCollector = useFakeChangesCollector(hierarchySnapshot);

	return <ErrorBoundary>
		<ParentLetterLauncher changesCollector={changesCollector}
		                      hierarchy={hierarchySnapshot}
		                      initModel={initModel}
		                      onClosed={props.onClose}/>
	</ErrorBoundary>;
}