import {Text} from '@esgi/ui/typography';
import {Container, IndicatorBox} from './index.styled';
import {forwardRef, useMemo} from 'react';
import {ComponentProps, VariantProps} from '@stitches/react';
import {FlexBox} from '@esgi/ui/layout';
import {isNull} from 'underscore';

type Props = Omit<ComponentProps<typeof FlexBox>, 'children'> & {
	value: number | null;
	percentageType?: boolean;
	deltaTranscript?: string;
	lightZeroValue?: boolean;
	decimals?: number;
};

type ValueItem = {
	formattedValue: string;
	valueColorVariant: NonNullable<VariantProps<typeof IndicatorBox>['variant']>;
	deltaTranscriptColor: NonNullable<VariantProps<typeof Text>['color']>;
};

export const TestSessionDelta = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'test-dashboard-test-session-delta',
			css = {},
			value,
			percentageType = false,
			deltaTranscript,
			lightZeroValue,
			decimals = 1,
			...props
		},
		forwardedRef,
	) => {
		const {formattedValue, valueColorVariant, deltaTranscriptColor} = useMemo<ValueItem>(() => {
			if (isNull(value)) {
				return {
					formattedValue: '-',
					valueColorVariant: 'lightZeroValue',
					deltaTranscriptColor: 'lowContrast',
				};
			}

			if (value > 0) {
				const formattedValue = percentageType ? Math.round(value) : value.toFixed(decimals);

				return {
					formattedValue: `+${formattedValue}`,
					valueColorVariant: 'success',
					deltaTranscriptColor: 'positive',
				};
			}

			if (value < 0) {
				const absValue = Math.abs(value);
				const formattedValue = percentageType ? Math.round(absValue) : absValue.toFixed(decimals);

				return {
					formattedValue: `-${formattedValue}`,
					valueColorVariant: 'error',
					deltaTranscriptColor: 'red56',
				};
			}

			return {
				formattedValue: '0',
				valueColorVariant: lightZeroValue ? 'lightZeroValue' : 'neutral',
				deltaTranscriptColor: lightZeroValue ? 'lowContrast' : 'neutral56',
			};
		}, [value, lightZeroValue, percentageType, decimals]);

		return (
			<Container display='inlineGrid'>
				<IndicatorBox variant={valueColorVariant} ref={forwardedRef} {...props}>
					<Text size='small' font='mono' bold color='vivid'>
						{formattedValue}
						{percentageType && !isNull(value) && '%'}
					</Text>
				</IndicatorBox>
				{deltaTranscript && (
					<Text size='xSmall' font='mono' color={deltaTranscriptColor}>
						{deltaTranscript}
					</Text>
				)}
			</Container>
		);
	},
);
