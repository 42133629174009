import * as React from 'react';
import type {SVGProps} from 'react';

export function Hide(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			data-cy='hide-subject-icon'
			{...props}
		>
			<path
				fill='#333'
				d='M23.707 8.293a1 1 0 0 1 0 1.414l-1.82 1.82a1 1 0 0 1-.153.153l-10.04 10.04-.009.012c-.052.065-.11.121-.172.17l-1.806 1.805a1 1 0 0 1-1.414-1.414l14-14a1 1 0 0 1 1.414 0Z'
			/>
			<path
				fill='#333'
				d='M16 8c1.97 0 3.642.666 5.003 1.583l-1.447 1.447C18.528 10.406 17.346 10 16 10c-2.581 0-4.579 1.503-5.99 3.15A14.744 14.744 0 0 0 8.107 16l.026.05c.15.294.379.71.684 1.188a13.511 13.511 0 0 0 2.032 2.498l-1.415 1.415a15.543 15.543 0 0 1-2.302-2.836 16.657 16.657 0 0 1-.978-1.757 6.088 6.088 0 0 1-.073-.162l-.001-.002a1 1 0 0 1 0-.788v.788-.79c.05-.116.106-.23.162-.345a16.743 16.743 0 0 1 2.248-3.41C10.079 9.997 12.58 8 16 8Z'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M13.172 13.172a4.008 4.008 0 0 1 4.865-.619l-1.515 1.515a2.002 2.002 0 0 0-2.452 2.45l-1.516 1.514a3.999 3.999 0 0 1 .618-4.86Zm10.027-.174a.996.996 0 0 1 .75-.156c.251.047.5.178.657.406.66.969 1.083 1.806 1.313 2.344.108.25.108.56 0 .812s-.292.66-.594 1.188c-.499.872-1.113 1.745-1.814 2.562-2.05 2.39-4.545 3.844-7.506 3.844-.382 0-.957-.065-1.564-.156a.99.99 0 0 1-.845-1.125.99.99 0 0 1 1.126-.844c.508.076 1.034.125 1.283.125 2.293 0 4.301-1.171 6.005-3.156a14.571 14.571 0 0 0 1.564-2.25c.137-.242.205-.38.283-.536l.03-.058c-.015-.029-.021-.049-.028-.07a.427.427 0 0 0-.035-.086c-.242-.46-.536-.971-.876-1.47a.978.978 0 0 1 .25-1.374Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
