import {unstable_useBlocker as useBlocker} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {CreateAssignmentService} from '../services/create-assignment-service';
import styles from './styles.module.less';
import {useEffect, useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useBlockBrowserNavigation} from '../../../hooks/use-block-browser-navigation';
import {AlertData, AssignmentAlert} from '../components/assignment-alert';
import {
	CredentialsAlert,
} from '../components/credentials-alert';
import {Route} from 'pages/assignment-center/hooks/use-navigation';
import {SaveRequestParameters, StudentItem, TestFilter} from '../types/types';

type UseValidationsParameters<Service extends CreateAssignmentService, SelectedStudent extends StudentItem> = {
	editMode: boolean;
	step: number;
	onCreated: ((assignmentID?: number) => void) | undefined;
	service: Service;
	extractData: ((data: {
		name: string;
		description: string;
		selectedTestsFilters: TestFilter[];
		selectedStudents: SelectedStudent[];
		selectedTestsIds: number[];
	}) => void) | undefined;
	selectedStudents: SelectedStudent[];
};

export function useValidations<Service extends CreateAssignmentService, SelectedStudent extends StudentItem>({
	editMode,
	step,
	onCreated,
	service,
	extractData,
	selectedStudents,
}: UseValidationsParameters<Service, SelectedStudent>) {
	const [alert, setAlert] = useState<AlertData | null>(null);
	const [credentialAlertShow, setCredentialAlertShow] = useState<boolean>(false);
	const [isHistoryBlock, setIsHistoryBlock] = useState(false);
	const [completed, setCompleted] = useState(false);
	const blocker = useBlocker(!editMode && isHistoryBlock);
	const navigate = useNavigate();
	const selectedTestsIds = useBehaviorSubject(service.selectedTestsIds);
	const selectedTestsFilters = useBehaviorSubject(service.selectedTestsFilters);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');

	const studentsAreSelected = Boolean(selectedStudents.length);
	const studentsWithoutCredentials = selectedStudents.filter((x) => !x.hasCredentials);

	useEffect(() => {
		service.nameFormGroup.controls.name.onChanged.subscribe(v => setName(v.currState.value));
		service.nameFormGroup.controls.description.onChanged.subscribe(v => setDescription(v.currState.value));
	}, [service.nameFormGroup]);

	useBlockBrowserNavigation();

	function cancelAssignment() {
		blocker.proceed?.();
		resetBlocker();

		setIsHistoryBlock(false);
	}

	function closeValidationAlert() {
		setAlert(null);
	}

	const resetBlocker = () => {
		blocker.reset?.();
		closeValidationAlert();
	};

	useEffect(() => {
		setIsHistoryBlock(Boolean(!completed && step));
	}, [completed, step]);

	// block router navigation
	useEffect(() => {
		if (blocker.state === 'blocked' && step) {
			if (completed) {
				setIsHistoryBlock(false);
				resetBlocker();
				navigate(blocker.location);

				return;
			}

			if (blocker.location.pathname.includes(Route.StudentCredentials)) {
				cancelAssignment();

				return;
			}

			setAlert({
				title: 'Cancel Assignment?',
				description: 'Cancel will delete your assignment.',
				btnBlock: <div className={styles.btnBlock}>
						<Buttons.Text className={styles.button} onClick={resetBlocker}>Continue
							Assignment</Buttons.Text>
						<Buttons.Text className={styles.button} onClick={cancelAssignment}>Cancel
							Assignment</Buttons.Text>
				</div>,
			});
		}
	}, [blocker.state, step, blocker.location]);

	function onNextClick(currentStep: number) {
		if (currentStep === 2) {
			service.validateSecondStep();
		}
	}

	function save() {
		service.savingInProgress.next(true);
		const data: SaveRequestParameters = {
			name,
			description,
			testIDs: selectedTestsIds,
			studentIDs: selectedStudents.map(s => s.id),
		};
		service.save(data).subscribe(({assignmentID}) => {
			setCompleted(true);
			onCreated?.(assignmentID);
			service.savingInProgress.next(false);
		});
	}

	function closeCredentialAlert () {
		setCredentialAlertShow(false);
	}

	function onFinish() {
		if (studentsAreSelected) {
			if (studentsWithoutCredentials.length > 0) {
				extractData?.({
					name: service.nameFormGroup.controls.name.value,
					description: service.nameFormGroup.controls.description.value,
					selectedTestsIds,
					selectedStudents,
					selectedTestsFilters,
				});
				setCredentialAlertShow(true);
			} else {
				save();
			}
		} else {
			setAlert({
				title: 'Save As Draft',
				description: `You have not added students to your assignment and will not be able to post until you add students.`,
				btnBlock: <div className={styles.btnBlock}>
					<Buttons.Text className={styles.button} onClick={closeValidationAlert}>Add students</Buttons.Text>
					<Buttons.Text className={styles.button} onClick={save}>Save as draft</Buttons.Text>
				</div>,
			});
		}
	}

	const renderAlert = alert && <AssignmentAlert data={alert}/>;
	const credentialsAlert = credentialAlertShow ? (
		<CredentialsAlert onSave={save} students={studentsWithoutCredentials} onClose={closeCredentialAlert} />
	) : null;

	return {
		onNextClick,
		renderAlert,
		onFinish,
		credentialsAlert,
	};
}
