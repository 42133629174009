import './styles.less';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {DataModel} from 'pages/student-manager/models/models';
import React, {ReactNode} from 'react';
import {concatMap} from 'rxjs';
import {skip} from 'rxjs/operators';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {Loader} from '@esgi/deprecated/jquery';
import Layout from 'shared/page/layout/layout';
import {EventBusManager} from '@esgillc/events';
import {ErrorBoundary} from '@esgi/core/react';
import {Header} from 'pages/student-manager/components/header';
import Filter from './components/filter/filter';
import {Table} from './components/table/table';
import DataService from './services/data-service';
import ExportService from './services/export-service';
import FilterService from './services/filter-service';
import NotificationService from './services/notification-service';
import StudentsService from './services/students-service';
import TableService from './services/table-service';
import {Footer} from 'shared/page/footer/footer';
import {SchoolYearsService} from 'pages/student-manager/services/school-years-service';

class State {
	data: DataModel = null;
}

export default class StudentManager extends React.Component<{excludeLayout?: boolean}, State> {
	public state = new State();
	private readonly eventBusManager = new EventBusManager();
	private readonly dataService = new DataService();
	private readonly filterService = new FilterService(this.dataService);
	private readonly studentsService = new StudentsService(this.dataService);
	private readonly tableService = new TableService(this.dataService, this.studentsService, this.filterService);
	private readonly exportService = new ExportService(this.tableService, this.dataService);
	private readonly notificationService = new NotificationService(this.dataService);
	private readonly schoolYearsService = new SchoolYearsService();
	private loader: Loader = null;

	public componentDidMount(): void {
		this.loader = new Loader('#app');
		this.schoolYearsService.selectedSchoolYearID$
			.pipe(
				skip(1),
				concatMap(() => this.dataService.refreshData()),
			).subscribe();

		this.dataService.data$.subscribe(() => this.setState({data: this.dataService.data}));
	}

	public render(): ReactNode {
		return <>
			<Layout
				redesign={this.props.excludeLayout}
				loaded={[true, this.state.data !== null]}>
				{this.state.data &&
					<div className='student-explorer-page'>
						<ErrorBoundary fillSpace={true}>
							<Header schoolYearsService={this.schoolYearsService}
							        data={this.state.data}
							        download={() => this.exportService.download()}
							/>
						</ErrorBoundary>
						<ErrorBoundary fillSpace={true}>
							<Filter
								filterService={this.filterService}
								dataService={this.dataService}
								showSpecialistFilter={this.state.data.showSpecialistFilter}
							/>
							<Table dataService={this.dataService} tableService={this.tableService}
							       studentsService={this.studentsService}/>
							<ServiceLoader trackingService={this.schoolYearsService} whiteBackground/>
						</ErrorBoundary>
					</div>
				}
			</Layout>
			{!this.props.excludeLayout && <Footer/>}
			<BackgroundDownloadManager/>
		</>;
	}

	public componentWillUnmount() {
		this.dataService.destroy();
		this.exportService.destroy();
		this.filterService.destroy();
		this.studentsService.destroy();
		this.tableService.destroy();
		this.notificationService.destroy();
		this.schoolYearsService.destroy();
		this.eventBusManager.destroy();
	}
}
