import {useEffect, useMemo, useState} from 'react';
import {ClassicHierarchyLevel, HierarchyMode, HierarchySnapshot} from 'modules/hierarchy/core/models';
import {EntityType} from '../../../types';
import {Class, Group, storage} from '@esgi/main/libs/store';
import {first} from 'rxjs';
import {useUser} from '@esgi/core/authentication';
import {useTestDashboardContext} from '../../../layout/outlet-context';

export function useHierarchy(entityType: EntityType) {
	const user = useUser();

	const {testId, subjectID, subjectType, studentId, classId, studentFromClassId, studentFromGroupId, groupId, studentsList, groupsList, classesList} = useTestDashboardContext();

	const [groups, setGroups] = useState<Group[]>();
	const [classes, setClasses] = useState<Class[]>();
	
	const {hierarchy} = useMemo(() => {
		let studentID, groupID, classID, levelID: ClassicHierarchyLevel = ClassicHierarchyLevel.None;

		if (entityType === EntityType.Student) {
			studentID = studentId || 0;
			groupID = groupId || studentFromGroupId || 0;
			classID = classId || studentFromClassId || 0;
			if(groupID) {
				const group = groups.find(g => g.id === groupID);
				if(group) {
					classID = group.classID;
				}
			}
		}

		if (entityType === EntityType.ClassGroup) {
			studentID = 0;
			groupID = 0;
			classID = 0;
		}

		if (studentID) {
			levelID = ClassicHierarchyLevel.Student;
		}

		if (groupID) {
			levelID = ClassicHierarchyLevel.Group;
		}

		if (classID) {
			levelID = ClassicHierarchyLevel.Class;
		}

		if(studentID && !classID) {
			classID = classes?.find(c => c.studentIDs.includes(studentID))?.id;
		}

		return {
			hierarchy: {
				districtID: user?.districtID,
				studentSort: user?.studentSort,
				mode: HierarchyMode.Classic,
				classic: {
					studentID,
					groupID,
					classID,
					teacherID: user?.userID,
					schoolID: user?.schoolID,
					districtID: user?.districtID,
					selected: {
						level: levelID,
						levelID: (studentID || groupID || classID) || 0,
					},
				},
				specialist: {
					studentID: 0,
					filter: {
						schoolID: 0,
						teacherID: 0,
					},
					type: 0,
					groupID: 0,
					userID: 0,
					districtID: 0,
					groupOfSpecialistsID: 0,
					selected: {
						level: 0,
						levelID: 0,
					},
				},
				preAssess: {
					studentID: 0,
					groupID: 0,
					userID: 0,
					districtID: 0,
					selected: {
						level: 0,
						levelID: 0,
					},
				},
			} as HierarchySnapshot,
		};
	}, [entityType, user?.districtID, user?.studentSort, user?.userID, user?.schoolID, studentId, groupId, studentFromGroupId, classId, studentFromClassId, groups, studentsList, classesList, groupsList, classes]);

	useEffect(() => {
		const groups = storage.groups();
		const classes = storage.classes();
		const groupsSub = groups.get().pipe(first()).subscribe((groups) => setGroups(groups));
		const classesSub = classes.get().pipe(first()).subscribe((classes) => setClasses(classes));

		return () => {
			classesSub.unsubscribe();
			groupsSub.unsubscribe();
			classes.dispose();
			groups.dispose();
		};
	}, []);
	
	return {hierarchy, testId, subjectID, subjectType} ;
}
