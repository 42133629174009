import moment from 'moment';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType, UserInfo} from '@esgi/core/authentication';
import {FilterModel, StudentModel} from '../models/models';
import DataService from './data-service';
import FilterService from './filter-service';
import StudentSorter from './student-sorter';
import StudentsService from './students-service';

export interface StudentTableInfo {
	studentName: string;
	schoolName: string;
	teacherName: string;
	studentClasses: string;
	studentGroups: string;
	specialistGroupUserNames: string;
	specialistGroupNames: string;
	studentGrade: string;
	studentCreateDate: string;
	studentIDN: string;
	editable: boolean;
	selected: boolean;
	studentID: number;
	schoolID: number;
	primaryTeacherID: number;
	specialistGroupIDs: number[];
}

export default class TableService extends BaseService {
	private currentUser: UserInfo = userStorage.get();
	private studentSorter: StudentSorter;

	constructor(private dataService: DataService,
	            private studentsService: StudentsService,
	            private filterService: FilterService) {
		super();
		dataService.data$.pipe(takeUntil(this.destroy$)).subscribe(() => this.init());
	}

	public studentsToShow$: BehaviorSubject<StudentModel[]> = new BehaviorSubject<StudentModel[]>([]);
	public sortBy$: BehaviorSubject<string> = new BehaviorSubject<string>('student');
	public sortAsc$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	public selectedStudentsIDs$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

	public getStudentInfo(student: StudentModel): StudentTableInfo {
		const school = this.dataService.data.schools.filter(x => x.schoolID === student.schoolID)[0];
		const filteredTeachers = school.teachers.filter(x => x.teacherID === student.primaryTeacherID);
		const teacher = filteredTeachers.length === 1 ? filteredTeachers[0] : null;

		let studentClasses = '';
		let studentGroups = '';
		let teacherName = '';
		if (teacher !== null) {
			teacherName = teacher.firstName + ' ' + teacher.lastName;

			const classes = teacher.classes.filter(x => student.classIDs.indexOf(x.classID) !== -1);
			studentClasses = classes.map(x => x.name).join(', ');

			for (let i = 0; i < classes.length; i++) {
				const c = classes[i];
				const groups = c.groups.filter(x => student.groupIDs.indexOf(x.groupID) !== -1);
				if (groups.length > 0) {
					studentGroups += (studentGroups !== '' ? ', ' : '') + groups.map(x => x.name).join(', ');
				}
			}
		}
		const studentGrade = this.dataService.data.gradeLevels.filter(x => x.gradeLevelID === student.gradeLevelID)[0].name;
		const specialistGroups = [].concat(...this.dataService.data.specialists.map(x => x.groups));

		return {
			studentName: student.firstName + ' ' + student.lastName,
			schoolName: school.name,
			teacherName,
			studentClasses,
			studentGroups,
			specialistGroupUserNames: this.getSpecialistsUserNames(student),
			specialistGroupNames: student.specialistGroupIDs?.map(x => specialistGroups.filter(c => c.groupID === x).map(x => x.name)).join(', '),
			studentGrade,
			studentCreateDate: moment(student.createDate).format('MM-DD-YY'),
			studentIDN: student.studentIDN,
			editable: student.editable,
			selected: this.selectedStudentsIDs$.value.indexOf(student.studentID) > -1,
			studentID: student.studentID,
			schoolID: student.schoolID,
			primaryTeacherID: student.primaryTeacherID,
			specialistGroupIDs: student.specialistGroupIDs,
		} as StudentTableInfo;
	}

	public changeStudentSelect(studentID: number): void {
		if (this.selectedStudentsIDs$.value.indexOf(studentID) > -1) {
			this.selectedStudentsIDs$.next(this.selectedStudentsIDs$.value.filter(s => s !== studentID));
		} else {
			this.selectedStudentsIDs$.next([...this.selectedStudentsIDs$.value, studentID]);
		}
	}

	public selectAll(selected: boolean): void {
		if (selected) {
			const selectedStudents = this.studentsToShow$.value.filter(v => v.editable).map(v => v.studentID);
			this.selectedStudentsIDs$.next(selectedStudents);
		} else {
			this.selectedStudentsIDs$.next([]);
		}
	}

	public unselectAll(): void {
		this.selectedStudentsIDs$.next([]);
	}

	private init(): void {
		this.studentSorter = new StudentSorter({
			schools: this.dataService.data.schools,
			specialists: this.dataService.data.specialists,
			studentSortBy: this.dataService.data.sortBy,
			gradeLevels: this.dataService.data.gradeLevels,
		});

		combineLatest(this.filterService.filter$, this.studentsService.studentsSource$)
			.pipe(takeUntil(this.destroy$))
			.subscribe((p) => this.filterData(p[0], p[1]));
		combineLatest(this.sortBy$, this.sortAsc$)
			.pipe(takeUntil(this.destroy$), debounceTime(1))
			.subscribe(p => this.sortData(p[0], p[1]));
	}

	private getSpecialistsUserNames(student: StudentModel): string {
		const specialistGroupUserNames = student.specialistGroupUserIDs?.map(x => this.dataService.data.specialists.filter(c => c.userID === x).map(x => x.name));
		const flattenedArray = [].concat(...specialistGroupUserNames);
		const distinct = (value, index, self) => self.indexOf(value) === index;
		return flattenedArray.filter(distinct).join(', ');
	}

	private filterData(filter: FilterModel, source: StudentModel[]): void {
		const currentUser = userStorage.get();
		let students = source;

		const name = filter.name?.toLowerCase();
		const schoolID = filter.schoolID;
		const teacherID = filter.teacherID;
		const classID = filter.classID;
		const groupID = filter.groupID;
		const gradeLevelID = filter.gradeLevelID;
		const specialistGroupID = filter.specialistGroupID;
		const specialistUserID = filter.specialistUserID;
		const specialistType = filter.specialistType;

		if (name) {
			const parts = name.split(' ').map(x => x.trim()).filter(x => x.length > 0).map((x, index) => {
				return {value: x, index: index};
			});
			students = students.filter(x => this.filterStudentName(parts, x.firstName.toLowerCase(), x.lastName.toLowerCase()) || (x.studentIDN && x.studentIDN.toLowerCase().indexOf(name) !== -1));
		}
		if (schoolID > 0) {
			students = students.filter(x => x.schoolID === schoolID);
		}
		if (teacherID > 0) {
			students = students.filter(x => x.primaryTeacherID === teacherID);
		}
		if (classID > 0) {
			students = students.filter(x => x.classIDs.indexOf(classID) !== -1);
		}
		if (groupID > 0) {
			students = students.filter(x => x.groupIDs.indexOf(groupID) !== -1);
		}
		if (gradeLevelID > 0) {
			students = students.filter(x => x.gradeLevelID === gradeLevelID);
		}
		if (specialistGroupID > 0) {
			students = students.filter(x => x.specialistGroupIDs.indexOf(specialistGroupID) !== -1);
			if (currentUser.userType === UserType.T) {
				students = students.filter(x => x.primaryTeacherID);
			}
		}
		if (specialistUserID > 0) {
			students = students.filter(x => x.specialistGroupUserIDs.indexOf(specialistUserID) !== -1);
		}
		if (specialistType > 0) {
			students = students.filter(x => x.specialistGroupTypes.indexOf(UserType[specialistType]) !== -1);
		}

		const sortedStudents = this.studentSorter.sortStudents(this.sortBy$.value, this.sortAsc$.value, students);
		if (this.selectedStudentsIDs$.value.some(sID => sortedStudents.findIndex(s => s.studentID === sID) === -1)) {
			this.selectedStudentsIDs$.next(this.selectedStudentsIDs$.value.filter(sID => sortedStudents.findIndex(s => s.studentID === sID) >= 0));
		}
		this.studentsToShow$.next(sortedStudents);
	}

	private sortData(sortBy: string, asc: boolean): void {
		const sortedStudents = this.studentSorter.sortStudents(sortBy, asc, this.studentsToShow$.value);
		this.studentsToShow$.next(sortedStudents);
	}

	private filterStudentName(parts: any[], first: string, last: string): boolean {
		const firstIndices = parts.filter(x => first.indexOf(x.value) !== -1).map(x => x.index);
		const lastIndices = parts.filter(x => last.indexOf(x.value) !== -1).map(x => x.index);
		const indices = firstIndices.concat(lastIndices).filter((x, index, arr) => {
			return arr.indexOf(x) === index;
		});
		return indices.length === parts.length;
	}
}

