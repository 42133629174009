import {
	UntestedStudentsReport,
	GroupingType,
	TestListDataItem,
	StudentListDataItem,
	TestItem,
} from '@esgi/main/features/untested-students-report';
import {Box} from '@esgi/ui/layout';
import {EmptyBox} from '../../index.styled';
import {Text} from '@esgi/ui/typography';
import {StudentModel} from '@esgi/main/features/assessments';

interface Props {
	mode: GroupingType;
	tests: TestListDataItem[];
	students: StudentListDataItem[];
	hasRequiredFields: boolean;
	showAllStudents: boolean;
	onStartTest: (test: TestItem, student: StudentModel) => void;
}

export function TestSection(props: Props) {
	return <Box>
		{props.hasRequiredFields ? <UntestedStudentsReport.TestsControl onStartTest={props.onStartTest} showAllStudents={props.showAllStudents} tests={props.tests} students={props.students} mode={props.mode}/>
			: <EmptyBox><Text size='small' font='mono' color='neutral40'>Select subject and class(or group)</Text></EmptyBox>}
	</Box>;
}