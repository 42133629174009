import {Button, isUndefined, useStreamEffect} from '@esgi/ui';
import {Delete} from '@esgi/ui/icons';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {
	ElementStatus,
	ErrorTooltip,
	Form,
	FormControl,
	FormElement,
	FormGroup,
	Validators,
	useForm,
} from '@esgi/ui/form';
import {Input, Checkbox} from '@esgi/ui/form-controls';
import {SubjectLevel, SubjectTab, SubjectType, storage, useStore} from '@esgi/main/libs/store';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {maxSubjectNameLength, validations} from '../constants';
import {of, switchMap} from 'rxjs';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {dispatchAppEvent} from '@esgillc/events';
import {CheckboxContainer, DeleteButton, FormInfoBox} from './index.styled';
import {SubjectName} from '@esgi/main/kits/common';
import {SubjectChangedEvent, SubjectRemovedEvent} from '../../events';
import {DeleteSubjectAlert} from '../../components/delete-subject-alert';
import {NoChangesConfirmation} from '../../components/no-changes-confirmation';

type Props = {
	onClose: VoidFunction;
	subjectID: SubjectTab['id'];
};

export function EditPersonalSubject({onClose, subjectID}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isOpenNoChangesConfirmation, setIsOpenNoChangesConfirmation] = useState(false);

	const [isSaveDisabled, setIsSaveDisabled] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [isDeleteSubjectAlertOpen, setIsDeleteSubjectAlertOpen] = useState(false);

	const [subjectsList, _, isSubjectsLoaded] = useStore(storage.subjects);

	const editableSubject = useMemo(() => {
		const subject = subjectsList.find(({id}) => id === subjectID);

		if (isSubjectsLoaded && isUndefined(subject) && !isRemoving) {
			throw new Error('Subject is not found');
		}

		return subject;
	}, [isSubjectsLoaded, subjectID, subjectsList, isRemoving]);

	const service = useService(Service);

	const form = useForm(
		() =>
			new FormGroup({
				subjectName: new FormControl('', {
					validators: [Validators.required(), Validators.length.max(maxSubjectNameLength)],
					disableValueComparison: false,
				}),
				subjectHidden: new FormControl(false),
			}),
	);

	useEffect(() => {
		if (!isUndefined(editableSubject)) {
			form.controls.subjectName.value = editableSubject.name;
			form.controls.subjectHidden.value = editableSubject.hidden;
		}
	}, [editableSubject, form]);

	useEffect(() => {
		if (isSubjectsLoaded && !isUndefined(editableSubject)) {
			form.controls.subjectName.validators.push(
				Validators.isDublicateValue(
					subjectsList
						.filter(({level, name}) => level === SubjectLevel.Teacher && name !== editableSubject.name)
						.map(({name}) => name),
				),
			);
		}
	}, [form.controls.subjectName.validators, isSubjectsLoaded, subjectsList, editableSubject]);

	useStreamEffect(form.controls.subjectName.onChanged, ({reason, currState}) => {
		setIsSaveDisabled(reason === 'status' && currState.status === ElementStatus.invalid);
	});

	useStreamEffect(form.onChanged, ({currState}) => {
		setIsFormTouched(
			currState.value.subjectName !== editableSubject?.name || currState.value.subjectHidden !== editableSubject.hidden,
		);
	});

	const onCloseAlert = useCallback(() => {
		if (isFormTouched) {
			setIsOpenNoChangesConfirmation(true);
			return;
		}

		closeAlert();
	}, [closeAlert, isFormTouched]);

	const handleSaveSubject = useCallback(() => {
		setIsSaving(true);

		const {subjectHidden, subjectName} = form.value;

		form
			.validate()
			.pipe(
				switchMap(({valid}) => {
					if (valid) {
						return service.updatePersonalTab({
							subjectID,
							subjectName: subjectName,
							hidden: subjectHidden,
						});
					}

					return of();
				}),
			)
			.subscribe({
				next: () => {
					dispatchAppEvent(
						SubjectChangedEvent,
						new SubjectChangedEvent(subjectID, subjectName, SubjectType.Personal, subjectHidden),
					);
				},
				complete: () => {
					setIsSaving(false);
					closeAlert();
				},
			});
	}, [closeAlert, form, service, subjectID]);

	const handleOpenRemoveSubjectDialog = useCallback(() => {
		setIsDeleteSubjectAlertOpen(true);
	}, []);

	const onSubjectRemove = useCallback(() => {
		setIsRemoving(true);

		service.removePersonalTab(subjectID).subscribe({
			next: () => {
				dispatchAppEvent(SubjectRemovedEvent, new SubjectRemovedEvent(subjectID));
			},
			complete: () => {
				setIsDeleteSubjectAlertOpen(false);
				setIsRemoving(false);
				closeAlert();
			},
		});
	}, [closeAlert, service, subjectID]);

	const onCloseNoChangesConfirmation = useCallback(() => {
		setIsOpenNoChangesConfirmation(false);
	}, []);

	const onCloseAnywayNoChangesConfirmation = useCallback(() => {
		setIsOpenNoChangesConfirmation(false);
		closeAlert();
	}, [closeAlert]);

	return (
		<>
			<Alert
				modalManagerRef={alertRef}
				css={{
					'& [data-alert-content]': {
						width: 510,
					},
				}}
			>
				<Alert.Header onCloseIconClick={onCloseAlert} withBacklight={false}>
					{!isUndefined(editableSubject) && (
						<SubjectName subjectLevel={editableSubject.level} size='medium' bold color='base'>
							{editableSubject.name}
						</SubjectName>
					)}
				</Alert.Header>
				<Alert.Body>
					<FormInfoBox>
						<Text size='medium' color='base'>
							Make any changes to the name of this subject tab below.
						</Text>

						<Form controller={form}>
							<FormElement control={form.controls.subjectName}>
								<Input.Base placeholder='Subject Name' />

								{validations.map(({showOnError, message}, index) => (
									<ErrorTooltip showOnError={showOnError} key={index}>
										<Text size='xSmall' font='mono' color='negativeVivid'>
											{message}
										</Text>
									</ErrorTooltip>
								))}
							</FormElement>

							<FormElement control={form.controls.subjectHidden}>
								<CheckboxContainer>
									<Checkbox label='Hidden Subject' />
								</CheckboxContainer>
							</FormElement>
						</Form>

						<Text size='medium' color='neutral40'>
							Hidden subjects are not displayed in your Subject Tabs. They can be recovered by entering Edit mode if you
							wish to view them again at a later time.
						</Text>
					</FormInfoBox>
				</Alert.Body>
				<Alert.Footer justify='between'>
					<DeleteButton color='tertiary' onClick={handleOpenRemoveSubjectDialog}>
						<Delete />
						<Text bold size='medium'>
							Delete Subject
						</Text>
					</DeleteButton>
					<GridBox gap='3' flow='column'>
						<Button color='tertiary' onClick={onCloseAlert}>
							<Text size='medium' bold color='base'>
								Cancel
							</Text>
						</Button>
						<Button
							color='secondary'
							disabled={isSaveDisabled || isSaving || !isSubjectsLoaded || !isFormTouched || isRemoving}
							onClick={handleSaveSubject}
						>
							<Text size='medium' bold color='base'>
								Save
							</Text>
						</Button>
					</GridBox>
				</Alert.Footer>
			</Alert>

			{isDeleteSubjectAlertOpen && (
				<DeleteSubjectAlert
					onDelete={onSubjectRemove}
					onCancel={() => setIsDeleteSubjectAlertOpen(false)}
					isRemoving={isRemoving}
				/>
			)}

			{isOpenNoChangesConfirmation && (
				<NoChangesConfirmation
					onCloseAnyway={onCloseAnywayNoChangesConfirmation}
					onClose={onCloseNoChangesConfirmation}
				/>
			)}
		</>
	);
}
