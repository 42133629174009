import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Item} from '@radix-ui/react-radio-group';
import {CSS} from '@stitches/react';

const indicatorStyles: CSS = {
	content: '',
	display: 'block',
	width: 4.5,
	height: 4.5,
	borderRadius: '50%',
	backgroundColor: 'primary',
	position: 'absolute',
	boxSizing: 'border-box',
};

export const Input = styled(Item, {
	all: 'unset',
	width: 16,
	height: 16,
	borderRadius: 8,
	position: 'relative',
	boxSizing: 'border-box',
});

export const Label = styled('label', {
	display: 'inline-flex',
	cursor: 'pointer',
});

export const Container = styled(Box, {
	display: 'inline-flex',
	width: 'max-content',
	cursor: 'pointer',
	transition: 'background-color .3s, border-radius .3s',

	'& > button': {
		transition: 'background-color .3s, background .3s, border-color .3s, border-width .3s, border-style .3s',

		'&::after': {
			transition: 'background-color .3s',
		},
	},

	'& > label': {
		transition: 'color .3s',
	},

	'&[data-type="single"]': {
		'& > button': {
			backgroundColor: '$border',
		},

		'&[data-state="checked"]': {
			'& > button': {
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '$primary92',

				'&::after': {
					...indicatorStyles,
					backgroundColor: '$primary',
				},
			},
		},

		'&[data-disabled]': {
			'& > button': {
				cursor: 'default',
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: '$mild',
				backgroundColor: '$vivid',

				'&::after': {
					backgroundColor: '$mild',
				},
			},
		},

		'&:hover:not([data-disabled])': {
			'& > button': {
				backgroundColor: '$secondaryMuted',

				'&::after': {
					backgroundColor: '$secondary',
				},
			},
		},
	},

	'&[data-type="embedded"]': {
		display: 'inline-flex',
		alignItems: 'center',
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 4,
		paddingRight: 8,

		'& > button': {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: '$neutral72',
			backgroundColor: '$vivid',
		},

		'& > label': {
			marginLeft: '$2',
			color: '$neutral40',
		},

		'&[data-state="checked"]': {
			'& > button': {
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderColor: '$primaryMuted',
				backgroundColor: 'unset',
				background: 'linear-gradient(272deg, $primaryBackground 48.17%, $primary98 100%)',

				'&::after': {
					...indicatorStyles,
					backgroundColor: '$primary',
				},
			},

			'& > label': {
				color: '$primary',
			},
		},

		'&:hover:not([data-disabled])': {
			backgroundColor: '$secondaryBackground',
			borderRadius: 6,

			'& > button': {
				borderColor: '$secondaryMuted',
				background: 'unset',
				backgroundColor: '$secondaryBackground',

				'&::after': {
					backgroundColor: '$secondary',
				},
			},

			'& > label': {
				color: '$secondary',
			},
		},

		'&[data-disabled]': {
			cursor: 'default',
			backgroundColor: 'unset',
			borderRadius: 'unset',

			'& > button': {
				cursor: 'default',
				borderColor: '$mild',
				background: 'unset',
				backgroundColor: '$vivid',

				'&::after': {
					backgroundColor: '$mild',
				},
			},

			'& > label': {
				cursor: 'default',
				color: '$muted',
			},
		},
	},
});
