import {Input} from '@esgi/ui/controls';

interface Props {
	value: number;
	onChange: (data: number) => void;
}

export function LastWeekControl({value, onChange}: Props) {
	return (
		<Input
			type='number'
			placeholder='Last week'
			max={40}
			min={1}
			value={value}
			onInput={(value: any) => onChange(value.target.value)}
			dataCy='last-week'
		/>
	);
}
