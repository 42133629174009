import React from 'react';
import {TestType} from '@esgi/core/enums';
import {TestIntroImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {isIOS} from '@esgillc/ui-kit/utils';
import {userStorage} from '@esgi/core/authentication';
import '../component.less';

export class State {
	opened: boolean;
}

export class Props {
	pageId: number;
	testId: number;
	pregenerated: boolean;
	modifyTicks: number;
	readonly: boolean;
	imageIsProcessing: boolean;
	testType: TestType;
	editHandler: () => void;
	deleteHandler: () => void;
	isWhiteBackground: boolean;
	dragAndDropIsProccessing: boolean;
}

export class TestIntroTile extends React.PureComponent<Props, State> {
	private img: HTMLImageElement;
	private imageLoadingErrorTimes: number = 0;
	private readonly notAvaliableSrc = '/ESGI/Images/150x100.gif';
	private isIOS: boolean = isIOS();
	dropdownMenuRef: HTMLDivElement;

	constructor(props, state) {
		super(props, state);
		this.state = new State();
	}

	private imageURL = (pregenerated = true) => {
		if (this.props.imageIsProcessing) {
			return this.notAvaliableSrc;
		}

		const {pageId, testId, modifyTicks} = this.props;

		const userContext = userStorage.get();
		let urlBuilder = new TestIntroImageUrlBuilder(userContext.awsBucket);

		if (this.props.testType === TestType.Score) {
			return urlBuilder.large(pageId, testId, pregenerated, modifyTicks);
		}
		return urlBuilder.medium(pageId, testId, pregenerated, modifyTicks);
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.dragAndDropIsProccessing != this.props.dragAndDropIsProccessing && this.state.opened) {
			this.dropdownToogle();
		}
	}

	handleClickOutside(event) {
		if (this.state.opened && this.dropdownMenuRef && !this.dropdownMenuRef.contains(event.target)) {
			this.dropdownToogle();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	componentDidMount() {
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	private getFallbackImageURL = () => {

		if (this.imageLoadingErrorTimes <= 1) {
			this.img.src = this.imageURL(false) + '&regenerate=true';
		} else {
			this.img.src = this.notAvaliableSrc;
		}

		this.imageLoadingErrorTimes++;
	}

	private finishLoad = () => {
		if (this.imageLoadingErrorTimes > 0) {
			this.imageLoadingErrorTimes--;
		}
	}

	dropdownToogle() {
		this.setState((state) => {
			return {opened: !state.opened};
		});
	}

	render() {
		let liClassName = 'te-question test-intro-thumbnail ' + (this.isIOS && 'mobile ' || '');
		liClassName += this.props.testType == TestType.Score && 'single-score' || '';
		return <li className={liClassName}>
			<img ref={r => this.img = r} src={this.imageURL(this.props.pregenerated)} onError={this.getFallbackImageURL}
				 onLoad={this.finishLoad}/>
			<div className='question-tag'>
				{!this.props.readonly &&
				<div className='dropdown' ref={(ref) => this.dropdownMenuRef = ref}>
					<a className={this.props.isWhiteBackground ? 'dropdown-toggle white-background' : 'dropdown-toggle black-background'}
					   onClick={() => this.dropdownToogle()} href='#'>
						<i className='ace-icon fa fa-ellipsis-v white'/>
					</a>
					{this.state.opened &&
					<div className='dropdown-menu'>
						<a className='dropdown-item' href='#' onClick={() => this.props.editHandler()}>
							<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'
								 fill='none'>
								<path
									d='M0.25 10.9375V13.75H3.0625L11.3575 5.45498L8.545 2.64248L0.25 10.9375ZM13.5325 3.27998C13.825 2.98748 13.825 2.51498 13.5325 2.22248L11.7775 0.467483C11.485 0.174983 11.0125 0.174983 10.72 0.467483L9.3475 1.83998L12.16 4.65248L13.5325 3.27998Z'
									fill='#828282'/>
							</svg>
							<span>Edit</span>
						</a>
						<a className='dropdown-item' href='#' onClick={() => this.props.deleteHandler()}>
							<svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14'
								 fill='none'>
								<path
									d='M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM11.25 1H8.625L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1Z'
									fill='#828282'/>
							</svg>
							<span>Delete</span>
						</a>
					</div>}
				</div>
				}
			</div>
			<div className='question-name intro-page-name'>
				<span className='label-holder'>Test Intro Page</span>
			</div>
		</li>;
	}
}
