import {IndexableType, Table as DexieTable} from 'dexie';
import {BehaviorSubject, from, map, Observable, switchMap, take, tap} from 'rxjs';
import {Crypto} from './crypto';
import {DexieAsyncWrapper} from './dexie-async-wrapper';

type RowValue = {value: string};

export class CryptoTable<ValueType> {
	private initialized: boolean = false;
	private currentData: BehaviorSubject<ValueType[]> = new BehaviorSubject<ValueType[]>(undefined);
	private table: Observable<DexieTable<RowValue, IndexableType>>;

	constructor(private key: string, store: DexieAsyncWrapper, private crypto: Crypto) {
		this.table = store.instance.pipe(map((instance) => instance.table(this.key)));
	}

	public find(search: (item: ValueType) => boolean): Observable<ValueType | null> {
		return this.data().pipe(
			map(values => values.find(search)),
			take(1),
		);
	}

	public update(search: (item: ValueType) => ValueType): Observable<void> {
		return this.data().pipe(
			take(1),
			map(values => values.map(search).filter(v => v !== undefined)),
			switchMap((items) => this.set(...items)),
		);
	}

	public data(): Observable<ValueType[]> {
		if(!this.initialized) {
			return this.readData().pipe(switchMap(data => this.currentData));
		}

		return this.currentData;
	}

	//Add data
	public add(...value: ValueType[]): Observable<void> {
		const encrypted = this.bulkEncrypt(value).map((v) => ({value: v}));
		return this.table
			.pipe(
				tap((table) => table.bulkAdd(encrypted)),
				switchMap(() => this.onDataChanged()),
			);
	}

	//Override all data
	public set(...value: ValueType[]): Observable<void> {
		return this.clear().pipe(switchMap(() => this.add(...value)));
	}

	private clear(): Observable<void> {
		return this.table.pipe(map((table) => {
			table.clear();
		}));
	}

	private onDataChanged(): Observable<void> {
		this.initialized = true;
		return this.readData().pipe(tap(() => this.onDataChanged()), map(values => {}));
	}

	private readData(): Observable<ValueType[]> {
		return this.table.pipe(
			switchMap((table) => from(table.toArray())),
			map((data) => {
				return this.bulkDecrypt(data.map(d => d.value));
			}),
			tap((data) => this.currentData.next(data)),
		);
	}

	private encrypt(value: ValueType): string {
		const jsonValue = JSON.stringify(value);
		return this.crypto.encrypt(jsonValue);
	}

	private decrypt(value: string): ValueType {
		const jsonEncrypted = this.crypto.decrypt(value);
		return JSON.parse(jsonEncrypted);
	}

	private bulkEncrypt(values: ValueType[]): string[] {
		const out: string[] = [];
		for (const value of values) {
			out.push(this.encrypt(value));
		}
		return out;
	}

	private bulkDecrypt(values: string[]): ValueType[] {
		const out: ValueType[] = [];
		for (const value of values) {
			out.push(this.decrypt(value));
		}
		return out;
	}
}