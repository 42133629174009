import {Button as ButtonUI, isUndefined} from '@esgi/ui';
import {Property} from '@stitches/react/types/css';
import {ComponentProps, MouseEvent, useCallback} from 'react';
import {ButtonIcon, IconWrapper} from './index.styled';

type Props = {
	dataCy?: string;
	width?: Property.Width;
	children: ComponentProps<typeof ButtonUI.Icon>['children'];
	onClick?: VoidFunction | undefined;
	disabled?: boolean;
};

export function SelfAssessButton({dataCy, width = 36, children, onClick, disabled}: Props) {
	const handleClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
			event.stopPropagation();

			onClick?.();
		},
		[onClick],
	);

	return (
		<ButtonIcon dataCy={dataCy} css={{width}} onClick={handleClick} noClicable={isUndefined(onClick)} disabled={disabled}>
			<IconWrapper>
				{children}
			</IconWrapper>
		</ButtonIcon>
	);
}
