import React, {useCallback, useState} from 'react';
import {Sidebar} from './components/sidebar';
import {Table} from './components/table';
import {SectionBlock} from '../../kit';
import {TestInfo, TestSession, TrackDate, GradeRange} from '../../types';
import {Wrapper} from './index.styled';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	trackDates: TrackDate[],
	gradeRanges: GradeRange[];
	isTablet: boolean;
}

export function ProgressSection({trackDates, testInfo, testSessions, gradeRanges, isTablet}: Props) {
	const [checkboxState, setCheckboxesState] = useState({
		score: true,
		percentage: true,
		grade: true,
	});

	const toggleCheckbox = useCallback((field) => {
		setCheckboxesState((prev) => ({...prev, [field]: !prev[field]}));
	}, []);

	return (
		<SectionBlock isTablet={isTablet} title='Progress'>
			<Wrapper isTablet={isTablet} >
				<Sidebar isTablet={isTablet} checkboxState={checkboxState} toggleCheckbox={toggleCheckbox} />
				<Table
					trackDates={trackDates}
					testInfo={testInfo}
					testSessions={testSessions}
					checkboxState={checkboxState}
					gradeRanges={gradeRanges}
					isTablet={isTablet}
				/>
			</Wrapper>
		</SectionBlock>
	);
}
