import {tap} from 'rxjs';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {colorConfigInEditMode} from './constants';
import {YesNoTestSession} from './components/variant-mode/yes-no-test-session';
import {TestType, routes} from '@esgi/main/libs/core';
import {SingleScoreTestSession} from './components/variant-mode/single-score-test-session';
import {RubricTestSession} from './components/variant-mode/rubric-test-session';
import {SkeletonTestSession} from './components/variant-mode/skeleton-test-session';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {useBehaviorSubject} from '@esgi/ui';
import {isNull, max, noop} from 'underscore';
import {Question, TestInfo, UpdateScoreSessionRequestParams, UpdateYNSessionRequestParams} from './types';
import {TestSessionTimeConflict} from '../kit/test-session-time-conflict';
import {Class, SubjectTab, storage, useStore} from '@esgi/main/libs/store';
import {TestContentArea} from '@esgi/main/kits/common';
import {ReportErrorEvent} from '@esgi/core/react';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {TestLauncherData, Testing} from '@esgi/main/features/assessments';
import {useNavigate} from 'react-router-dom';
import {TestSubsetStartedEvent} from 'modules/assessments/general/session-details/test-subset/events';
import {userStorage, UserType} from '@esgi/core/authentication';

type Props = {
	onAlertClose: VoidFunction;
	testSessionID: number;
	classID: Class['id'];
	subjectID: SubjectTab['id'];
};

export function TestSessionDetailsAlert({onAlertClose, testSessionID, classID, subjectID}: Props) {
	const [testLauncherQuestionIDs, setTestLauncherQuestionIDs] = useState<Question['id'][] | null>(null);
	const [isDataLoaded, setIsDataLoaded] = useState(true);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isTimeConflict, setIsTimeConflict] = useState(false);

	const navigate = useNavigate();

	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onAlertClose);

	const service = useService(Service);

	const testSession = useBehaviorSubject(service.testSession$);
	const questions = useBehaviorSubject(service.questions$);
	const rubricCriteria = useBehaviorSubject(service.rubricCriteria$);

	const [classesList, , isClassesListLoaded] = useStore(storage.classes);
	const [studentsList, , isStudentsListLoaded] = useStore(storage.students);
	const [subjects, _, isSubjectsLoaded] = useStore(storage.subjects);

	useEffect(() => {
		setIsDataLoaded(false);

		service.init({testSessionID}).subscribe(() => {
			setIsDataLoaded(true);
		});
	}, [testSessionID]);

	const student = useMemo(
		() => studentsList.find(({id}) => id === testSession?.studentID) ?? null,
		[studentsList, testSession],
	);

	const selectedClass = useMemo<Class | null>(() => {
		if (isNull(student)) {
			return null;
		}

		const studentsClasses = classesList.filter(({studentIDs}) => studentIDs.includes(student.id));

		if (classID === -1) {
			const classWithMaxStudents = max(studentsClasses, ({studentIDs}) => studentIDs.length);

			return typeof classWithMaxStudents === 'number' ? null : classWithMaxStudents;
		}

		return classesList.find(({id}) => id === classID) ?? studentsClasses[0] ?? null;
	}, [classID, classesList, student]);

	const subjectTestData = useMemo(() => {
		if (isNull(testSession)) {
			return null;
		}

		const subject = subjects.find(({id}) => id === subjectID);
		const test = subject?.tests.find(({id}) => id === testSession.testID);

		if (!subject || !test) {
			return null;
		}

		const testInfo: TestInfo = {
			id: test.id,
			name: test.name,
			testType: test.type,
			contentArea: test.contentArea as TestContentArea,
			questions,
			rubricCriteria,
			totalPossible: test.maxScore,
		};

		return {testInfo, subject};
	}, [questions, rubricCriteria, subjectID, subjects, testSession]);

	const onUpdateYNSession = useCallback((params: UpdateYNSessionRequestParams) => {
		return service.updateYNSession(params).pipe(tap((response) => {
			if (response.isSuccess) {
				return;
			}

			setIsTimeConflict(true);
		}));
	}, [service]);

	const onUpdateScoreSession = useCallback((params: UpdateScoreSessionRequestParams) => {
		return service.updateScoreSession(params).pipe(tap((response) => {
			if (response.isSuccess) {
				return;
			}

			setIsTimeConflict(true);
		}));
	}, [service]);

	const onDeleteSession = useCallback(() => {
		service.deleteSessionRequest({testSessionID}).subscribe(closeAlert);
	}, [closeAlert, service, testSessionID]);

	const onRestoreSession = useCallback(() => {
		service.restoreSession({testSessionID});
	}, [service, testSessionID]);

	const onDownloadSession = useCallback(() => {
		if (isNull(subjectTestData) || isNull(student) || isNull(testSession)) {
			return;
		}

		const {subject, testInfo} = subjectTestData;

		if (testInfo.testType === TestType.YN || testInfo.testType === TestType.Score) {
			service
				.downloadYNScoreSessionDetails({
					testName: testInfo.name,
					studentFirstName: student.firstName,
					studentLastName: student.lastName,
					classID,
					studentID: student.id,
					testID: testSession.testID,
					sessionID: testSession.id,
					subjectName: subject.name,
				})
				.subscribe({
					error: () => {
						dispatchAppEvent(ReportErrorEvent, new ReportErrorEvent('Unable to load pdf file.'));
					},
				});
		}

		if (testInfo.testType === TestType.Rubric) {
			service
				.downloadRubricSessionDetails({
					classID,
					sessionID: testSession.id,
					studentID: student.id,
					subjectID,
					subjectType: subject.type,
					testID: testSession.testID,
				})
				.subscribe();
		}
	}, [classID, service, student, subjectID, subjectTestData, testSession]);

	const testLauncherData = useMemo<TestLauncherData | null>(() => {
		if (!isNull(subjectTestData)) {
			return {
				analyticsData: null,
				studentResult: null,
				contentArea: subjectTestData.testInfo.contentArea,
				testID: subjectTestData.testInfo.id,
				testType: subjectTestData.testInfo.testType,
			};
		}

		return null;
	}, [subjectTestData]);


	const canEdit = useMemo( () => {
		const user = userStorage.get();
		if (!user.canAddEditTestSessions || !testSession){
			return false;
		}

		if (user.userType === UserType.T) {
			return user.userID === testSession.testedBy || testSession.testedBy === null;
		} else {
			return user.userID === testSession.testedBy;
		}
	}, [testSession]);

	useEventEffect(
		TestSubsetStartedEvent,
		({questionsIDs}) => {
			setTestLauncherQuestionIDs(questionsIDs);
		},
		[],
	);

	const withSubsetButton =
		!isNull(testLauncherData) && !isNull(student) && !isNull(subjectTestData) && !isNull(selectedClass);

	const getAlertContent = () => {
		if (!isDataLoaded || !isStudentsListLoaded || !isSubjectsLoaded || !isClassesListLoaded) {
			return <SkeletonTestSession canEdit={canEdit} onCloseAlert={closeAlert} />;
		}

		if (isNull(testSession) || isNull(subjectTestData) || isNull(student)) {
			return null;
		}

		const {testInfo} = subjectTestData;

		switch (testInfo.testType) {
			case TestType.YN:
				return (
					<YesNoTestSession
						testInfo={testInfo}
						sessionInfo={testSession}
						canEdit={canEdit}
						isEditMode={isEditMode}
						onCloseAlert={closeAlert}
						setIsEditMode={setIsEditMode}
						student={student}
						onDeleteSession={onDeleteSession}
						onRestoreSession={onRestoreSession}
						updateYNSession={onUpdateYNSession}
						onDownloadSession={onDownloadSession}
						withSubsetButton={withSubsetButton}
					/>
				);
			case TestType.Score:
				return (
					<SingleScoreTestSession
						testInfo={testInfo}
						sessionInfo={testSession}
						canEdit={canEdit}
						isEditMode={isEditMode}
						onCloseAlert={closeAlert}
						setIsEditMode={setIsEditMode}
						student={student}
						onDeleteSession={onDeleteSession}
						onDownloadSession={onDownloadSession}
						onRestoreSession={onRestoreSession}
						updateScoreSession={onUpdateScoreSession}
					/>
				);
			case TestType.Rubric:
				return (
					<RubricTestSession
						testInfo={testInfo}
						sessionInfo={testSession}
						canEdit={canEdit}
						isEditMode={isEditMode}
						onCloseAlert={closeAlert}
						setIsEditMode={setIsEditMode}
						student={student}
						onDeleteSession={onDeleteSession}
						onRestoreSession={onRestoreSession}
						onDownloadSession={onDownloadSession}
						updateRubricSession={service.updateRubricSession.bind(service)}
					/>
				);

			default:
				return null;
		}
	};

	const isOpenedTestLauncher = !isNull(testLauncherQuestionIDs);

	return (
		<>
			{isTimeConflict && (
				<TestSessionTimeConflict onClose={() => setIsTimeConflict(false)} />
			)}
			{!isOpenedTestLauncher && (
				<Alert
					colorConfig={isEditMode ? colorConfigInEditMode : alertColorsConfig.neutral}
					modalManagerRef={alertRef}
					css={{
						'& [data-alert-content]': {
							width: 510,
							maxHeight: 'calc(100% - 40px)',
							gridTemplateRows: 'auto 1fr auto',
							overflow: 'hidden',
						},
					}}
					dataCy='session-details-alert'
				>
					{getAlertContent()}
				</Alert>
			)}

			{isOpenedTestLauncher && withSubsetButton && (
				<Testing
					onFlashCardsClicked={() => navigate(routes.teacher.activities.flashcards)}
					onTestSessionDetailsClicked={noop}
					student={student}
					subject={{
						id: subjectID,
						type: subjectTestData.subject.type,
						name: subjectTestData.subject.name,
					}}
					studentClass={selectedClass}
					launcherData={testLauncherData}
					onClose={onAlertClose}
					questionIds={testLauncherQuestionIDs}
				/>
			)}
		</>
	);
}
