import {ProgressBar} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {isNull} from 'underscore';

type Props = {
	value: number | null;
	maxValue: number;
};

export function RingFullFilledProgress({value, maxValue}: Props) {
	return (
		<ProgressBar value={value ?? 0} maxValue={maxValue}>
			{({progress}) => {
				const progressPercentage = progress * 100;
				const isPositiveResult = progressPercentage > 50;

				return (
					<GridBox gap='3' align='center' flow='column'>
						<ProgressBar.RingFullFilled
							size={60}
							circleFillColor='background'
							circleStrokeColor='border'
							chartStrokeWidth={1}
							progressColor={isPositiveResult ? 'positiveVivid' : 'lowContrast'}
						/>

						<Text size='large' font='mono' bold color={isPositiveResult ? 'positive' : 'highContrast'}>
							{!isNull(value) ? `${Math.round(progressPercentage)}%` : '-'}
						</Text>
					</GridBox>
				);
			}}
		</ProgressBar>
	);
}
