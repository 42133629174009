import {RoutesMap} from '@esgi/main/libs/core';

export class InvitationTemplate {
	public static Render() {
		return <div className='invitation-container'>
			<div className='invitation-registration-modal fixed'>
				<div className='inner-modal'>
					<div className='header'>
						<div className='trial-header'>
							<div className='title-container'>
								<div className='title'>
									<b>Apply Invitation Code</b>
								</div>
							</div>
						</div>
					</div>
					<div className='fields'>
						<div className='trial-content'>
							<div data-bind='render:code' />
							<ko data-bind='if: codeIsValid()'>
								<div data-bind='render:schoolName'></div>
								<div data-bind='render:email' />
								<div data-bind="css: {'show': email.validationResult().isExist}"
								     className='exist-validation-message'>
									An account with this email already exists. Do you want to <a
									href='/login'>login</a> to your existing account?
								</div>
								<div data-bind='render:username' />
								<div data-bind='render:password' />
								<div data-bind='render:fullName' />
								<label>Timezone</label>
								<div data-bind='render:timezone' />

								<div className='terms-policy'>
										<span>
											By creating an account, you are agreeing to our&nbsp;
											<a href={RoutesMap.esgiAgreement}>
												Terms of Use
											</a>
											&nbsp;and&nbsp;
											<a href={RoutesMap.privacyPolicy}>
												Privacy Policy
											</a>.
										</span>
								</div>
							</ko>
						</div>
					</div>
					<div className='footer'>
						<div className='actions'>
							<ko data-bind='if: codeIsValid()'>
								<button className='btn next-btn' data-bind='enable: !email.environmentError() && !userCreating(), click:ui.register'>REGISTER</button>
							</ko>
							<ko data-bind='if: !codeIsValid()'>
								<button className='btn next-btn'>ACTIVATE</button>
							</ko>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}
