import {AnswerState, SessionType} from '@esgi/core/enums';
import {QuestionModel} from '../../../../../kit/models';
import {convertToQuestionModel} from '../../../../../kit/utils';
import {TestingModel} from '../../../models';

export function convertQuestions(testingModel: TestingModel, sessionType: SessionType, order: number[], resume: boolean, continueSession?: boolean): QuestionModel[] {
	let source = testingModel.questions.map(q => convertToQuestionModel(q, testingModel.bucketName, testingModel.general.testID, resume, continueSession));

	source = order.map(id => source.find(q => q.questionID === id)).filter(Boolean);

	if(resume) {
		return source;
	}

	return source.filter((q) => {
		if (sessionType === SessionType.TestIncorrect) {
			return q.oldAnswerState !== AnswerState.Correct;
		}
		return true;
	});
}
