import {Folder} from '@esgi/ui/icons';
import {Drawer, OptionItem, Select, StudentsSearchableList} from '@esgi/main/features/teacher/home';
import {Class, Group} from '@esgi/main/libs/store';
import {DrawerBody} from '../drawer-body.styled';
import {useEffect, useMemo, useState} from 'react';
import {useService} from '@esgi/core/service';
import {AddGroupService} from './service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Student} from '../../types';
import {dispatchAppEvent} from '@esgillc/events';
import {AddNewGroup} from '../../events';
import {GroupNameForm} from '../group-name-form';
import {isNull} from 'underscore';
import {useFormState} from '../../../hooks';

type Props = {
	onLoaded: (value: boolean) => void;
	onFormTouched: (value: boolean) => void;
	initialClassID: Class['id'] | null;
};

export function AddGroup({onLoaded, onFormTouched, initialClassID}: Props) {
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	const [selectedClassId, setSelectedClassId] = useState<string | undefined>(
		!isNull(initialClassID) ? String(initialClassID) : undefined,
	);

	const [groupName, setGroupName] = useState<string | null>(null);
	const [restoredGroupName, setRestoredGroupName] = useState<string>('');
	const [isGroupNameFormValid, setIsGroupNameFormValid] = useState(false);
	const [isGroupNameFormTouched, setIsGroupNameFormTouched] = useState(false);

	const [studentsList, setStudentsList] = useState<Student[]>([]);
	const [selectedStudentIds, setSelectedStudentIds] = useState<Student['id'][]>([]);

	const dataService = useService(AddGroupService);

	const classes = useBehaviorSubject(dataService.classes$);

	const classSelectItems = useMemo(
		() => classes?.map<OptionItem>(({id, name}) => ({label: name, value: String(id)})) ?? [],
		[classes],
	);

	const forceDrawerClose = Drawer.useForceDrawerClose();

	const {getState, setState} = useFormState('addGroup');

	useEffect(() => {
		onLoaded(false);

		dataService.init().subscribe(() => {
			onLoaded(true);
			const state = getState(true);
			if (state?.selectedClassId) {
				setSelectedClassId(state.selectedClassId);
			}
			if (state?.groupName) {
				setRestoredGroupName(state.groupName);
			}
		});
	}, []);

	useEffect(() => {
		onFormTouched(
			isNull(initialClassID)
				? Boolean(selectedClassId) || isGroupNameFormTouched
				: selectedClassId !== String(initialClassID) || isGroupNameFormTouched || Boolean(selectedStudentIds.length),
		);
	}, [selectedClassId, isGroupNameFormTouched, initialClassID, selectedStudentIds]);

	useEffect(() => {
		if (selectedClassId) {
			setSelectedStudentIds([]);

			setStudentsList(() => {
				const selectedClass = classes?.find(({id}) => String(id) === selectedClassId);

				return selectedClass ? selectedClass.students : [];
			});
		}
	}, [selectedClassId, classes]);

	const handleSaveGroup = () => {
		if (groupName) {
			setIsFormSubmitting(true);

			dataService
				.createGroup({name: groupName, studentIDs: selectedStudentIds, classId: Number(selectedClassId)})
				.subscribe({
					next: ({groupID}) => {
						const newGroup: Group = {
							classID: Number(selectedClassId),
							id: groupID,
							name: groupName,
							studentIDs: selectedStudentIds,
						};

						dispatchAppEvent(AddNewGroup, new AddNewGroup(newGroup));
					},
					complete: () => {
						setIsFormSubmitting(false);
						forceDrawerClose();
					},
				});
		}
	};

	const isActionButtonDisabled =
		!(selectedClassId && isGroupNameFormValid && isGroupNameFormTouched) || isFormSubmitting;

	return (
		<>
			<Drawer.Header
				Icon={Folder}
				sectionName='New Group'
				withActionButton
				actionButtonDisabled={isActionButtonDisabled}
				actionButtonText='Create Group'
				onActionButtonClick={handleSaveGroup}
			/>
			<DrawerBody>
				<Drawer.ContentBlock title='Details'>
					<Select
						placeholder='Class'
						items={classSelectItems}
						selectedValue={selectedClassId}
						onValueChange={setSelectedClassId}
					/>
					<GroupNameForm
						restoredGroupName={restoredGroupName}
						setIsFormValid={setIsGroupNameFormValid}
						setGroupName={setGroupName}
						onFormTouched={setIsGroupNameFormTouched}
					/>
				</Drawer.ContentBlock>
				<Drawer.ContentBlock title='Select Students' titleBold>
					<StudentsSearchableList
						students={studentsList}
						selectedStudentIds={selectedStudentIds}
						setSelectedStudentIds={setSelectedStudentIds}
						onAddStudent={() => setState({selectedClassId, groupName})}
					/>
				</Drawer.ContentBlock>
			</DrawerBody>
		</>
	);
}
