import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useMemo, useState} from 'react';
import {SubjectLevel, SubjectTab, storage, useStore} from '@esgi/main/libs/store';
import {isNull} from 'underscore';
import {Select} from '@esgi/ui/controls';
import {SelectOption} from './components/select-option';

type Props = {
	onClose: VoidFunction;
	testID: number;
	subjectID: SubjectTab['id'];
	onSave: (args: {previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}) => void;
};

export function MoveToAlert({onClose, subjectID, testID, onSave}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [selectedSubjectID, setSelectedSubjectID] = useState(String(subjectID));

	const [subjects, _, isSubjectsLoaded] = useStore(storage.subjects);

	const subjectSelectItems = useMemo(
		() =>
			subjects
				.filter(({level}) => level === SubjectLevel.Teacher)
				.map(({id, name}) => ({label: name, value: String(id)})),
		[subjects],
	);

	const {currentSubject, currentTest} = useMemo(() => {
		const subject = subjects.find(({id}) => id === subjectID);
		const test = subject?.tests.find(({id}) => id === testID);

		return {
			currentSubject: subject ?? null,
			currentTest: test ?? null,
		};
	}, [subjectID, subjects, testID]);

	const saveAndClose = () => {
		const newSubject = subjects.find(({id}) => id === Number(selectedSubjectID)) ?? null;

		if (isNull(currentSubject) || isNull(newSubject)) {
			return;
		}

		onSave({previousSubject: currentSubject, newSubject, testID});
		closeAlert();
	};

	return (
		<Alert
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					width: 510,
				},
			}}
		>
			{isSubjectsLoaded && !isNull(currentTest) && (
				<>
					<Alert.Header onCloseIconClick={closeAlert}>
						<Text size='large'>Move "{currentTest.name}" to a Subject Tab</Text>
					</Alert.Header>
					<Alert.Body>
						<Select.Root value={selectedSubjectID} onValueChange={setSelectedSubjectID}>
							<Select.Field placeholder='Subject' />
							<Select.Content>
								{subjectSelectItems.map(({label, value}) => (
									<SelectOption value={value} key={value} label={label} />
								))}
							</Select.Content>
						</Select.Root>
					</Alert.Body>
					<Alert.Footer>
						<GridBox gap='3' flow='column'>
							<Button color='tertiary' onClick={closeAlert}>
								<Text size='medium' bold color='base'>
									Cancel
								</Text>
							</Button>
							<Button color='secondary' onClick={saveAndClose} disabled={subjectID === Number(selectedSubjectID)}>
								<Text size='medium' bold color='base'>
									Save
								</Text>
							</Button>
						</GridBox>
					</Alert.Footer>
				</>
			)}
		</Alert>
	);
}
