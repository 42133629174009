import React, {useMemo} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {CardViewType, TestModel} from '../../service/types';
import {ListView} from './components/list-view';
import {GridView} from './components/grid-view';
import {SubjectTab} from '@esgi/main/libs/store';
import {useUser} from '@esgi/core/authentication';

type Props = BaseComponentProps & Omit<TestModel & {subjects: SubjectTab[]}, 'gradeLevels'> & {
	selectedStandardType: number;
	selectedStandardsIDs: number[];
	cardView: CardViewType,
	onTestSelect: (testID: TestModel['testID']) => void,
	onAddToFavoriteClick: VoidFunction,
	isHidden,
	showTestDetails: VoidFunction,
	selected: boolean,
	showAddToFavorites: boolean,
	gradeLevels: string[],
	isSmallScreen: boolean,
}

export function Card({cardView, isSmallScreen, ...props}: Props) {
	const currentUser = useUser();

	const standards = useMemo(() => {
		if (props.selectedStandardsIDs.length) {
			const selectedStandardTypeStandards = props.stateStandards?.filter(({stateID}) => stateID === props.selectedStandardType);

			return selectedStandardTypeStandards?.map(({name}) => name).sort();
		}


		const userStateRelatedStandards = props.stateStandards?.filter(({stateID}) => stateID === currentUser.stateID);
		const commonCoreStandards = props.stateStandards?.filter(({stateID}) => stateID === 0);

		return (userStateRelatedStandards.length ? userStateRelatedStandards : commonCoreStandards).map(({name}) => name).sort();
	}, [currentUser.stateID, props.selectedStandardType, props.selectedStandardsIDs.length, props.stateStandards]);


	if (cardView === CardViewType.Grid || isSmallScreen) {
		return <GridView {...props} stateStandards={standards} cardView={cardView} isSmallScreen={isSmallScreen}/>;
	}

	if (cardView === CardViewType.List) {
		return <ListView {...props} stateStandards={standards} cardView={cardView}/>;
	}
}