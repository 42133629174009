import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useMemo} from 'react';
import moment from 'moment';
import {MeridiemText, TimeMeridiem, TimeText} from './index.styled';
import {useUser} from '@esgi/core/authentication';
import {convertToDateTimeZone} from './helper';

type Props = {
	dateTime: Date | string;
	dataCy?: string
};

export function TimeStamp({dateTime, dataCy='time-stamp'}: Props) {
	const userTimeZone = useUser()?.timeZone;

	const {date, time, meridiem} = useMemo(() => {
		const dateValue = convertToDateTimeZone(userTimeZone, dateTime);
		return {
			meridiem: moment(dateValue).format('A'),
			time: moment(dateValue).format('hh:mm'),
			date: moment(dateValue).format('MM/DD/YYYY'),
		};
	}, [dateTime, userTimeZone]);

	return (
			<GridBox flow='column' gap='3' align='center' dataCy={dataCy}>
				<Text size='medium' font='mono' bold data-cy={`${dataCy}-date`}>
					{date}
				</Text>
				<TimeMeridiem>
					<TimeText size='small' font='mono' color='neutral40' bold data-cy={`${dataCy}-time`}>
						{time}
					</TimeText>
					<MeridiemText size='small' font='mono' color='vivid' data-cy={`${dataCy}-meridiem`}>
						{meridiem}
					</MeridiemText>
				</TimeMeridiem>
			</GridBox>
	);
}
