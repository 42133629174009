import {useService} from '@esgi/core/service';
import {DataStateService} from '../services/data-state-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {StudentSort, useUser} from '@esgi/core/authentication';

export function useStateService() {
	const currentUser = useUser();

	const stateService = useService(DataStateService);

	const sectionsState = useBehaviorSubject(stateService.sectionsState$);
	const selectedSubjectId = useBehaviorSubject(stateService.selectedSubjectId$);
	const activeSubjectTab = useBehaviorSubject(stateService.activeSubjectTab$);
	const selectedClassId = useBehaviorSubject(stateService.selectedClassId$);
	const selectedGroupId = useBehaviorSubject(stateService.selectedGroupId$);
	const groupingFilterType = useBehaviorSubject(stateService.groupingFilterType);
	const showAllStudents = useBehaviorSubject(stateService.showAllStudents);
	const lastWeekList = useBehaviorSubject(stateService.lastWeekList);
	const lastWeek = useBehaviorSubject(stateService.selectedLastWeek);
	const tests = useBehaviorSubject(stateService.testsService.tests);
	const students = useBehaviorSubject(stateService.testsService.students);

	const sortedStudents = [...students].sort((a, b) => {
		const sortStudentsBy = currentUser?.studentSort;

		if (sortStudentsBy === StudentSort.FirstName) {
			return a.firstName.localeCompare(b.firstName);
		}

		if (sortStudentsBy === StudentSort.LastName) {
			return a.lastName.localeCompare(b.lastName);
		}

		return 0;
	});

	return {
		sectionsState,
		selectedSubjectId,
		activeSubjectTab,
		selectedClassId,
		selectedGroupId,
		groupingFilterType,
		showAllStudents,
		lastWeekList,
		lastWeek,
		stateService,
		tests,
		students: sortedStudents,
	};
}
