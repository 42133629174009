import {Drawer} from '@esgi/main/kits/common';
import {ChangeEvent, useCallback, useState} from 'react';
import {PanelContent} from '../../components/panels.styled';
import {ContentBox} from '../../components/content-box.styled';
import {GridBox} from '@esgi/ui/layout';
import {Input} from '@esgi/ui/controls';
import {DisabledInput} from '../../components/disabled-input';
import {SecondaryProfile} from '../../../../types';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {Logout} from '../../../logout';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Observable} from 'rxjs';

type Props = {
	firstName: string;
	lastName: string;
	email: string;
	saveSecondaryProfileData: (body: Partial<SecondaryProfile>) => Observable<unknown>;
};

export function PersonalInformationContent({firstName, lastName, email, saveSecondaryProfileData}: Props) {
	const [controlledFirstName, setControlledFirstName] = useState(firstName);
	const [controlledLastName, setControlledLastName] = useState(lastName);

	const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>, nameType: 'firstName' | 'lastName') => {
		const value = event.target.value;

		if (nameType === 'firstName') {
			setControlledFirstName(value);
		}

		if (nameType === 'lastName') {
			setControlledLastName(value);
		}
	}, []);

	const handleSave = () => {
		saveSecondaryProfileData({
			firstName: controlledFirstName,
			lastName: controlledLastName,
		}).subscribe();
	};

	const isActionButtonActive = controlledFirstName !== firstName || controlledLastName !== lastName;

	return (
		<>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={!isActionButtonActive}
			>
				<PanelHeaderTitle title='Personal Information' />
			</Drawer.PanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						<Drawer.ContentBlock title='Name'>
							<GridBox flow='column' gap='3'>
								<Input
									placeholder='First Name'
									value={controlledFirstName}
									onChange={(event) => handleNameChange(event, 'firstName')}
								/>
								<Input
									placeholder='Last Name'
									value={controlledLastName}
									onChange={(event) => handleNameChange(event, 'lastName')}
								/>
							</GridBox>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Email'>
							<DisabledInput
								value={email}
								placeholder='Email'
								inputTranscript='To change your email, please contact our Customer Support team or your system administrator.'
								dataCy='email-disabled-input'
							/>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Terminate Current Session'>
							<Logout />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
