import {
	Drawer,
	SubjectName,
	TestColorIndicator,
	TestContentArea,
	useDrawerClose,
	useDrawerRef,
} from '@esgi/main/kits/common';
import {Button, Close, Plus} from '@esgi/ui';
import {RefObject, useCallback, useState} from 'react';
import {TestModel} from '../../service/types';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {Wrapper, SubTitle, TestListItem, Section, SelectableListItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {SubjectTab} from '@esgi/main/libs/store';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {TestExplorerService} from '../../service/service';
import {PersonalSubjectDialog} from '@esgi/main/features/teacher/personal-subject-dialog';
import {SubjectCreatedEvent} from '@esgi/main/features/teacher/personal-subject-dialog/events';
import {useEventEffect} from '@esgillc/events';
import {AddButton} from '@esgi/main/features/standards-drawer';

type Props = {
	service: TestExplorerService;
	subjects: SubjectTab[];
	tests: TestModel[];
	selectedSubject: SubjectTab;
	onClose: VoidFunction;
	onSubjectSelect: (subject: SubjectTab) => void;
	onTestSelect: (testID: TestModel['testID']) => void;
	loaded: boolean;
	snackbarRef: RefObject<SnackbarManager>;
}

export function AddToSubjectDrawer({
	service,
	tests,
	selectedSubject,
	onClose,
	onTestSelect,
	onSubjectSelect,
	snackbarRef,
	subjects,
}: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, onClose);

	const [showCreateSubjectModal, setShowCreateSubjectModal] = useState(false);

	const onActionButtonClick = useCallback(() => {
		if (!selectedSubject) {
			return;
		}
		service.onAddToSubject().subscribe(() => {
			snackbarRef.current?.showSnackbar(`You have added selected tests to ${selectedSubject.name} subject`);
			close();
		});
	}, [close, selectedSubject, service, snackbarRef]);

	const onTestRemoveClick = useCallback((testID: TestModel['testID']) => {
		onTestSelect(testID);
		if (tests.length === 1) {
			onClose();
		}
	}, [onClose, onTestSelect, tests.length]);

	useEventEffect(SubjectCreatedEvent, ({newSubject}) => {
		onSubjectSelect(newSubject);
	});

	return (
		<>
			<Drawer width={400} drawerRef={drawerRef}>
				<Drawer.Header
					Icon={Plus}
					sectionName='Add to Subject Tab'
					actionButtonDisabled={!selectedSubject}
					withActionButton
					onActionButtonClick={onActionButtonClick}
					actionButtonText='Add'
					closeDrawer={close}
					childrenAlignment='right'
				/>
				<Drawer.Body>
					<Wrapper>
						<GridBox>
							<Section>
								<SubTitle size='small' font='mono' color='mediumContrast'>
									Selected Tests
								</SubTitle>
								<OverlayScrollbarsComponent style={{height: 'calc(100% + 0px)'}} defer options={{scrollbars: {autoHide: 'leave'}}}>
									<GridBox gap={2}>
										{tests.map(({testID, contentArea, name}) => (
											<TestListItem key={testID}>
												<GridBox flow='column' justify='start' align='center' gap={2}>
													<TestColorIndicator testID={testID} contentArea={contentArea as TestContentArea}/>
													<Text size='large'>{name}</Text>
												</GridBox>
												<Button.Icon onClick={() => onTestRemoveClick(testID)}>
													<Close height={24} width={24}/>
												</Button.Icon>
											</TestListItem>
										))}
									</GridBox>
								</OverlayScrollbarsComponent>
							</Section>
							<Section>
								<SubTitle size='small' font='mono' color='mediumContrast'>
									Subject Tabs
								</SubTitle>
								<OverlayScrollbarsComponent style={{height: 'calc(100% + 0px)'}} defer options={{scrollbars: {autoHide: 'leave'}}}>
									<GridBox gap={2}>
										<SelectableList>
											<SelectableList.GroupRoot type='single' value={String(selectedSubject?.id)}>
												<SelectableList.Group >
													{subjects?.map((subject) => (
														<SelectableListItem value={String(subject.id)} key={subject.id} onClick={() => onSubjectSelect(subject)}>
															<SubjectName subjectLevel={subject.level} noWrap size='medium' bold>
																{subject.name}
															</SubjectName>
														</SelectableListItem>
													))}
												</SelectableList.Group>
											</SelectableList.GroupRoot>
										</SelectableList>
									</GridBox>
								</OverlayScrollbarsComponent>
							</Section>
						</GridBox>
						<Section>
							<Text size='medium' color='highContrast'>
								You can create a new subject tab by selecting the Create New Subject Tab button below. Once your new tab appears in the list above, you may add tests to it.
							</Text>
							<AddButton label='Create New Subject Tab' borderType='secondary' onClick={() => setShowCreateSubjectModal(true)}/>
						</Section>
					</Wrapper>
				</Drawer.Body>
			</Drawer>
			{showCreateSubjectModal &&
				<PersonalSubjectDialog.CreatePersonalSubject
          onClose={() => setShowCreateSubjectModal(false)}
					forceClose
				/>
			}
		</>
	);
}