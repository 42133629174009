import {Dispatch, useCallback, useMemo} from 'react';
import {Label, SelectRoot, SelectContent, SelectField, SelectOption} from './index.styled';
import {Option} from './types';
import {SubjectTab} from '@esgi/main/libs/store';

interface Props {
	subjects: SubjectTab[];
	value: SubjectTab['id'];
	onChange: Dispatch<SubjectTab['id']>;
}

export function SubjectTabControl({subjects, onChange, value}: Props) {
	const handleValueChanged = useCallback(
		(value: string) => {
			onChange(Number(value));
		},
		[onChange],
	);

	const selectItems = useMemo<Option[]>(
		() => [
			{
				value: -1,
				label: 'All',
			},
			...subjects.map<Option>(({id, name}) => ({
				value: id,
				label: name,
			})),
		],
		[subjects],
	);

	return (
		<SelectRoot dataCy='subject-select' value={String(value)} onValueChange={handleValueChanged}>
			<SelectField placeholder='Subject Tab' />
			<SelectContent>
				{selectItems.map(({value, label}) => (
					<SelectOption key={value} value={String(value)}>
						<Label size='medium' bold>
							{label}
						</Label>
					</SelectOption>
				))}
			</SelectContent>
		</SelectRoot>
	);
}
