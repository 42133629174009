import {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {StudentForSaving, StudentWithInitialCredentials} from '../types';
import {StudentCredentialsState, UpdateStudentInState} from './types';
import {Student, storage, useStore} from '@esgi/main/libs/store';
import {useService} from '@esgi/core/service';
import {StudentCredentialsService} from '../student-credentials-service';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {map} from 'rxjs';
import {notUniqueUserNameMessage} from '../constants';
import {isNull} from 'underscore';

export function useComponentState({
	studentIds,
	snackbarRef,
	closeStudentCredentialsAlert,
}: {
	studentIds: Student['id'][];
	snackbarRef: RefObject<SnackbarManager>;
	closeStudentCredentialsAlert: VoidFunction;
}) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isCredentialsSaving, setIsCredentialsSaving] = useState(false);

	const studentCredentialsList = useRef<StudentWithInitialCredentials[]>([]);

	const [studentsCredentialsState, setStudentsCredentialsState] = useState<StudentCredentialsState>({});

	const [studentList, updateStudentInStore, isStudentListLoaded] = useStore(storage.students);

	const studentCredentialsService = useService(StudentCredentialsService);

	useEffect(() => {
		if (isStudentListLoaded) {
			setIsLoaded(false);

			const students = studentList.filter(({id}) => studentIds.includes(id));

			studentCredentialsService.init({studentIDs: students.map(({id}) => id)}).subscribe({
				next: ({studentCredentials}) => {
					const initialState = students.reduce((currentValue, student) => {
						const studentModel = students.find(({id}) => id === student.id);
						const studentWithCredentials = studentCredentials.find(({id}) => id === student.id);

						const userName = studentWithCredentials?.userName ?? '';
						const password = studentWithCredentials?.password ?? '';

						currentValue[student.id] = {
							firstName: studentModel?.firstName ?? '',
							lastName: studentModel?.lastName ?? '',
							photoUrl: studentModel?.photoUrl ?? '',
							isCredentialsValid: true,
							isCredentialsTouched: false,
							isPasswordHidden: true,
							initialUserName: userName,
							userName,
							initialPassword: password,
							password,
						};

						return currentValue;
					}, {} as StudentCredentialsState);

					studentCredentialsList.current = students.map<StudentWithInitialCredentials>((student) => ({
						...student,
						initialUserName: initialState[student.id]?.initialUserName ?? '',
						initialPassword: initialState[student.id]?.initialPassword ?? '',
					}));

					setStudentsCredentialsState(initialState);
				},
				complete: () => {
					setIsLoaded(true);
				},
			});
		}
	}, [studentIds, isStudentListLoaded, studentList]);

	const isAllCredentialsValid = Object.values(studentsCredentialsState).every(
		({isCredentialsValid}) => isCredentialsValid,
	);

	const isStudentCredentialsTouched = Object.values(studentsCredentialsState).some(
		({isCredentialsTouched}) => isCredentialsTouched,
	);

	const isAllPasswordsOpened = Object.values(studentsCredentialsState).every(({isPasswordHidden}) => !isPasswordHidden);

	const updateStudentInState = useCallback<UpdateStudentInState>(({studentId, newValue}) => {
		setStudentsCredentialsState((currentState) => {
			const currentStudentValue = currentState[studentId];

			if (currentStudentValue) {
				currentState[studentId] = {
					...currentStudentValue,
					...newValue,
				};
			}

			return {
				...currentState,
			};
		});
	}, []);

	const resetAllCredentials = useCallback(() => {
		studentCredentialsList.current.forEach(({id, initialPassword, initialUserName}) => {
			updateStudentInState({
				studentId: id,
				newValue: {
					userName: initialUserName,
					password: initialPassword,
					isCredentialsTouched: false,
				},
			});
		});
	}, [updateStudentInState]);

	const toggleAllPasswordHidden = useCallback(() => {
		studentCredentialsList.current.forEach(({id}) => {
			updateStudentInState({
				studentId: id,
				newValue: {
					isPasswordHidden: isAllPasswordsOpened,
				},
			});
		});
	}, [updateStudentInState, isAllPasswordsOpened]);

	const onValidateUsername = ({
		value,
		initialValue,
		studentID,
	}: {
		value: string;
		studentID: number;
		initialValue: string;
	}) => {
		return studentCredentialsService
			.validateUsername({
				value,
				studentID,
				initialValue,
			})
			.pipe(
				map((validateMessage) => {
					if (isNull(validateMessage) && !value) {
						return null;
					}

					const studentWithSameUserName = Object.entries(studentsCredentialsState).find(
						([id, {userName}]) => userName === value && Number(id) !== studentID,
					);

					if (studentWithSameUserName) {
						return notUniqueUserNameMessage;
					}

					const studentWithSameInitialUserName = Object.entries(studentsCredentialsState).find(
						([_, {initialUserName}]) => initialUserName === value,
					);

					if (studentWithSameInitialUserName) {
						updateStudentInState({
							studentId: Number(studentWithSameInitialUserName[0]),
							newValue: {
								isCredentialsValid: true,
							},
						});

						updateStudentInState({
							studentId: studentID,
							newValue: {
								isCredentialsValid: true,
							},
						});

						return null;
					}

					const studentWithValueAsInitialValue = Object.entries(studentsCredentialsState).find(
						([_, {userName}]) => userName === initialValue,
					);

					if (studentWithValueAsInitialValue) {
						updateStudentInState({
							studentId: Number(studentWithValueAsInitialValue[0]),
							newValue: {
								isCredentialsValid: true,
							},
						});
					}

					return validateMessage;
				}),
			);
	};

	const onSaveAllUserCredentialsClick = () => {
		setIsCredentialsSaving(true);

		const students = Object.entries(studentsCredentialsState).map<StudentForSaving>(
			([studentId, {userName, password}]) => ({
				id: Number(studentId),
				name: userName,
				password,
			}),
		);

		studentCredentialsService.saveStudentCredentials(students).subscribe({
			next: () => {
				function processStudent(index: number) {
					if (index < students.length) {
						const {id: studentId, name, password} = students[index]!;
						const student = studentList.find(({id}) => id === studentId);

						if (student) {
							updateStudentInStore(student, {...student, hasCredentials: Boolean(name && password)}).subscribe(() => {
								processStudent(index + 1);
							});
						} else {
							processStudent(index + 1);
						}
					}
				}

				processStudent(0);
			},
			complete: () => {
				closeStudentCredentialsAlert();

				setIsCredentialsSaving(false);

				snackbarRef.current?.showSnackbar('Credentials for selected students have been updated successfully');
			},
		});
	};

	const skeleton = !isLoaded || !isStudentListLoaded;

	return {
		isStudentCredentialsTouched,
		skeleton,
		isAllPasswordsOpened,
		isAllCredentialsValid,
		toggleAllPasswordHidden,
		studentCredentialsService,
		studentsCredentialsState,
		resetAllCredentials,
		onSaveAllUserCredentialsClick,
		isCredentialsSaving,
		updateStudentInState,
		onValidateUsername,
	};
}
