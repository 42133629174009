import {
	Checkbox,
	Container,
	Content,
	Header,
	HiddenIcon,
	ImageWrapper,
	InfoWrapper,
	StyledButton,
	StyledImage,
	Title,
} from '../index.styled';
import {TestColorIndicator, TestContentArea} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {CardViewType, TestModel, TestType} from '../../../service/types';
import {TestTypeLabel} from '../../test-type-label';
import {MultipleLabels} from '../../multiple-labels';
import {BaseComponentProps, Fav, Tooltip} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {LabelBox} from '../../test-type-label/index.styled';
import React, {useCallback, useState} from 'react';
import {questionLabelByTestType, testLabelByTestType} from '../../../constants';
import {InfoItem} from './info-item';
import {QuestionsSlider} from '../../questions-slider';
import {rubricThumbnail} from '../../questions-slider/constants';
import {TestType as TestTypeModel} from '@esgi/main/libs/core';
import RubricPreview from 'modules/assets/tests/rubric/preview/preview-flow';
import {SpecialInfo} from './special-info';
import {StyledDescription} from './styled-description';
import {SubjectTab} from '@esgi/main/libs/store';
import moment from 'moment/moment';
import {SubjectsCounter} from './subjects-counter';

type Props = BaseComponentProps & Omit<TestModel & {subjects: SubjectTab[]}, 'gradeLevels' | 'stateStandards'> & {
	onTestSelect: (testID: TestModel['testID']) => void,
	onAddToFavoriteClick: VoidFunction,
	showTestDetails: VoidFunction,
	showAddToFavorites,
	isHidden: boolean,
	selected: boolean,
	gradeLevels: string[],
	stateStandards: string[],
	cardView: CardViewType,
}

export function ListView({
	name,
	testID,
	type,
	contentArea,
	stateStandards,
	description,
	creatorName,
	gradeLevels,
	totalPossible,
	numberOfQuestions,
	starred,
	dataCy,
	selected,
	onTestSelect,
	onAddToFavoriteClick,
	isHidden,
	showTestDetails,
	questions,
	testScreenTypes,
	showAddToFavorites,
	createDate,
	subjects,
	cardView,
	imageQuestionID,
}: Props) {
	const [showRubricPreview, setShowRubricPreview] = useState(false);
	const showRubricPreviewToggle = useCallback(() => setShowRubricPreview(prev => !prev), []);

	return (
		<Container selected={selected}>
			<Header>
				<InfoWrapper>
					<Checkbox checked={selected} onCheckedChange={() => onTestSelect(testID)} />
					<TestColorIndicator testID={testID} contentArea={contentArea as TestContentArea}/>
					<Title size='xLarge' onClick={showTestDetails} color={isHidden ? 'mediumContrast' : 'primary'} css={{
						lineHeight: '22px',
					}}>{name}</Title>
					{isHidden && <HiddenIcon />}
					<TestTypeLabel testType={type}/>
					{stateStandards.length ? <MultipleLabels items={stateStandards} /> : undefined}
				</InfoWrapper>
				<GridBox gap={3} flow='column'>
					<SubjectsCounter subjects={subjects}/>
					{showAddToFavorites && <Tooltip>
						<Tooltip.Trigger>
							<StyledButton checked={starred} onClick={onAddToFavoriteClick} dataCy='favorites-button'>
								<Fav/>
							</StyledButton>
						</Tooltip.Trigger>
						<Tooltip.Content side='top'>
							<Text size='large' color='tertiary'>
								{starred ? 'Remove from favorites' : 'Add to favorites'}
							</Text>
						</Tooltip.Content>
					</Tooltip>
					}
				</GridBox>
			</Header>
			<Content>
				{testLabelByTestType[type] === TestTypeModel.Rubric
					? <ImageWrapper><StyledImage src={rubricThumbnail} onClick={showRubricPreviewToggle}/></ImageWrapper>
					: <QuestionsSlider imageQuestionID={imageQuestionID} questions={questions} testID={testID} cardView={cardView}/>
				}
				<GridBox gap={4}>
					<StyledDescription description={description}/>
					<GridBox gap={3} flow='column' justify='start'>
						<GridBox gap={2} flow='column' justify='start'>
							<Text size='xSmall' font='mono'>By:</Text>
							<Text size='xSmall' font='mono' color='highContrast'>{creatorName}</Text>
						</GridBox>
						<GridBox gap={2} flow='column' justify='start'>
							<Text size='xSmall' font='mono'>Date:</Text>
							<Text size='xSmall' font='mono' color='highContrast'>{moment(createDate).format('MM.DD.YY')}</Text>
						</GridBox>
					</GridBox>
					<GridBox gap={3} flow='column' justify='start'>
						<InfoItem title='Content Area'>
							<LabelBox>
								<Text data-cy={`${dataCy}-label`} size='small' color='highContrast'>{contentArea}</Text>
							</LabelBox>
						</InfoItem>
						<InfoItem title='Grade Level'>
							<MultipleLabels items={gradeLevels}/>
						</InfoItem>
						<InfoItem title={questionLabelByTestType[TestType[type]]}>
							<LabelBox>
								<Text data-cy={`${dataCy}-label`} size='small' color='highContrast'>
									{TestTypeModel[type] === TestTypeModel.Score ? totalPossible : numberOfQuestions}
								</Text>
							</LabelBox>
						</InfoItem>
						<SpecialInfo testScreenTypes={testScreenTypes}/>
          </GridBox>
				</GridBox>
			</Content>
			{showRubricPreview && <RubricPreview testID={testID} onClose={showRubricPreviewToggle}/>}
		</Container>
	);
}
