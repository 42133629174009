import {BaseService} from '@esgi/core/service';
import {Class, Group, storage, Student} from '@esgi/main/libs/store';
import {BehaviorSubject} from 'rxjs';

export class DataStudentsService extends BaseService {
	public isLoadedData$ = new BehaviorSubject(false);
	public students$ = new BehaviorSubject<Student[]>([]);
	public classes$ = new BehaviorSubject<Class[]>([]);
	public groups$ = new BehaviorSubject<Group[]>([]);

	private studentsStorage = storage.students();
	private classesStorage = storage.classes();
	private groupsStorage = storage.groups();

	constructor() {
		super();
		this.isLoadedData$.next(false);

		this.studentsStorage.get().subscribe(students => {
			this.students$.next(students);
			if(!this.isLoadedData$.value) {
				this.isLoadedData$.next(true);
			}
		});

		this.classesStorage.get().subscribe(classes => {
			this.classes$.next(classes);
			if(!this.isLoadedData$.value) {
				this.isLoadedData$.next(true);
			}
		});

		this.groupsStorage.get().subscribe(groups => {
			this.groups$.next(groups);
			if(!this.isLoadedData$.value) {
				this.isLoadedData$.next(true);
			}
		});
	}
}
