import {SuggestedGradeLevel as GradeLevelStatic} from 'api/entities/suggested-grade-level';
import {SuggestedGradeLevel} from '@esgi/contracts/esgi/types/esgi.assets/enums/suggested-grade-level';
import {ExtendedBankQuestionModel} from './types';

export function getItemTextColor(cardEntered: boolean, selected: boolean) {
	if (cardEntered) {
		return 'secondary';
	}

	if (selected) {
		return 'blue';
	}

	return undefined;
}

export function getItemGradeLevels(gradeLevelIDs: number[], gradeLevelFieldName: string) {
	if (gradeLevelIDs?.length > 0) {
		return gradeLevelIDs.map((level) => GradeLevelStatic.ByID(level)?.[gradeLevelFieldName] ?? 'None');
	}

	return ['None'];
}

export function getTagVariant(
	index: number,
	count: number
): 'first' | 'last' | 'middle' | undefined {
	if (count === 1) {
		return;
	}
	if (index === count - 1) {
		return 'last';
	}
	if (index === 0) {
		return 'first';
	}
	return 'middle';
}

export const getSuggestedGradeLevel = (value: number | undefined) => {
	switch (value) {
		case GradeLevelStatic.GradeOne.id:
			return SuggestedGradeLevel.GradeOne;
		case GradeLevelStatic.GradeThreePlus.id:
			return SuggestedGradeLevel.GradeThreePlus;
		case GradeLevelStatic.GradeTwo.id:
			return SuggestedGradeLevel.GradeTwo;
		case GradeLevelStatic.K.id:
			return SuggestedGradeLevel.K;
		case GradeLevelStatic.PreK.id:
			return SuggestedGradeLevel.PreK;
		case GradeLevelStatic.TK.id:
			return SuggestedGradeLevel.TK;
		default:
			return SuggestedGradeLevel.None;
	}
};

export function sortQuestionByOrderNum(question1: ExtendedBankQuestionModel, question2: ExtendedBankQuestionModel) {
	const orderNum1 = question1.orderNum ?? question1.id;
	const orderNum2 = question2.orderNum ?? question2.id;
	return orderNum1 - orderNum2;
}