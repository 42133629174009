import {styled} from '@esgi/ui/theme';

export const StyledImage = styled('img', {
	flexShrink: 0,
	width: '168px',
	height: '74px',
	objectFit: 'cover',
	borderRadius: '6px',
	variants: {
		isListView: {
			true: {
				width: '227px',
				height: '100px',
			},
		},
	},
});