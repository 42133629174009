import {Test} from '../../../../types';
import {ListItem, CaptionText, Counter, CounterText, TestColorIndicator} from './index.styled';
import {TestTypeLabel} from '@esgi/main/kits/reports';
import {TestType} from '@esgi/main/libs/core';

interface Props {
	item: Test;
	allSubjectMode?: boolean
}

function TestResult (props: {test: Test}) {
	if (props.test.type === TestType.YN) {
		return <Counter>
			<CounterText size='small' font='mono' bold type='correct' zero={!props.test.correct}>{props.test.correct}</CounterText>
			<CounterText size='small' font='mono' bold type='incorrect' zero={!props.test.incorrect}>{props.test.incorrect}</CounterText>
			<CounterText size='small' font='mono' bold type='untested' zero={!props.test.untested}>{props.test.untested}</CounterText>
		</Counter>;
	}
	if (props.test.type === TestType.Score || props.test.type === TestType.Rubric) {
		return <Counter>
			<CounterText size='small' font='mono' bold type='untested'>{props.test.correct}</CounterText>
			<CounterText size='small' font='mono' bold type='untested'>/</CounterText>
			<CounterText size='small' font='mono' bold type='untested'>{props.test.maxScore}</CounterText>
		</Counter>;
	}
	return null;
}

export function SelectableLisItemContent({item, allSubjectMode}: Props) {
	const hasResults = ({correct, incorrect, untested}: Omit<Test, 'id'>) =>
		correct + incorrect + untested !== 0;

	return (
		<ListItem data-cy='test-item-content'>
			<TestColorIndicator testID={item.id}/>
			<CaptionText data-cy='test-name' size='large'>{item.name} {allSubjectMode ? `(${item.subjectName})` : ''}</CaptionText>
			<TestTypeLabel testType={item.type} css={{marginRight: 12}} />
			{hasResults(item)
				?
				<TestResult test={item}/>
				:
				<Counter>
					<CounterText size='small' font='mono'>No Results</CounterText>
				</Counter>
			}
		</ListItem>
	);
}
