import {EntityType, TestType} from '../../../../types';
import {useHierarchy} from '../../hooks/use-hierarchy';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import TestHistory from 'modules/reports/test-history';

type Props = {
	entityType: EntityType,
	onCancel?: VoidFunction,
	testType: TestType
}

export function ChartExpandedView({onCancel, entityType, testType}: Props): JSX.Element {
	const {
		hierarchy,
		subjectType,
		subjectID,
		testId,
	} = useHierarchy(entityType);

	return (
		<TestHistory
			hierarchy={hierarchy}
			subjectID={subjectID}
			subjectType={SubjectType[subjectType]}
			testId={testId}
			onClose={onCancel}
			testType={testType}
			withoutSettings={true}
		/>
	);
}