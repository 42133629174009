import {VirtualItem} from '@tanstack/react-virtual';
import {BankQuestionModel, ColumnsCountType, QuestionContainer, ViewMode} from '../../../../types';
import {QuestionListItem, QuestionTileItem} from '../question-item';
import {Root} from './index.styled';
import {forwardRef} from 'react';

type VirtualQuestionItemProps = {
	virtualItem: VirtualItem;
	selectedView: ViewMode;
	columnsCount: ColumnsCountType;
	questions: QuestionContainer;
	allRowsLoaded?: boolean;
	selectedQuestions: QuestionContainer;
	onSelectionChanged: (question: BankQuestionModel) => void;
	onPreviewClick?: (question: BankQuestionModel) => void;
	selectedStandardType: number
};

export const QuestionRow = forwardRef<HTMLDivElement, VirtualQuestionItemProps>(({
	virtualItem,
	onSelectionChanged,
	selectedView,
	columnsCount,
	allRowsLoaded,
	questions,
	selectedQuestions,
	onPreviewClick,
	selectedStandardType,
}, forwardedRef) => {
	const singleQuestion = questions[virtualItem.index];
	const questionStartIndex = virtualItem.index * columnsCount;

	const containerStyle = {
		height: `${virtualItem.size}px`,
		transform: `translateY(${virtualItem.start}px)`,
	};

	return (
		<Root
			ref={forwardedRef}
			key={virtualItem.key}
			columns={selectedView === ViewMode.Grid ? columnsCount : undefined}
			style={containerStyle}
		>
			{selectedView === ViewMode.List && (
				<QuestionListItem
					selected={singleQuestion && !!selectedQuestions[singleQuestion.id]}
					onCheckedChanged={onSelectionChanged}
					question={singleQuestion}
					virtualKey={virtualItem.key}
					onPreviewClick={onPreviewClick}
					selectedStandardType={selectedStandardType}
				/>
			)}
			{selectedView === ViewMode.Grid && (
				Array.from({length: columnsCount}, (_, i) => i)
					.map((column) => questions[questionStartIndex + column])
					.filter((question) => question || !allRowsLoaded)
					.map((question)=> (
						<QuestionTileItem
							key={question?.id}
							selected={!!selectedQuestions[question?.id]}
							question={question}
							virtualKey={virtualItem.key}
							onCheckedChanged={onSelectionChanged}
							onPreviewClick={onPreviewClick}
							selectedStandardType={selectedStandardType}
						/>
					))
			)}
		</Root>
	);
});