import {BaseService} from '@esgi/core/service';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	filter,
	Observable,
	of,
	Subject,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs';
import {V2TeachersPagesReportsRubricResultsController} from '@esgi/contracts/esgi';
import {Test} from '@esgi/main/kits/reports';
import {BuildReportRequest, InitReportResponse, MarkingPeriod, NotTested, ReportResponse} from './types';
import {SubjectService} from './subject-service';
import {Class, Group, SubjectTab} from '@esgi/main/libs/store';
import {TestContentArea} from '@esgi/main/kits/common';
import {getUser, StudentSort} from '@esgi/core/authentication';
import {
	DownloadData,
	DownloadType,
	FileType,
	ReportErrors,
	SortByCriteriaResultsOptions,
	SortByType,
	SortDirection,
	sortDirectionMap,
	SortModel,
	StudentResult,
} from '../types/table-level-report-service-types';
import {isNaN, isNull} from 'underscore';
import {StudentService} from './student-service';
import {deepCopy} from 'shared/utils';
import {Comparator} from '../utils/report-comporator';
import {StudentLevelReportModel} from '../types/student-level-report-service-types';
import {map} from 'rxjs/operators';
import {SortableKeys} from 'modules/reports/rubric-report/types/table-level-report-service-types';
import {SettingsService} from './settings-service';
import {StudentLevelService} from './student-level-service';
import moment from 'moment/moment';

export class RubricResultReportService extends BaseService {
	public selectedClassId$ = new BehaviorSubject<Class | null>(null);
	public selectedGroupId$ = new BehaviorSubject<Group | null>(null);
	public readonly selectedTest$ = new BehaviorSubject<number>(0);
	public readonly subjectTests$ = new BehaviorSubject<Test[]>([]);
	public readonly subject$ = new BehaviorSubject<SubjectTab>(null);
	public reportData$ = new BehaviorSubject<ReportResponse>(null);
	public rows$ = new BehaviorSubject<StudentResult[]>([]);
	public initData$ = new BehaviorSubject<InitReportResponse>(null);
	public trackId$ = new BehaviorSubject<number | null>(null);
	public sessionGuid$ = new BehaviorSubject<string>(null);

	public studentLevelReportStudentID = new BehaviorSubject<number | null>(null);

	public readonly reportErrors$ = new Subject<ReportErrors | null>();
	public sortByCriteriaResultsOptions$ = new BehaviorSubject<SortByCriteriaResultsOptions | null>(null);
	public sortModel = new BehaviorSubject<SortModel>({
		fieldName: 'id',
		direction: 'Desc',
	});

	public subjectService = new SubjectService();
	public studentService = new StudentService();
	public controller = new V2TeachersPagesReportsRubricResultsController();
	public settingsService = new SettingsService(this.controller);

	private readonly downloadType$ = new BehaviorSubject<DownloadType | null>(null);
	private readonly selectedStudentID = new BehaviorSubject<number | null>(null);
	private currentUser = getUser();

	constructor() {
		super();

		this.completeOnDestroy(
			combineLatest([this.subject$, this.selectedClassId$, this.selectedGroupId$])
		).pipe(
			debounceTime(100),
			filter(([selectedSubject, selectedClassId, selectedGroupId]) => {
				return Boolean(selectedSubject && (selectedClassId || selectedGroupId));
			}),
			switchMap(([selectedSubject, selectedClassId, selectedGroupId]) => {
				return combineLatest([this.controller.tableLevelInit({
					subjectID: selectedSubject.id,
					subjectType: selectedSubject.type,
					groupID: selectedGroupId?.id ?? 0,
					classID: selectedClassId?.id ?? 0,
				}), of(selectedSubject)]);
			}),
			filter(([response]) => {
				return Boolean(response.isSuccess);
			})
		).subscribe(([response, subject]) => {
			this.reportErrors$.next(null);
			if (response.value?.rubrics.length === 0) {
				this.reportErrors$.next({noRubrics: true});
			}
			const testList = subject.tests.filter(st => response.value?.rubrics.map(rt => rt.id).includes(st.id)).map((x) => {
				const test: Test = {
					id: x.id,
					name: x.name,
					type: x.type,
					contentArea: x.contentArea as TestContentArea,
					isTested: false,
					correct: 0,
					incorrect: 0,
					untested: 0,
				};
				return test;
			});
			this.initData$.next(response.value);
			this.sessionGuid$.next(response.value.sessionGuid);
			this.trackId$.next(response.value.trackID);
			this.subjectTests$.next(testList);
			if (testList.length > 0) {
				this.selectedTest$.next(testList[0].id);
			}
		});

		this.completeOnDestroy(this.subjectService.loaded$)
			.pipe(tap((value) => {
				if (value) {
					this.subject$.next(this.subjectService.getByIndex(0));
				}
			})).subscribe();

		this.completeOnDestroy(this.initData$)
			.pipe(
				withLatestFrom(this.subjectTests$),
				filter(([initData, tests]) => {
					return !isNull(initData);
				}),
			).subscribe(([data, tests]) => {
				this.settingsService.setFilters({
					showBaseline: data?.filter.showBaseline,
					displayZeroIfNotTested: data?.filter.displayZeroIfNotTested ? NotTested.Zero : NotTested.NT,
					showCurrentMarkingPeriod: data?.filter.showCurrentMarkingPeriod ? MarkingPeriod.Current : MarkingPeriod.All,
					carryScoresForward: data?.filter.carryScoresForward,
					includeNotTested: false,
				});

				const testList = tests.filter(st => data?.rubrics.map(rt => rt.id).includes(st.id));
				if (testList.length > 0) {
					this.selectedTest$.next(testList[0].id);
				}
			});

		this.completeOnDestroy(this.selectedTest$)
			.pipe(
				withLatestFrom(this.initData$, this.selectedClassId$, this.selectedGroupId$),
				debounceTime(100),
				filter(([test, data, classId, groupId]) => {
					return Boolean(test !== null) && data !== null && (!!classId || !!groupId);
				}),
				switchMap(([test, initData, classId, groupId]) => {
					const model = {
						globalSchoolYearID: this.currentUser?.globalSchoolYearID,
						rubricID: test,
						sessionGuid: initData.sessionGuid,
						teacherClassID: classId?.id ?? 0,
						teacherGroupID: groupId?.id ?? 0,
						trackID: initData.trackID,
					};
					this.updateReport(model);
					return [test];
				}),
			).subscribe();

		this.completeOnDestroy(
			combineLatest([this.reportData$, this.settingsService.filter$, this.sortModel])
		).pipe(
			debounceTime(100),
			filter(([reportData]) => {
				return Boolean(reportData !== null);
			}),
			switchMap(([reportData, filter, sortModel]) => {

				const rows = this.settingsService.applyFilters(reportData.results, filter, reportData.currentPeriod);
				if (sortModel) {
					const comparator = new Comparator(sortModel);
					rows.sort(
						(left, right) => comparator.compare(left, right),
					);
				}
				this.rows$.next(rows);
				return rows;
			}),
		).subscribe();

		this.completeOnDestroy(
			this.downloadType$
		).pipe(
			withLatestFrom(this.reportData$, this.downloadData$, this.selectedClassId$, this.selectedGroupId$,
				this.selectedTest$, this.sessionGuid$, this.settingsService.filter$,
				this.studentLevelService.showInColor$, this.studentLevelService.showSummary$, this.studentLevelService.showNotes$, this.rows$)
		).subscribe(([downloadType, report,
			downloadData, classId, groupId, testId, sessionGuid,
			filter, showInColor, showSummary, showNotes, studentRows]) => {
			if (isNull(downloadType) || isNull(downloadData)) {
				return;
			}

			const filename = this.buildDownloadFileName();
			const {id, type} = this.subject$.value;
			const rows = studentRows.map(x => x.studentID);

			const downloadFilter = {
				showBaseline: filter.showBaseline,
				displayZeroIfNotTested: filter.displayZeroIfNotTested === NotTested.Zero,
				showCurrentMarkingPeriod: filter.showCurrentMarkingPeriod === MarkingPeriod.Current,
				carryScoresForward: filter.carryScoresForward,
				showNotes,
				showSummary,
				showInColor,
				includeNotTested: filter.includeNotTested,
			};

			const downloadRequest = {
				...downloadData,
				rows,
				filter: downloadFilter,
				sessionGuid: sessionGuid,
				subjectID: id,
				subjectType: type,
				rubricID: testId,
				teacherClassID: classId ?? 0,
				teacherGroupID: groupId ?? 0,
				specialistGroupID: 0,
				filename,
			};

			const fileActions: Record<DownloadType, string> = {
				[DownloadType.Excel]: 'export/excel',
				[DownloadType.PDF]: 'export/pdf',
			};

			const fileAction = fileActions[downloadType];
			if (fileAction) {
				this.httpClient.ESGIApi.file('/v2/teachers/pages/reports/rubric-results/table-level', fileAction, filename, downloadRequest)
					.subscribe();
			}
		});

	}

	public setSubject(value: number) {
		this.subject$.next(this.subjectService.subjects$.value.find(x => x.id === value));
	}

	public initReport() {
		const model = {
			globalSchoolYearID: this.currentUser?.globalSchoolYearID,
			rubricID: this.selectedTest$.value,
			sessionGuid: this.initData$.value.sessionGuid,
			teacherClassID: this.selectedClassId$.value?.id ?? 0,
			teacherGroupID: this.selectedGroupId$.value?.id ?? 0,
			trackID: this.initData$.value.trackID,
		};
		this.updateReport(model);
	}

	public setTest(value: number) {
		this.selectedTest$.next(value);
	}

	public setSelectedClassId(value: Class) {
		this.selectedClassId$.next(value);
		this.selectedGroupId$.next(null);
	}

	public setSelectedGroupId(value: Group) {
		this.selectedClassId$.next(null);
		this.selectedGroupId$.next(value);
	}

	public setSelectedClassFilter(value: number) {
		this.selectedClassId$.next(this.studentService.classes$.value.find(x => x.id === value));
		this.selectedGroupId$.next(null);
	}

	public setSelectedGroupFilter(value: number) {
		this.selectedClassId$.next(null);
		this.selectedGroupId$.next(this.studentService.groups$.value.find(x => x.id === value));
	}

	public setSortModel(sortModel: SortModel) {
		this.sortModel.next(sortModel);
	}

	public toggleSortDirection(
		sortByType: SortByType,
		newDirection: SortDirection,
	) {
		if (sortByType.sortKey === 'criteriaResults') {
			const {criteriaName, periodResultName} = sortByType;
			this.sortByCriteriaResultsOptions$.next({
				criteriaName,
				periodResultName,
			});
		}

		const {sortKey: fieldName} = sortByType;
		this.setSortModel({
			fieldName,
			fieldValue: fieldName === 'criteriaResults'
				? this.rowValueByCriteriaResult.bind(this)
				: null,
			direction: sortDirectionMap[newDirection],
		});
	}

	public selectStudent(studentID: number) {
		this.selectedStudentID.next(studentID);
	}

	public openStudentLevelReport(studentID: number) {
		this.studentLevelReportStudentID.next(studentID);
		this.selectedStudentID.next(studentID);
	}

	public closeStudentLevelReport() {
		this.studentLevelReportStudentID.next(null);
		this.selectedStudentID.next(null);
	}

	public onDownload(fileType: FileType = FileType.Pdf) {
		if (fileType === FileType.Excel) {
			this.downloadType$.next(DownloadType.Excel);
		} else if (FileType.Pdf) {
			this.downloadType$.next(DownloadType.PDF);
		}
		this.downloadType$.next(null);
	}

	private downloadData$: Observable<DownloadData | null> = this.completeOnDestroy(
		combineLatest([this.subjectTests$, this.selectedGroupId$, this.selectedClassId$, this.selectedTest$]),
	).pipe(
		map(([tests, selectedGroup, selectedClass, testId]) => {
			if (tests.length === 0 || isNull(testId)) {
				return null;
			}
			if (!(isNull(selectedGroup) || isNull(selectedClass))) {
				return null;
			}

			const rubric = tests.find(({id}) => id === testId);

			let entityName: string | undefined;
			let entityType: string | undefined;

			if (selectedClass) {
				entityName = selectedClass?.name;
				entityType = 'Class';
			}

			if (selectedGroup) {
				entityName = selectedGroup?.name;
				entityType = 'Group';
			}

			if (!entityName || !entityType || !rubric) {
				return null;
			}

			const downloadData: DownloadData = {
				rubricName: rubric.name,
				schoolEntityName: entityName,
				schoolEntityType: entityType,
				userName: this.currentUser?.firstName + ' ' + this.currentUser?.lastName,
			};

			return downloadData;
		}),
	);

	public studentLevelReportData$: Observable<StudentLevelReportModel | null> = this.completeOnDestroy(
		combineLatest([
			this.selectedStudentID,
			this.reportData$,
			this.downloadData$,
			this.sortModel,
			this.selectedGroupId$, this.selectedClassId$, this.selectedTest$,
			this.subject$,
			this.settingsService.filter$,
		]),
	).pipe(
		map(
			([
				selectedStudentID,
				report,
				downloadData,
				sortModel,
				selectedGroup, selectedClass, testId,
				selectedSubject,
				filter,
			]) => {
				if (
					isNull(selectedStudentID) ||
					isNull(report) ||
					isNull(downloadData) ||
					isNull(sortModel) ||
					!(isNull(selectedGroup) || isNull(selectedClass)) || isNull(testId) ||
					isNull(selectedSubject)
				) {
					return null;
				}

				if (!this.trackId$.value) {
					throw new Error('trackID is undefined');
				}

				if (!this.sessionGuid$.value) {
					throw new Error('sessionGuid is undefined');
				}

				this.studentLevelReportStudentID.next(null);

				const results: StudentResult[] = deepCopy(report.results).filter(({hasResult}) => hasResult);
				const {levels, currentPeriod} = report;
				const maxLevel = levels.some(({score}) => score === 0) ? levels.length - 1 : levels.length;
				const sortBy = {
					sortKey: sortModel.fieldName as SortableKeys,
					direction: SortDirection[sortModel.direction] as SortDirection,
				};

				const reportFilter = {
					showBaseline: filter.showBaseline,
					displayZeroIfNotTested: filter.displayZeroIfNotTested === NotTested.Zero,
					showCurrentMarkingPeriod: filter.showCurrentMarkingPeriod === MarkingPeriod.Current,
					carryScoresForward: filter.carryScoresForward,
				};

				const studentLevelReportModel: StudentLevelReportModel = {
					trackID: this.trackId$.value,
					currentPeriod,
					selectedStudentID,
					results,
					maxLevel,
					rubricID: testId,
					teacherClassID: selectedClass?.id ?? 0,
					teacherGroupID: selectedGroup?.id ?? 0,
					specialistGroupID: 0,
					filter: reportFilter,
					sortBy,
					sessionGuid: this.sessionGuid$.value,
					subjectID: selectedSubject.id,
					subjectType: selectedSubject.type,
					downloadData,
				};

				return studentLevelReportModel;
			},
		),
	);

	private buildDownloadFileName() {
		const date = moment().format('YYYY_M_D');

		return `Rubric_Results_Class_${date}`;
	}

	public studentLevelService = new StudentLevelService(this.controller, this.selectedStudentID, this.studentLevelReportData$, this.initData$, this.settingsService);


	public override dispose() {
		super.dispose();
		this.settingsService.dispose();
		this.subjectService.dispose();
		this.studentService.dispose();
		this.controller.dispose();
	}

	private updateReport(model: BuildReportRequest) {
		this.controller.tableLevelBuildReport(model)
			.pipe(
				filter((response) => {
					return Boolean(response.isSuccess);
				})
			).subscribe((response) => {
				this.reportErrors$.next(null);

				if (response.value.noStudents) {
					this.reportData$.next(null);
					this.reportErrors$.next({noStudents: true});
					return [];
				}

				this.reportData$.next(response.value);
				const sortRule = this.currentUser?.studentSort === StudentSort.FirstName ? 'firstName' : 'lastName';
				this.sortModel.next({
					fieldName: sortRule,
					fieldValue: null,
					direction: 'Asc',
				})
			});
	}

	private rowValueByCriteriaResult(row: StudentResult) {
		const sortByCriteriaResultsOptions = this.sortByCriteriaResultsOptions$.value;

		if (isNull(sortByCriteriaResultsOptions)) {
			return 0;
		}

		const {criteriaName, periodResultName} = sortByCriteriaResultsOptions;

		const criteriaResult = row.criteriaResults.find((criteriaResults) => criteriaResults.criteriaName === criteriaName);

		if (!criteriaResult) {
			return 0;
		}

		const periodResult = criteriaResult.periodResults.find(({periodName}) => periodName === periodResultName);

		const value = periodResult?.value;

		if (!value) {
			return 0;
		}

		const parsedValue = parseInt(value);

		return value === 'B' || isNaN(parsedValue) ? 0 : parsedValue;
	}
}
