import {useCallback, useMemo, useState} from 'react';
import {Setting} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/kits/common';
import {SidebarMenu} from './components/settings-tab-menu';
import {GradeScales} from './components/tabs/grade-scales';
import {SystemSettings} from './components/tabs/system-settings';
import {TestButtonLabels} from './components/tabs/test-button-labels';
import {TestResultsVerbiage} from './components/tabs/test-results-verbiage';
import {StudentTestingOptions} from './components/tabs/student-testing-options';
import {LanguagesAndTemplates} from './components/tabs/languages-and-templates';
import {TestingKeyboardShortcuts} from './components/tabs/keyboard-shortcuts';
import {ExpandedTestSessionResults} from './components/tabs/test-session-results';
import {SettingsTab, SettingsTabTouchedData} from '../../types';
import {SidebarHeader} from './index.styled';
import {tabItems} from './constants';
import {OutModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/teacher/init/out-model';
import {UnsavedChangesTabAlert} from './unsaved-changes-tab-alert';
import {languagesAndTemplatesMock} from './components/tabs/languages-and-templates/test-mocks';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

interface Props {
	initData: OutModel;
	onChangeDesignVersion: VoidFunction;
	onUpdateInitData: (props: Partial<OutModel>) => void;
	onCloseDrawer: (isTouched: boolean) => void;
	tabsTouchedData: SettingsTabTouchedData;
	onChangeTabsTouchedData: (tab: SettingsTabTouchedData) => void;
}

export function Panel({initData, onChangeDesignVersion, onUpdateInitData, onCloseDrawer, tabsTouchedData, onChangeTabsTouchedData}: Props) {
	const [selectedTab, setSelectedTab] = useState<SettingsTab>(SettingsTab.SystemSettings);
	const [nextTab, setNextTab] = useState<SettingsTab>(SettingsTab.SystemSettings);
	const [isUnsavedChangesTabAlertOpen, setUnsavedChangesTabAlertOpen] = useState(false);

	const handelCloseDrawer = () => {
		onCloseDrawer(tabsTouchedData[selectedTab]);
	};

	const handelChangeTabData = (tab: SettingsTab, isTouched: boolean) => {
		onChangeTabsTouchedData({...tabsTouchedData, [tab]: isTouched});
	};

	const handelChangeTab = useCallback((tab: SettingsTab) => {
		if(selectedTab === tab){
			return null;
		}

		setNextTab(tab);

		const isTouched = tabsTouchedData[selectedTab];

		if(isTouched){
			setUnsavedChangesTabAlertOpen(true);
		} else {
			setSelectedTab(tab);
		}
	}, [selectedTab, initData, tabsTouchedData, initData]);

	const renderTabContent = useMemo(() => {
		switch (selectedTab) {
			case SettingsTab.SystemSettings:
				return (
					<SystemSettings
						initData={initData}
						onUpdateInitData={onUpdateInitData}
						onChangeDesignVersion={onChangeDesignVersion}
						onCloseDrawer={handelCloseDrawer}
						onUpdateTabTouchedData={handelChangeTabData}
						isCleverAccountLinked={initData.isCleverAccountLinked}
						isOneClickAccountLinked={initData.isOneClickAccountLinked}
					/>
				);

			case SettingsTab.LanguagesAndTemplates:
				return <LanguagesAndTemplates
					languages={languagesAndTemplatesMock}
					onUpdateTabTouchedData={handelChangeTabData}
					onCloseDrawer={handelCloseDrawer}
				/>;

			case SettingsTab.GradeScales:
				return <GradeScales/>;

			case SettingsTab.KeyboardShortcuts:
				return (
					<TestingKeyboardShortcuts
						isKeyboardShortcuts={initData.enableShortcuts}
						onUpdateInitData={onUpdateInitData}
						onCloseDrawer={handelCloseDrawer}
						onUpdateTabTouchedData={handelChangeTabData}
					/>
				);

			case SettingsTab.TestButtonLabels:
				return (
					<TestButtonLabels
						correctTestLabel={initData.correctTestLabel}
						incorrectTestLabel={initData.incorrectTestLabel}
						testButtonsLabelsEnabled={initData.testButtonsLabelsEnabled}
						onUpdateInitData={onUpdateInitData}
						onCloseDrawer={handelCloseDrawer}
						onUpdateTabTouchedData={handelChangeTabData}
					/>
				);

			case SettingsTab.StudentTestingOptions:
				return (
					<StudentTestingOptions
						lockScreen={initData.lockScreen}
						screenLockPIN={initData.screenLockPIN}
						showSelfAssessOption={initData.showSelfAssessOption}
						onUpdateInitData={onUpdateInitData}
						onCloseDrawer={handelCloseDrawer}
						onUpdateTabTouchedData={handelChangeTabData}
					/>
				);

			case SettingsTab.TestResultVerbiage:
				return (
					<TestResultsVerbiage
						testResultsVerbiagesEnabled={initData.testResultsVerbiagesEnabled}
						testResultsCorrectVerbiage={initData.testResultsCorrectVerbiage}
						testResultsIncorrectVerbiage={initData.testResultsIncorrectVerbiage}
						onUpdateInitData={onUpdateInitData}
						onCloseDrawer={handelCloseDrawer}
						onUpdateTabTouchedData={handelChangeTabData}
					/>
				);

			case SettingsTab.TestSessionResults:
				return (
					<ExpandedTestSessionResults
						showTestSessionResults={initData.showTestSessionResults}
						onUpdateInitData={onUpdateInitData}
						onCloseDrawer={handelCloseDrawer}
						onUpdateTabTouchedData={handelChangeTabData}
					/>
				);
		}
	}, [selectedTab, initData, onUpdateInitData, onChangeDesignVersion, tabsTouchedData]);

	return (
		<>
			<Drawer.Panel data-cy='settings-panel'>
				<Drawer.PanelSidebar data-cy='settings-tab-bar'>
					<SidebarHeader>
						<Setting/>
						<Text size='small' color='neutral56' data-cy='settings-title'>
							Settings
						</Text>
					</SidebarHeader>
					<SidebarMenu
						selectedTab={selectedTab}
						onChange={handelChangeTab}
						tabItems={tabItems}
					/>
				</Drawer.PanelSidebar>
				<OverlayScrollbarsComponent
					defer
					style={{height: 'calc(100% + 0px)'}}
					options={{scrollbars: {autoHide: 'leave'}}}
				>
					{renderTabContent}
				</OverlayScrollbarsComponent>
			</Drawer.Panel>

			{isUnsavedChangesTabAlertOpen &&
				<UnsavedChangesTabAlert
					onChangeAnyway={() => {
						onChangeTabsTouchedData(({...tabsTouchedData, [selectedTab]: false}));
						setSelectedTab(nextTab);
					}}
					onClose={() => setUnsavedChangesTabAlertOpen(false)}
				/>
			}
		</>
	);
}
