import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Button, SortTable as SortTableIcon} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

export const Root = styled(Box, {
	flex: '1 1 auto',
	overflow: 'hidden',
	height: '85vh',

	'@media (max-width: 1024px)': {
		height: '65vh',
	},
});

export const Header = styled(FlexBox, {
	alignItems: 'center',
	padding: '8px 12px 8px 8px',
	marginBottom: '20px',
});

export const ButtonIcon = styled(Button.Icon, {
	marginRight: '20px',
});

export const ButtonText = styled(Button.Text, {
	marginRight: '8px',
	display: 'flex',
	alignItems: 'center',
	color: '$mediumContrast',

	'& svg': {
		marginLeft: '2px',
	},
});

export const SortTable = styled(SortTableIcon, {
	variants: {
		direction: {
			asc: {
				'& path:last-child': {
					fill: '$primary',
				},
			},
			desc: {
				'& path:first-child': {
					fill: '$primary',
				},
			},
		},
		field: {
			default: {
				'& path:first-child, & path:last-child,': {
					fill: 'currentColor',
				},
			},
			name: {},
		},
	},
});

export const EmptyBox = styled(Box, {
	padding: '20px',
	textAlign: 'center',

	[`& ${Text}`]: {
		borderRadius: '6px',
		padding: '12px 20px',
		border: '1px solid $border',
		background: '$vivid',
		color: '$neutral40',
	},
});

export const StyledButton = styled(Button, {
	padding: '12px 41px',
});