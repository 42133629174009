import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';

export const Wrapper = styled(GridBox, {
	gap: '$5',

	'& > :last-child': {
		marginTop: 'auto',
		paddingBottom: '20px',
	},
});

export const Link = styled('a', {
	lineHeight: 0,
});

export const CheckboxWrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '8px',
	border: '1px solid $border',
	backgroundColor: '$vivid',
	borderRadius: '8px',
	marginTop: '8px',
});

export const Checkbox = styled(CheckboxUI, {
	justifyContent: 'flex-start',

	'& > span': {
		display: 'inline-flex',
	},
});