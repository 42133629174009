import {Buttons} from '@esgillc/ui-kit/button';
import {QuestionHeader, QuestionLayout} from '../question-layout';
import {TestIntroSelectors} from './test-intro.selectors';
import {useQuestionImage} from './use-question-image';
import {CloseButton} from '../buttons/close-button';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';

export type TestIntoProps = {
	studentName?: string;
	testID: number;
	bucketName: string;
	testName: string;
	isWhiteBackground: boolean;
	pregenerated: boolean;
	modifyTicks: number;
	testIntroID: number;
	onCancel: () => void;
	onStart: () => void;
	onClose: () => void;
}

export function TestIntro(props: TestIntoProps): JSX.Element {
	const image = useQuestionImage(props.bucketName, props.testIntroID, props.testID, props.pregenerated, props.modifyTicks);

	return <QuestionLayout whiteBackground={props.isWhiteBackground} data-cy={TestIntroSelectors.testIntro}>
		<QuestionHeader>
			<div className={styles.studentName}>
				{props.studentName}
			</div>
			<div className={join(styles.testIntroTitle, props.isWhiteBackground && styles.isWhiteBackground)}>
				<span>{props.testName}</span> - Introduction Page
			</div>
			<CloseButton isWhiteBackground={props.isWhiteBackground}
			             onClick={props.onClose}
			             tooltip='Close'/>
		</QuestionHeader>
		<div className={styles.testIntroBody}>
			{image}
		</div>
		<div className={styles.testIntroFooter}>
			<Buttons.Gray className={styles.btnCancel} tabIndex={4} data-cy={TestIntroSelectors.cancel} onClick={props.onCancel}>
				CANCEL
			</Buttons.Gray>
			<Buttons.Contained className={styles.btnStart} tabIndex={5} data-cy={TestIntroSelectors.startTest} onClick={props.onStart}>
				START TEST
			</Buttons.Contained>
		</div>
	</QuestionLayout>;
}
