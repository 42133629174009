import React, {Dispatch, useMemo} from 'react';
import {CardBody} from '../components/card-body';
import {CardHeader} from '../components/card-header';
import {CardRoot} from '../components/card-root';
import {AverageProgress, LastSessionInfo} from '../../../../progress-bars';
import {useAnalyticsDataItems} from '../../../../../hooks';
import {EntityType, TestSingleStudentModel} from '../../../../../../../types';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {TestSectionSingleStudent} from '../../../../../types';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {onCardHoverText} from '../../constants';

type Props = TestSingleStudentModel & {
	onTestCardClicked: Dispatch<TestSingleStudentModel>;
	dataCy: string;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	openTestDetails: VoidFunction;
	isSmallScreen: boolean;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	withSelfAssess: boolean;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
	statisticEntity: TestSectionSingleStudent['statisticEntity'];
	onStartSelfAssessTestHandler: VoidFunction;
	onStartTestHandler: VoidFunction;
};

export function SingleStudentTestCard({
	onTestCardClicked,
	dataCy,
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	isSmallScreen,
	onTestMoveTo,
	onRemoveTest,
	withSelfAssess,
	testInfo,
	analyticsData,
	studentResult,
	studentsResult,
	lastTestSessions,
	subjectID,
	classID,
	statisticEntity,
	onStartSelfAssessTestHandler,
	onStartTestHandler,
}: Props) {
	const {analyticsDataItems} = useAnalyticsDataItems({testInfo, analyticsData});

	const statisticLabel = statisticEntity === 'group' ? 'Group' : 'Class';

	const progressBarsItems = useMemo<React.JSX.Element[]>(
		() => [
			<LastSessionInfo
				value={studentResult?.correctAnswers ?? null}
				maxValue={testInfo.totalPossible}
				testType={testInfo.type}
				rubricAnswers={studentResult?.lastTestSessionRubricAnswers ?? []}
				rubricCriteria={testInfo?.rubricCriteria}
				deltaIndicatorValue={studentResult?.delta ?? null}
				result={studentResult?.result ?? null}
				isSmallScreen={isSmallScreen}
			/>,
			<AverageProgress
				value={studentsResult?.result ?? null}
				maxValue={testInfo.totalPossible}
				testType={testInfo.type}
				deltaIndicatorValue={studentsResult?.delta ?? null}
				result={!studentsResult?.studentsTested ? null : studentsResult.result}
				dataCy={`${statisticLabel.toLocaleLowerCase()}-average`}
				label={`${statisticLabel} Average`}
				onHoverText={`Difference between ${statisticLabel.toLocaleLowerCase()} and student performance`}
			/>,
		],
		[studentsResult, isSmallScreen, statisticLabel, studentResult, testInfo],
	);

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<CardRoot
					onClick={() => onTestCardClicked({testInfo, analyticsData, studentResult, studentsResult, lastTestSessions})}
					dataCy={dataCy}
				>
					{testInfo && (
						<CardHeader
							withSelfAssess={withSelfAssess}
							withStartTestButton
							name={testInfo.name}
							contentArea={testInfo.contentArea}
							type={testInfo.type}
							standards={testInfo.stateStandards}
							onStartTestClicked={onStartTestHandler}
							onSelfAssessStartTestClicked={onStartSelfAssessTestHandler}
							dataCy='single-student-test-card-header'
							lastTestSessions={lastTestSessions}
							sessionsCount={analyticsData?.sessionCount ?? 0}
							maxScore={testInfo.totalPossible}
							userCanEditTest={userCanEditTest}
							userCanRemoveTest={userCanRemoveTest}
							openTestDetails={openTestDetails}
							testID={testInfo.id}
							onTestMoveTo={onTestMoveTo}
							onRemoveTest={onRemoveTest}
							isSmallScreen={isSmallScreen}
							entityType={EntityType.Student}
							classID={classID}
							subjectID={subjectID}
						/>
					)}
					<CardBody
						progressBars={progressBarsItems}
						textWithTranscriptItems={analyticsDataItems}
						dataCy='single-student-test-card-body'
						isSmallScreen={isSmallScreen}
						lastTestSessions={lastTestSessions}
						sessionsCount={analyticsData?.sessionCount ?? 0}
						maxScore={testInfo.totalPossible}
						withStartTestButton
						withSelfAssess={testInfo.selfAssessmentTestAllowed}
						onStartTestClicked={onStartTestHandler}
						onSelfAssessStartTestClicked={onStartSelfAssessTestHandler}
						openTestDetails={openTestDetails}
						userCanEditTest={userCanEditTest}
						userCanRemoveTest={userCanRemoveTest}
						testID={testInfo.id}
						onTestMoveTo={onTestMoveTo}
						onRemoveTest={onRemoveTest}
						entityType={EntityType.Student}
						classID={classID}
						subjectID={subjectID}
					/>
				</CardRoot>
			</Tooltip.Trigger>
			<Tooltip.Content side='top'>
				<Text size='large' color='tertiary'>
					{onCardHoverText}
				</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
