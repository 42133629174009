import {Dispatch, useCallback, useState} from 'react';
import {SortBy, SortDirection, Sorting} from '../../../service/types';
import {useSearchParams} from 'react-router-dom';

const sortingKey = 'sorting';
const directionKey = 'direction';

export function useSorting(): [Sorting, Dispatch<Sorting>] {
	const [searchParams, updateSearchParams] = useSearchParams();

	const [sorting, setSorting] = useState<Sorting>(() => {
		let by = SortBy.Created;
		let direction = SortDirection.Desc;

		const sortingByParam = searchParams.get(sortingKey);
		if(sortingByParam && Object.values(SortBy).map(k => k).includes(sortingByParam)) {
			by = SortBy[sortingByParam];
		}

		const directionParam = searchParams.get(directionKey);
		if(directionParam && Object.values(SortDirection).map(k => k.toString()).includes(directionParam)) {
			direction = SortDirection[directionParam];
		}

		return {by, direction};
	});
	const updateSorting = useCallback((value: Sorting) => {
		setSorting(value);
		updateSearchParams((search) => {
			search.set(sortingKey, value.by.toString());
			search.set(directionKey, value.direction.toString());
			return search;
		});
	}, [updateSearchParams]);

	return [sorting, updateSorting];
}