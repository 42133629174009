export interface TestInfo {
	name: string;
	description: string;
	gradeLevelIDs: number[];
	color: string;
	contentAreaID: number;
	stateStandard?: string;
	stateStandardID: number;
	shared: boolean;
}

export interface Field {
	id: number,
	name: string,
	metaInfo?: { defaultField?: boolean };
}

export interface Criteria extends Field {
	order: number;
}

export interface Level extends Field {
	score: number;
}

export interface Description {
	id: number;
	description: string;
	details: string;
	levelID: number;
	criteriaID: number;
}

export enum LevelDisplayMode {
	Number = 1,
	Text = 2,
}
