import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
	OnRenderedParams,
	ReportData,
	SchoolModel,
	SimpleSubjectModel,
} from 'shared/modules/reports/pie-chart/service/models';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {AutoSizer, Grid, GridRef, List, ListRef} from '@esgi/deprecated/old-libs';
import {PieChartService} from 'shared/modules/reports/pie-chart/service/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {GridCellProps, ListRowProps} from 'react-virtualized';
import PersonalCard from 'shared/modules/reports/pie-chart/components/cards/personal-card';
import CombineCard from 'shared/modules/reports/pie-chart/components/cards/combine-card';

import styles from './body.module.less';

interface Props {
	service: PieChartService;
	data: ReportData;
	showEachStudent: boolean;
	sortBy: string;
	schoolID?: number;
	schools: SchoolModel[];
	teacherID: number;
	classID?: number;
	groupID?: number;
	specialistGroupID?: number;
	studentID: number;
	gradeLevelID: number;
	subjects: SimpleSubjectModel[];
	printInColor: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	hierarchy: HierarchySnapshot;
}

const DEFAULT_CARD_WIDTH = 260;
const DEFAULT_CARD_HEIGHT = 184;

export default function Body ({
	service,
	data,
	showEachStudent,
	gradeLevelID,
	printInColor,
	testResultsCorrectVerbiage,
	testResultsIncorrectVerbiage,
	hierarchy,
}: Props) {
	const gridRef: GridRef = useRef(null);
	const listRef: ListRef = useRef(null);
	const {classID, subjectID, schoolID, teacherID, studentID} = useBehaviorSubject(service.settings);
	const headerLoaded = useBehaviorSubject(service.headerLoaded);
	const chartBlockModels = useBehaviorSubject(service.chartBlockModels);
	const [columnCount, setColumnCount] = useState(1);
	const [maxTestHeight, setMaxTestHeight] = useState(0);
	const [cardsHeight, setCardsHeight] = useState(0);

	const filteredStudents = useMemo(() => {
		if (data?.students) {
			if (gradeLevelID > 0) {
				return data?.students.filter(x => x.gradeLevelID === gradeLevelID);
			}

			return data?.students;
		}
		return [];
	}, [data?.students, gradeLevelID]);

	const renderWarning = (warning: string) => {
		return (
			<div className={styles.emptyTextContainer}>
				<span className={styles.emptyReportText}>{warning}</span>
			</div>
		);
	};

	const updateCardsHeight = (newValue: number) => {
		setCardsHeight(newValue);

		if (gridRef.current) {
			gridRef.current.recomputeGridSize();
			return;
		}
		if (listRef.current) {
			listRef.current.recomputeRowHeights();
			return;
		}
	};

	const onCardRendered = (params: OnRenderedParams) => {
		if (maxTestHeight < params.height) {
			setMaxTestHeight(params.height);
			updateCardsHeight(params.height);
		}
	};

	const renderTestCombinedRow = useCallback((cellRenderInfo: GridCellProps): JSX.Element => {
		const {rowIndex, columnIndex, key, style} = cellRenderInfo;
		const testIndex = rowIndex * columnCount + columnIndex;
		const test = data.tests[testIndex];
		if (!test) {
			return <div className={styles.resultContainer} key={key} style={style}/>;
		}

		const chartBlockModel = chartBlockModels.filter(s => s.testID === test.testID && s.studentID === 0)[0];
		return (
			<div className={styles.resultContainer} key={key} style={style}>
				<CombineCard
					test={test}
		             uniqueID={`${test.testID}-${studentID}-${data?.tests.length}`}
		             chartBlockModel={chartBlockModel}
		             printInColor={printInColor}
		             totalStudentCount={data.students.length}
		             testResultsCorrectVerbiage={testResultsCorrectVerbiage}
		             testResultsIncorrectVerbiage={testResultsIncorrectVerbiage}
		             onCardRendered={onCardRendered}
				/>
			</div>
		);
	}, [data.tests.length, onCardRendered, data.students.length, printInColor, testResultsCorrectVerbiage, testResultsIncorrectVerbiage]);

	const recalculateColumnCount = (width: number) => {
		const recalculatedColumnCount = Math.floor(width / DEFAULT_CARD_WIDTH);
		setColumnCount(recalculatedColumnCount);

		return recalculatedColumnCount;
	};

	const renderCombined = useCallback(() => {
		if (data?.tests.length) {

			return (
				<AutoSizer defaultHeight={700}>
					{(props) => {
						if (props.width) {
							return (
								<Grid
									ref={gridRef}
									width={props.width}
									height={props.height}
									className={styles.eachStudentsBlock}
									cellRenderer={renderTestCombinedRow}
									columnCount={recalculateColumnCount(props.width)}
									columnWidth={DEFAULT_CARD_WIDTH}
									rowHeight={cardsHeight}
									rowCount={Math.ceil(data?.tests.length / columnCount)}
								/>
							);
						}
					}}
				</AutoSizer>
			);
		}
	}, [data.tests.length, renderTestCombinedRow, cardsHeight, columnCount]) ;

	const calculateRowHeight = useCallback((width: number) => {
		const studentNameHeight = 35;
		const rootContainerPadding = 30;
		const testsCount = data?.tests.length;
		const testsByRow = Math.floor((width - rootContainerPadding) / DEFAULT_CARD_WIDTH);
		const rows = Math.ceil(testsCount / testsByRow);
		const rowHeight = rows * cardsHeight + studentNameHeight;

		if (rowHeight !== DEFAULT_CARD_HEIGHT) {
			listRef?.current?.recomputeRowHeights();
		}

		return rowHeight;
	}, [data?.tests.length, cardsHeight]);

	const renderEachStudentRow = useCallback((props: ListRowProps) => {
		const {key, style, index} = props;
		const student = data?.students[index];

		if (!student) {
			console.error('Error. Can\'t find student-form', props);
			return <div key={key} style={style}/>;
		}

		return (
			<div key={key} style={style}>
				{studentID === 0 &&
					<div className={styles.name}>{student.firstName + ' ' + student.lastName}</div>
				}
				<div className={styles.results}>
					{data?.tests.map(test => {
						const chartBlockModel = chartBlockModels.filter(s => s.testID === test.testID && s.studentID === student.studentID)[0];

						return (
							<div
								className={styles.resultContainer}
								style={{height: cardsHeight + 'px'}}
								key={test.testID}
							>
								<PersonalCard
									test={test}
									uniqueID={`${test.testID}-${studentID}-${data?.tests.length}`}
									chartBlockModel={chartBlockModel}
									printInColor={printInColor}
									testResultsCorrectVerbiage={testResultsCorrectVerbiage}
									testResultsIncorrectVerbiage={testResultsIncorrectVerbiage}
									onCardRendered={onCardRendered}
								/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}, [data.tests, onCardRendered, printInColor, testResultsCorrectVerbiage, testResultsIncorrectVerbiage]);

	const renderEachStudent = useCallback(() => {
		if (data?.tests.length) {
			return (
				<AutoSizer defaultHeight={700}>
					{(props) => (
						<List
							ref={listRef}
							className={styles.eachStudentsBlock}
							width={props.width}
							height={props.height}
							rowHeight={calculateRowHeight(props.width)}
							overscanRowCount={0}
							rowRenderer={renderEachStudentRow}
							rowCount={data?.students.length}
						/>
					)}
				</AutoSizer>
			);
		}
	}, [data?.students, data?.tests, renderEachStudentRow]);

	useEffect(() => {
		if (headerLoaded) {
			service.getReport(subjectID, hierarchy, headerLoaded);
		}
	}, [classID, subjectID, schoolID, teacherID, headerLoaded, studentID]);

	return (
		data && headerLoaded &&
		<>
			{data.students.length === 0
				&& renderWarning('There are no students in this group or class')
				|| filteredStudents.length > 0 && data.tests.length > 0
				&& (
					<div className={styles.reportBody}>
						{!showEachStudent && (studentID === 0 || data.students.length > 1) && renderCombined()}
						{(showEachStudent || (data.students.length === 1 && studentID > 0)) && renderEachStudent()}
					</div>
				)
				|| renderWarning('There is no student assessment data for this filter')
			}
		</>
	);
}
