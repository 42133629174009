import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';

export const Content = styled(GridBox, {
	gridTemplateColumns: '1fr 1fr',
	gap: 20,
});

export const EmptyDataContainer = styled(GridBox, {
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
});

export const EmptyContentBox = styled(GridBox, {
	textAlign: 'center',
	gap: '$2',
});

export const PlusIconBox = styled(FlexBox, {
	borderRadius: 6,
	color: '$base',
	backgroundColor: '$vivid',
	boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(230, 232, 239, 0.24)',

	'& > svg': {
		width: 20,
		height: 20,
	},
});
