import {Header} from '../header';
import {
	ContentBox,
	Divider,
	FiltersContainer,
	FiltersSelectsContainer,
	FiltersTabsContainer,
	TestSectionTemplateContent,
	WidgetFiltersBox,
	WidgetsContainer,
	cardViewItemCSS,
} from './index.styled';
import {useTestSectionContext} from '../../context/test-section-context';
import {Tabs} from '../tabs';
import {Select} from '../select';
import {
	CardViewType,
	SortByOption,
	TestedOption,
	TestSectionContentType,
	TestSectionDefault,
	TestSectionGroupStudents,
	TestSectionMode,
	TestSectionSingleStudent,
	TestSectionTabId,
} from '../../types';
import {useSortByOptions} from './hooks/use-sort-by-options';
import {isNull} from 'underscore';
import {EmptyContentTemplate} from '../empty-content-template';
import {useSectionContentState} from '../../hooks';
import {Student, SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {testedOptionItems} from './constants';
import {useTabItems} from './hooks/use-tab-items';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Widgets, WidgetsInfo} from '@esgi/main/features/teacher/widgets';
import {lazyComponent} from '@esgi/core/react';
import {UserType, useUser} from '@esgi/core/authentication';
import {TestType} from '@esgi/main/libs/core';
import {useRootPageContext, ScreenSize} from '@esgi/main/kits/common';
import {useEffect, useMemo, useRef} from 'react';
import {TestModel} from '../../../../types';
import {View1, View2} from '@esgi/ui/icons';
import {TabItem} from '../tabs/types';
import {ModeContentVariant} from '../mode-content-variant';
import {useModesProps} from './hooks/use-modes-props';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

export type TestSectionContentCommonProps = {
	subject: SubjectTab | null;
	hasSubjects: boolean;
	hasStudents: boolean;
	isLoadedData: boolean;
	onAddTestClick: VoidFunction;
	widgets: WidgetsInfo;
	onTestMoveTo: (args: {previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}) => void;
	onRemoveTest: (args: {subjectID: SubjectTab['id']; testID: number}) => void;
};

type Props = TestSectionContentCommonProps & (TestSectionSingleStudent | TestSectionGroupStudents | TestSectionDefault);

export function TestSectionContent({
	subject,
	hasSubjects,
	hasStudents,
	isLoadedData,
	onAddTestClick,
	widgets,
	onTestMoveTo,
	onRemoveTest,
	...props
}: Props) {
	const user = useUser();
	const {sectionMode, cardViewType, setCardViewType} = useTestSectionContext();

	const {allTests, studentsIDsForWidgets} = useMemo<{
		allTests: TestModel[];
		studentsIDsForWidgets: Student['id'][];
	}>(() => {
		if (props.contentType === TestSectionContentType.SingleStudent) {
			return {
				allTests: props.allTests,
				studentsIDsForWidgets: props.studentsIDsForStatistic,
			};
		}

		if (props.contentType === TestSectionContentType.GroupStudents) {
			return {
				allTests: props.allTests,
				studentsIDsForWidgets: props.studentsIDs,
			};
		}

		return {
			allTests: [],
			studentsIDsForWidgets: [],
		};
	}, [props]);

	const {
		modeButtonsPermissions,
		setSelectedTestedOption,
		setSelectedSortByOption,
		activeTab,
		setActiveTab,
		selectedSortByOption,
		selectedTestedOption,
		testItems,
		closeTestDetails,
		testDetailsState,
		setTestDetailsState,
	} = useSectionContentState({allTests, subject});

	const {modeRearrangeContentProps, modeViewContentProps} = useModesProps({props, testItems});

	const {current: cardViewItems} = useRef<TabItem<CardViewType>[]>([
		{
			value: CardViewType.List,
			label: <View1 width='24' height='24' />,
			css: cardViewItemCSS,
			tooltipText: 'Expanded View',
		},
		{
			value: CardViewType.Grid,
			label: <View2 width='24' height='24' />,
			css: cardViewItemCSS,
			tooltipText: 'Classic View',
		},
	]);

	const tabItems = useTabItems(allTests);

	const {screenSize} = useRootPageContext();
	const isSmallScreen = screenSize === ScreenSize.Small;
	const isTabletScreen = screenSize === ScreenSize.Tablet || screenSize === ScreenSize.PortraitTablet;
	const availableTabs = useMemo(() => tabItems.filter((t) => !t?.disabled).map((t) => t?.value), [tabItems]);

	const canRemove = useMemo(() => {
		return subject?.type === SubjectType.Personal;
	}, [subject]);

	useEffect(() => {
		if (!availableTabs.includes(activeTab) && availableTabs.find((el) => el)) {
			setActiveTab(TestSectionTabId.All);
		}
	}, [availableTabs]);

	const sortByOptionItems = useSortByOptions({
		contentType: props.contentType,
	});

	const getContent = () => {
		if (sectionMode === TestSectionMode.Rearrange && !isNull(modeRearrangeContentProps)) {
			return <ModeContentVariant.Rearrange cardViewType={cardViewType} {...modeRearrangeContentProps} />;
		}

		if (!testItems.length) {
			return (
				<EmptyContentTemplate
					onAllTestClick={onAddTestClick}
					templateText='No tests are available in the selected subject'
				/>
			);
		}

		if (isNull(subject) || isNull(modeViewContentProps)) {
			return null;
		}

		return (
			<ModeContentVariant.View
				subject={subject}
				currentUserId={user?.userID}
				canRemove={canRemove}
				openTestDetails={setTestDetailsState}
				onTestMoveTo={onTestMoveTo}
				onRemoveTest={onRemoveTest}
				isSmallScreen={isSmallScreen || isTabletScreen}
				cardViewType={cardViewType}
				{...modeViewContentProps}
			/>
		);
	};

	const getTestSectionTemplateContent = () => {
		if (!hasSubjects) {
			return (
				<EmptyContentTemplate
					onAllTestClick={onAddTestClick}
					templateText='No tests are available in the selected subject'
				/>
			);
		}

		if (isNull(subject)) {
			return (
				<EmptyContentTemplate
					onAllTestClick={onAddTestClick}
					templateText='No tests are available in the selected subject'
					additionalText='Please select a subject'
				/>
			);
		}

		return (
			<OverlayScrollbarsComponent
				defer
				style={{
					height: 'calc(100% + 0px)',
				}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<TestSectionTemplateContent isTabletScreen={isTabletScreen}>
					<WidgetFiltersBox>
						<WidgetsContainer>
							<Widgets
								studentIDsInClass={studentsIDsForWidgets}
								widgets={widgets}
								testIDs={testItems.map((i) => i.testInfo.id)}
								singleStudentID={props.contentType === TestSectionContentType.SingleStudent ? props.student.id : 0}
							/>
						</WidgetsContainer>
						<FiltersContainer isTabletScreen={isTabletScreen} isSmallScreen={isSmallScreen}>
							<FiltersTabsContainer>
								<Tabs<CardViewType>
									activeTab={cardViewType}
									sectionMode={sectionMode}
									setActiveTab={setCardViewType}
									tabItems={cardViewItems}
									isSmallScreen={isSmallScreen}
									dataCy='view-type-tabs'
								/>
								<Tabs<TestSectionTabId>
									activeTab={activeTab}
									sectionMode={sectionMode}
									setActiveTab={setActiveTab}
									tabItems={tabItems}
									isSmallScreen={isSmallScreen}
									dataCy='test-type-tabs'
								/>
							</FiltersTabsContainer>

							<FiltersSelectsContainer>
								<Select<TestedOption>
									placeholder='Tested'
									items={testedOptionItems}
									onValueChange={setSelectedTestedOption}
									selectedValue={selectedTestedOption}
									disabled={sectionMode !== TestSectionMode.View}
									defaultValue={TestedOption.Any}
									displayDefaultLabel
									dataCy='tested-option-select'
								/>
								<Select<SortByOption>
									placeholder='Sort By'
									items={sortByOptionItems}
									onValueChange={setSelectedSortByOption}
									selectedValue={selectedSortByOption}
									disabled={sectionMode !== TestSectionMode.View}
									dataCy='sort-by-option-select'
								/>
							</FiltersSelectsContainer>
						</FiltersContainer>
					</WidgetFiltersBox>

					<OverlayScrollbarsComponent
						defer
						style={{
							height: 'calc(100% + 0px)',
						}}
						options={{
							scrollbars: {autoHide: 'leave'},
							paddingAbsolute: true,
						}}
					>
						{getContent()}
					</OverlayScrollbarsComponent>
				</TestSectionTemplateContent>
			</OverlayScrollbarsComponent>
		);
	};

	return (
		<>
			<ContentBox>
				<Header
					subject={subject}
					modeButtonsPermissions={modeButtonsPermissions}
					onAddTestClick={onAddTestClick}
					canView={Boolean(user?.canViewStudents)}
					{...props}
				/>
				<Divider />
				{getTestSectionTemplateContent()}
			</ContentBox>

			{!isNull(user) &&
				!isNull(testDetailsState) &&
				(testDetailsState.testType === TestType.Score || testDetailsState.testType === TestType.YN) && (
					<TestDetails
						testID={testDetailsState.testId}
						firstName={user.firstName}
						lastName={user.lastName}
						userID={user.userID}
						close={closeTestDetails}
						disableCopyTest
					/>
				)}

			{!isNull(testDetailsState) && testDetailsState.testType === TestType.Rubric && (
				<RubricDetails testID={testDetailsState.testId} disableCopy disableAddToSubject close={closeTestDetails} />
			)}
		</>
	);
}
