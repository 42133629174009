import React from 'react';
import styles from './add-additional-tests.module.less';
import DataService from '../../../../services/data-service';
import {ValidationTooltip, OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {AddSelfassessTests} from './components/add-selfassess-tests/add-selfassess-tests';
import {takeUntil, map, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import _ from 'underscore';
import {CloseIcon, Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {Checkbox} from '@esgillc/ui-kit/control';
import {Buttons} from '@esgillc/ui-kit/button';

interface AddTestsModalProps {
	closed: () => any;
	dataService: DataService;
}

class AddTestsModalState {
	selectedTests: number[];
	valid: boolean = true;
	addSelfAssessmentTestsModalOpened: boolean = false;
	addMoreTestsDisabled: boolean = false;
}


class AddAdditionalTests extends React.Component<AddTestsModalProps, AddTestsModalState> {
	private ref: React.RefObject<HTMLDivElement> = React.createRef();
	private tooltipRef: React.RefObject<HTMLDivElement> = React.createRef();
	private destroy$: Subject<void> = new Subject();
	private modalManagerRef: ModalManagerRefObject = React.createRef();
	private selectedTestsCount: number = 0;

	state = new AddTestsModalState();

	async componentDidMount(): Promise<void> {
		this.props.dataService.selectedAvailableTests$
			.pipe(
				takeUntil(this.destroy$),
				map(t => t.map(test => test.id)),
				tap(t => this.selectedTestsCount = t?.length ?? 0),
			)
			.subscribe(ids => this.setState({selectedTests: ids}));
		await this.updateAddMoreTestsDisabled();
	}

	componentWillUnmount(): void {
		this.destroy$.next();
	}

	private updateAddMoreTestsDisabled = async () => {
		let selectedTests = this.props.dataService.availableTests.map(test => test.id);
		let allTests = (await this.props.dataService.getTests()).tests.map(t => t.id);
		this.setState({addMoreTestsDisabled: _.difference(allTests, selectedTests).length === 0});
	};

	private onAdd() {
		this.props.dataService.addTests(this.state.selectedTests);
		this.onClose();
	}

	private onSelected(testId: number): void {
		const testIds = this.state.selectedTests.includes(testId)
			? this.state.selectedTests.filter(t => t !== testId)
			: [...this.state.selectedTests, testId];

		this.setState({selectedTests: testIds, valid: true});
	}

	private onClose() {
		this.modalManagerRef.current.close(this.props.closed);
	}

	private get title(): string {
		return `Add ${this.selectedTestsCount === 0 ? '' : 'Additional '}Tests From '${this.props.dataService.selectedSubject.name}'`;
	}

	render() {
		return (
			<>
				<Modal modalManagerRef={this.modalManagerRef} className={styles.modal}>
					<Modal.Header>
						<Title>{this.title}</Title>
						<CloseIcon onClick={() => this.onClose()}/>
					</Modal.Header>
					<Modal.Body>
						<div className={styles.testsContainer}>
							{this.state.selectedTests && this.props.dataService.availableTests.map(test =>
								<Checkbox
									className={styles.testItem}
									key={test.id}
									checked={this.state.selectedTests.includes(test.id)}
									onClick={() => this.onSelected(test.id)}
								>
									{test.name}
								</Checkbox>)}
						</div>

					</Modal.Body>
					<Modal.Footer>
						<div className={styles.buttonsContainer}>
							<Buttons.Gray
								onClick={() => this.onClose()}
							>
								CANCEL
							</Buttons.Gray>
							{this.props.dataService.selectedSubject.canEdit && <OnHoverTooltip
								message={this.state.addMoreTestsDisabled && 'All self-assess tests have been added to your subject tab'}>
								<div ref={this.tooltipRef} className={styles.addMoreTests}>
									<Buttons.Outlined
										title={this.state.addMoreTestsDisabled && 'All self-assess tests have been added to your subject tab'}
										disabled={this.state.addMoreTestsDisabled}
										onClick={() => this.setState({addSelfAssessmentTestsModalOpened: true})}>
										ADD MORE TESTS
									</Buttons.Outlined>
								</div>
							</OnHoverTooltip>}
							<div ref={this.ref}>
								<Buttons.Contained
									onClick={() => this.onAdd()}
									disabled={this.state.selectedTests?.length > 3}
								>
									DONE
								</Buttons.Contained>
							</div>
							{this.state.selectedTests?.length > 3
								&& <ValidationTooltip
									element={this.ref.current}
									container={'body'}
									notHide={true}
									placement={'right'}
								>
									You have exceeded the maximum number of tests per session.
								</ValidationTooltip>}
						</div>
					</Modal.Footer>
				</Modal>
				{this.state.addSelfAssessmentTestsModalOpened &&
					<AddSelfassessTests
						updateAddMoreTestsDisabled={this.updateAddMoreTestsDisabled}
						dataService={this.props.dataService}
						closed={() => this.setState({addSelfAssessmentTestsModalOpened: false})}
					/>}
			</>
		);
	}
}

export default AddAdditionalTests;
