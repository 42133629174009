import {Container, Sidebar} from '../content.styled';
import {SessionList} from '../session-list';
import {noop} from 'underscore';
import {TestInfo, TestSession} from '../../../../../../../types';
import {DiagramBox} from '../../../../diagram-box';
import {useSessionItems} from './hooks/use-session-items';
import {useSelectedSessions} from './hooks/use-selected-sessions';
import {useSectionData} from './hooks/use-section-data';
import {useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {CenteredText} from './components/text.styled';
import {ItemAnalysisDiagram, ItemAnalysisDiagramDetailed} from '../../../../item-analysis-diagram';
import {CheckboxValue} from './types';
import {Checkbox, CheckboxesBox} from '../../../../../../../kit';
import {useDiagramLegendItems} from './hooks/use-diagram-legend-items';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	isTablet: boolean;
};

export function YesNoContent({testSessions, testInfo, isTablet}: Props) {
	const [visibleSessionItemsCount, setVisibleSessionItemsCount] = useState<number>(5);
	const [diagramBoxHeight, setDiagramBoxHeight] = useState(0);

	const diagramBoxRef = useRef<HTMLDivElement>(null);

	useResizeObserver(diagramBoxRef, (entry) => setDiagramBoxHeight(entry.contentRect.height));

	const {
		isAllSessionsSelected,
		selectedSessionIds,
		setSelectedSessionIds,
		isAllSessionsChecked,
		isDetailedViewChecked,
		toggleAllSessionsCheckbox,
		toggleDetailedViewCheckbox,
	} = useSectionData({testSessions});

	const selectedSessions = useSelectedSessions({testSessions, selectedSessionIds});
	const sessionItems = useSessionItems({testSessions, testInfo});

	const diagramLegendItems = useDiagramLegendItems({isDetailedViewChecked});

	return (
		<Container isTablet={isTablet} columnGap='60'>
			<Sidebar
				gap={isAllSessionsSelected ? '16' : '20'}
				isTablet={isTablet}
				css={{
					height: !isTablet && diagramBoxHeight,
					minHeight: !isTablet && 296,
				}}
			>
				<CheckboxesBox isTablet={isTablet}>
					<Checkbox
						value={CheckboxValue.DetailedView}
						label='Show Detailed View'
						checked={isDetailedViewChecked}
						onCheckedChange={toggleDetailedViewCheckbox}
						selected={isDetailedViewChecked}
					/>
					<Checkbox
						value={CheckboxValue.AllSessions}
						label='Show All Sessions'
						checked={isAllSessionsChecked}
						onCheckedChange={toggleAllSessionsCheckbox}
						selected={isAllSessionsChecked}
					/>
				</CheckboxesBox>

				{isAllSessionsSelected ? (
					<CenteredText isTablet={isTablet} size='small' font='mono' color='lowContrast'>
						The average score from all sessions is currently being displayed
					</CenteredText>
				) : (
					<SessionList
						selectedSessionIds={selectedSessionIds}
						setSelectedSessionIds={setSelectedSessionIds}
						sessionItems={sessionItems}
						type='multiple'
						indicatorPercentageType
						visibleSessionItemsCount={visibleSessionItemsCount}
						setVisibleSessionItemsCount={setVisibleSessionItemsCount}
					/>
				)}
			</Sidebar>

			<DiagramBox legendItems={diagramLegendItems} onFullScreenButtonClick={noop} ref={diagramBoxRef}>
				{isDetailedViewChecked ? (
					<ItemAnalysisDiagramDetailed sessions={selectedSessions} testInfo={testInfo} YAxisLabel='Answers' isTablet={isTablet} />
				) : (
					<ItemAnalysisDiagram sessions={selectedSessions} testInfo={testInfo} YAxisLabel='Answers' />
				)}
			</DiagramBox>
		</Container>
	);
}
