import {forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {Text} from '../../../typography';
import {BaseButton, BaseButtonProps} from '../base-button';
import {VariantProps} from '@stitches/react';

type ButtonProps = Omit<BaseButtonProps, 'color'> &
	VariantProps<typeof ButtonStyled> & {
		/**
		 * Activate error mode styles, if the prop is true.
		 */
		error?: boolean;
	};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({dataCy = 'ui-kit-button', color = 'primary', css = {}, error, ...props}, forwaredRef) => (
		<ButtonStyled
			data-cy={dataCy}
			color={color}
			css={css}
			data-state-error={error ? '' : undefined}
			ref={forwaredRef}
			{...props}
		/>
	),
);

const ButtonStyled = styled(BaseButton, {
	borderWidth: '1px',
	borderStyle: 'solid',
	padding: '7px 11px',
	borderRadius: '6px',
	minWidth: '120px',
	color: '$base',

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'& > svg, & > svg *': {
		fill: 'currentColor',
	},

	'& > svg': {
		width: '24px',
		height: '24px',
	},

	'& > svg:first-child': {
		margin: '-4px 6px -4px -4px',
	},

	'& > svg:last-child': {
		margin: '-4px -4px -4px 6px',
	},

	'&[data-state-error]': {
		backgroundColor: '$negativeBackground',
		borderColor: '$negativeMuted',
		color: '$negative',
		boxShadow: 'none',
	},

	'&:hover': {
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',
		color: '$secondary',
		boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
	},

	'&:active': {
		backgroundColor: '$surface',
		borderColor: '$primaryMuted',
		color: '$primary',
		boxShadow: `0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)`,
	},

	'&:disabled': {
		backgroundColor: '$background',
		borderColor: '$border',
		color: '$lowContrast',
		boxShadow: 'none',
	},

	variants: {
		/** Color variants. Default value is "primary". */
		color: {
			primary: {
				backgroundColor: '$primary',
				borderColor: '$primary',
				padding: '7px 19px',
				borderRadius: '8px',
				minWidth: 'auto',
				color: '$surface',
			},

			secondary: {
				borderColor: '$border',
				backgroundColor: '$surface',
				boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(230, 232, 239, 0.24)',
			},

			tertiary: {
				borderColor: 'transparent',
				backgroundColor: 'transparent',

				'&:hover': {
					borderColor: '$secondaryBackground',
					boxShadow: 'none',
					color: '$secondary',
				},

				'&:hover:active': {
					borderColor: '$primaryMuted',
					boxShadow: `0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)`,
				},

				'&:disabled, &:disabled:active': {
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					boxShadow: 'none',
				},
			},

			warn: {
				borderColor: '$red88',
				backgroundColor: '$red96',
				color: '$red48',
			},
		},
	},
});
