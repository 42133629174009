import {Buttons} from '@esgillc/ui-kit/button';
import AppHistory from 'shared/tools/history/history';
import RowTitle from '../row-title';
import {studentScreenHelpUrl} from './constants';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	code: string;
	codeExpireTime: string;
	createShareSession: () => void;
}

export default function StudentScreenRow({code, codeExpireTime, createShareSession}: Props) {

	const handleClick = () => AppHistory.navigateOutside('https://www.esgiss.com');

	return <div>
		<RowTitle
			title='STUDENT SCREEN'
			helpUrl={studentScreenHelpUrl}
		>
			<p>
				Utilize a second screen that only displays test question content.
				Buttons, notes, and test directions are hidden from the student view.
			</p>
		</RowTitle>
		<p className={styles.title}>1. Select the Get Code button.</p>
		{code
			? <>
				<div className={styles.codeContainer}>
					<div data-cy='code' className={styles.code}>{code}</div>
				</div>
				<span data-cy='codeExpireTime'>Code expires in: {codeExpireTime}</span>
			</>
			: <Buttons.Outlined className={styles.codeButton} onClick={createShareSession}>Get Code</Buttons.Outlined>
		}
		<p data-cy='esgiss-link' className={join(styles.title, styles.textWithLink)}>2. Go to
			<Buttons.Link className={styles.esgiss}
			              onClick={handleClick}>
				esgiss.com
			</Buttons.Link> on your student screen.
		</p>

		<p className={styles.title}>3. Enter the code. Codes not entered within 5 minutes will expire.</p>
	</div>;
}
