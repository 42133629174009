import {ClassGradesService} from '../service';
import {storage, useStore} from '@esgi/main/libs/store';
import {useCallback, useEffect, useState} from 'react';
import {SelectableListItem} from '../service/types';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';

export default function useReportState(service: ClassGradesService) {
	const [classes] = useStore(storage.classes);
	const [groups] = useStore(storage.groups);
	const [selectedEntity, setSelectedEntity] = useState<SelectableListItem>(null);

	const onEntitySelect = useCallback(({classId, groupId}: SingleSelectState) => {
		let selected;

		if (classId) {
			selected = classes.find(({id}) => id === classId);
		} else {
			selected = groups.find(({id}) => id === groupId);
		}

		setSelectedEntity({
			value: selected?.id.toString(),
			label: selected?.name,
			disabled: false,
		});
	}, [classes, groups]);

	const [isCarryForwardChecked, setCarryForwardChecked] = useState(false);


	const isSubjectsLoaded = useBehaviorSubject(service.subjectService.loaded$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const selectedSubject = useBehaviorSubject(service.subject$);

	const tests = useBehaviorSubject(service.testService.tests$);
	const selectedTests = useBehaviorSubject(service.tests$);
	const isTestsLoading = useBehaviorSubject(service.testService.loading$);

	const [showAddTestsDrawer, setShowAddTestsDrawer] = useState(false);

	useEffect(() => {
		if (classes.length) {
			const notEmptyClass = classes.find(({studentIDs}) => studentIDs.length);
			setSelectedEntity({
				value: notEmptyClass.id.toString(),
				label: notEmptyClass.name,
				disabled: !notEmptyClass.studentIDs.length,
			});
		}
	}, [classes]);

	return {
		classes,
		groups,
		selectedEntity,
		subjects,
		isSubjectsLoaded,
		selectedSubject,
		tests,
		isTestsLoading,
		isCarryForwardChecked,
		setCarryForwardChecked,
		showAddTestsDrawer,
		setShowAddTestsDrawer,
		selectedTests,
		onEntitySelect,
	};
}
