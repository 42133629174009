import {GridBox} from '@esgi/ui/layout';
import {RingFullFilledProgress} from '../../../../components/ring-full-filled-progress';
import {CardBody as CardBodyContainer, DeltaInfoBox} from '../../../../components/components.styled';
import {DeltaCheap} from '../../../../components/delta-cheap';
import {Text} from '@esgi/ui/typography';
import {AnswersInfo} from '../../../../components/answers-info';
import {useChartData} from './use-chart-data';
import {TestSingleStudentModel} from '../../../../../../../../../../types';
import {useCallback} from 'react';
import {GridProgressBar} from '@esgi/ui';
import {ChartType} from './types';

type Props = Pick<TestSingleStudentModel, 'testInfo' | 'studentResult'>;

export function CardBody({testInfo, studentResult}: Props) {
	const {answersInfoTranscription, chartValues, deltaInfo} = useChartData({
		testInfo,
		studentResult,
	});

	const renderChartInfo = useCallback(() => {
		if (chartValues.type === ChartType.Grid) {
			return <GridProgressBar boxSize={48} items={chartValues.items} />;
		}

		return <RingFullFilledProgress value={chartValues.value} maxValue={chartValues.maxValue} />;
	}, [chartValues]);

	return (
		<CardBodyContainer>
			{renderChartInfo()}

			<GridBox gap='3'>
				<DeltaInfoBox>
					<DeltaCheap cheapBackground={deltaInfo.color} color='vivid' size='xSmall' font='mono' bold>
						{deltaInfo.value}
					</DeltaCheap>
					<Text size='xSmall' font='mono' color={deltaInfo.color}>
						Since Last Test
					</Text>
				</DeltaInfoBox>

				<AnswersInfo
					transcriptText={answersInfoTranscription}
					value={chartValues.value}
					maxValue={chartValues.maxValue}
				/>
			</GridBox>
		</CardBodyContainer>
	);
}
