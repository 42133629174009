import {Textarea} from '@esgi/ui/controls';
import {Question, TestInfo, TestSession, UpdateYNSessionRequestParams} from '../../../../../types';
import {AlertBody} from '../../../../components.styled';
import {FilterContainer} from '../../index.styled';
import {FilterButton} from '../filter-button';
import {TestNameWithBar} from '../test-name-with-bar';
import {AlertFooter} from '../../../../alert-footer';
import {EditableSessionTimeInfo} from '../../../../editable-session-time-info';
import {AlertHeader} from '../../../../alert-header';
import {
	NoChangesConfirmation,
	QuestionRowEditable,
	QuestionsContainer,
	YNAnswerToggleState,
	maxYNCommentLength,
} from '../../../../../../kit';
import {useNoChangesConfirmationState} from '../../../hooks/use-no-changes-confirmation-state';
import {useEditModeState} from './use-edit-mode-state';
import {Student} from '@esgi/main/libs/store';

type Props = {
	canEdit: boolean;
	sessionInfo: TestSession;
	questionTitlesById: Record<Question['id'], Question['label']>;
	testInfo: TestInfo;
	onCloseAlert: VoidFunction;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	student: Student;
	onUpdateTestSession: (args: {
		answers: UpdateYNSessionRequestParams['answers'];
		notes: string;
		testDateTouched: boolean;
		testDate: string;
	}) => void;
};

export function EditModeContent({
	canEdit,
	testInfo,
	sessionInfo,
	questionTitlesById,
	onCloseAlert,
	setIsEditMode,
	student,
	onUpdateTestSession,
}: Props) {
	const {
		isDataTouched,
		correctAnswers,
		filterItems,
		setSessionTimeInfoTouched,
		setIsSessionTimeInfoValid,
		dateTimeRef,
		answerState,
		toggleQuestionState,
		sessionNote,
		onUpdateSessionNote,
		isDataValid,
		handleSave,
		onAddComment,
		onDeleteComment,
	} = useEditModeState({sessionInfo, testInfo, onUpdateTestSession});

	const {handleCloseAlert, setViewMode, isOpenNoChangesConfirmation, onCloseNoChangesConfirmation, onCloseAnyway} =
		useNoChangesConfirmationState({isDataTouched, onCloseAlert, setIsEditMode});

	return (
		<>
			<AlertHeader canEdit={canEdit} isEditMode onCloseAlert={handleCloseAlert} onEditModeClick={setViewMode} student={student} />
			<AlertBody>
				<TestNameWithBar testInfo={testInfo} correctAnswers={correctAnswers} />

				<FilterContainer>
					{filterItems.map(({answerState, countAnswers}, index) => (
						<FilterButton answerState={answerState} countAnswers={countAnswers} disabled key={index} />
					))}
				</FilterContainer>

				<EditableSessionTimeInfo
					sessionInfo={sessionInfo}
					setTouched={setSessionTimeInfoTouched}
					setisValid={setIsSessionTimeInfoValid}
					editableSessionTimeRef={dateTimeRef}
				/>

				<QuestionsContainer>
					{answerState.map(({comment, questionID, answerState}, index) => (
						<QuestionRowEditable
							questionNumber={index + 1}
							questionTitle={questionTitlesById[questionID] ?? ''}
							comment={comment}
							withQuestionUnderline={index !== sessionInfo.answers.length - 1}
							key={questionID}
							onAddComment={(comment) => onAddComment({comment, questionID})}
							onDeleteComment={() => onDeleteComment({questionID})}
							maxCommentLength={maxYNCommentLength}
						>
							<YNAnswerToggleState
								activeState={answerState}
								onToggle={({newState, previousState}) =>
									toggleQuestionState({newState, questionId: questionID, previousState})
								}
							/>
						</QuestionRowEditable>
					))}
				</QuestionsContainer>

				<Textarea placeholder='Summary Note' value={sessionNote} onChange={onUpdateSessionNote} dataCy='summary-note' />
			</AlertBody>

			<AlertFooter isEditMode isSaveDisabled={!isDataTouched || !isDataValid} onSave={handleSave} />

			{isOpenNoChangesConfirmation && (
				<NoChangesConfirmation onCloseAnyway={() => onCloseAnyway?.()} onClose={onCloseNoChangesConfirmation} />
			)}
		</>
	);
}
