import {memo, useCallback} from 'react';
import {Cell, NameCell, NameCheckbox, Timestamp, LableBox, TimestampContainer, ButtonText, StyledText} from './index.styled';
import {useTableRowStyles} from '../../hooks/use-table-row-styles';
import {Row} from '../../index.styled';
import {Test} from '../../../../service/types';
import {OneLinedText, TestColorIndicator, TestContentArea} from '@esgi/main/kits/common';
import moment from 'moment';
import {TestDetailsState} from '../body/types';
import {GridBox} from '@esgi/ui/layout';
import {useUser} from '@esgi/core/authentication';
import {MultipleLabels} from './multiple-labels';

type Props = {
	selected: boolean;
	testInfo: Test;
	onTestSelect: (id: number) => void;
	onTestNameClick: (args: TestDetailsState) => void;
};

enum TestTypeLabel {
	YN = 'Yes/No',
	Rubric = 'Rubric',
	Score = 'Single Score',
}

function TestRowImpl({testInfo, onTestSelect, selected, onTestNameClick}: Props) {
	const user = useUser();

	const userStateStandard = testInfo.stateStandards.filter(({stateID}) => stateID === user.stateID);
	const commonStandards = testInfo.stateStandards.filter(({stateID}) => stateID === 0);

	const standards = userStateStandard.length ? userStateStandard : commonStandards;

	const {id, name, author, questions, created, testType, disabled, contentArea} = testInfo;

	const styles = useTableRowStyles(false, selected);

	const onCheckedChange = useCallback(() => {
		if (!disabled) {
			onTestSelect(id);
		}
	}, [disabled, onTestSelect, id]);

	const handleTestNameClick = useCallback(() => {
		onTestNameClick({
			testId: id,
			testType,
		});
	}, [id, onTestNameClick, testType]);

	return (
		<Row css={styles} key={id} dataCy='test-row'>
			<NameCell dataCy='name-cell'>
				<NameCheckbox disabled={disabled} checked={selected} active={selected} onCheckedChange={onCheckedChange} />
				<TestColorIndicator testID={id} contentArea={contentArea as TestContentArea} />
				<GridBox gap='3' flow='column' align='center' justify='start'>
					<ButtonText onClick={handleTestNameClick}>
						<OneLinedText size='medium' >
							{name}
						</OneLinedText>
					</ButtonText>
					<LableBox dataCy='test-type'>
						<StyledText size='small' color='neutral40' disabled={disabled}>
							{TestTypeLabel[testType]}
						</StyledText>
					</LableBox>
					{
						standards.length > 0 && <MultipleLabels items={standards.map(s => s.name)}/>
					}
				</GridBox>
			</NameCell>
			<Cell dataCy='author-cell'>
				<StyledText size='small' color='neutral40' disabled={disabled}>
					{author}
				</StyledText>
			</Cell>
			<Cell dataCy='questions-cell'>
				<StyledText size='small' color='neutral40' disabled={disabled}>
					{questions}
				</StyledText>
			</Cell>
			<Cell dataCy='created-cell'>
				<TimestampContainer>
					<Timestamp>
						<StyledText size='small' color='neutral40' disabled={disabled}>
							{moment(created).format('MM/DD/YY')}
						</StyledText>
					</Timestamp>
				</TimestampContainer>
			</Cell>
		</Row>
	);
}

export const TestRow = memo(TestRowImpl);
