import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';

export const EmptyContent = styled(GridBox, {
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	backgroundColor: '$background',
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 20,
	paddingRight: 20,
	height: 'max-content',
	justifyContent: 'center',
	gap: 6,
	textAlign: 'center',
	alignSelf: 'center',
	justifySelf: 'center',
});

export const EmptyContentBox = styled(GridBox, {
	height: '100px',
	placeItems: 'center',
});

export const ButtonAdd = styled(Button, {
	width: '166px',
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});