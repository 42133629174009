import {SubjectType, TestScreenType} from '@esgi/core/enums';
import {
	SubjectHiddenEvent,
	SubjectReorderedEvent,
	TestAddedToSubjectEvent,
	TestMovedBetweenSubjectsEvent,
	TestRemovedFromSubjectEvent,
	SubjectUnhiddenEvent,
	TestData,
} from 'api/entities/events/subject';
import {finalize} from 'rxjs/operators';
import {HttpClient} from '@esgi/api';
import {SsoTracker} from '@esgi/core/tracker';
import {dispatchAppEvent} from '@esgillc/events';

export class Subject {
	private static readonly controllerCommonName = 'assets/subjects/common';
	private static readonly controllerPersonalName = 'assets/subjects/personal';

	public static addTest(subjectId: number, subjectType: SubjectType, testId: number, testInfo?: TestData) {
		return HttpClient.default.ESGIApi.post<{testScreenTypes: TestScreenType}>(this.controllerPersonalName, 'test/add', {
			testId: testId,
			subjectId: subjectId,
			subjectType: subjectType,
		}).toPromise().then((result) => {
			if (result.testScreenTypes?.includes(TestScreenType.SelfAssessment)) {
				SsoTracker.trackEvent({
					trackingEvent: 'SATestAdded',
					data: {testId, subjectId},
				});
			}
			dispatchAppEvent(TestAddedToSubjectEvent, new TestAddedToSubjectEvent(subjectId, subjectType, testId, testInfo));
		});
	}

	public static moveTestToSubject(testId: number, sourceSubjectId: number, destinationSubjectId: number, subjectType: number, orderNumber: number = -1) {
		return HttpClient.default.ESGIApi.post(this.controllerPersonalName, 'test/move', {
			testId: testId,
			sourceSubjectId: sourceSubjectId,
			destinationSubjectId: destinationSubjectId,
			subjectType: subjectType,
			orderNumber: orderNumber,
		}).pipe(finalize(() => {
			dispatchAppEvent(TestMovedBetweenSubjectsEvent, new TestMovedBetweenSubjectsEvent(testId, sourceSubjectId, destinationSubjectId));
		}));
	}

	public static removeTest(subjectId: number, subjectType: SubjectType, testId: number) {
		return HttpClient.default.ESGIApi.post(this.controllerPersonalName, 'test/remove', {
			subjectId: subjectId,
			testId: testId,
			subjectType: subjectType,
		}).toPromise().then((resolve) => {
			dispatchAppEvent(TestRemovedFromSubjectEvent, new TestRemovedFromSubjectEvent(subjectId, subjectType, testId));
		});
	}

	public static async reorder(type: SubjectType, ids: number[]) {
		await HttpClient.default.ESGIApi.post(this.controllerCommonName, 'reorder', {
			subjectType: type,
			subjectIds: ids,
		}).toPromise();
		dispatchAppEvent(SubjectReorderedEvent, new SubjectReorderedEvent(type, ids));
	}

	public static async hide(id: number, type: SubjectType) {
		await HttpClient.default.ESGIApi.post(this.controllerPersonalName, 'hide', {
			subjectId: id,
			subjectType: type,
		}).toPromise();
		dispatchAppEvent(SubjectHiddenEvent, new SubjectHiddenEvent(id, type));
	}

	public static async unhide(id: number, type: SubjectType) {
		await HttpClient.default.ESGIApi.post(this.controllerPersonalName, 'unhide', {
			subjectId: id,
			subjectType: type,
		}).toPromise();
		dispatchAppEvent(SubjectUnhiddenEvent, new SubjectUnhiddenEvent(id, type));
	}
}
