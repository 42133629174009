import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {Button, isUndefined} from '@esgi/ui';
import {ChangeEvent, Dispatch, useCallback, useState} from 'react';
import {Textarea} from '@esgi/ui/controls';
import {NoChangesConfirmation} from '../../../no-changes-confirmation';
import {AlertBody} from './index.styled';

type Props = {
	defaultComment: string;
	questionTitle: string;
	onAlertClose: VoidFunction;
	onAddComment: Dispatch<string>;
	onDeleteComment: VoidFunction;
	maxCommentLength?: number | undefined;
};

export function AddEditComment({
	defaultComment,
	questionTitle,
	onAlertClose,
	onAddComment,
	onDeleteComment,
	maxCommentLength,
}: Props) {
	const isEditMode = Boolean(defaultComment);

	const [comment, setComment] = useState(defaultComment);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [isOpenNoChangesConfirmation, setIsOpenNoChangesConfirmation] = useState(false);

	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onAlertClose);

	const setDefaultValue = useCallback(() => {
		setComment(defaultComment);
	}, [defaultComment]);

	const onCommentChange = useCallback(
		(event: ChangeEvent<HTMLTextAreaElement>) => {
			const value = event.target.value;

			setComment(value);

			if (!isUndefined(maxCommentLength) && value.length > maxCommentLength) {
				setErrorMessage(`Comment is too long. Max allowed characters: ${maxCommentLength}`);
			} else {
				setErrorMessage(undefined);
			}
		},
		[maxCommentLength],
	);

	const isCommentTouched = comment !== defaultComment;

	const onCloseAlert = () => {
		if (isCommentTouched) {
			setIsOpenNoChangesConfirmation(true);
			return;
		}

		closeAlert();
	};

	const onCloseNoChangesConfirmation = useCallback(() => {
		setIsOpenNoChangesConfirmation(false);
	}, []);

	const onCloseAnywayNoChangesConfirmation = useCallback(() => {
		setIsOpenNoChangesConfirmation(false);
		closeAlert();
	}, [closeAlert]);

	const handleUpdateCommentAndCloseAlert = () => {
		if (isEditMode && !comment) {
			onDeleteComment();
		}

		if (comment) {
			onAddComment(comment);
		}

		closeAlert();
	};

	return (
		<>
			<Alert
				colorConfig={isEditMode ? {headerBgColor: 'primaryBackground'} : alertColorsConfig.neutral}
				modalManagerRef={alertRef}
			>
				<Alert.Header withBacklight={false} onCloseIconClick={onCloseAlert}>
					<GridBox flow='column' gap='2'>
						<Text size='small' color='base'>
							Comment
						</Text>
						{isEditMode && (
							<Text size='small' color='primary'>
								Edit Mode
							</Text>
						)}
					</GridBox>
				</Alert.Header>
				<AlertBody>
					<Text size='xLarge' color='base'>
						{questionTitle}
					</Text>
					<GridBox>
						<Textarea placeholder='Comment' value={comment} onChange={onCommentChange} error={errorMessage} />
					</GridBox>
				</AlertBody>
				<Alert.Footer>
					<GridBox gap='3' flow='column'>
						<Button color='tertiary' onClick={setDefaultValue} disabled={!isCommentTouched}>
							<Text size='medium' bold color='base'>
								Cancel
							</Text>
						</Button>
						<Button
							color='secondary'
							onClick={handleUpdateCommentAndCloseAlert}
							disabled={!isCommentTouched || Boolean(errorMessage)}
						>
							<Text size='medium' bold color='base'>
								{isEditMode ? 'Save' : 'Add'}
							</Text>
						</Button>
					</GridBox>
				</Alert.Footer>
			</Alert>

			{isOpenNoChangesConfirmation && (
				<NoChangesConfirmation
					onCloseAnyway={onCloseAnywayNoChangesConfirmation}
					onClose={onCloseNoChangesConfirmation}
				/>
			)}
		</>
	);
}
