import {Buttons} from '@esgillc/ui-kit/button';
import {Alert} from '@esgillc/ui-kit/modal';
import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

interface Props {
	onClose: () => void,
	onSave: () => void,
	onCancel: () => void
	name?: string
}

export function SaveDuplicateConfirmModal({onClose, onSave, onCancel, name}: Props){
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	return <Alert data-cy='save-duplicate-confirm-modal' modalManagerRef={modalRef} className={styles.confirmDuplicateModal}>
		<Alert.Header className={styles.header}>
			<Title className={styles.title}>Save a duplicate?</Title>
			<p className={styles.text}>{name} matches a student that already exists in ESGI. Would you like to save anyway?</p>
		</Alert.Header>
		<Alert.Footer className={styles.footer}>
			<div data-cy='footer-container'>
			<Buttons.Text onClick={() => {
				onCancel();
				handleClose();
			}
			}>Cancel</Buttons.Text>
			<Buttons.Text onClick={() => {
				onSave();
				handleClose();
			}}>SAVE ANYWAY</Buttons.Text>
			</div>
		</Alert.Footer>
	</Alert>;
}
