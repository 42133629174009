import {StudentSectionMode} from '@esgi/main/features/teacher/home';
import {Group, Class, Student} from '@esgi/main/libs/store';
import {StudentSectionModePermissions} from '../types';
import {TabID} from '@esgi/main/kits/common';

export function getSectionModePermissionsData({
	classes,
	groups,
	students,
	canEdit,
	canAdd,
}: {
	classes: Class[];
	groups: Group[];
	students: Student[];
	canEdit: boolean;
	canAdd: boolean;
}): StudentSectionModePermissions {
	const canEditStudents = Boolean(canEdit && students.length);

	return {
		[StudentSectionMode.View]: {
			[TabID.Classes]: true,
			[TabID.Groups]: true,
			[TabID.Students]: true,
		},
		[StudentSectionMode.Edit]: {
			[TabID.Classes]: Boolean(classes.length),
			[TabID.Groups]: Boolean(groups.length || canEditStudents),
			[TabID.Students]: canEditStudents,
		},
		[StudentSectionMode.Rearrange]: {
			[TabID.Classes]: Boolean(classes.length),
			[TabID.Groups]: Boolean(groups.length),
			[TabID.Students]: false,
		},
		[StudentSectionMode.Add]: {
			[TabID.Classes]: true,
			[TabID.Groups]: Boolean(classes.length),
			[TabID.Students]: Boolean(canAdd),
		},
	};
}
