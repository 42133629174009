import React from 'react';
import {CardBodyBox, ProgressBarsDivider, ProgressBarsWrapper} from './index.styled';
import {Box, Card, GridBox} from '@esgi/ui/layout';
import {TextWithTranscript, TextWithTranscriptProps} from '../text-with-transcript';
import {isNull} from 'underscore';
import {CardActionButtons} from '../card-action-buttons';
import {TestSession} from '@esgi/main/features/teacher/home';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {ClassGroupActionButtonsProps, StudentActionButtonsProps} from '../card-action-buttons/types';

type Props = {
	textWithTranscriptItems: (TextWithTranscriptProps | null)[];
	progressBars: React.JSX.Element[];
	dataCy?: string;
	isSmallScreen: boolean;
	lastTestSessions: TestSession[];
	sessionsCount: number;
	maxScore: number;
	openTestDetails: VoidFunction;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	testID: number;
	onTestMoveTo: (args: {previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}) => void;
	onRemoveTest: (args: {subjectID: SubjectTab['id']; testID: number}) => void;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
} & (StudentActionButtonsProps | ClassGroupActionButtonsProps);

export function CardBody({
	textWithTranscriptItems,
	progressBars,
	isSmallScreen,
	dataCy = 'test-card-body',
	lastTestSessions,
	sessionsCount,
	maxScore,
	openTestDetails,
	userCanEditTest,
	userCanRemoveTest,
	testID,
	onRemoveTest,
	onTestMoveTo,
	subjectID,
	classID,
	...props
}: Props) {
	return (
		<GridBox gap={2}>
			<CardBodyBox dataCy={dataCy} withPadding={!isSmallScreen}>
				<ProgressBarsWrapper>
					{progressBars.map((progressBarComponent, index) => (
						<React.Fragment key={index}>
							{progressBarComponent}
							{index !== progressBars.length - 1 && !isSmallScreen && <ProgressBarsDivider />}
						</React.Fragment>
					))}
				</ProgressBarsWrapper>

				<GridBox gapX='8' gapY='4' rows='2' columns='2' dataCy={`${dataCy}-transcript-items`}>
					{textWithTranscriptItems.map((itemProps, index) =>
						isNull(itemProps) ? <Box key={index} /> : <TextWithTranscript {...itemProps} key={index} />,
					)}
				</GridBox>
			</CardBodyBox>
			{isSmallScreen && (
				<GridBox gap={2}>
					<Card.Separator />
					<CardActionButtons
						dataCy={dataCy}
						lastTestSessions={lastTestSessions}
						sessionsCount={sessionsCount}
						maxScore={maxScore}
						openTestDetails={openTestDetails}
						userCanEditTest={userCanEditTest}
						userCanRemoveTest={userCanRemoveTest}
						testID={testID}
						onTestMoveTo={onTestMoveTo}
						onRemoveTest={onRemoveTest}
						isSmallScreen
						classID={classID}
						subjectID={subjectID}
						{...props}
					/>
				</GridBox>
			)}
		</GridBox>
	);
}
