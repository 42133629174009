import {useCallback, useState} from 'react';
import {Drawer, DrawerMode, useDrawerMode, useDrawerParameters} from '@esgi/main/features/teacher/home';
import {EditGroupFormParams, TeacherDrawerName, AddGroupFormParams} from '../types';
import {AddGroup} from './components/add-group';
import {EditGroup} from './components/edit-group';
import {NoChangesConfirmation} from './components/no-changes-confirmation';
import {Alert} from '@esgi/ui/alert';
import {isNull} from 'underscore';

export * from './events';

export function DrawerWithGroupForm() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);

	const drawerMode = useDrawerMode({drawerName: TeacherDrawerName.TeacherGroup});

	const {classId, groupId, schoolYear} = useDrawerParameters<EditGroupFormParams>(['classId', 'groupId', 'schoolYear']);
	const {classId: addModeDefaultClassID} = useDrawerParameters<AddGroupFormParams>(['classId']);

	const confirmationAlert = Alert.useRef();

	const openUnsavedChangesAlert = Alert.useOpen(confirmationAlert);

	const onBeforeCloseDrawerCb = useCallback(() => {
		if (isFormTouched) {
			openUnsavedChangesAlert();
		}

		return !isFormTouched;
	}, [isFormTouched, openUnsavedChangesAlert]);

	return (
		<Drawer
			drawerName={TeacherDrawerName.TeacherGroup}
			skeleton={!isLoaded}
			beforeCloseDrawerCb={onBeforeCloseDrawerCb}
			dataCy='drawer-with-group'
		>
			{drawerMode === DrawerMode.Create && (
				<AddGroup
					onLoaded={setIsLoaded}
					onFormTouched={setIsFormTouched}
					initialClassID={!isNull(addModeDefaultClassID) ? Number(addModeDefaultClassID) : null}
				/>
			)}
			{drawerMode === DrawerMode.Edit && classId && groupId && (
				<EditGroup
					onLoaded={setIsLoaded}
					initialClassID={Number(classId)}
					groupID={Number(groupId)}
					schoolYear={Number(schoolYear)}
					onFormTouched={setIsFormTouched}
				/>
			)}

			<NoChangesConfirmation confirmationAlert={confirmationAlert} />
		</Drawer>
	);
}
