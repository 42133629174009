import {lazyComponent} from '@esgi/core/react';
import {withErrorHandling} from '@esgillc/ui-kit/core';
import {StudentTestDashboard as ReExportStudentTestDashboard, ClassGroupTestDashboard as ReExportClassGroupTestDashboard} from '@esgi/main/features/teacher/test-dashboard';

export {EmptyBody as ReportsEmptyBody} from './reports/empty-body';
export {ReportsLayout} from './reports/layout';
export {useOpenDrawer} from './navigator/teacher/drawers';

export const Home = withErrorHandling(lazyComponent(() => import('./home')));
export const TestExplorer = withErrorHandling(lazyComponent(() => import('./test-explorer')));
export const ParentConferencer = withErrorHandling(lazyComponent(() => import('./parent-conferencer')));
export const StudentManager = withErrorHandling(lazyComponent(() => import('./student-manager')));
export const Assignment = withErrorHandling(lazyComponent(() => import('./assignments/assignment')));
export const ManageAssignment = withErrorHandling(lazyComponent(() => import('./assignments/assignment-manage')));
export const AssignmentsList = withErrorHandling(lazyComponent(() => import('./assignments/assignments-list')));
export const AssignmentsLayout = withErrorHandling(lazyComponent(() => import('./assignments/layout')));
export const TotalReport = withErrorHandling(lazyComponent(() => import('./reports/class/total')));
export const ItemsAnalysisReport = withErrorHandling(lazyComponent(() => import('./reports/class/item-analysis')));
export const RubricReport = withErrorHandling(lazyComponent(() => import('./reports/class/rubric')));
export const ParentLetter = withErrorHandling(lazyComponent(() => import('./reports/student/parent-letter')));
export const Bingo = withErrorHandling(lazyComponent(() => import('./activities/bingo')));
export const Flashcards = withErrorHandling(lazyComponent(() => import('./activities/flashcards')));
export const SightWords = withErrorHandling(lazyComponent(() => import('./activities/sight-words')));
export const PieCharts = withErrorHandling(lazyComponent(() => import('./reports/class/pie-chart')));
export const StudentProgressReport = withErrorHandling(lazyComponent(() => import('./reports/student/student-progress')));
export const StudentDetail = withErrorHandling(lazyComponent(() => import('./reports/student/student-detail')));
export const ClassGrades = withErrorHandling(lazyComponent(() => import('./reports/class/grades')));
export const UntestedStudents = withErrorHandling(lazyComponent(() => import('./reports/class/untested-students')));
export const NotFound = withErrorHandling(lazyComponent(() => import('./not-found')));
export const Root = withErrorHandling(lazyComponent(() => import('./page-with-navigation')));
export const WritingPractice = withErrorHandling(lazyComponent(() => import('./activities/writing-practice')));

export {ActivitiesLayout, EmptyBody as ActivitiesEmptyBody} from './activities';
export const StudentTestDashboard = withErrorHandling(ReExportStudentTestDashboard);
export const ClassGroupTestDashboard = withErrorHandling(ReExportClassGroupTestDashboard);

