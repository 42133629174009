import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Filters, Layout, StyledSort} from './index.styled';
import {Header} from './components/header';
import {Button} from '@esgi/ui';
import {useService} from '@esgi/core/service';
import {AssignmentsService} from './service';
import {Body} from './components/body';
import {Tabs} from './components/tabs';
import {useTeacherAssignmentsContext} from '../layout/outlet-context';
import {useOpenAddAssignment} from '../hooks/use-open-add-assignment';
import {storage, useStore} from '@esgi/main/libs/store';
import {ExportStudentsCredentialsAlert} from '../export-students-credentials-alert';

export default function () {
	const onAddAssignmentClick = useOpenAddAssignment();

	const [isOpenExportStudentsCredentialsAlert, setIsOpenExportStudentsCredentialsAlert] = useState(false);

	const [descendingSort, setDescendingSort] = useState(true);

	const [studentList, updateStudentList, isStudentListLoaded] = useStore(storage.students);

	const {
		visibleAssignmentsList,
		selectedAssignmentState,
		setSelectedAssignmentState,
		isAssignmentListLoaded,
		snackbarRef,
		assignmentsList,
	} = useTeacherAssignmentsContext();

	const service = useService(AssignmentsService);

	useEffect(() => {
		const body = document.body;

		if (body.style.pointerEvents) {
			body.style.pointerEvents = '';
		}
	}, [visibleAssignmentsList.length]);

	const sortedAssignments = useMemo(
		() =>
			[...visibleAssignmentsList].sort((a, b) => {
				const aCreated = new Date(a.created).getTime();
				const bCreated = new Date(b.created).getTime();

				return descendingSort ? bCreated - aCreated : aCreated - bCreated;
			}),
		[visibleAssignmentsList, descendingSort],
	);

	const onOpenExportStudentsCredentialsAlert = useCallback(() => {
		if (studentList.length) {
			setIsOpenExportStudentsCredentialsAlert(true);
		}
	}, [studentList]);

	const closeExportStudentsCredentialsAlert = useCallback(() => {
		setIsOpenExportStudentsCredentialsAlert(false);
	}, []);

	return (
		<>
			<Layout dataCy='assignments-list'>
				<Header
					loaded={isAssignmentListLoaded && isStudentListLoaded}
					onExportClick={onOpenExportStudentsCredentialsAlert}
					onAddClick={onAddAssignmentClick}
					students={studentList}
				/>
				{Boolean(assignmentsList.length) && (
					<Filters>
						<Tabs
							activeTab={selectedAssignmentState}
							setActiveTab={setSelectedAssignmentState}
							disabled={!isAssignmentListLoaded}
						/>
						{Boolean(assignmentsList.length) && (
							<Button.Icon dataCy='sort-button' onClick={() => setDescendingSort(!descendingSort)} disabled={!isAssignmentListLoaded}>
								<StyledSort descending={descendingSort} />
							</Button.Icon>
						)}
					</Filters>
				)}
				<Body
					service={service}
					isInitialized={isAssignmentListLoaded}
					assignments={sortedAssignments}
					snackbarRef={snackbarRef}
					selectedAssignmentState={selectedAssignmentState}
					isAssignmentsExist={Boolean(assignmentsList.length)}
				/>
			</Layout>

			{isOpenExportStudentsCredentialsAlert && (
				<ExportStudentsCredentialsAlert
					studentsIDs={studentList.map(({id}) => id)}
					onCloseAlert={closeExportStudentsCredentialsAlert}
					type='multipleAssignments'
					assignments={visibleAssignmentsList.map(({id, students, name}) => ({
						id,
						name,
						studentsIDs: students.map((id) => id),
					}))}
				/>
			)}
		</>
	);
}
