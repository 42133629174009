import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
	value: number | null;
	maxValue: number;
	caption?: string;
	dataCy?: string;
};

export function AnswersInfo({value, maxValue, caption, dataCy ='answers-info'}: Props) {
	return (
		<GridBox data-cy={dataCy}>
			<FlexBox align={value ? 'end' : 'center'} dataCy={`${dataCy}-container`}>
				<Text css={{lineHeight: '14px'}} size='medium' bold font='mono' color='base' data-cy={`${dataCy}-value`}>
					{value ?? '-'}
				</Text>
				<Text size='xSmall' font='mono' color='lowContrast' data-cy={`${dataCy}-max-value`}>
					{' '}
					/{maxValue}
				</Text>
			</FlexBox>
			{caption && (
				<Text size='xSmall' font='mono' color='mediumContrast' data-cy={`${dataCy}-caption`}>
					{caption}
				</Text>
			)}
		</GridBox>
	);
}
