import {useCallback} from 'react';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';

type Props = {
	onClose: VoidFunction;
	onCloseAnyway: VoidFunction;
};

export function UnsavedChangesAlert({onClose, onCloseAnyway}: Props) {
	const confirmationAlert = Alert.useRef();

	const closeAlert = Alert.useClose(confirmationAlert, onClose);

	const closeAnyway = useCallback(() => {
		closeAlert();
		onCloseAnyway();
	}, [closeAlert, onCloseAnyway]);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={confirmationAlert}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Unsaved Changes
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox>
					<Text size='medium' color='lowContrast'>
						You’ve made some changes but didn’t save them. All unsaved changes will be lost.
					</Text>
					<Text size='medium' color='lowContrast'>
						Are you sure you want to close the window?
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAnyway}>
						<Text size='medium' bold color='base'>
							Close Anyway
						</Text>
					</Button>
					<Button color='secondary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Go Back
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}