import {SelectableList as SelectableListUI} from '@esgi/ui/layout';
import {SelectableListGroup, SelectableListItem, StyledText} from './index.styled';
import {OneLinedText} from '@esgi/main/kits/common';
import {OptionItem} from '../../types';

type Props = {
	selected: OptionItem['value'];
	items: OptionItem[];
	onClick?: (id: number) => void;
}

export function SelectableList({items, onClick, selected}: Props) {
	return (
		<SelectableListUI>
			<SelectableListUI.GroupRoot type='single' value={String(selected)}>
				<SelectableListGroup>
					{items.map(({value, label, description, disabled}) => (
						<SelectableListItem
							value={String(value)}
							key={value}
							variant='outlined'
							onClick={() => onClick?.(value)}
							disabled={disabled}
						>
							<StyledText size='large' selected={!disabled && selected === value}>{label}</StyledText>
							{description && <OneLinedText size='small' css={{color: '$mediumContrast !important'}}>{description}</OneLinedText>}
						</SelectableListItem>
					))}
				</SelectableListGroup>
			</SelectableListUI.GroupRoot>
		</SelectableListUI>
	);
}