import {Text} from '@esgi/ui/typography';
import React, {PropsWithChildren} from 'react';
import {InfoItemWrapper} from './index.styled';

type Props = PropsWithChildren & { title: string, viewType?: 'grid' }

export function InfoItem({title, children, viewType}: Props) {
	return (
		<InfoItemWrapper viewType={viewType}>
			<Text size='xSmall' font='mono' color='mediumContrast'>{title}:</Text>
			{children}
		</InfoItemWrapper>
	);
}