import {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {Drawer, DrawerMode, useDrawerMode} from '@esgi/main/features/teacher/home';
import {StudentProfile} from './components/student-profile';
import {StudentProfileMode} from './components/student-profile/types';
import {ModalOwnerContainer} from './index.styled';
import {useSearchParams, useLocation} from 'react-router-dom';
import {UnsavedChangesAlert} from './components/student-profile/components/unsaved-changes-alert';
import {useUser} from '@esgi/core/authentication';

export * from './events';

export const StudentProfileDrawerName = 'teacherStudentViaProfile';

export function StudentProfileDrawer() {
	const user = useUser();

	const [searchParams, setSearchParams] = useSearchParams();
	const {state} = useLocation();
	const drawerMode = useDrawerMode({
		drawerName: StudentProfileDrawerName,
	});
	const studentDrawerModalsOwner = useRef();
	const mode = drawerMode === DrawerMode.Create ? StudentProfileMode.add : StudentProfileMode.edit;
	const name = searchParams.get('drawerName');
	const isOpen = name === StudentProfileDrawerName;

	const [isUnsavedChangesAlert, setUnsavedChangesAlert] = useState(false);

	const formTouchedRef = useRef<{
		updateTouchedStatus: () => boolean;
		isInitialized: boolean;
	}>();

	const onBeforeCloseDrawerCb = useCallback(() => {
		const isFormTouched = formTouchedRef.current.updateTouchedStatus();
		const isInitialized = formTouchedRef.current.isInitialized;

		if (isFormTouched && isInitialized) {
			setUnsavedChangesAlert(true);
		}

		return !(isFormTouched && isInitialized);
	}, [formTouchedRef]);

	useLayoutEffect(() => {
		if (isOpen && (!user?.canAddStudents || !user.canEditStudents)) {
			setSearchParams({}, {replace: true});
		}
	}, [isOpen, user]);

	const onDrawerClose = useCallback(() => {
		if (state?.goBack) {
			// TODO: Unify the operation with closing a drower and restoring a previous state. Typify the state. Work only through the address location. There are cases where the drower is opened without changing the address location.
			history.go(-2); // It is necessary to make a 2 steps back. After closing the drower, the address location will already be updated. If you take only one step back, the same drower will be opened again and cannot be closed.
		}
	}, [state]);

	return (
		<>
			<Drawer
				drawerName={StudentProfileDrawerName}
				beforeCloseDrawerCb={onBeforeCloseDrawerCb}
				dataCy='drawer-with-student-via-profile'
				onDrawerClose={onDrawerClose}
			>
				{isOpen && (
					<StudentProfile mode={mode} modalsOwner={studentDrawerModalsOwner} formTouchedRef={formTouchedRef} />
				)}
				{isUnsavedChangesAlert && <UnsavedChangesAlert onClose={() => setUnsavedChangesAlert(false)} />}
			</Drawer>
			<ModalOwnerContainer ref={studentDrawerModalsOwner} />
		</>
	);
}
