import {SubjectTab} from '@esgi/main/libs/store';
import {Label, SelectRoot, SelectContent, SelectField, SelectOption} from './index.styled';
import {Skeletonable} from '@esgi/ui';
import {ChangeFunction} from '../../../types';

interface Props extends Skeletonable {
	subjects: SubjectTab[];
	value: string;
	disabled?: boolean;
	onChange: ChangeFunction<string>;
}

export function SubjectControl(props: Props) {
	const {skeleton, subjects, value, disabled, onChange} = props;

	return (
		<SelectRoot
			value={value}
			disabled={disabled}
			onValueChange={(value) => onChange(value)}
			dataCy='subject-select'
		>
			<SelectField
				skeleton={skeleton}
				placeholder='Subject Tab'
			/>
			<SelectContent>
				{subjects.map(({id, name}) => (
					<SelectOption
						value={id.toString()}
						key={id}
					>
						<Label size='medium' bold>{name}</Label>
					</SelectOption>
				))}
			</SelectContent>
		</SelectRoot>
	);
}
