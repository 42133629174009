export const diagramHeight = 212;

export const yAxisTicksTitleGap = 24;
export const yAxixLineChartWidth = 24;
export const yAxisGap = 20;

export const yAxisDiagramGap = 20;
export const xAxisDiagramGap = 16;

export const barLineWidth = 20;

export const pageSize = 14;
