import {sortBy} from 'underscore';
import {useCallback, useMemo, useRef} from 'react';
import {GradeLevelModel, SchoolModel, SpecialistModel, StudentModel} from '../../types';
import {Container, SkeletonRow} from './styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Header} from './components/header';
import {StudentRow} from './components/row';
import {useStudentsInfo} from './hooks/use-students-info';
import {FieldType, SortDirection} from './types';
import {useSorting} from './hooks/use-sorting';
import {useVirtualizer} from '@tanstack/react-virtual';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {Box} from '@esgi/ui/layout';
import {NoStudents} from './components/no-students';
import {StudentManagerService} from '../../service';

type Props = {
	loaded: boolean,
	agreementLevel: string,
	gradeLevels: GradeLevelModel[],
	schools: SchoolModel[]
	students: StudentModel[],
	selectedStudents: StudentModel[],
	onAllSelect: VoidFunction,
	onStudentSelect: (student: StudentModel) => void,
	service: StudentManagerService,
	showSpecialistColumns: boolean,
	specialists: SpecialistModel[],
}

const SkeletonRowArray = new Array(30).fill('');

export function Table(props: Props) {
	const studentsInfo = useStudentsInfo(props.students, props.schools, props.gradeLevels);

	const [sorting, setSorting] = useSorting();

	const onCellClicked = useCallback((field: FieldType) => {
		if(field === sorting.field) {
			setSorting({...sorting, direction: sorting.direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc});
		} else {
			setSorting({...sorting, field});
		}
	}, [sorting, setSorting]);

	const students = useMemo(() => {
		const sorted = sortBy(studentsInfo, (student) => {
			switch (sorting.field) {
				case FieldType.Name: return student.name.toLowerCase();
				case FieldType.Grade: return student.grade.gradeLevelID;
				case FieldType.Teacher: return `${student.teacher?.firstName} ${student.teacher?.lastName}`;
				case FieldType.Class: return student.classes.map(c => c.name).sort().join(', ');
				case FieldType.Groups: return student.groups.map(g => g.name).sort().join(', ');
				case FieldType.Created: return new Date(student.createDate).getTime();
				case FieldType.ID: return student.id;
			}
		});
		if(sorting.direction === SortDirection.Asc) {
			props.service.setSortedStudentsIds(sorted.map(({model: {studentID}}) => studentID));
			return sorted;
		} else {
			const reversed = sorted.reverse();
			props.service.setSortedStudentsIds(reversed.map(({model: {studentID}}) => studentID));
			return reversed;
		}
	}, [studentsInfo, sorting]);

	const allSelected = useMemo(() => {
		const selected = props.selectedStudents.map(s => s.studentID);
		const students = props.students.map(s => s.studentID);
		return students.every(s => selected.includes(s));
	}, [props.selectedStudents, props.students]);

	const osRef = useRef<OverlayScrollbarsComponentRef>();

	const rowVirtualizer = useVirtualizer({
		count: students.length,
		getScrollElement: () => osRef.current?.osInstance()?.elements().viewport,
		estimateSize: () => 35,
	});

	return <Container css={{height: props.students.length ? 'auto' : '100%'}}>
		<OverlayScrollbarsComponent
			ref={osRef}
			defer
			style={{
				height: 'calc(100% + 0px)',
				paddingBottom: 20,
				paddingRight: 20,
			}}
			options={{
				scrollbars: {
					autoHide: 'leave',
				},
				overflow: {
					x: props.loaded ? 'scroll' : 'hidden',
					y: props.loaded ? 'scroll' : 'hidden',
				},
			}}
		>
		<Header sorting={sorting}
		        allSelected={allSelected}
		        onSelectAllClicked={props.onAllSelect}
		        skeleton={!props.loaded}
		        agreementLevel={props.agreementLevel}
		        onCellClicked={onCellClicked}
				showSpecialistColumns={props.showSpecialistColumns}
		/>
		{!props.loaded && SkeletonRowArray.map((_, index) => <SkeletonRow key={index}/>)}
		{!props.students.length && <NoStudents/>}
		<Box
			style={{
				height: `${rowVirtualizer.getTotalSize()}px`,
				width: '100%',
				position: 'relative',
			}}
		>
			{rowVirtualizer.getVirtualItems().map(virtualItem => {
				const s = students[virtualItem.index];
				return <Box
					key={virtualItem.key}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: `${virtualItem.size}px`,
						transform: `translateY(${virtualItem.start}px)`,
					}}
					dataCy='student-row'
				>
					<StudentRow virtualKey={virtualItem.key}
						          key={s.model.studentID}
						          studentInfo={s}
						          onSelect={props.onStudentSelect}
						          selected={props.selectedStudents.includes(s.model)}
						          agreementLevel={props.agreementLevel}
								  specialists={props.specialists}
								  showSpecialistColumns={props.showSpecialistColumns}
					/>
				</Box>;
			})}
		</Box>
	</OverlayScrollbarsComponent>
	</Container>;
}
