import {ComponentPropsWithoutRef} from 'react';
import {EmptyContent, EmptyContentBox, ButtonAdd} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	templateText: string;
	additionalText?: string;
	dataCy?: string;
	onAllTestClick: VoidFunction;
} & ComponentPropsWithoutRef<'div'>;

export function EmptyContentTemplate({templateText, additionalText, dataCy = 'test-panel-empty-content-template', onAllTestClick, ...props}: Props) {
	return (
		<EmptyContentBox>
			<EmptyContent {...props} dataCy={dataCy}>
				<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-text`}>
					{templateText}
				</Text>
				{additionalText && (
					<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-additional-text`}>
						{additionalText}
					</Text>
				)}
			</EmptyContent>
			<ButtonAdd onClick={onAllTestClick} color='secondary'>
				<Text size='medium' bold>Browse All Tests</Text>
			</ButtonAdd> 
		</EmptyContentBox>
	);
}
