import {CSS} from '@stitches/react';
import {Alert} from '@esgi/ui/alert';
import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		width: 520,
		overflow: 'hidden',
	},
};

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$3',
});

export const StyledButton = styled(Button, {
	color: '$negative',
});