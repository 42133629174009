import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {storage, SubjectTab} from '@esgi/main/libs/store';
import {TestType} from '@esgi/core/enums';
import {mapToEnum} from 'shared/utils';

export class SubjectService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public subjects$ = new BehaviorSubject<SubjectTab[]>([]);
	private subjectStorage = storage.subjects();

	constructor() {
		super();

		this.subjectStorage.get().subscribe((subjects) => {
			const filteredSubjects = subjects.filter(({hidden, tests}) => {
				if (hidden) {
					return false;
				}
				const hasYN = tests.some(({type}) => mapToEnum(type, TestType) === TestType.YN);
				return (tests.length ? hasYN : true) && tests.length > 0;
			});
			this.subjects$.next(filteredSubjects);

			if (!this.loaded$.value) {
				this.loaded$.next(true);
			}
		});
	}

	public override dispose() {
		this.subjectStorage.dispose();
	}

	public get(subjectId: SubjectTab['id']) {
		return this.subjects$.value.find(({id}) => id === subjectId);
	}

	public getByIndex(index: number) {
		return this.subjects$.value[index];
	}
}
