import {useService} from '@esgi/core/service';
import {Input} from '@esgillc/ui-kit/control';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import React, {useEffect, useState} from 'react';
import {environment} from '@esgi/core/environments';
import './styles.less';
import {DevLoginService} from './dev-login-service';

const loginWithClever = () => location.assign(`${environment.ssoApiURL}/integrations/clever/authesgi`);

export default function DevLoginPage() {
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [secureMode, setSecureMode] = useState(false);

	const service = useService(DevLoginService);

	useEffect(() => location.protocol.startsWith('https') && setSecureMode(true), []);

	const login = () => service.login(userName, password, secureMode);

	return <div className='container dev-login'>
		<ServiceLoader trackingService={service} fullscreen/>
		<div className='row'>
			<div className='col-sm-6 col-md-4 col-md-offset-4'>
				<h1 className='text-center login-title'>Sign in to continue to ESGI</h1>
				<div className='account-wall'>
					<img className='profile-img'
					     src='https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120'
					     alt=''/>
					<div className='form-signin'>
						<div className='form-group float'>
							<Input type='text'
							       data-cy='username'
							       onKeyPress={(e) => e.key === 'Enter' && !secureMode && login()}
							       value={userName}
							       onChange={(e) => setUserName(e.target.value)}/>
							<label>User name</label>
						</div>
						{secureMode && <div className='form-group float'>
							<Input type='password'
							       data-cy='password'
							       onKeyUp={(e) => e.key === 'Enter' && login()}
							       value={password}
							       onChange={(e) => setPassword(e.target.value)}/>
							<label>Password</label>
						</div>}
						<button className='btn btn-lg btn-primary btn-block'
						        data-cy='sign-in'
						        onClick={login}>
							Sign in
						</button>
						<a href='#' onClick={loginWithClever}>
							<img src='https://s3.amazonaws.com/esgiwebfiles/Images/Login-Clever-Button.png'/>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>;
}
