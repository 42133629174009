import {useMemo} from 'react';
import {OptionItem, Select} from '../select';
import {DatePicker} from '../datepicker';
import {GridBox} from '@esgi/ui/layout';
import {FiltersState} from '../../types';
import {TrackDate} from '../../../../types';
import {DateTools} from '@esgi/main/features/user-profile';

type Props = {
	filtersState: FiltersState,
	trackDates: TrackDate[],
	isTablet: boolean,
	onFilterValueChange: (field: 'period' | 'dateRange') => (value: string|Array<string | Date>) => void,
}

const getFormattedDate = (date: Date | string | null) => date ? DateTools.toUIString(new Date(date)) : '-';

export function Filters({trackDates, filtersState: {period, dateRange}, onFilterValueChange, isTablet}: Props) {
	const items: OptionItem[] = useMemo(() => trackDates.map(({dateFrom, dateTo}) =>
		({value: dateFrom, label: `${getFormattedDate(dateFrom)} - ${getFormattedDate(dateTo)}`}),
	), [trackDates]);

	return (
		<GridBox justify='start' flow={isTablet ? 'column' : 'row'} gap={4} css={{height: 'min-content'}}>
			<Select
				placeholder='Period'
				onValueChange={onFilterValueChange('period')}
				selectedValue={period}
				items={items}
			/>
			<DatePicker
				value={dateRange || []}
				onValueChange={onFilterValueChange('dateRange')}
			/>
		</GridBox>
	);
}