import {BaseService} from '@esgi/core/service';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {moveInArray} from '@esgillc/ui-kit/drag-n-drop';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {SubjectEntity} from 'api/entities';
import {
	SubjectCreatedEvent,
	SubjectReorderedEvent,
	SubjectUpdatedEvent, TestAddedToSubjectEvent,
	TestRemovedFromSubjectEvent,
} from 'api/entities/events/subject';
import {BehaviorSubject, map} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SubjectType, TestType} from '@esgi/core/enums';
import {TestChanged} from 'shared/modules/test-details/events';
import {mapToEnum} from 'shared/utils';
import {SubjectRemovedEvent, TestMovedToSubjectByMSATEvent, TestsReorderedByMSATEvent} from './events';
import {SubjectModel, TestModel} from './models';
import {userStorage, UserType} from '@esgi/core/authentication';
import {ManageSubjectsAndTestsController} from '@esgi/contracts/esgi';

type Subject = {
	id: number;
	subjectType: SubjectType;
}

export class ManageSubjectsAndTestsService extends BaseService {
	public readonly subjects: BehaviorSubject<SubjectModel[]> = new BehaviorSubject<SubjectModel[]>([]);
	private readonly eventBus = new EventBusManager();
	private readonly currentUser = userStorage.get();

	private readonly controller = new ManageSubjectsAndTestsController();

	constructor() {
		super();
		this.eventBus.subscribe(SubjectUpdatedEvent, (args) => {
			const subject = this.findSubject({
				id: args.id,
				subjectType: mapToEnum(args.properties.subjectType, SubjectType),
			});

			subject.name = args.properties.name;
			subject.published = args.published;
			subject.subjectType = args.properties.subjectType;
			this.subjects.next([...this.subjects.value]);
		});
		this.eventBus.subscribe(SubjectCreatedEvent, (args) => {
			const subject = {
				subjectType: mapToEnum(args.properties.subjectType, SubjectType),
				hidden: !args.published,
				id: args.id,
				name: args.properties.name,
				tests: [],
				justAdded: true,
				published: args.published,
			} as SubjectModel;

			this.subjects.next([...this.subjects.value, subject]);
		});
		this.eventBus.subscribe(TestRemovedFromSubjectEvent, (args) => {
			const subject = this.findSubject({
				id: args.subjectID,
				subjectType: mapToEnum(args.subjectType, SubjectType),
			});
			this.updateTests(subject, subject.tests.filter(t => t.id !== args.testID));
		});
		this.eventBus.subscribe(TestAddedToSubjectEvent, (args) => {
			if (!args.testData) {
				return;
			}

			const subject = this.findSubject({
				id: args.subjectID,
				subjectType: mapToEnum(args.subjectType, SubjectType),
			});
			const test = {
				id: args.testID,
				name: args.testData?.name,
				type: args.testData?.type,
				creatorID: args.testData?.creatorID,
			} as TestModel;
			this.updateTests(subject, [...subject.tests, test]);
		});
		this.eventBus.subscribe(TestChanged, (event) => {
			const {id, newName} = event;
			const subjects = this.subjects.value.filter(
				({tests}) => tests.some((t) => t.id === id),
			);
			for (const subject of subjects) {
				this.updateTests(subject, subject.tests.map((t) => {
					if (t.id === id) {
						t.name = newName;
					}
					return t;
				}));
			}
		});
	}

	public init() {
		return this.controller.init()
			.pipe(
				map((r) => r.subjects.map(s => (({
					...s,
					tests: s.tests.map(t => ({...t, type: mapToEnum(t.type, TestType)})),
					subjectType: mapToEnum(s.subjectType, SubjectType),
					justAdded: false,
				}) as SubjectModel))),
				tap((s) => this.subjects.next(s)),
			);
	}

	public reorder(from: number, to: number) {
		const subjects = this.subjects.value;
		if (subjects[to].subjectType === SubjectType.Stock) {
			return;
		}
		const reorderedArray = moveInArray(subjects, from, to);
		this.subjects.next(reorderedArray);
		const ids = reorderedArray.filter(s => s.subjectType !== SubjectType.Stock).map(i => i.id);
		this.controller.reorderSubjects(ids).subscribe(() => {
			dispatchAppEvent(SubjectReorderedEvent, new SubjectReorderedEvent(reorderedArray.map(s => s.subjectType)[0], ids));
		});
	}

	public removeSubject(subject: SubjectModel) {
		const subjects = this.subjects.value.filter(s => s.id !== subject.id);
		this.controller.remove({subjectID: subject.id, userID: 0}).subscribe(() => {
			this.subjects.next(subjects);
			dispatchAppEvent(SubjectRemovedEvent, new SubjectRemovedEvent(subject.id, subject.subjectType));
			setTimeout(() => showSnackbarNotification(`You've deleted ${subject.name}`), 100);
		});
	}

	public updateTests(subject: Subject, tests: TestModel[]) {
		this.subjects.next(this.subjects.value.map(s => {
			if (s.id === subject.id && s.subjectType === subject.subjectType) {
				s.tests = tests;
			}
			return s;
		}));
	}

	public reorderTests(subject: Subject, from: number, to: number) {
		const tests = this.findSubject(subject).tests;
		const reorderedArray = moveInArray(tests, from, to);
		this.updateTests(subject, reorderedArray);

		const ids = reorderedArray.map(t => t.id);
		this.controller.reorderTests({subjectID: subject.id, testIds: ids})
			.subscribe(() => dispatchAppEvent(TestsReorderedByMSATEvent, new TestsReorderedByMSATEvent(subject.id, subject.subjectType, ids)));
	}

	public toggleSubjectVisibility(subject: Subject, hidden: boolean) {
		const targetSubject = this.findSubject(subject);
		const isAdmin = [
			UserType.C,
			UserType.D,
		].includes(this.currentUser.userType);
		const isSubjectDeployed = targetSubject.subjectType === SubjectType.Deployed;
		const adminCanHideStock = isAdmin && targetSubject.subjectType === SubjectType.Stock;
		if (hidden && ((targetSubject.published && !adminCanHideStock) || (!isAdmin && isSubjectDeployed))) {
			showSnackbarNotification(`This subject tab is currently published and cannot be hidden. Please un-publish the subject tab first.`);
			return;
		}

		targetSubject.hidden = hidden;
		this.subjects.next([...this.subjects.value]);

		if (hidden) {
			SubjectEntity.hide(subject.id, targetSubject.subjectType);
			showSnackbarNotification(`You've hidden ${targetSubject.name}`);
		} else {
			SubjectEntity.unhide(subject.id, targetSubject.subjectType);
			showSnackbarNotification(`You've unhidden ${targetSubject.name}`);
		}
	}

	public removeTest(subject: Subject, testID: number) {
		const targetSubject = this.findSubject(subject);
		const targetTest = targetSubject.tests.find(t => t.id === testID);
		targetSubject.tests = targetSubject.tests.filter((t) => t.id !== testID);

		this.controller.removeTest({
			subjectID: subject.id,
			testID: targetTest.id,
			subjectType: targetSubject.subjectType,
		}).subscribe(() => {
			this.updateTests(targetSubject, targetSubject.tests);
			showSnackbarNotification(`You've deleted ${targetTest.name} from ${targetSubject.name}`);
			dispatchAppEvent(TestRemovedFromSubjectEvent, new TestRemovedFromSubjectEvent(targetSubject.id, targetSubject.subjectType, targetTest.id));
		});
	}

	public moveTestToEnd(sourceSubject: Subject, destinationSubjectInfo: Subject, testID: number) {
		const destinationTests = this.findSubject(destinationSubjectInfo).tests;
		this.moveTest(sourceSubject, destinationSubjectInfo, testID, destinationTests.length);
	}

	public moveTest(sourceSubject: Subject, destinationSubjectInfo: Subject, testID: number, index: number) {
		const sourceTests = this.findSubject(sourceSubject).tests;
		const destinationSubject = this.findSubject(destinationSubjectInfo);
		const destinationTests = destinationSubject.tests;
		const testModel = sourceTests.find(t => t.id === testID);

		const destinationTestsWithTest = [...destinationTests.slice(0, index), testModel, ...destinationTests.slice(index)];

		this.updateTests(sourceSubject, sourceTests.filter(t => t.id !== testID));
		this.updateTests(destinationSubjectInfo, destinationTestsWithTest);

		SubjectEntity.moveTestToSubject(testID, sourceSubject.id, destinationSubject.id, destinationSubject.subjectType, index).subscribe(r => {
			showSnackbarNotification(`You've moved ${testModel.name} to ${destinationSubject.name}`);
		});
		dispatchAppEvent(TestMovedToSubjectByMSATEvent, new TestMovedToSubjectByMSATEvent(testID, destinationSubjectInfo.id, testModel.name, sourceSubject.id, index, testModel.creatorID, testModel.type));
	}

	public override destroy() {
		super.destroy();
		this.eventBus.destroy();
		this.controller.dispose();
	}

	private findSubject = (subject: Subject) => this.subjects.value.find(s => s.id === subject.id && s.subjectType === subject.subjectType);
}
