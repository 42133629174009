import {BaseService} from '@esgi/core/service';
import {V2PersonalSubjectsController} from '@esgi/contracts/esgi';
import {userStorage} from '@esgi/core/authentication';
import {SubjectType as SubjectTypeContract} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {SubjectLevel, SubjectTab, SubjectType, storage} from '@esgi/main/libs/store';
import {concatMap, map} from 'rxjs';

export class Service extends BaseService {
	private readonly controller = new V2PersonalSubjectsController();

	private subjectStorage = storage.subjects();

	public createPersonalTab(subjectName: string) {
		const currentUser = userStorage.get();

		return this.controller
			.create({
				name: subjectName,
				globalSchoolYearID: currentUser.globalSchoolYearID,
				subjectType: SubjectTypeContract.Personal,
			})
			.pipe(
				concatMap(({id, subjectName, subjectType}) => {
					const createdSubject: SubjectTab = {
						id,
						name: subjectName,
						type: subjectType as unknown as SubjectType,
						hidden: false,
						level: SubjectLevel.Teacher,
						gradeLevels: [],
						tests: [],
					};

					return this.subjectStorage.add(createdSubject).pipe(map(() => createdSubject));
				}),
			);
	}

	public override dispose() {
		this.controller.dispose();
	}
}
