import {styled} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';

export const SelectField = styled(Select.Field, {
	width: '300px',
	overflow: 'hidden',
});

export const SelectOption = styled(Select.Option, {
	'&:hover': {
		background: '$secondary96',
	},
});