import {Text} from '@esgi/ui/typography';
import {NotesContainer} from './index.styled';

type Props = {
	sessionNote: string;
	contentPaddingLeft?: number;
};

export function Notes({sessionNote, contentPaddingLeft}: Props) {
	return (
		<NotesContainer css={{paddingLeft: contentPaddingLeft}}>
			<Text size='xSmall' font='mono' color='lowContrast'>
				Notes
			</Text>
			<Text size='xSmall' font='mono' color='neutral40'>
				{sessionNote}
			</Text>
		</NotesContainer>
	);
}
