import {BaseService} from '@esgi/core/service';
import {createParentLetterTemplateForm} from './form';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {V2PagesReportsParentLetterTemplateController} from '@esgi/contracts/esgi';
import {useUser} from '@esgi/core/authentication';
import {LanguageModel, Request, LetterTemplate, LetterTemplateResponse} from './types';
import {storage} from '@esgi/main/libs/store';

export class ParentLetterPreviewModalService extends BaseService {
	public form = createParentLetterTemplateForm();
	public languageTemplate = new BehaviorSubject<LetterTemplate>(null);
	public defaultLanguage = new BehaviorSubject<LanguageModel>(null);
	public studentLanguageID = new BehaviorSubject<number>(null);

	private user = useUser();
	private controller = new V2PagesReportsParentLetterTemplateController();

	public init = (selectedStudentID: number) => {

		this.completeOnDestroy(storage.students().get()).subscribe(students => {
			this.studentLanguageID.next(students.find(s => s.id === selectedStudentID).languageID);
		});

		return this.controller.init({userID: this.user?.userID}).pipe(tap((response) => {
			const language = response.languages.find(l => l.languageID === this.studentLanguageID.value);
			this.defaultLanguage.next(language);

			this.fetchLanguage(language.languageID);
		}));
	};

	public fetchLanguage = (languageID: number) => {

		const model = {
			userID: this.user?.userID,
			districtID: this.user?.districtID,
			languageID,
		};

		return this.controller.fetchLanguage(model).subscribe((response) => {
			this.languageTemplate.next(response);

			this.form.value = {
				parentLetterText: response.template.templateBody,
				letterTemplateID: response.template.letterTemplateID,
			};
		});
	};

	public resetEditor = () => {
		if(this.languageTemplate.value.defaultTemplate.templateBody !== this.form.controls.parentLetterText.value) {

			this.form.value = {
				parentLetterText: this.languageTemplate.value.defaultTemplate.templateBody,
				letterTemplateID: this.languageTemplate.value.defaultTemplate.letterTemplateID,
			};
		}
	};

	public save = (): Observable<LetterTemplateResponse> => {
		const isReset = this.form.controls.parentLetterText.value === this.languageTemplate.value.defaultTemplate.templateBody
			&& this.form.controls.parentLetterText.value !== this.languageTemplate.value.template.templateBody;

		if(isReset){
			return this.revert();
		}

		if(this.languageTemplate.value.template.letterTemplateID) {
			return this.update();
		}

		return this.create();
	};

	public override dispose() {
		this.controller.dispose();
	}

	private update = () => {
		return this.controller.update({
			letterTemplateID: this.form.controls.letterTemplateID.value,
			body: this.form.controls.parentLetterText.value,
		});
	};

	private create = () => {
		return this.controller.create({
			userID: this.user?.userID,
			body: this.form.controls.parentLetterText.value,
			languageID: this.defaultLanguage.value.languageID,
		} as Request);
	};

	private revert = () => {
		return this.controller.revert({
			letterTemplateID: this.languageTemplate.value.template.letterTemplateID,
		});
	};
}
