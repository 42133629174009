import {ReportHeaderField} from './components/report-header-field';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportEntityDropdown} from './components/report-entity-dropdown';
import {RubricResultReportService} from '../../../../../../../../service';
import {Class, Group, SubjectTab} from '@esgi/main/libs/store';
import {Test} from '@esgi/main/kits/reports';
import {useUser} from '@esgi/core/authentication';
import {ReportFilterRow} from './index.styled';
import {useMemo} from 'react';

interface Props {
	service: RubricResultReportService;
}

export function ReportHeader({service}: Props) {
	const selectedSubject = useBehaviorSubject<SubjectTab>(service.subject$);
	const subjectTests = useBehaviorSubject<Test[]>(service.subjectTests$);
	const selectedTest = useBehaviorSubject<number>(service.selectedTest$);
	const selectedGroup = useBehaviorSubject<Group | null>(service.selectedGroupId$);
	const selectedClass = useBehaviorSubject<Class | null>(service.selectedClassId$);
	const subjects = useBehaviorSubject<SubjectTab[]>(service.subjectService.subjects$);
	const classes = useBehaviorSubject<Class[]>(service.studentService.classes$);
	const groups = useBehaviorSubject<Group[]>(service.studentService.groups$);
	const user = useUser();

	const isShowTeacherGroupsDropdown = selectedGroup && groups.length > 0;

	const isShowTeacherClassesDropdown = selectedClass && classes.length > 0;

	const isShowRubricsDropdown = selectedTest && subjectTests?.length > 0;

	const isShowSubjectEntityDropdown = selectedSubject?.id && subjects.length > 0;

	const memoGroups = useMemo(() => {
		return groups?.map(g => ({id: g.id, name: g.name}));
	}, [groups]);

	const memoClasses = useMemo(() => {
		return classes?.map(c => ({id: c.id, name: c.name}));
	}, [classes]);

	const memoTests = useMemo(() => {
		return subjectTests?.map(x => ({id: x.id, name: x.name}));
	}, [subjectTests]);

	const memoSubjects = useMemo(() => {
		return subjects?.map(x => ({id: x.id, name: x.name}));
	}, [subjects]);

	return (
		<ReportFilterRow>
			<ReportHeaderField title={'Teacher'} value={`${user?.firstName} ${user?.lastName}`} />

			{isShowTeacherGroupsDropdown && (
				<ReportEntityDropdown
					groupID={selectedGroup?.id}
					title='Group'
					onSelected={(value) => service.setSelectedGroupFilter(value)}
					entityGroup={memoGroups}
					showAlternativeHeader
					validateOn='length'
				/>
			)}

			{isShowTeacherClassesDropdown && (
				<ReportEntityDropdown
					groupID={selectedClass?.id}
					title='Class'
					onSelected={(value) => service.setSelectedClassFilter(value)}
					entityGroup={memoClasses}
					showAlternativeHeader
					validateOn='length'
				/>
			)}

			{isShowRubricsDropdown && (
				<ReportEntityDropdown
					groupID={selectedTest}
					title='Rubric'
					onSelected={(value) => service.setTest(value)}
					entityGroup={memoTests}
					validateOn='existGroup'
				/>
			)}

			{isShowSubjectEntityDropdown && (
				<ReportEntityDropdown
					groupID={selectedSubject.id}
					title='Subject'
					onSelected={(value) => service.setSubject(value)}
					entityGroup={memoSubjects}
					validateOn='existGroup'
				/>
			)}
		</ReportFilterRow>
	);
}
