import React, {useEffect} from 'react';
import {environment} from '@esgi/core/environments';
import {userStorage} from '@esgi/core/authentication';

import styles from './question-tile.module.less';

export interface QuestionTileProps {
	testID: number;
	pregenerated: boolean;
	questionID: number;
	name: string;
}


export default function QuestionTile ({testID, pregenerated, questionID, name}: QuestionTileProps) {
	const currentUser = userStorage.get();
	let img;
	let url;

	const onError = () => {
		if (img.src.includes('s3.amazonaws.com')) {
			return buildQuestionUrlFromServer();
		} else {
			return '/ESGI/Images/150x100.gif';
		}
	};

	const buildQuestionUrl = () => {
		const modifyTicks = (new Date()).getTime();
		if (pregenerated) {
			let correctHost = document.location.host.replace('app', '');
			if (correctHost.startsWith('.')) {
				correctHost = `www${correctHost}`;
			}
			return `https://s3.amazonaws.com/${currentUser.awsBucket}/QuestionCards/${correctHost}/${testID}/${questionID}/medium.png?ticks=${modifyTicks}`;
		}
		return buildQuestionUrlFromServer();
	};

	const buildQuestionUrlFromServer = () => {
		const modifyTicks = (new Date()).getTime();
		return `${environment.esgiApiURL}/assets/questions/image?questionID=${questionID}&width=221&height=100&ticks=${modifyTicks}&regenerate=true`;
	};

	useEffect(() => {
		if (!url) {
			url = buildQuestionUrl();
		}
	}, []);

	return (
		<div data-cy='question-tile' className={styles.questionTileBox}>
			<img
				ref={ref => img = ref}
				onError={(e) => img.src = onError() }
				src={buildQuestionUrl()}
			/>
			<div className={styles.questionShortName}>
				{name}
			</div>
		</div>
	);
}
