import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {ActionButtons} from '../../../kit';
import {GroupInfo} from './components/group-info';
import {GridBox} from '@esgi/ui/layout';
import {HeaderBox} from '../../../index.styled';
import {SubjectTab, storage, useStore} from '@esgi/main/libs/store';
import {Divider} from './index.styled';
import {SubjectName} from '@esgi/main/kits/common';
import {isNull} from 'underscore';
import {routes} from '@esgi/main/libs/core';

interface Props {
	sectionName: string;
	onDownload: VoidFunction;
	subjectID: SubjectTab['id'];
}

export function Header({sectionName, onDownload, subjectID}: Props) {
	const navigate = useNavigate();

	const [subjectsList, updateSubjectsList, isSubjectsListLoaded] = useStore(storage.subjects);

	const subject = useMemo(() => subjectsList.find(({id}) => id === subjectID) ?? null, [subjectID, subjectsList]);

	return (
		<HeaderBox>
			<GridBox flow='column' gap='4' align='center'>
				<GroupInfo sectionName={sectionName} />
				{!isNull(subject) && (
					<>
						<Divider />
						<SubjectName subjectLevel={subject.level} size='large' noWrap>
							{subject.name}
						</SubjectName>
					</>
				)}
			</GridBox>
			<GridBox flow='column' gap='4' align='center'>
				<ActionButtons
					onDownloadClicked={onDownload}
					onLetterFileClicked={() => navigate(routes.teacher.reports.parentLetter)}
					onFleshCardsClicked={() => navigate(routes.teacher.activities.flashcards)}
					onBingoGameClicked={() => navigate(routes.teacher.activities.bingo)}
				/>
			</GridBox>
		</HeaderBox>
	);
}
