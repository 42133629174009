import {HttpClient} from '@esgi/api';
import {userStorage} from '@esgi/core/authentication';
import AppHistory from 'shared/tools/history/history';
import {environment, Environments} from '@esgi/core/environments';
import {WindowTool} from '@esgi/deprecated/utils';
import {RoutesMap} from '@esgi/main/libs/core';

function redirectToLogin() {
	if(environment.environmentType === Environments.Local) {
		if(!AppHistory.currentPath.endsWith('dev-login')) {
			AppHistory.navigate(RoutesMap.devLogin + '?r='+location.pathname);
		}
	} else {
		AppHistory.navigateOutside('/login');
	}
}

export function closeWindowAndRedirectTo(path: string = '/login') {
	const n = WindowTool.getESGIWindowName();
	if (window.name === 'ESGIAppWindowNormal' || window.name === 'ESGIAppWindow' || window.name == n) {
		window.close();
	} else {
		if (environment.environmentType === Environments.Local || (location.protocol.startsWith('https') && location.hostname.startsWith('localhost'))) {
			if (!AppHistory.currentPath.endsWith('dev-login')) {
				AppHistory.navigate(RoutesMap.devLogin + '?r=' + location.pathname);
			}
		} else {
			AppHistory.navigateOutside(path);
		}
	}
}

export function forceUserLogout() {
	HttpClient.default.ESGIApi.post('Login', 'ForceLogout', {}).subscribe(null, null, () => {
		logoutFromFrontend();
	});
}

export function logoutFromFrontend() {
	const windowProvider = window as any;

	if (windowProvider.Assessments){
		const sSession = new windowProvider.Assessments.ShareScreenSession();

		sSession.events.sessionEnded(() => {
			userStorage.clear();
			redirectToLogin();
		});

		sSession.events.reconnectAttempt(() => {
			userStorage.clear();
			redirectToLogin();
		});

		const user = userStorage.get();
		sSession.endSession(user.userID.toString());
	}else{
		userStorage.clear();
		redirectToLogin();
	}
}

export function getMetaValue(metaName: string): string {
	const metas = document.getElementsByTagName('meta');

	for (let i = 0; i < metas.length; i++) {
		if (metas[i].getAttribute('name') === metaName) {
			return metas[i].getAttribute('content');
		}
	}

	return '';
}

export function safeExecute(func: () => void, onError?: (e) => void): void {
	try {
		func && func();
	} catch (e) {
		console.error(e);
		onError && onError(e);
	}
}
