import {Box, Card, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {cardHeaderGap} from '../card-header/index.styled';
import {testColorIndicatorWidth} from '@esgi/main/kits/common';

export const CardBodyBox = styled(Card.Body, {
	display: 'grid',
	gridTemplateColumns: 'auto auto',
	justifyContent: 'space-between',
	variants: {
		withPadding: {
			true: {
				paddingLeft: `calc(${cardHeaderGap} + ${testColorIndicatorWidth}px)`,
			},
		},
	},
});

export const ProgressBarsWrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 20,
});

export const ProgressBarsDivider = styled(Box, {
	width: 1,
	height: 48,
	backgroundColor: '$border',
});
