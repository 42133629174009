import {useCallback, useEffect, useState} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {Checkbox} from '@esgillc/ui-kit/control';
import {ElementStatus, FormControl, FormElement} from '@esgillc/ui-kit/form';
import {SelectableList, SelectableListItem} from '@esgillc/ui-kit/form-control';
import styles from './styles.module.less';

interface Props<ListItem> {
	listName: string;
	list: ListItem[];
	control: FormControl<number[]>;
	className?: string;
	'data-cy'?: string
}

export function ManagedCheckboxesList<ListItem extends { id: number, name: string, isDisabled?: boolean }>({
	listName,
	list,
	control,
	className,
	'data-cy': dataCy = 'managed-checkboxes-list',
}: Props<ListItem>) {
	const [checkboxCheckedStatus, setCheckboxCheckedStatus] = useState(false);

	const toggleCheckbox = useCallback(({currState}) => {
		let checkedStatus;
		if (list?.every(item => {
			return currState.value.includes(Number(item.id));
		})) {
			checkedStatus = true;
		} else if (list?.some(item => currState.value.includes(Number(item.id)))) {
			checkedStatus = undefined;
		} else {
			checkedStatus = false;
		}
		setCheckboxCheckedStatus(checkedStatus);
	}, [list, setCheckboxCheckedStatus]);
	useEffect(() => {
		let subscription;
		if(list){
			toggleCheckbox({currState: control});
			subscription = control.onChanged.subscribe(toggleCheckbox);
		}

		return () => subscription?.unsubscribe();
	}, [control, list, toggleCheckbox]);

	if (!list?.length) {
		return null;
	}

	const isCheckboxDisabled = list?.every(item => item.isDisabled);

	const onGeneralCheckboxClick = () => {
		if (!checkboxCheckedStatus) {
			control.value = list.map(item => Number(item.id));
		} else {
			control.value = [];
		}
	};

	return <div data-cy={dataCy} className={join(styles.checkboxList, className)}>
		<div data-cy='main-checkbox'>
		<Checkbox disabled={isCheckboxDisabled || control.status === ElementStatus.disabled} checked={checkboxCheckedStatus}
							onChange={onGeneralCheckboxClick}>{listName}</Checkbox>
		</div>
		<FormElement control={control}>
			<SelectableList checkboxPlacement='left'>
				{list?.map(v => <SelectableListItem className={styles.listItem} key={v.id}
																						disabled={v.isDisabled || control.status === ElementStatus.disabled}
																						value={Number(v.id)}>
					{v.name}
				</SelectableListItem>)}
			</SelectableList>
		</FormElement>
	</div>;
}
