import {User} from '@esgi/contracts/esgi/types/esgi.apigateway/endpoints/teachers/modules/user-account/init/user';
import {Photo as UserPhoto} from '@esgi/contracts/esgi/types/sso.accounts/endpoints/esgi.apigateway/teachers/modules/user-account/init/photo';
import {DatesPeriodModel as TrackDateModel} from '@esgi/contracts/esgi/types/esgi.schools/queries/esgi.apigateway/profiles/tracks/dates-period-model';
import {TrackModel as TrackModelContract} from '@esgi/contracts/esgi/types/esgi.schools/queries/esgi.apigateway/profiles/tracks/track-model';
import {PersonalSaveRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/teachers/modules/user-account/personal-save-request';
import {NewPhoto as NewPhotoContract} from '@esgi/contracts/esgi/types/sso.accounts/endpoints/esgi.apigateway/teachers/modules/user-account/tabs/personal/save/new-photo';
import {Observable} from 'rxjs';
import {OutModel as CreateTrackOutModel} from '@esgi/contracts/esgi/types/esgi.schools/commands/esgi.apigateway/tracks/create/out-model';

export {type SecondaryProfile} from '@esgi/contracts/esgi/types/esgi.apigateway/endpoints/teachers/modules/user-account/init/secondary-profile';
export {type StateModel as UserState} from '@esgi/contracts/esgi/types/esgi.countries/endpoints/esgi.apigateway/profiles/state-model';
export {type CountryModel as UserCountry} from '@esgi/contracts/esgi/types/esgi.countries/endpoints/esgi.apigateway/profiles/country-model';
export {type UserPhoto};
export {type TrackDateModel};
export {type SchoolYearTypeModel as SchoolYearModel} from '@esgi/contracts/esgi/types/esgi.schools/queries/esgi.apigateway/school-year-types/school-year-type-model';
export {type BaseResponse} from '@esgi/contracts/esgi/types/esgi.app/endpoints/abstractions/base-response';
export {type NewPhotoContract};
export {type Response as TabsPersonalSaveResponseContract} from '@esgi/contracts/esgi/types/sso.accounts/endpoints/esgi.apigateway/teachers/modules/user-account/tabs/personal/save/response';
export {type ChangePasswordRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/teachers/modules/user-account/change-password-request';
export {type DatesPeriodModel as BaseTrackDateModel} from '@esgi/contracts/esgi/types/esgi.schools/models/dates-period-model';

export enum NameTitle {
	MR = 'Mr.',
	MS = 'Ms.',
	MRS = 'Mrs.',
	MISS = 'Miss',
	DR = 'Dr.',
}

export enum TrackType {
	Semester = 'Semester',
	Trimester = 'Trimester',
	Quarter = 'Quarter',
	Periods5 = '(5) periods',
	Periods6 = '(6) periods',
	Periods7 = '(7) periods',
	Periods8 = '(8) periods',
	Periods9 = '(9) periods',
}

export type UserModel = Omit<User, 'title' | 'photo'> & {
	title: NameTitle;
	photo: UserPhoto | null;
};

export interface TrackModel extends TrackModelContract {
	schoolYearType: TrackType;
}

export type DateModelForState = {
	value: Date | null;
	error: string | undefined;
	disabled: boolean;
};

export type TrackDateModelState = {
	rowId: string;
	dateFrom: DateModelForState;
	dateTo: DateModelForState;
};

export type NewPhoto =
	| {
			remove: true;
	  }
	| ({
			remove: false;
	  } & Omit<NewPhotoContract, 'remove'>);

export type SavePersonalDataBody = Omit<PersonalSaveRequest, 'newPhoto'> & {
	countryID: number;
	title: NameTitle;
	newPhoto: NewPhoto | null;
};

export type SchoolModel = {
	federalDistrictID: number;
	federalDistrictName: string;
	federalSchoolID: number;
	federalSchoolName: string;
};

export type SchoolsByQueryParamsResponse = {
	items: SchoolModel[];
};

export type CreateTrackRequest = (args: {
	trackName: string;
	trackType: TrackType;
	trackDates: TrackDateModelState[];
}) => Observable<CreateTrackOutModel>;

export type UpdateTrackRequestParams = {
	updatedTrack: TrackModel;
	trackName: string;
	trackType: TrackType;
	trackDates: TrackDateModelState[];
};

export enum CurrentPasswordStatus {
	Valid = 'valid',
	NotValid = 'notValid',
}
