import {styled} from '@stitches/react';
import {Indicator as CheckboxIndicator, Root as CheckboxRoot} from '@radix-ui/react-checkbox';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Root = styled(CheckboxRoot, {
	background: 'none',
	display: 'flex',
	borderRadius: '6px',
	padding: '4px 8px 4px 4px',
	alignItems: 'center',
	justifyContent: 'center',
	width: 'max-content',
	border: 'none',

	'&:hover': {
		transition: '.2s ease',
		backgroundColor: '$secondaryBackground',
		cursor: 'pointer',

		'& > span': {
			color: '$secondary',
		},

		'& > div': {
			borderColor: '$secondaryMuted',
		},

		'& svg': {
			fill: '$secondary',
			'& path': {
				fill: '$secondary',
			},
		},
	},

	variants: {
		colors: {
			'checked': {
				'& > span': {
					color: '$primary',
				},

				'& > div': {
					borderColor: '$primaryMuted',
				},

				'& svg': {
					fill: '$primary',
					'& path': {
						fill: '$primary',
					},
				},
			},

			'disabled': {
				'& > span': {
					color: '$muted',
				},

				'& > div': {
					borderColor: '$lowContrast',
				},

				'& svg': {
					fill: '$lowContrast',
					'& path': {
						fill: '$lowContrast',
					},
				},

				'&:hover': {
					cursor: 'default',
					backgroundColor: 'inherit',

					'& > span': {
						color: '$muted',
					},

					'& > div': {
						borderColor: '$mild',
					},

					'& svg': {
						fill: '$mild',
						'& path': {
							fill: '$mild',
						},
					},
				},
			},
		},
	},
});

export const IndicatorContainer = styled(FlexBox, {
	background: 'none',
	width: '20px',
	height: '20px',
	minWidth: '20px',
	borderRadius: '4px',
	alignItems: 'center',
	justifyContent: 'center',
	border: '1px solid $muted',
});

export const Indicator = styled(CheckboxIndicator, {
	background: 'none',
	display: 'flex',
});

export const IndeterminateIcon = styled(Text, {
	color: '$primary',
});

export const StyledLabel = styled(Text, {
	paddingLeft: '8px',
	color: '$highContrast',
});
