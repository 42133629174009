import {MapFunction, Store, StoreInstance, UpdateMethod} from './types';
import {Observable, Subject, takeUntil} from 'rxjs';
import {isFunction} from 'underscore';

const DisposableSymbol = Symbol('DisposableStore');


export function isDisposableStore(target: any): target is DisposableStore<any> {
	if (target && '_symbol' in target) {
		return target._symbol === DisposableSymbol;
	}
	return false;
}

export class DisposableStore<T> implements Store<T> {
	public key: string;
	private _symbol = DisposableSymbol;
	private dispose$ = new Subject<void>();

	constructor(private storeInstance: StoreInstance<T>) {
	}

	public invalidateCache(immediate?: boolean) {
		return this.storeInstance.invalidateCache(immediate);
	}

	public update: UpdateMethod<T> = (oldValue: T | MapFunction<T>, newValue?: T) => {
		if (isFunction(oldValue)) {
			return this.storeInstance.update(oldValue);
		} else {
			return this.storeInstance.update(oldValue, newValue);
		}
	};

	public get = () => this.storeInstance.get().pipe(takeUntil(this.dispose$));
	public add = (...value: T[]) => this.storeInstance.add(...value).pipe(takeUntil(this.dispose$));
	public dispose = () => {
		this.dispose$.next();
		this.dispose$.complete();
	};

}