import {Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button} from './index.styled';

type Props = {
	label: string;
	borderType?: 'default' | 'secondary';
	onClick: VoidFunction;
	disabled?: boolean;
};

export function AddButton({label, onClick, disabled, borderType = 'default'}: Props) {
	const handleClick = () => {
		if(!disabled) {
			onClick();
		}
	};
	return (
		<Button borderType={borderType} onClick={handleClick} disabled={Boolean(disabled)}>
			<Plus />
			<Text size='medium' bold>
				{label}
			</Text>
		</Button>
	);
}
