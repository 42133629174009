import {BaseService} from '@esgi/core/service';
import {
	ClassModel,
	GradeLevelModel,
	GroupModel,
	InitResponse,
	SchoolModel,
	SpecialistModel,
	StudentModel,
	TeacherModel, UnassignType,
	StudentExportModel,
} from './types';
import {BehaviorSubject, from, Observable, tap} from 'rxjs';
import {getUser} from '@esgi/core/authentication';
import {V2PagesStudentManagerController} from '@esgi/contracts/esgi';
import {sortBy, uniq} from 'underscore';
import {EventBusManager} from '@esgillc/events';
import {EditStudent, AddStudent, RemoveStudent, RemoveStudentEvent} from '@esgi/main/features/teacher/drawers';
import {HttpClient} from '@esgi/api';
import {DateTools} from 'global/utils/date-utils';
import {storage} from '@esgi/main/libs/store';

export class StudentManagerService extends BaseService {
	public gradeLevels: BehaviorSubject<GradeLevelModel[]> = new BehaviorSubject<GradeLevelModel[]>([]);
	public classes: BehaviorSubject<ClassModel[]> = new BehaviorSubject<ClassModel[]>([]);
	public groups: BehaviorSubject<GroupModel[]> = new BehaviorSubject<GroupModel[]>([]);
	public teachers: BehaviorSubject<TeacherModel[]> = new BehaviorSubject<TeacherModel[]>([]);
	public schools: BehaviorSubject<SchoolModel[]> = new BehaviorSubject<SchoolModel[]>([]);
	public specialists: BehaviorSubject<SpecialistModel[]> = new BehaviorSubject<SpecialistModel[]>([]);
	public data: BehaviorSubject<InitResponse> = new BehaviorSubject<InitResponse>(null);

	public students: BehaviorSubject<StudentModel[]> = new BehaviorSubject<StudentModel[]>([]);

	private controller = new V2PagesStudentManagerController();
	private readonly eventBus = new EventBusManager();

	private sortedStudentsIds: number[] = [];

	constructor() {
		super();
		this.eventBus.subscribe(EditStudent, (event) => this.updateStudentFromOutside(event));
		this.eventBus.subscribe(RemoveStudentEvent, () => this.init());
		this.eventBus.subscribe(AddStudent, () => this.init());
		this.eventBus.subscribe(RemoveStudent, (event) =>this.deleteStudentFromOutside(event));
	}

	public init() {
		this.controller.init().pipe(tap((r) => this.updateData(r))).subscribe();
	}

	public export(filteredStudents: StudentModel[]) {
		const dateStr = DateTools.toUIString(new Date());
		const studentsForExport = this.getExportData(filteredStudents);
		const fileName = `Student_Manager_${dateStr}.csv`;

		HttpClient.default.ESGIApi.file('v2/pages/student-manager', 'export', fileName, {studentsData: JSON.stringify(studentsForExport)}).subscribe();
	}

	public unassign(studentIDs: number[]) {
		return this.controller.unassign({studentIDs, type: UnassignType.Both}).pipe(tap(() => {
			const students = this.students.value;

			this.students.next([...students.map((student) => (
				!studentIDs.includes(student.studentID) ? student : {...student, classIDs: [], groupIDs: [], primaryTeacherID: null}
			))]);
		}));
	}

	public assign(studentIDs: number[], classIDs: number[], groupIDs: number[]): Observable<void> {
		const requestsData: {classID: number, groupID: number}[] = [];
		const classes = this.classes.value.filter(c => classIDs.includes(c.classID));
		for (const classModel of classes) {
			const groups = classModel.groups.filter(g => groupIDs.includes(g.groupID));

			if (groups.length) {
				for (const group of groups) {
					requestsData.push({classID: classModel.classID, groupID: group.groupID});
				}
			} else {
				requestsData.push({classID: classModel.classID, groupID: null});
			}
		}

		return from(this.assignMultiple(studentIDs, requestsData)).pipe(tap(() => {
			this.students.next(this.students.value.map(student => {
				if(studentIDs.includes(student.studentID)) {
					student.primaryTeacherID = getUser()?.userID;
					student.classIDs = uniq([...student.classIDs, ...classIDs]);
					student.groupIDs = uniq([...student.groupIDs, ...groupIDs]);
				}
				return {...student};
			}));
		}));
	}

	public delete(studentIDs: number[]) {
		return this.controller.makeDelete({studentIDs}).pipe(tap(() => {
			this.students.next(this.students.value.filter(s => !studentIDs.includes(s.studentID)));
		}));
	}

	public setSortedStudentsIds(sortedStudentsIds: number[]) {
		this.sortedStudentsIds = sortedStudentsIds;
	}

	public updateStorage() {
		storage.classes().invalidateCache().subscribe();
		storage.students().invalidateCache().subscribe();
		storage.groups().invalidateCache().subscribe();
	}

	private async assignMultiple(studentIDs: number[], requestsData: {classID: number, groupID: number}[]) {
		for (const {classID, groupID} of requestsData) {
			await this.controller.move({studentIDs: studentIDs, classID, groupID}).toPromise();
		}
	}

	private updateData(initData: InitResponse) {
		const user = getUser();
		const userSchool = initData.schools.find(s => s.schoolID === user.schoolID);
		const teacher = userSchool.teachers.find(t => t.teacherID === user.userID);
		this.groups.next(teacher.classes.map(c => c.groups).flat());
		this.classes.next(teacher.classes);
		this.teachers.next(userSchool.teachers);
		this.gradeLevels.next(initData.gradeLevels);
		this.schools.next(initData.schools);
		this.students.next(initData.students);
		this.specialists.next(initData.specialists);
		this.data.next(initData);
	}

	private updateStudentFromOutside(event: EditStudent) {
		const students = this.students.value;
		const student = students.find(t => t.studentID === event.studentsData.student.id);
		if (!student) {
			return;
		}
		student.firstName = event.studentsData.student.firstName;
		student.lastName = event.studentsData.student.lastName;
		student.gradeLevelID = event.studentsData.student.gradeLevelID;
		student.classIDs = event.studentsData.classIds;
		student.groupIDs = event.studentsData.groupIds;
		student.studentIDN = event.studentsData.student.studentIDN;
		this.students.next([...students]);
	}

	private getExportData(filteredStudents: StudentModel[]): StudentExportModel[] {
		return sortBy(filteredStudents, ({studentID}) => this.sortedStudentsIds.indexOf(studentID)).map(x => {
			const school = this.schools.value.filter(s => s.schoolID === x.schoolID)[0];
			let teacherName = '';
			let classesName = '';
			let groupsName = '';
			const teachers = school.teachers.filter(t => t.teacherID === x.primaryTeacherID);
			if (teachers.length > 0) {
				const teacher = teachers[0];
				teacherName = teacher.firstName + ' ' + teacher.lastName;
				const classes = teacher.classes.filter(c => x.classIDs.indexOf(c.classID) !== -1);
				if (classes.length > 0) {
					classesName = classes.filter(className => !!className).map(c => c.name).join(', ');

					for (let i = 0; i < classes.length; i++) {
						const classModel = classes[i];
						const groups = classModel.groups.filter(g => x.groupIDs.indexOf(g.groupID) !== -1);
						if (groups.length > 0) {
							groupsName += (groupsName !== '' ? ', ' : '') + groups.map(x => x.name).join(', ');
						}
					}
				}
			}

			return {
				name: `${x.firstName} ${x.lastName}`,
				primaryTeacher: teacherName,
				classes: classesName,
				groups: groupsName,
				grade: this.gradeLevels.value.filter(g => g.gradeLevelID === x.gradeLevelID)[0].name,
				id: x.studentIDN,
				created: DateTools.toUIString(new Date(x.createDate)),
			};
		});
	}
	private deleteStudentFromOutside(removedStudent: RemoveStudent) {
		const {studentID} = removedStudent;
		this.students.next(this.students.value.filter(s => s.studentID !== studentID));
	}
}