import {styled} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';

export const TextStyled = styled(Text, {
	display: 'flex',
	marginRight: '10px',
});

export const SelectField = styled(Select.Field, {
	textAlign: 'left',
	'& > :first-child': {
		width: '150px',
		overflow: 'hidden',

		'span:last-child': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
});

export const SelectOption = styled(Select.Option, {
	'&:hover': {
		background: '$secondary96',
	},
});
