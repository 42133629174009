import {styled} from '@esgi/ui/theme';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';
import {GridBox, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const CheckBox = styled(CheckboxUI, {
	padding: 0,
	'& > div': {
		background: '$border',
		borderColor: '$vivid',
	},

	variants: {
		active: {
			true: {
				'& > div': {
					background: '$primary92',
				},
			},
		},
		disabled: {
			true: {
				'& > div': {
					background: '$neutral88',
				},
				'& svg': {
					'& path': {
						fill: '$neutral64',
					},
				},
			},
		},
	},
});

export const Container = styled(FlexBox, {
	paddingTop: 1,
	paddingBottom: 1,
	alignItems: 'center',
});

export const Wrapper = styled(GridBox, {
	height: 32,
	gridAutoFlow: 'column',
	gap: 12,
	justifyContent: 'start',
	alignItems: 'center',
});

export const TextChip = styled(Text, {
	background: '$background',
	borderRadius: '15px',
	padding: '6px 12px 6px 12px',
	fontSize: 'bold',
	marginLeft: '10px',
	color: 'inherit',
	border: '1px solid $mild',
});

export const TestTagWrapper = styled(FlexBox, {
	alignItems: 'center',
	color: '$highContrast',
});