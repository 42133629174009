import {ToggleGroup} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	gap: 2,
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	minWidth: 137,
	minHeight: 36,
});

export const TabDescriptionBox = styled(GridBox, {
	maxWidth: 280,
	height: 'max-content',
	variants: {
		isTablet: {
			true: {
				maxWidth: '100%',
			},
		},
	},
});

export const CenteredText = styled(Text, {
	textAlign: 'center',
});
