import {Text} from '@esgi/ui/typography';
import {Counter, Wrapper} from './index.styled';
import {isUndefined} from '@esgi/ui';

type Props = {
	itemsCount: number;
	label?: string;
};

export function ItemsCounter({itemsCount, label}: Props) {
	return (
		<Wrapper>
			<Counter>
				<Text size='small' font='mono' color='primaryVivid'>
					{itemsCount}
				</Text>
			</Counter>
			{!isUndefined(label) && (
				<Text size='small' font='mono' color='mediumContrast'>
					{label}
				</Text>
			)}
		</Wrapper>
	);
}