import {keys} from 'underscore';
import {map, Observable} from 'rxjs';
import {EventBusManager} from '@esgillc/events';
import {Crypto} from './crypto';
import {StoreInstanceImpl} from './store-instance-impl';
import {Store, StoreConfig, StoresConfig, TableInfoSchema} from './types';
import {CryptoTable} from './crypto-table';
import {StoreExpiredEvent} from './store-expired-event';
import {DisposableStore} from './disposable-store';
import {DexieAsyncWrapper} from './dexie-async-wrapper';
import {logger} from './logger';

type Stores<T> = {
	readonly [P in keyof T]: () => Store<T[P] extends StoreConfig<infer R> ? R : unknown>;
} & {
	authenticate: (key: string) => Observable<boolean>;
	drop: () => Observable<void>;
}

const StoreConstructorSymbol = Symbol('StoreConstructor');

export function isStoreConstructor(target: any): target is () => DisposableStore<any> {
	if (target && '_symbol' in target) {
		return target._symbol === StoreConstructorSymbol;
	}
	return false;
}

export function createStorage<T extends StoresConfig>(stores: T): Stores<T> {
	logger.info('create storage');
	const eventBus = new EventBusManager();
	const crypt = new Crypto('esgikey');
	const storage = new DexieAsyncWrapper(['tableInfo', ...keys(stores)]);

	const out = {
		authenticate: (key) => {

		},
		drop: () => {
			return storage.instance.pipe(map(instance => {
				instance.delete();
			}));
		},
	} as Stores<T>;


	eventBus.subscribe(StoreExpiredEvent, (args) => {
		try {
			// let store: StoreInstanceImpl<any>;
			// if (typeof args.store === 'string') {
			// 	store = (storage[args.store] as StoreConstructor<any>)() as StoreInstanceImpl<any>;
			// } else {
			// 	store = args.store() as StoreInstanceImpl<any>;
			// }
			//
			// if(store) {
			// 	return store.invalidateCache().subscribe();
			// } else {
			// 	console.error(`Handling StoreExpiredEvent. Not found store:`, args.store);
			// }
		} catch (e) {
			console.error(e);
		}
	});

	const tableInfo = new CryptoTable<TableInfoSchema>('tableInfo', storage, crypt);

	for (const key of keys(stores) as Extract<keyof T, string>[]) {
		const store = stores[key];
		const storeInstance = new StoreInstanceImpl(crypt, storage, key, store, tableInfo);
		out[key] = (() => new DisposableStore(storeInstance)) as any;
		(out[key] as any)._symbol = StoreConstructorSymbol;
	}

	return out;
}