import {Snackbar} from '@esgi/ui/snackbar';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useService} from '@esgi/core/service';
import {UserType, useUser} from '@esgi/core/authentication';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

import {Layout} from './styled';
import {Header} from './components/header';
import {Filter} from './components/filter';
import {Table} from './components/table';

import {StudentManagerService} from './service';

import {useFilter} from './hooks/use-filter';
import {useStudentsFilter} from './hooks/use-students-filter';
import {useStudentsSelection} from './hooks/use-students-selection';

import {AssignDrawer} from './drawers/assign';
import {DeleteDialog} from './dialogs/delete';
import {StudentsLimitDialog} from './dialogs/students-limit';
import {UnassignDialog} from './dialogs/unassign';
import {MultipleAssignDrawer} from './drawers/multiple-assign';

//TODO Rewrite
import {DrawerMode, useSwitchDrawerMode} from '@esgi/main/features/teacher/home';
import {GradeLevelModel} from './types';
import {MultipleUnAssignDialog} from './dialogs/multiple-unassign';
import {StudentProfileDrawer, TeacherDrawerName} from '@esgi/main/features/teacher/drawers';

export default function () {
	const user = useUser();
	const snackbarRef = Snackbar.useRef();

	const [openAssign, setOpenAssign] = useState(false);
	const [openAssignMultiple, setOpenAssignMultiple] = useState(false);
	const [showUnAssignDialog, setShowUnAssignDialog] = useState(false);
	const [showMultipleUnAssignDialog, setShowMultipleUnAssignDialog] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showStudentsLimitDialog, setShowStudentsLimitDialog] = useState(false);

	const service = useService(StudentManagerService);

	useEffect(() => {
		service.init();

		return () => service.updateStorage();
	}, []);

	const data = useBehaviorSubject(service.data);
	const teachers = useBehaviorSubject(service.teachers);
	const grades = useBehaviorSubject(service.gradeLevels);

	const students = useBehaviorSubject(service.students);
	const schools = useBehaviorSubject(service.schools);
	const classes = useBehaviorSubject(service.classes);
	const groups = useBehaviorSubject(service.groups);
	const specialists = useBehaviorSubject(service.specialists);
	const [filter, setFilter] = useFilter();
	const {filteredStudents, filteredWithoutGradeStudents} = useStudentsFilter(
		students,
		filter
	);
	const filteredGrades: GradeLevelModel[] = useMemo(
		() =>
			filteredWithoutGradeStudents
				.reduce((acc, {gradeLevelID}) => {
					if (acc.find((item) => item.gradeLevelID === gradeLevelID)) {
						return acc;
					} else {
						return [
							...acc,
							grades.find((item) => item.gradeLevelID === gradeLevelID),
						];
					}
				}, [])
				.sort((a, b) => a.gradeLevelID - b.gradeLevelID),
		[filteredWithoutGradeStudents, grades]
	);

	const {selectedStudents, select, toggleAll, unselectAll} =
		useStudentsSelection(filteredStudents);

	const teacherInfo = useMemo(() => {
		return teachers.find(t => t.teacherID === user?.userID);
	}, [user, teachers]);

	const onAssignClicked = useCallback(() => {
		const classIDs = teacherInfo.classes.map(x => x.classID);
		const currentStudents = students.filter(x => x.classIDs.some(s => classIDs.includes(s)));

		const newStudents = selectedStudents.filter(
			selectedStudent => !currentStudents.some(
				currentStudent => currentStudent.studentID === selectedStudent.studentID
			)
		);
		const totalStudents = currentStudents.length + newStudents.length;
		const isLimitExceeded = teacherInfo.studentsMax < totalStudents;

		if (isLimitExceeded) {
			setShowStudentsLimitDialog(true);
			return;
		}
		if (selectedStudents.length === 1) {
			setOpenAssign(true);
		} else {
			setOpenAssignMultiple(true);
		}
	}, [selectedStudents, students, teacherInfo]);

	const onUnAssignClicked = useCallback(() => {
		if (selectedStudents.length === 1) {
			setShowUnAssignDialog(true);
		} else {
			setShowMultipleUnAssignDialog(true);
		}
	}, [selectedStudents]);

	const canAddStudents = useMemo(() => {
		if (!data) {
			return false;
		}

		if (user.userType !== UserType.D) {
			return data.canAddStudents;
		}
		return true;
	}, [data, user.userType]);

	const switchProfile = useSwitchDrawerMode({
		drawerName: TeacherDrawerName.TeacherStudentViaProfile,
	});

	const showSpecialistColumns = specialists.length > 0;

	const loaded = !!data;
	return (
		<Layout data-cy='student-manager'>
			<StudentProfileDrawer/>
			<Header
				filter={filter}
				loaded={loaded}
				updateFilter={setFilter}
				onExportClick={() => service.export(filteredStudents)}
				onAddClick={() => switchProfile({mode: DrawerMode.Create})}
				canAddStudents={canAddStudents}
			/>
			<Filter
				filter={filter}
				loaded={loaded}
				classes={classes}
				teachers={teachers}
				grades={filteredGrades}
				groups={groups}
				selectedStudents={selectedStudents}
				updateFilter={setFilter}
				agreementLevel={user?.agreementLevelCode}
				onAssignClicked={onAssignClicked}
				onDeleteClicked={() => setShowDeleteDialog(true)}
				onUnassignClicked={onUnAssignClicked}
			/>
			<Table
				selectedStudents={selectedStudents}
				onAllSelect={toggleAll}
				loaded={loaded}
				schools={schools}
				gradeLevels={grades}
				onStudentSelect={select}
				students={filteredStudents}
				agreementLevel={user?.agreementLevelCode}
				service={service}
				showSpecialistColumns={showSpecialistColumns}
				specialists={specialists}
			/>
			<Snackbar snackbarRef={snackbarRef} />

			{openAssign && (
				<AssignDrawer
					service={service}
					snackbarRef={snackbarRef}
					classes={classes}
					student={selectedStudents[0]}
					onClose={() => setOpenAssign(false)}
				/>
			)}

			{openAssignMultiple && (
				<MultipleAssignDrawer
					classes={classes}
					service={service}
					snackbarRef={snackbarRef}
					students={selectedStudents}
					onClose={() => setOpenAssignMultiple(false)}
				/>
			)}

			{showUnAssignDialog && (
				<UnassignDialog
					classes={classes}
					snackbarRef={snackbarRef}
					service={service}
					student={selectedStudents[0]}
					onSuccess={unselectAll}
					onClose={() => {
						setShowUnAssignDialog(false);
					}}
				/>
			)}

			{showMultipleUnAssignDialog && (
				<MultipleUnAssignDialog
					classes={classes}
					snackbarRef={snackbarRef}
					service={service}
					students={selectedStudents}
					onSuccess={unselectAll}
					onClose={() => {
						setShowMultipleUnAssignDialog(false);
					}}
				/>
			)}

			{showDeleteDialog && (
				<DeleteDialog
					students={selectedStudents}
					snackbarRef={snackbarRef}
					service={service}
					onClose={() => setShowDeleteDialog(false)}
				/>
			)}

			{showStudentsLimitDialog && (
				<StudentsLimitDialog
					studentsLimit={teacherInfo.studentsMax}
					onClose={() => setShowStudentsLimitDialog(false)}
				/>
			)}
		</Layout>
	);
}
