import {useCallback, useMemo, useState} from 'react';
import {useOpenAssignment} from '../../hooks/use-open-assignment';
import {useOpenAssignmentList} from '../../hooks/use-open-assignment-list';
import {Student, storage, useStore} from '@esgi/main/libs/store';
import {SubjectWithSelAssesTests, Test, TestScreenType, TestScreenTypeContract} from '../types';
import {uniq} from 'underscore';

export function useCommonAssignmentVariantState() {
	const navigateToAssignmentsList = useOpenAssignmentList();
	const navigateToAssignment = useOpenAssignment();

	const [classesList, updateClassesList, isClassesListLoaded] = useStore(storage.classes);
	const [groupsList, updateGroupsList, isGroupsListLoaded] = useStore(storage.groups);
	const [studentsList, updateStudentsList, isStudentsListLoaded] = useStore(storage.students);
	const [subjectsList, updateSubjectsList, isSubjectsListLoaded] = useStore(storage.subjects);

	const [isAssignmentSettingsValid, setIsAssignmentSettingsValid] = useState(false);
	const [assignmentName, setAssignmentName] = useState('');
	const [assignmentDescription, setAssignmentDescription] = useState('');

	const [selectedStudentsIDs, setSelectedStudentsIDs] = useState<Student['id'][]>([]);

	const [selectedTestsIDs, setSelectedTestsIDs] = useState<Test['id'][]>([]);

	const subjectsWithSelAssesTests = useMemo(
		() =>
			subjectsList.reduce((currentState, {id: subjectID, tests: subjectTests, ...iteratedSubjectData}) => {
				const tests = subjectTests.filter(({testScreenTypes}) =>
					testScreenTypes.includes(TestScreenType.SelfAssessment as unknown as TestScreenTypeContract),
				);

				if (tests.length) {
					currentState[subjectID] = {
						...iteratedSubjectData,
						tests,
					};
				}

				return currentState;
			}, {} as SubjectWithSelAssesTests),
		[subjectsList],
	);

	const allSelfAsessTests = useMemo(
		() =>
			uniq(
				Object.values(subjectsWithSelAssesTests).flatMap(({tests}) => tests),
				({id}) => id,
			),
		[subjectsWithSelAssesTests],
	);

	const onToggleSelectedStudentsIDs = useCallback((selectedStudentId: Student['id']) => {
		setSelectedStudentsIDs((currentState) =>
			currentState.includes(selectedStudentId)
				? currentState.filter((id) => id !== selectedStudentId)
				: [...currentState, selectedStudentId],
		);
	}, []);

	const removeStudentIDFromState = useCallback((studentID: Student['id']) => {
		setSelectedStudentsIDs((currentState) => currentState.filter((id) => id !== studentID));
	}, []);

	const onClearAllSelectedStudentsIDs = useCallback(() => {
		setSelectedStudentsIDs([]);
	}, []);

	const removeTestIDFromState = useCallback((testID: Test['id']) => {
		setSelectedTestsIDs((currentState) => currentState.filter((id) => id !== testID));
	}, []);

	const onToggleSelectedTestsIDs = useCallback((testID: Test['id']) => {
		setSelectedTestsIDs((currentState) =>
			currentState.includes(testID) ? currentState.filter((id) => id !== testID) : [...currentState, testID],
		);
	}, []);

	const onSwapTests = useCallback((index1: number, index2: number) => {
		setSelectedTestsIDs((currentState) => {
			const copiedState = [...currentState];

			if (copiedState[index1] && copiedState[index2]) {
				[copiedState[index1], copiedState[index2]] = [copiedState[index2]!, copiedState[index1]!];
			}

			return copiedState;
		});
	}, []);

	const isStoreDataLoaded = useMemo(
		() => isClassesListLoaded && isGroupsListLoaded && isStudentsListLoaded && isSubjectsListLoaded,
		[isClassesListLoaded, isGroupsListLoaded, isStudentsListLoaded, isSubjectsListLoaded],
	);

	const toggleEntityStudents = useCallback(
		({studentsIDs, method}: {studentsIDs: Student['id'][]; method: 'add' | 'remove'}) => {
			if (method === 'add') {
				setSelectedStudentsIDs((currentState) => [...currentState, ...studentsIDs]);

				return;
			}

			if (method === 'remove') {
				setSelectedStudentsIDs((currentState) => currentState.filter((studentID) => !studentsIDs.includes(studentID)));
			}
		},
		[],
	);

	return {
		isAssignmentSettingsValid,
		selectedStudentsIDs,
		selectedTestsIDs,
		onToggleSelectedStudentsIDs,
		onToggleSelectedTestsIDs,
		navigateToAssignmentsList,
		assignmentName,
		setAssignmentName,
		assignmentDescription,
		setAssignmentDescription,
		setIsAssignmentSettingsValid,
		onClearAllSelectedStudentsIDs,
		removeStudentIDFromState,
		onSwapTests,
		removeTestIDFromState,
		navigateToAssignment,
		setSelectedStudentsIDs,
		setSelectedTestsIDs,
		isStoreDataLoaded,
		subjectsWithSelAssesTests,
		allSelfAsessTests,
		toggleEntityStudents,
	};
}
