import {Content} from './index.styled';
import {Card} from '../card';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import React, {useEffect, useRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {NoTestsMessage} from '../../constants';
import {CardViewType, FilterScope, TestModel} from '../../service/types';
import {GradeLevelsMap} from '@esgi/main/kits/common';
import {TestType} from '@esgi/main/libs/core';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {useUser} from '@esgi/core/authentication';
import {SubjectTab} from '@esgi/main/libs/store';
import {NoItems, Skeleton} from '@esgi/main/features/standards-drawer';

type Props = BaseComponentProps & {
	selectedStandardType: number;
	selectedStandardsIDs: number[];
	tests: Array<TestModel & {subjects: SubjectTab[]}>,
	selectedTestIDs: TestModel['testID'][],
	showOnlySelectedTests: boolean,
	onTestSelect: (testID: TestModel['testID']) => void,
	onAddToFavoriteClick: (test: TestModel) => void,
	showTestDetails: (testID: TestModel['testID'], testType: TestType) => (changedTest: Partial<TestModel>) => void,
	onNextPage: VoidFunction,
	isLoaded: boolean,
	scope: FilterScope,
	cardView: CardViewType,
	isSmallScreen: boolean,
	osRef: React.MutableRefObject<OverlayScrollbarsComponentRef<'div'>>;
}

export function TestsList({
	selectedStandardsIDs,
	selectedStandardType,
	tests,
	selectedTestIDs,
	onTestSelect,
	onAddToFavoriteClick,
	onNextPage,
	isLoaded,
	scope,
	cardView,
	showTestDetails,
	osRef,
	isSmallScreen,
}: Props) {
	const user = useUser();
	const observerTarget: React.RefObject<HTMLDivElement> = useRef();

	useEffect(() => {
		if (!observerTarget.current) {
			return;
		}

		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0]?.isIntersecting) {
					onNextPage();
				}
			},
			{threshold: 0},
		);

		const target = observerTarget.current;

		if (target) {
			observer.observe(target);
		}

		return () => observer.unobserve(target);
	}, [onNextPage]);

	if (isLoaded && !tests.length) {
		if (scope === FilterScope.DistrictTests) {
			const userIsNotLinked = user?.agreementLevelCode === 'T';

			return <NoItems message={NoTestsMessage[userIsNotLinked ? FilterScope.DistrictTests : FilterScope.AllTests]}/>;
		}

		return (
			<NoItems message={NoTestsMessage[scope]}/>
		);
	}

	return (
		<OverlayScrollbarsComponent ref={osRef} defer options={{scrollbars: {autoHide: 'leave'}}}>
			<Content gridView={cardView === CardViewType.Grid}>
				{tests.map((test) =>
					<Card
						{...test}
						selectedStandardsIDs={selectedStandardsIDs}
						selectedStandardType={selectedStandardType}
						key={test.testID + test.createDate}
						selected={selectedTestIDs.includes(test.testID)}
						cardView={cardView}
						onTestSelect={onTestSelect}
						showTestDetails={() => showTestDetails(test.testID, test.type)(null)}
						onAddToFavoriteClick={() => onAddToFavoriteClick(test)}
						gradeLevels={test.gradeLevels.map((level) => GradeLevelsMap[level]?.shortName ?? '')}
						showAddToFavorites={scope !== FilterScope.DraftTests}
						isHidden={test.hidden}
						isSmallScreen={isSmallScreen}
					/>)}
				{!isLoaded && <Skeleton/>}
			</Content>
			<div ref={observerTarget} />
		</OverlayScrollbarsComponent>
	);
}