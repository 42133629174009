import {BaseComponentProps} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {ComponentProps, ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = BaseComponentProps &
	ComponentPropsWithoutRef<'div'> &
	ComponentProps<typeof GridBox> & {
		transcript: string;
	};

export const InfoWithTranscript = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'test-dashboard-statistics-info-with-transcript', css = {}, transcript, children, ...props},
		forwardedRef,
	) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
			{children}
			<Text size='small' font='mono' color='neutral56'>
				{transcript}
			</Text>
		</Container>
	),
);
