import {ContextMenu, Tooltip} from '@esgi/ui';
import {History} from '@esgi/ui/icons';
import {ButtonIconable} from '../../../header/components/button-iconable.styled';
import {AllSessionsItem, ContextMenuItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {TestSession} from '../../../../../../types';
import {TimeStamp} from '../../../../../../kit';
import {AnswersInfo} from '../../../answers-info';
import {Box, GridBox} from '@esgi/ui/layout';
import {MouseEvent, useCallback, useState} from 'react';
import {isNull} from 'underscore';
import {TestSessionDetailsAlert} from '@esgi/main/features/teacher/test-session-details';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {useTestSectionContext} from '../../../../context/test-section-context';

const maxVisibleSessions = 4;

type Props = {
	lastTestSessions: TestSession[];
	sessionsCount: number;
	maxScore: number;
	dataCy?: string;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
	testID: number;
};

export function SessionsContextMenu({
	dataCy = 'session-context-menu',
	maxScore,
	sessionsCount,
	lastTestSessions,
	subjectID,
	classID,
	testID,
}: Props) {
	const {allSessionsClick} = useTestSectionContext();

	const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);

	const [openedTestSessionDetailsID, setOpenedTestSessionDetailsID] = useState<number | null>(null);

	const onCloseTestSessionDetailsAlert = useCallback(() => {
		setOpenedTestSessionDetailsID(null);
	}, []);

	const handleSessionClick = useCallback((event: MouseEvent<HTMLDivElement>, sessionID: number) => {
		event.stopPropagation();

		setIsContextMenuOpened(false);

		setOpenedTestSessionDetailsID(sessionID);
	}, []);

	return (
		<Box
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<ContextMenu.Root open={isContextMenuOpened} onOpenChange={setIsContextMenuOpened} data-cy={dataCy}>
				<ContextMenu.Trigger dataCy={`${dataCy}-trigger`}>
					<GridBox align='center'>
						<ButtonIconable withBackgroundHover disabled={!lastTestSessions.length}>
							<Tooltip delayDuration={400}>
								<Tooltip.Trigger>
									<History />
								</Tooltip.Trigger>
								<Tooltip.Content>
									<Text size='small'>Test Sessions Summary</Text>
								</Tooltip.Content>
							</Tooltip>
						</ButtonIconable>
					</GridBox>
				</ContextMenu.Trigger>
				<ContextMenu.Content dataCy={`${dataCy}-content`}>
					<ContextMenu.Group dataCy={`${dataCy}-group`}>
						{lastTestSessions.slice(0, maxVisibleSessions).map(({id, testDate, correctAnswers}) => (
							<ContextMenuItem key={id} dataCy={`${dataCy}-item`} onClick={(event) => handleSessionClick(event, id)}>
								<GridBox flow='column' gap='4' align='center'>
									<TimeStamp dateTime={testDate} dataCy={`${dataCy}-time-stamp`} />
									<AnswersInfo value={correctAnswers} maxValue={maxScore} dataCy={`${dataCy}-answers-info`} />
								</GridBox>
							</ContextMenuItem>
						))}
						{sessionsCount > maxVisibleSessions && (
							<AllSessionsItem data-cy={`${dataCy}-all-sessions-button`} onClick={() => allSessionsClick({testID})}>
								<History />
								<Text size='small' data-cy={`${dataCy}-all-sessions-button-label`}>
									All Sessions...
								</Text>
							</AllSessionsItem>
						)}
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>
			{!isNull(openedTestSessionDetailsID) && (
				<TestSessionDetailsAlert
					testSessionID={openedTestSessionDetailsID}
					onAlertClose={onCloseTestSessionDetailsAlert}
					classID={classID}
					subjectID={subjectID}
				/>
			)}
		</Box>
	);
}
